/**
 * Copyright(c) 2020 Mozanta Technologies Private Ltd.
 * 
 * All rights reserved.                
 * 
 * This software is the confidential and proprietary information of Mozanta ("Confidential
 * Information"). You shall not disclose such Confidential Information and shall use it only in
 * accordance with the terms of the contract agreement you entered into with Mozanta.
 * 
 * @author Indrajith C
 */

.uploadImagePreview {
    max-width: 150px;
    margin-right: 1rem;
    position: relative;
  }
.uploadImagePreviewIcon {
    max-width: 150px;
}
.uploadImagePreviewClose{
  display: none;
}
.uploadImagePreview:hover .uploadImagePreviewClose {
  display: flex;
  border-radius: 50%;
  position: absolute;
  top: -4px;
  right: -4px;
  background: #b3b3b3;
  font-size: 11px;
  padding: 0px 5px;
  font-weight: 600;
  cursor: pointer;
}

.uploadImagePreview:last-child {
      margin-right: 0;
  }

