.ContentOuter h6 {
  font-size: 14px;
}
.ProductListOuter {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  list-style: none;
  padding: 0;
  margin: 0;
}
.ProductListOuter .ProductItem {
  display: flex;
  flex-direction: column;
  margin-right: 2rem;
  margin-bottom: 2rem;
}
.ProductListOuter .ProductItem figure {
  width: 100px;
  height: 100px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 0.5rem;
  background-color: #f2f2f2;
  position: relative;
}
.ProductListOuter .ProductItem figure .BtnClose {
  position: absolute;
  right: -10px;
  top: -10px;
  z-index: 10;
}
.ProductListOuter .ProductItem figure>img {
  max-width: 100%;
  max-height: 100%;
}
.ProductListOuter .ProductItem p {
  margin: 0;
}
