/* block 1 */

.block1 {
    padding-bottom: 10px;
  }
  
  /** =========== ACTION AREA STYLES ============== **/
  .block1 .areaActionRight {
    display: flex;
    justify-content: flex-end;
  }
  
  .block1 .borderRadius2 {
    border-radius: 0.125rem;
  }
  
  .block1 .areaActionRight > div:first-child {
    margin-right: 0.3rem;
  }
  .block2 .areaActionRight > div:last-child {
    display: flex;
  }
  
  /** ========= SEARCH FILED ================ **/
  .block1 .areaActionRight .search {
    border: 1px solid #DBDBDB;
    border-radius: 5px;
    height: 40px;
  }
  
  .block1 .areaActionRight .search input {
    border: 0;
    width: 100%;
    height: calc(1.5em + 0.75rem + 0px);
  }
  
  .block1 .areaActionRight .search input:focus, 
  .block1 .areaActionRight .search input:active {
    border: 0;
    outline: 0;
    box-shadow: none;
  }
  
  .block1 .areaActionRight .search div {
    border: 0;
    border-radius: 0;
    background-color: inherit;
    padding-left: 0.6rem;
    padding-right: 0.6rem;
  }
  
  /* block2 */
  
  .block2 button {
    background: none;
    color: black;
    border-radius: 5px;
    border: 1px solid #e0e0e0;
  }
  
  .block2 .dropdownBtn {
    padding: 0px;
  }
  
  .block2 button:after {
    border: solid #707070;
    border-width: 0 2px 2px 0;
    display: inline-block;
    padding: 2px;
    transform: rotate(45deg);
    -webkit-transform: rotate(45deg);
    margin-top: 8px;
  }
  
  .block2 .dropdownBtn button {
    float: right !important;
  }
  .tableWrapper {
    min-height: calc(100vh - 342px);
  }
  
  .searchContainer {
    display: flex;
    align-items: center;
  }
  
  /***** ===================== PAGINATION ======================= ******/
  .pagination {
  }
  
  .pagination .item {
    height: 25px;
    width: 25px;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
  }
  
  .pagination .arrowLeft,
  .pagination .arrowRight {
    border-radius: 50%;
    border: 2px solid;
  }
  
  .pagination .arrowLeft > span,
  .pagination .arrowRight > span {
    border-bottom: 2px solid;
    border-right: 2px solid;
    width: 8px;
    height: 8px;
    margin-top: -0.5px;
  }
  
  .pagination .arrowLeft > span {
    transform: rotate(127deg);
    margin-right: -2.5px;
  }
  
  .pagination .arrowRight > span {
    transform: rotate(315deg);
    margin-left: -2.5px;
  }
  
  .pagination .arrowLeft,
  .pagination .arrowRight,
  .pagination .item[active] {
    color: #e91e63;
    font-weight: 600;
  }
  
  .pagination .item[active] {
    cursor: default;
  }
  
  .pagination .item[disabled] {
    cursor: not-allowed;
    color: #5d5d5d;
  }
  
  .borderRadius2 {
    border-radius: 0.125rem !important;
  }
  
  .btnColor {
    background: #229E79 !important;
    border-color: #229E79 !important;
  }
  
  .block2 {
    display: flex;
    justify-content: center;
  }