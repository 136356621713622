.createUserLayout .BtnSecondary .Btn,
.createUserLayout .BtnSecondary .Btn:active,
.createUserLayout .BtnSecondary .Btn:focus {
  background-color: #fff !important;
  color: #000 !important;
  border: 1px solid #ced4da;
  border-radius: 2px;
  box-shadow: none !important;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.createUserLayout .DropdownMenu {
  min-width: 100%;
  padding: 0;
  background: #f9f9f9;
  box-shadow: 2px 2px 2px rgba(0, 0, 0, 0.08);
}
.createUserLayout .DropdownMenu .DropdownItem {
  padding: 0.3rem 1rem 0.5rem;
  cursor: pointer;
}
.createUserLayout .DropdownMenu .DropdownItem:hover {
  background-color: #edf0f5;
}
.createUserLayout .DropdownMenu .DropdownItem:focus {
  outline: 0;
}
.createUserLayout .DropdownMenu .DropdownItem:active {
  background-color: #edf0f5;
  color: #000;
  outline: 0;
}
.createUserLayout .FormCheckInput {
  margin-top: 0.4rem;
}
.createUserLayout .btnTertiary {
  color: #fff !important;
  background-color: #17a3b8 !important;
  border-color: #17a3b8 !important;
}
.createUserLayout .BtnOk {
  padding: 0.2rem 0.5rem 0.4rem;
  margin: 0.25rem 0;
  border-radius: 2px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #17a3b8;
  border-color: #17a3b8;
}
.createUserLayout .errorText {
  width: 100%;
  margin-top: 0.25rem;
  font-size: 80%;
  color: #dc3545;
}
