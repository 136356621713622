
/**
 * Copyright(c) 2020 Mozanta Technologies Private Ltd.
 *
 * All rights reserved.
 *
 * This software is the confidential and proprietary information of Mozanta
 * ("Confidential Information"). You shall not disclose such Confidential
 * Information and shall use it only in accordance with the terms of the
 * contract agreement you entered into with Mozanta.
 *
 * PermissionManagement.module.css
 *
 * @author Naseef O
 *
 */



/* block 2 */

.block2{
    padding-top: 20px;
    padding-bottom: 20px;
}

.block2 .buttonAdd{
  color: #ffffff;
  width: 113px;
  height: 35px;
  border-radius: 2px;
  background-color: #e91e63;
  border: 0px;
  float: right;
}

.tableWrapper{
  min-height: calc(100vh - 373px);
}