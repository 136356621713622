/**
 * Copyright(c) 2020 Mozanta Technologies Private Ltd.
 * 
 * All rights reserved.                
 * 
 * This software is the confidential and proprietary information of Mozanta ("Confidential
 * Information"). You shall not disclose such Confidential Information and shall use it only in
 * accordance with the terms of the contract agreement you entered into with Mozanta.
 * 
 * @author Indrajith C
 */

.liveBtnWrapper .liveBtn{
  border: none;
  border-radius: 2px;
  text-transform: uppercase;
}

.block2 {
  padding-top: 20px;
  padding-bottom: 20px;
}

/** =========== ACTION AREA STYLES ============== **/
.block2 .areaActionRight {
  display: flex;
  justify-content: flex-end;
}

.block2 .borderRadius2 {
  border-radius: 0.125rem;
}

.block2 .areaActionRight > div:first-child {
  margin-right: 0.3rem;
}
.block2 .areaActionRight > div:last-child {
  display: flex;
}

/** ========= SEARCH FILED ================ **/
.block2 .areaActionRight .search {
  border: 1px solid;
  border-radius: 0.125rem;
}

.block2 .areaActionRight .search input {
  border: 0;
  width: 100%;
  height: calc(1.5em + 0.75rem + 0px);
}

.block2 .areaActionRight .search div {
  border: 0;
  border-radius: 0;
  background-color: inherit;
  padding-left: 0.6rem;
  padding-right: 0.35rem;
}

/***** ========== MAIN ACTION AREA ============ **********/
.block3 {
  padding: 1.25rem 0;
}

/***** ============== MAIN ACTION COMPONENT ========= *******/
.block3 .categoryTree {
  position: relative;
}

/***** ============== ACTION COMPONENT HEADER ========= *******/
.block3 .categoryTree .categoryTreeHead {
  padding-bottom: 1rem;
}

.block3 .categoryTree .categoryTreeHead .componentTitle {
  font-weight: 600;
  font-size: 16x;
  color: #212121;
}

/***** ============== ACTION COMPONENT BODY ========= *******/
.block3 .categoryTree .categoryTreeBody .categoryTreeTable thead {
  background-color: #edf0f5;
}

.block3 .categoryTree .categoryTreeBody .categoryTreeItem {
  display: flex;
  flex-direction: column;
}
.tableWrapper{
  min-height: calc(100vh - 385px);
}