/**
 * Copyright(c) 2020 Mozanta Technologies Private Ltd.
 * 
 * All rights reserved.
 * 
 * This software is the confidential and proprietary information of Mozanta ("Confidential
 * Information"). You shall not disclose such Confidential Information and shall use it only in
 * accordance with the terms of the contract agreement you entered into with Mozanta.
 * 
 * @author Indrajith C
 * 
 */


.dropDown button:hover,
.dropDown button:active {
    background-color: #ffff;
    color: black;
    border: solid 1px #313131;

}

.dropDown button {
    background-color: #ffff !important;
    color: black !important;
    box-shadow: none !important;
    border: solid 1px #313131;
    border-radius: 2px;
    text-align: left;
    display: flex;
    align-items: center;
}

.dropDown  button[disabled] {
  background-color: #e9ecef !important;
}

.dropDown[disabled] button,
.dropDown.disabled button {
    background-color: #E9ECEF !important;
    border: solid 1px #d0d3d6!important;
}

.dropDown button>span[disabled] {
    color: #767f86 !important;
}

.dropDown button::after {
    margin-left: auto !important;
}

.dropDown .dropdownItem {
    border: none !important;
    padding: 0.5rem 0.75rem !important;
    cursor: default !important;
}

.dropDown .dropdownItem:hover {
    color: #229E79 !important;
    background-color: #f9f9f9 !important;
}

.dropDown .dropdownItem:focus {
    outline: none !important;
    box-shadow: none !important;
}

.dropDown .dropdownItem[active] {
    color: #f9f9f9 !important;
    background-color: #229E79 !important;
    border-top: 1px solid white !important;
}


.dropDown .dropdownItem[disabled] {
    color: #767f86 !important;
    background-color: #f9f9f9 !important;
    cursor: not-allowed !important;
}

.dropDown .dropdownWrapper {
    width: 100% !important;
    border: none !important;
    box-shadow: 0px 3px 10px -4px #ccc
}

.dropDown.invalid>button[type="button"] {
    border-color: #dc3545;
    padding-right: calc(1.5em + 0.75rem);
    background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='12' height='12' fill='none' stroke='%23dc3545' viewBox='0 0 12 12'%3e%3ccircle cx='6' cy='6' r='4.5'/%3e%3cpath stroke-linejoin='round' d='M5.8 3.6h.4L6 6.5z'/%3e%3ccircle cx='6' cy='8.2' r='.6' fill='%23dc3545' stroke='none'/%3e%3c/svg%3e");
    background-repeat: no-repeat;
    background-position: right calc(0.375em + 0.1875rem) center;
    background-size: calc(0.75em + 0.375rem) calc(0.75em + 0.375rem);
}