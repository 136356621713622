.ContentOuter h6 {
  font-size: 14px;
}
.BtnClose {
  position: absolute;
  right: 6px;
  top: 7px;
  z-index: 10;
}

.block3 .product .productBody .productTable .productImage {
    max-height: 50px;
  }

  /***** ===================== PAGINATION ======================= ******/
  .block3 .product .pagination { }
  
  .block3 .product .pagination .item {
    height: 25px;
    width: 25px;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
  }

  .block3 .product .pagination .arrowLeft, .block3 .product .pagination .arrowRight {
    border-radius: 50%;
    border: 2px solid;
  }

  .block3 .product .pagination .arrowLeft > span, .block3 .product .pagination .arrowRight > span {
    border-bottom: 2px solid;
    border-right: 2px solid;
    width: 8px;
    height: 8px; 
    margin-top: -0.5px;
  }

  .block3 .product .pagination .arrowLeft > span{ 
    transform: rotate(127deg); 
    margin-right: -2.5px;
  }

  .block3 .product .pagination .arrowRight > span{ 
    transform: rotate(315deg); 
    margin-left: -2.5px;
  }

  .block3 .product .pagination .arrowLeft , .block3 .product .pagination .arrowRight, .block3 .product .pagination .item[active] {
    color: #e91e63;
    font-weight: 600;
  }

  .block3 .product .pagination .item[active] {
    cursor: default;
  }

  .block3 .product .pagination .item[disabled] {
    cursor: not-allowed;
    color: #5d5d5d;
  }
  .tableWrapper{
    min-height: calc(100vh - 387px);
  }