/**
 * Copyright(c) 2020 Mozanta Technologies Private Ltd.
 * 
 * All rights reserved.                
 * 
 * This software is the confidential and proprietary information of Mozanta ("Confidential
 * Information"). You shall not disclose such Confidential Information and shall use it only in
 * accordance with the terms of the contract agreement you entered into with Mozanta.
 * 
 * @author Aardra S
 */

 .block1 .primaryColor {
  background: #17a2b8 !important;
  border-color: #17a2b8 !important;
}

.block1 .borderRadius {
  border-radius: 0.125rem;
}

.block1 .btn {
  min-width: 11rem;
}

  /***** ===================== PAGINATION ======================= ******/

  .pagination .item {
   height: 25px;
   width: 25px;
   display: flex;
   justify-content: center;
   align-items: center;
   cursor: pointer;
 }

  .pagination .arrowLeft,  .pagination .arrowRight {
   border-radius: 50%;
   border: 2px solid;
 }

  .pagination .arrowLeft > span,  .pagination .arrowRight > span {
   border-bottom: 2px solid;
   border-right: 2px solid;
   width: 8px;
   height: 8px; 
   margin-top: -0.5px;
 }

  .pagination .arrowLeft > span{ 
   transform: rotate(127deg); 
   margin-right: -2.5px;
 }

  .pagination .arrowRight > span{ 
   transform: rotate(315deg); 
   margin-left: -2.5px;
 }

  .pagination .arrowLeft ,  .pagination .arrowRight,  .pagination .item[active] {
   color: #e91e63;
   font-weight: 600;
 }

  .pagination .item[active] {
   cursor: default;
 }

  .pagination .item[disabled] {
   cursor: not-allowed;
   color: #5d5d5d;
 }
  