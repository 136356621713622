/**
 * Copyright(c) 2020 Mozanta Technologies Private Ltd.
 * 
 * All rights reserved.
 * 
 * This software is the confidential and proprietary information of Mozanta ("Confidential
 * Information"). You shall not disclose such Confidential Information and shall use it only in
 * accordance with the terms of the contract agreement you entered into with Mozanta.
 * 
 * @author Indrajith C
 * 
 */
 .block1{
  padding-top: 20px;
  padding-bottom: 20px;
}

img.icon {
  width: 20px;
}

.cursorMove { 
  cursor: move;
}

.blockWrapper{
  min-height: calc(100vh - 385px);
}

.loadingOverlay {
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  z-index: 99;
  background: rgba(33, 37, 41, 0.01);
}

/* ============= sub menu collapse icon ===================== */
.collapseIcon {
   min-width: 23px;
}

.collapseIcon > img {
  width: 15px;
  height: 15px;
  -webkit-transition: 0.6s ease-out;
  -moz-transition:  0.6s ease-out;
  transition:  0.6s ease-out;
}

.openCollapse {
  -webkit-transform: rotateZ(90deg);
  -moz-transform: rotateZ(90deg);
  transform: rotateZ(90deg);
}

.closeCollapse { 
  -webkit-transform: rotateZ(-90deg);
  -moz-transform: rotateZ(-90deg);
  transform: rotateZ(-90deg);
}

/* ============= menu actions ===================== */
.categoryTreeItem .actions > span[disabled]  { 
  /* cursor: not-allowed; */
  pointer-events: none !important;
}

.categoryTreeItem .actions > span[disabled] > img {
  opacity: 0.4; 
}

/* ============= menu drag ===================== */
.dragContainer *[data-rbd-placeholder-context-id] {
  margin-bottom: 0.25rem !important; /* fix for extra margin  */
}

/* ============== menu columns =================== */
.menuColumnContainer {
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  overflow: hidden;
}

.menuColumnContainer > .menuColumn .dargHand {
  min-width: 3px;
  height: 100%;
  display: flex;
  background: #dfdfdf;
  cursor: col-resize;
  margin: 0 1px;
  border-color: #fff;
  border-radius: 10px;
}
 
.menuColumnContainer > .menuColumn:last-child > .dargHand,
.menuColumnContainer > .menuColumn.onDragging .dargHand {
  display: none !important;
}

.menuColumnContainer > .dummyResize {
  min-width: 0.20rem;
  display: flex; 
  cursor: col-resize; 
  border-color: #fff;
  border-radius: 20px;
  left: 0;
  position: absolute;
  top: 0;
  height: calc( 100% - 0.20rem);
  background: #2d2d2d73;
  display: none;
  z-index: 2;
}
 
.menuColumnContainer.cursorResizeChild, .menuColumnContainer.cursorResizeChild * {
cursor: col-resize;
}

.menuColumnContainer .cardHeader {
  display: flex;
  padding: 2px;
}

.menuColumnContainer .cardHeader > .icon {
  padding: 1px 2px;
  display: flex;
  cursor: pointer;
}

.menuColumnContainer .cardHeader > .move {
  cursor: grab;
}

.menuColumnContainer .cardHeader > .icon img{
  width: 14px;
  height: 14px;
}

.menuColumnContainer .cardHeader > .icon[disabled] {
  pointer-events: none;
  opacity: 0.5;
}

/* =============== sum menu item ==================== */

.subMenuItem {

}

.fullscreen {
  position: fixed;
    z-index: 222;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    background: #00000077;
    padding: 2rem;
    overflow: hidden;
    overflow-y: auto;
}

.fullscreen .fullscreenBody {
  background: #fff;
  border-radius: 5px;
}

.minimal .icon {
  width: 15px;
  display: flex;
  align-items: center;
}

.subMenuItem{
background: #fff;
}

.subMenuItem .smallText {
  font-size: 12px;
}

.subMenuItem .superSmallText {
  font-size: 10px !important;
  margin-top: -3px;
}

.subMenuItem .actions{
  position: absolute !important;
  white-space: nowrap;
  /* transform: translateX(0); */
  width: 0;
  transition: 0.6s;
  /* right: -80px; */
  right: -0.5rem;
  padding: 0 2px;
  justify-content: flex-end !important;
  overflow: hidden;
  top: 0;
  padding: 4px 2px 5px 0px;
  background: #fff;
}
.subMenuItem  .actions > span {
  background: #fff;
  display: flex;
}

.subMenuItem:hover .hideInHover {
  display: none;
}

.subMenuItem:hover .actions {
  /* position: absolute !important; */
  /* right: 0; */
  /* padding: 3px 2px; */
  width: unset;
  padding-right: 0.5rem !important;
}

.subMenuItem >span {
  min-height: 1.5rem;
}

.subMenuItem.minimal >span {
  min-height: 1rem;
}

