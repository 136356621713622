/**
 * Copyright(c) 2020 Mozanta Technologies Private Ltd.
 * 
 * All rights reserved.                
 * 
 * This software is the confidential and proprietary information of Mozanta ("Confidential
 * Information"). You shall not disclose such Confidential Information and shall use it only in
 * accordance with the terms of the contract agreement you entered into with Mozanta.
 * 
 * @author Aneesh K
 */

.block2 {
    padding-top: 20px;
    padding-bottom: 20px;
}

.borderRadius2 {
    border-radius: 0.125rem !important;
}

.secondaryColor {
    background-color: #757575 !important;
    border-color: #757575 !important;
}

.primaryColor {
    background: #e91e63 !important;
    border-color: #e91e63 !important;
}

.btn {
    min-width: 7rem;
}

.icon {
    width: 1.25rem;
}

/* ========== FORM AREA ============ */
.block3 {
    padding: 1.25rem;
}

.block3 .noLabelFormGroup {
    margin-top: 1.75rem;
    padding: 0.375rem 0.75rem;
}

.block3 .variantTable thead {
    background-color: #edf0f5;
}

.block3 .variantTable tbody .productImage {
    max-height: 50px;
}

.block3 .variantTable tbody .mw75 {
    max-width: 75px;
}



/* ============= PRODUCT TITLE ========= */
.titleTextContainer {
    padding: 0 2.1rem 0.125rem 2.1rem;
    margin: 0 -0.938rem;
}

.titleTextContainer .pageTitleText {
    text-align: left;
    color: #333333;
    padding-bottom: 17px;
}

.collapseIcon>img {
    width: 15px;
    height: 15px;
    -webkit-transition: 0.6s ease-out;
    -moz-transition: 0.6s ease-out;
    transition: 0.6s ease-out;
}

.openCollapse {
    -webkit-transform: rotateZ(90deg);
    -moz-transform: rotateZ(90deg);
    transform: rotateZ(90deg);
}

.closeCollapse {
    -webkit-transform: rotateZ(-90deg);
    -moz-transform: rotateZ(-90deg);
    transform: rotateZ(-90deg);
}