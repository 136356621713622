/**
 * Copyright(c) 2020 Mozanta Technologies Private Ltd.
 * 
 * All rights reserved.                
 * 
 * This software is the confidential and proprietary information of Mozanta ("Confidential
 * Information"). You shall not disclose such Confidential Information and shall use it only in
 * accordance with the terms of the contract agreement you entered into with Mozanta.
 * 
 * @author Ajay J A
 */

.collapseIcon>img {
  width: 15px;
  height: 15px;
  -webkit-transition: 0.6s ease-out;
  -moz-transition: 0.6s ease-out;
  transition: 0.6s ease-out;
  float: right;
}

.openCollapse {
  -webkit-transform: rotateZ(-90deg);
  -moz-transform: rotateZ(-90deg);
  transform: rotateZ(-90deg);
}

.closeCollapse {
  -webkit-transform: rotateZ(90deg);
  -moz-transform: rotateZ(90deg);
  transform: rotateZ(90deg);
}

.block2 {
  padding-top: 20px;
  padding-bottom: 20px;
}

.borderRadius2 {
  border-radius: 0.125rem !important;
}

.secondaryColor {
  background-color: #757575 !important;
  border-color: #757575 !important;
}

.primaryColor {
  background: #17a2b8 !important;
  border-color: #17a2b8 !important;
}


.btn {
  min-width: 7rem;
}

.removeCacheSwitch{
    margin-left: 0px;
}