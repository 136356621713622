
.footerWrapper{
    background: #ffffff;
    padding: 20px 10px 20px 10px;
    color: #5d5d5d;
}
.textUnderline{
    text-decoration: underline;
}

.menuWrap{
    display: block;
    width: 100%;
}

.headerWrapper{
    background: white;
    position: fixed;
    height: 75px;
    display: inline-grid;
    z-index: 100;
    left: 32px;
    right: 32px;
    top: 0px;
    padding: 10px 0px;
    border-bottom: 1px solid #f2f2f2;
    font-weight: 100;
}