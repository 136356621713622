/**
 * Copyright(c) 2021 Mozanta Technologies Private Ltd.
 * 
 * All rights reserved.                
 * 
 * This software is the confidential and proprietary information of Mozanta ("Confidential
 * Information"). You shall not disclose such Confidential Information and shall use it only in
 * accordance with the terms of the contract agreement you entered into with Mozanta.
 * 
 * @author Amritha
 */

 .block2 {
    padding-top: 20px;
    padding-bottom: 20px;
  }
  
  .block2 button {
    background: none;
    color: black;
    border-radius: 2px;
    border: 1px solid #e0e0e0;
  }
  
  .block2 .dropdownBtn {
    padding: 0px;
  }
  
  .block2 .dropdownBtn button {
    float: right !important;
  }
  
  .borderRadius2 {
    border-radius: 0.125rem !important;
  }
  
  .secondaryColor {
    background-color: #757575 !important;
    border-color: #757575 !important;
  }
  
  .primaryColor {
    background: #e91e63 !important;
    border-color: #e91e63 !important;
  }
  
  .btn {
    min-width: 7rem;
  }
  
  /* ============= PRODUCT TITLE ========= */
  .titleTextContainer {
    padding: 0 2.1rem 0.125rem 2.1rem;
    border-bottom: 1px solid #edf0f5;
    margin: 0 -0.938rem;
  }
  
  .titleTextContainer .pageTitleText{
    text-align: left;
    color: #333333;
    padding-bottom: 17px;
  }
   
   
  
  /** =========== ACTION AREA STYLES ============== **/
  .block2 .areaActionRight {
    display: flex;
    justify-content: flex-end;
  }
  
  .block2 .borderRadius2 {
    border-radius: 0.125rem;
  }
  
  .block2 .areaActionRight > div:first-child {
    margin-right: 0.3rem;
  }
  .block2 .areaActionRight > div:last-child {
    display: flex;
  }
  
  /** ========= SEARCH FILED ================ **/
  .block2 .areaActionRight .search {
    border: 1px solid;
    border-radius: 0.125rem;
  }
  
  .block2 .areaActionRight .search input {
    border: 0;
    width: 100%;
    height: calc(1.5em + 0.75rem + 0px);
  }
  
  .block2 .areaActionRight .search div {
    border: 0;
    border-radius: 0;
    background-color: inherit;
    padding-left: 0.6rem;
    padding-right: 0.35rem;
  }
  
  /***** ========== MAIN ACTION AREA ============ **********/
  .block3 {
    padding: 1.25rem 0;
  }
  
  /***** ===================== PAGINATION ======================= ******/
   .pagination { }
  
   .pagination .item {
    height: 25px;
    width: 25px;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
  }
  
   .pagination .arrowLeft,  .pagination .arrowRight {
    border-radius: 50%;
    border: 2px solid;
  }
  
   .pagination .arrowLeft > span,  .pagination .arrowRight > span {
    border-bottom: 2px solid;
    border-right: 2px solid;
    width: 8px;
    height: 8px; 
    margin-top: -0.5px;
  }
  
   .pagination .arrowLeft > span{ 
    transform: rotate(127deg); 
    margin-right: -2.5px;
  }
  
   .pagination .arrowRight > span{ 
    transform: rotate(315deg); 
    margin-left: -2.5px;
  }
  
   .pagination .arrowLeft ,  .pagination .arrowRight,  .pagination .item[active] {
    color: #e91e63;
    font-weight: 600;
  }
  
   .pagination .item[active] {
    cursor: default;
  }
  
   .pagination .item[disabled] {
    cursor: not-allowed;
    color: #5d5d5d;
  }
  .tableWrapper  {
    min-height: calc(100vh - 393px);
   }
  
   .display-data {
      background: #F4F4F4;
      padding: 1rem;
    }
    
    .data-table-list {
      width: 100%;
      max-width: 90%;
      list-style: none;
      padding: 0;
      margin: 0 auto;
      background-color: #fff;
      border-radius: .25rem;
      overflow: hidden;
    }
    
    @media (max-width: 992px) {
      .data-table-list {
        max-width: 100%;
      }
    }
    
    .data-table-list li {
      display: flex;
      flex-direction: row;
      padding: 1rem;
      border-bottom: 1px solid #e3e7eb;
    }
    
    .data-table-list li:hover {
      background-color: #fcfcfc;
    }
    
    .data-table-list li .data-table-title {
      width: 30%;
      flex: 0 0 30%;
      color: #8f9bb3;
      word-break: break-word;
    }
    
    .data-table-list li .data-table-text {
      width: calc(70% - 1rem);
      flex: 0 0 calc(70% - 1rem);
      padding-left: 1rem;
      word-break: break-word;
    }
    
    /* New UI styles */
  .SearchCol {
    position: relative;
  }
  .SearchCol input[type="text"] {
   padding-right: 48px;
   min-width: 220px;
   height: 38px;
   font-size: 14px;
  }
  .SearchCol .btn,
  .SearchCol button {
    position: absolute;
    right: 0;
    top: 0;
    width: 38px;
    height: 38px;
    background-color: transparent;
    border: 0;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .SearchCol .btn img,
  .SearchCol button img {
    width: 16px;
  }
  .SearchCol .btn:hover,
  .SearchCol button:hover,
  .SearchCol .btn:focus,
  .SearchCol button:focus,
  .SearchCol .btn:not(:disabled):not(.disabled):active,
  .SearchCol button:not(:disabled):not(.disabled):active {
    background-color: transparent;
    outline: 0;
    border: 0;
    box-shadow: none !important;
  }