
/**
 * Copyright(c) 2020 Mozanta Technologies Private Ltd.
 *
 * All rights reserved.
 *
 * This software is the confidential and proprietary information of Mozanta
 * ("Confidential Information"). You shall not disclose such Confidential
 * Information and shall use it only in accordance with the terms of the
 * contract agreement you entered into with Mozanta.
 *
 * EditPage.module.css
 *
 * @author Naseef O
 *
 */


/* block 1 */
 .block1 {
   background: #ffffff;
   margin: 0px !important;
   padding-top: 10px !important;
   margin-bottom: 10px !important;
}

/* block 2 */
.block2{
  padding-top: 16px !important;
  background: #ffffff;
  display: flex;
  justify-content: center;
}
