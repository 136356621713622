/**
 * Copyright(c) 2020 Mozanta Technologies Private Ltd.
 *
 * All rights reserved.
 *
 * This software is the confidential and proprietary information of Mozanta
 * ("Confidential Information"). You shall not disclose such Confidential
 * Information and shall use it only in accordance with the terms of the
 * contract agreement you entered into with Mozanta.
 *
 * PasswordReset.module.css
 *
 * @author Harikrishnan A K
 *
 */

.viewPassword{
  position: relative;
}

.viewPassword .iconShowPassword{
  position: absolute;
  top: 0.8rem;
  width: 2rem;
  height: 2rem;
  font-size: 1.5rem;
  padding: 0;
  right: 1.7rem;

}

.loginWrapper1{
  min-height: calc(100vh - 205px);
  display: flex;
}

.link-info:hover{
text-decoration: underline !important;
color: blue !important;
}

.passwordResetFormWrapper{
  max-height: unset !important;
}