/**
 * Copyright(c) 2020 Mozanta Technologies Private Ltd.
 * 
 * All rights reserved.                
 * 
 * This software is the confidential and proprietary information of Mozanta ("Confidential
 * Information"). You shall not disclose such Confidential Information and shall use it only in
 * accordance with the terms of the contract agreement you entered into with Mozanta.
 * 
 * @author Aardra S
 */
.block2 {
    padding-top: 20px;
    padding-bottom: 20px;
  } 
  
  .borderRadius2 {
    border-radius: 0.125rem !important;
  }
  
  .secondaryColor {
    background-color: #757575 !important;
    border-color: #757575 !important;
  }
  
  .primaryColor {
    background: #17a2b8 !important;
    border-color: #17a2b8 !important;
  }
  
  .btn {
    min-width: 7rem;
  }
  