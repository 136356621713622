

/**
 * Copyright(c) 2020 Mozanta Technologies Private Ltd.
 *
 * All rights reserved.
 *
 * This software is the confidential and proprietary information of Mozanta
 * ("Confidential Information"). You shall not disclose such Confidential
 * Information and shall use it only in accordance with the terms of the
 * contract agreement you entered into with Mozanta.
 *
 * ModifyCarousel.module.css
 *
 * @author Naseef O
 *
 */

.title{
    padding-top: 20px;   
    font-size: 20px;
    font-weight: bold;
    padding-bottom: 20px;   
}
.subTitle{
    font-size: 18px;
    font-weight: bold;
}

.widgetContentEditorPreviewCol{
    justify-content: center;
    align-items: center;
    justify-items: center;
}
.widgetContentEditorPreviewRow{
    justify-content: center;
    height: 100%;
    align-items: center;
    background: #f9f9f9;
    justify-items: center;
    margin-right: 0px !important;
}
.widgetContentEditorPreviewRow1{
    height: 73%;
}
.block2{
    justify-content: start;
    padding-top: 10px;
}
.block2 .widgetContentEditorDropdownBtn button {
    width:100% !important;
}
.block2 .widgetContentEditorDropdownMenu  {
    width:100% !important;
}
.block2 .widgetContentEditorDropdownBtn  {
    padding: 0px;
}
.block3{
    justify-content: start;
    padding-top: 10px;
}
.block4{
    display: flex;
    align-content: center;
    width: 100%;
    justify-content: flex-end;
}
.block4 .mediaError{
    display: flex;
    align-content: center;
    width: 100%;
    justify-content: flex-end;
    font-size: 16px;
    color: #dc3545;
    font-weight: normal;
}

