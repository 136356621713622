
/**
 * Copyright(c) 2020 Mozanta Technologies Private Ltd.
 *
 * All rights reserved.
 *
 * This software is the confidential and proprietary information of Mozanta
 * ("Confidential Information"). You shall not disclose such Confidential
 * Information and shall use it only in accordance with the terms of the
 * contract agreement you entered into with Mozanta.
 *
 * Table.module.css
 *
 * @author Naseef O
 *
 */


.spinnerWrapper{
	width: 1.5rem !important;
	height: 1.5rem !important;
	color: #17a2b8;
}

.liveBtnWrapper .liveBtn{
  border: none;
  border-radius: 2px;
  text-transform: uppercase;
}
