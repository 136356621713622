/**
 * Copyright(c) 2020 Mozanta Technologies Private Ltd.
 * 
 * All rights reserved.                
 * 
 * This software is the confidential and proprietary information of Mozanta ("Confidential
 * Information"). You shall not disclose such Confidential Information and shall use it only in
 * accordance with the terms of the contract agreement you entered into with Mozanta.
 * 
 * @author Aneesh K
 */

.block2 {
    padding-top: 20px;
    padding-bottom: 20px;
}

.borderRadius2 {
    border-radius: 0.125rem !important;
}

.secondaryColor {
    background-color: #757575 !important;
    border-color: #757575 !important;
}

.primaryColor {
    background: #17a2b8 !important;
    border-color: #17a2b8 !important;
}

.btn {
    min-width: 7rem;
}


/* ========== FORM AREA ============ */
.block3 {
    padding: 1.25rem;
}

/* ============= PRODUCT TITLE ========= */
.titleTextContainer {
    padding: 0 2.1rem 0.125rem 2.1rem;
    border-bottom: 1px solid #edf0f5;
    margin: 0 -0.938rem;
}

.variantsText {
    text-align: left;
    font-weight: 600;
    font-size: 16px;
    color: #212121;
}



/** ========= PARENT CATEGORY  ================ **/
.block5 {
    padding: 1.25rem;
}

/** ========= Table Area  ================ **/

.block3 .noLabelFormGroup {
    margin-top: 1.75rem;
    padding: 0.375rem 0.75rem;
}

.block3 .variantTable thead {
    background-color: #edf0f5;
}

.block3 .variantTable tbody .productImage {
    max-height: 50px;
}

.block3 .variantTable tbody .mw75 {
    max-width: 75px;
}

/** =========== details table ============== **/
.tableDetails>tbody>tr>th {
    color: grey;
}

.tableDetails>tbody>tr>td {
    font-weight: 600;
}