/**
 * Copyright(c) 2020 Mozanta Technologies Private Ltd.
 * 
 * All rights reserved.
 * 
 * This software is the confidential and proprietary information of Mozanta ("Confidential
 * Information"). You shall not disclose such Confidential Information and shall use it only in
 * accordance with the terms of the contract agreement you entered into with Mozanta.
 * 
 * @author Indrajith C
 * 
 */ 
 .borderRadius2 {
    border-radius: 0.125rem !important;
  }
  
  .secondaryColor {
    background-color: #757575 !important;
    border-color: #757575 !important;
  }
  
  .primaryColor {
    background: #17a2b8 !important;
    border-color: #17a2b8 !important;
  }
  
  .btn {
    min-width: 7rem;
  }
  
   .block1{
    padding-top: 20px;
    padding-bottom: 20px;
  }
  .block1 .navigationManagementnText{
    text-align: left;
    color: #333333;
    padding-bottom: 17px;
  }
  
  .block1 .buttonAddCoupon{
    color: #ffffff;
    width: 113px;
    height: 35px;
    border-radius: 2px;
    background-color: #448aff;
    border: 0px;
    float: right;
  }
  
  .changePasswordOuter {
    margin: 3rem auto;
  }
  .changePasswordBox {
    background: #f9f9f9;
    padding: 2rem;
    border-radius: 6px;
    border: 1px solid #ebebeb;
  }