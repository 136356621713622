/**
 * Copyright(c) 2020 Mozanta Technologies Private Ltd.
 * 
 * All rights reserved.                
 * 
 * This software is the confidential and proprietary information of Mozanta ("Confidential
 * Information"). You shall not disclose such Confidential Information and shall use it only in
 * accordance with the terms of the contract agreement you entered into with Mozanta.
 * 
 * @author Naseef O
 */


.block2 .search {
    border: 1px solid #229E79;
    border-radius: 0.125rem;
  }
  
  .block2 .search input {
    border: 0;
    width: 100%;
  }
  
  .block2 .search div {
    border: 0;
    border-radius: 0;
    background-color: #229E79;
    padding-left: 0.6rem;
    padding-right: 0.35rem;
  }
  
  .block2 img.icon {
    width: 20px;
  }
  .labelImg{
    width: 30px;
    height: 30px;
    margin-right: 10px !important;
    margin-left: 5px !important;
}
.block3{
  min-height: calc(100vh - 420px); 
}

.block3 .categorySelector{
  height: 350px;
  overflow: scroll;
}