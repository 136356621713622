/**
 * Copyright(c) 2020 Mozanta Technologies Private Ltd.
 * 
 * All rights reserved.                
 * 
 * This software is the confidential and proprietary information of Mozanta ("Confidential
 * Information"). You shall not disclose such Confidential Information and shall use it only in
 * accordance with the terms of the contract agreement you entered into with Mozanta.
 * 
 * @author Aardra S
 */

 .borderRadius2 {
  border-radius: 0.125rem;
}

.secondaryColor {
  background-color: #757575 !important;
  border-color: #757575 !important;
}

.primaryColor {
  background: #e91e63 !important;
  border-color: #e91e63 !important;
}

.btn1 {
  min-width: 6rem;
}

.btn2 {
  min-width: 9rem;
}
