/**
 * Copyright(c) 2023 Mozanta Technologies Private Ltd.
 *
 * All rights reserved.
 *
 * This software is the confidential and proprietary information of Mozanta ("Confidential
 * Information"). You shall not disclose such Confidential Information and shall use it only in
 * accordance with the terms of the contract agreement you entered into with Mozanta.
 *
 * @author kishor
 *
 */

.statusIcon {
  width: 39px;
  height: 39px;
  border-radius: 30px;
  padding-left: 12px;
  padding-top: 7.5px;
}

.statusItem {
  background-color: #FFFFFF;
  border-radius: 10px;
  padding: 22px;
  margin: 5px;
  width: 32px;
  position: fixed;
  z-Index: "100",
}

.currencyCode {
  font-size: 16px;
  padding-left: 1px;
  color: #212529;
}

.textTitle {
  font-style: normal;
  font-size: 16px;
  font-weight: 500;
  color: #212529;
  margin-top: 14px;

}

.numberTitle {
  font-style: normal;
  font-size: 20px;
  font-weight: 700;
  color: #212529;
}

.description {
  color: #949292;
  font-size: 14px;
}

.errorTitle {
  font-style: normal;
  font-size: 16px;
  font-weight: 500;
  color: #F32013;
  padding-top: 14px;
}