/**
 * Copyright(c) 2020 Mozanta Technologies Private Ltd.
 * 
 * All rights reserved.                
 * 
 * This software is the confidential and proprietary information of Mozanta ("Confidential
 * Information"). You shall not disclose such Confidential Information and shall use it only in
 * accordance with the terms of the contract agreement you entered into with Mozanta.
 * 
 * @author Amjad Rehman A
 */

 .areaActionRight {
  display: flex;
  justify-content: flex-end;
  }


  .block1 .buttonNewJob{
    color: #ffffff;
    width: 113px;
    border-radius: 2px;
    background-color: #e91e63;
    border: 0px;
    float: right;
  }
  .block1 .buttonStopJob{
    color: #ffffff;
    width: 113px;
    border-radius: 2px;
    background-color: #e91e63;
    border: 0px;
  }
  .block1 .buttonDisable{
    color: #ffffff;
    width: 113px;
    border-radius: 2px;
    background-color: #e91e63;
    border: 0px;
    margin-left: 15px;
    margin-right: 15px;
  }
  .block1 .buttonDelete{
    color: #ffffff;
    width: 113px;
    border-radius: 2px;
    background-color: #e91e63;
    border: 0px;
  }
  .jobButtons{
    float: right;
  }

/** =========== ACTION AREA STYLES ============== **/
  .block2 .areaActionRight {
    display: flex;
    justify-content: flex-end;
  }
  
  .block2 .borderRadius2 {
    border-radius: 0.125rem;
  }
  
  .block2 .areaActionRight > div:first-child {
    margin-right: 0.3rem;
  }
  .block2 .areaActionRight > div:last-child {
    display: flex;
  }
  
/** ========= SEARCH FILED ================ **/
  .block2 .areaActionRight .search {
    border: 1px solid;
    border-radius: 0.125rem;
  }
  
  .block2 .areaActionRight .search input {
    border: 0;
    width: 222px;
    height: calc(1.5em + 0.75rem + 0px);
  }
  
  .block2 .areaActionRight .search div {
    border: 0;
    border-radius: 0;
    background-color: inherit;
    padding-left: 0.6rem;
    padding-right: 0.35rem;
  }
  .tableWrapper{
    min-height: calc(100vh - 424px);
  }

  /** =========== TABLE ============== **/
  .jobTable .table th .table td {
    width: 0.75rem;
  }

  .jobTable {
    overflow-x: auto ;
  }
  .jobTable thead{
    background-color: #edf0f5;
  }
  .tableWrapper{
    min-height: calc(100vh - 365px);
  }
   
  /** =========== PAGINATION ============== **/
  .pagination .item {
   height: 25px;
   width: 25px;
   display: flex;
   justify-content: center;
   align-items: center;
   cursor: pointer;
 }

  .pagination .arrowLeft,  .pagination .arrowRight {
   border-radius: 50%;
   border: 2px solid;
 }

  .pagination .arrowLeft > span,  .pagination .arrowRight > span {
   border-bottom: 2px solid;
   border-right: 2px solid;
   width: 8px;
   height: 8px; 
   margin-top: -0.5px;
 }

  .pagination .arrowLeft > span{ 
   transform: rotate(127deg); 
   margin-right: -2.5px;
 }

  .pagination .arrowRight > span{ 
   transform: rotate(315deg); 
   margin-left: -2.5px;
 }

  .pagination .arrowLeft ,  .pagination .arrowRight,  .pagination .item[active] {
   color: #e91e63;
   font-weight: 600;
 }

  .pagination .item[active] {
   cursor: default;
 }

  .pagination .item[disabled] {
   cursor: not-allowed;
   color: #5d5d5d;
 }

 .borderRadius2 {
  border-radius: 0.125rem !important;
 }

/** =========== ACTION AREA STYLES ============== **/
 .areaActionRight {
  display: flex;
  justify-content: flex-end;
 }

 .secondaryColor,
 .secondaryColor:focus,
 .secondaryColor:active {
  background-color: #757575 !important;
  border-color: #757575 !important;
 }

 .secondaryColor:hover {
  background-color: #919191 !important;
  border-color: #919191 !important;
 }

 .border {
  border: 1px solid #c4c4c4;
 }

 .tableWrapper {
  min-height: calc(100vh - 365px);
 }

 .sectionHead {
  font-weight: bold;
  margin-bottom: 1rem;
 }
.borderRadius2 {
  border-radius: 0.125rem !important;
}

/** =========== ACTION AREA STYLES ============== **/
.areaActionRight {
  display: flex;
  justify-content: flex-end;
}

.secondaryColor,
.secondaryColor:focus,
.secondaryColor:active {
  background-color: #757575 !important;
  border-color: #757575 !important;
}

.secondaryColor:hover {
  background-color: #919191 !important;
  border-color: #919191 !important;
}

.border {
  border: 1px solid #c4c4c4;
}

.tableWrapper {
  min-height: calc(100vh - 365px);
}

.sectionHead {
  font-weight: bold;
  margin-bottom: 1rem;
}


.block3 .jobTaskConfig {
  padding-bottom: 1rem;
}

.block3 .jobTaskConfig .componentTitle {
  font-weight: 600;
  font-size: 16x;
  color: #212121;
}

.block3 .jobTaskConfig .jobTaskConfigBody .jobTaskConfigTable thead {
  background-color: #edf0f5;
}

.block3 .jobTaskConfig .jobTaskConfigBody .jobTaskConfigItem {
  display: flex;
  flex-direction: column;
}
.textTruncate{
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
    width: 300px;
    cursor: pointer;
}