
/**
 * Copyright(c) 2020 Mozanta Technologies Private Ltd.
 *
 * All rights reserved.
 *
 * This software is the confidential and proprietary information of Mozanta
 * ("Confidential Information"). You shall not disclose such Confidential
 * Information and shall use it only in accordance with the terms of the
 * contract agreement you entered into with Mozanta.
 *
 * StaticPages.module.css
 *
 * @author Naseef O
 *
 */


/* block 1 */
 .block1 {
   background: #ffffff;
   margin: 0px !important;
   padding-top: 10px !important;
   margin-bottom: 10px !important;
}

/* block 2 */
.block2{
  padding-top: 16px !important;
  background: #ffffff;
  display: flex;
  justify-content: center;
  min-height: calc(100vh - 276px);
}


/* Pagination  */
.pagination .item {
  height: 25px;
  width: 25px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}

.pagination .arrowLeft, .pagination .arrowRight {
  border-radius: 50%;
  border: 2px solid;
}

.pagination .arrowLeft > span, .pagination .arrowRight > span {
  border-bottom: 2px solid;
  border-right: 2px solid;
  width: 8px;
  height: 8px; 
  margin-top: -0.5px;
}

.pagination .arrowLeft > span{ 
  transform: rotate(127deg); 
  margin-right: -2.5px;
}

.pagination .arrowRight > span{ 
  transform: rotate(315deg); 
  margin-left: -2.5px;
}

.pagination .arrowLeft , .pagination .arrowRight, .pagination .item[active] {
  color: #e91e63;
  font-weight: 600;
}

.pagination .item[active] {
  cursor: default;
}

.pagination .item[disabled] {
  cursor: not-allowed;
  color: #5d5d5d;
}

.borderRadius2 {
 border-radius: 0.125rem !important;
}

.btnColor{
 background: #229E79 !important;
 border-color: #229E79 !important;
}

.block2{
 display: flex;
 justify-content: center;
}