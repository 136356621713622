/**
 * Copyright(c) 2020 Mozanta Technologies Private Ltd.
 * 
 * All rights reserved.                
 * 
 * This software is the confidential and proprietary information of Mozanta ("Confidential
 * Information"). You shall not disclose such Confidential Information and shall use it only in
 * accordance with the terms of the contract agreement you entered into with Mozanta.
 * 
 * @author Naseef O
 */

.uploadImagePreview {
    min-width: 150px;
    min-height: 150px;
    max-width: 150px;
    max-height: 150px;
    position: relative;
    justify-content: center;
    align-items: center;
    background: #000000;
    justify-items: center;
    display:flex;
    
  }
.uploadImagePreviewIcon {
    max-width: 150px;
    min-width: 150px;
}
.uploadImagePreviewEdit{
  display: none;
}
.uploadImagePreviewClose{
  display: none;
}
.uploadImagePreview:hover .uploadImagePreviewClose {
  display: flex;
  position: absolute;
  top: 2px;
  right: 2px;
  padding: 0px 1px;
  cursor: pointer;
  background: white;
  border-radius: 50%;
}

.uploadImagePreview:last-child {
      margin-right: 0;
}
.uploadImagePreview .img {
     object-fit: cover;
}


.labelImg{
    width: 30px;
    height: 30px;
    margin-right: 10px !important;
    margin-left: 5px !important;
}

.block2 .search {
  border: 1px solid #229E79;
  border-radius: 0.125rem;
}

.block2 .search input {
  border: 0;
  width: 100%;
}

.block2 .search div {
  border: 0;
  border-radius: 0;
  background-color: #229E79;
  padding-left: 0.6rem;
  padding-right: 0.35rem;
}

.block2 img.icon {
  width: 20px;
}
.block3 .title{
  font-size: 20px;
  font-weight: bold;
}