/* block 1 */

.block1 {
  padding-top: 20px;
  padding-bottom: 20px;
}
.block1 .navigationManagementnText {
  text-align: left;
  color: #333333;
  padding-bottom: 17px;
}

.block1 .buttonAddMenu {
  color: #ffffff;
  width: 113px;
  height: 35px;
  border-radius: 2px;
  background-color: #448aff;
  border: 0px;
  float: right;
}
.tableWrapper {
  min-height: calc(100vh - 385px);
}
.pagination .item {
  height: 25px;
  width: 25px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}

.pagination .arrowLeft,
.pagination .arrowRight {
  border-radius: 50%;
  border: 2px solid;
}

.pagination .arrowLeft > span,
.pagination .arrowRight > span {
  border-bottom: 2px solid;
  border-right: 2px solid;
  width: 8px;
  height: 8px;
  margin-top: -0.5px;
}

.pagination .arrowLeft > span {
  transform: rotate(127deg);
  margin-right: -2.5px;
}

.pagination .arrowRight > span {
  transform: rotate(315deg);
  margin-left: -2.5px;
}

.pagination .arrowLeft,
.pagination .arrowRight,
.pagination .item[active] {
  color: #e91e63;
  font-weight: 600;
}

.pagination .item[active] {
  cursor: default;
}

.pagination .item[disabled] {
  cursor: not-allowed;
  color: #5d5d5d;
}
.tableWrapper {
  min-height: calc(100vh - 387px);
}

/** ========= SEARCH FILED ================ **/
.block1 .areaActionRight {
  display: flex;
  justify-content: flex-end;
}

.block1 .areaActionRight .search {
  border: 1px solid;
  border-radius: 0.125rem;
}

.block1 .areaActionRight .search input {
  border: 0;
  width: 100%;
  height: calc(1.5em + 0.75rem + 0px);
}

.block1 .areaActionRight .search div {
  border: 0;
  border-radius: 0;
  background-color: inherit;
  padding-left: 0.6rem;
  padding-right: 0.35rem;
}
.searchContainer{
  display: flex;
  align-items: flex-end;
  margin: 0 0 1rem 0.3rem;
}

.block2 {
  padding-top: 20px;
  padding-bottom: 20px;
}

.block2 button {
  background: none;
  color: black;
  border-radius: 2px;
  border: 1px solid #e0e0e0;
}

.block2 .dropdownBtn {
  padding: 0px;
}

.block2 .dropdownBtn button {
  float: right !important;
}
