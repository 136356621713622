/**
 * Copyright(c) 2020 Mozanta Technologies Private Ltd.
 * 
 * All rights reserved.
 * 
 * This software is the confidential and proprietary information of Mozanta ("Confidential
 * Information"). You shall not disclose such Confidential Information and shall use it only in
 * accordance with the terms of the contract agreement you entered into with Mozanta.
 * 
 * @author Indrajith C
 * 
 */
 .block1{
  padding-top: 20px;
  padding-bottom: 20px;
}
.block1 .navigationManagementnText{
  text-align: left;
  color: #333333;
  padding-bottom: 17px;
}

.block1 .buttonAddMenu{
  color: #ffffff;
  width: 113px;
  height: 35px;
  border-radius: 2px;
  background-color: #448aff;
  border: 0px;
  float: right;
}
.blockWrapper{
  min-height: calc(100vh - 385px);
}

.borderRadius2 {
  border-radius: 0.125rem !important;
}

.secondaryColor {
  background-color: #757575 !important;
  border-color: #757575 !important;
}

.primaryColor {
  background: #e91e63 !important;
  border-color: #e91e63 !important;
}

.btn {
  min-width: 7rem;
}

/* ======================= form ================= */
.form {

}

.form .header {
  display: block;
}

.form .formLabel {
  font-size: 0.875rem;
}

.form .header .formLabel {
  opacity: 0.4;
}

.form .noLabelFormGroup {
  margin-top: 1.75rem;
  padding: 0.375rem 0.75rem;
} 

.form .icon {
  width: 1.25rem;
}

.form .imageUpload {
  border-radius: 5px;
  border: 1px dashed rgba(67, 148, 209, 0.5);
  text-align: center;
}

.form .primaryTextColor { 
  color: #4394D1;
}

.form .secondaryTextColor { 
  color: #909090;
}