.nocolor {
  background-color: transparent;
}

.filterCard {
  margin: 0px !important;
  padding-top: 20px !important;
  padding-bottom: 20px !important;
  margin-bottom: 10px !important;
  ;
}

.filterHeader {
  padding-left: 30px;
  color: rgba(128, 128, 128, 1);
  ;
}

.filterDiv {
  padding-top: "11%";
  padding-left: "2.5%";
  color: "#808080";
  align-items: center;
}

.card {
  z-index: 90;
  background-color: #F8F9FA;
  top: 75px;
  width: 98%;
}

.topcard {
  padding-top: 180px;
}