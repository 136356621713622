

/* block 2 */

.block2{
    padding-top: 20px;
    padding-bottom: 20px;
    color: black;
}

/** =========== ACTION AREA STYLES ============== **/
.areaActionRight {
  display: flex;
  justify-content: flex-end;
}


.tableWrapper  {
    min-height: calc(100vh - 420px);
   }

   
  /***** ===================== PAGINATION ======================= ******/
  .pagination { }
  
  .pagination .item {
   height: 25px;
   width: 25px;
   display: flex;
   justify-content: center;
   align-items: center;
   cursor: pointer;
 }

  .pagination .arrowLeft,  .pagination .arrowRight {
   border-radius: 50%;
   border: 2px solid;
 }

  .pagination .arrowLeft > span,  .pagination .arrowRight > span {
   border-bottom: 2px solid;
   border-right: 2px solid;
   width: 8px;
   height: 8px; 
   margin-top: -0.5px;
 }

  .pagination .arrowLeft > span{ 
   transform: rotate(127deg); 
   margin-right: -2.5px;
 }

  .pagination .arrowRight > span{ 
   transform: rotate(315deg); 
   margin-left: -2.5px;
 }

  .pagination .arrowLeft ,  .pagination .arrowRight,  .pagination .item[active] {
   color: #e91e63;
   font-weight: 600;
 }

  .pagination .item[active] {
   cursor: default;
 }

  .pagination .item[disabled] {
   cursor: not-allowed;
   color: #5d5d5d;
 }

 .collapseIcon > img {
  width: 15px;
  height: 15px;
  -webkit-transition: 0.6s ease-out;
  -moz-transition:  0.6s ease-out;
  transition:  0.6s ease-out;
  float: right;
}

.openCollapse {
  -webkit-transform: rotateZ(-90deg);
  -moz-transform: rotateZ(-90deg);
  transform: rotateZ(-90deg);
}

.closeCollapse { 
  -webkit-transform: rotateZ(90deg);
  -moz-transform: rotateZ(90deg);
  transform: rotateZ(90deg);
}

.cardTitle{
  font-size: 16px;
}

.cardBody{
  font-size: 14px;
  font-weight: 400;
}

.block3{
  font-size:14px ;
}

.date{
  color: lightgray;
  font-weight: 400;
}

.secondaryColor {
    background-color: #757575 !important;
    border-color: #757575 !important;
  }
  
  .primaryColor {
    background: #e91e63 !important;
    border-color: #e91e63 !important;
  }
  
  .btn1 {
    min-width: 6rem;
  }
  
  .btn2 {
    min-width: 9rem;
  }
  