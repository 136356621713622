/**
 * Copyright(c) 2020 Mozanta Technologies Private Ltd.
 * 
 * All rights reserved.                
 * 
 * This software is the confidential and proprietary information of Mozanta ("Confidential
 * Information"). You shall not disclose such Confidential Information and shall use it only in
 * accordance with the terms of the contract agreement you entered into with Mozanta.
 * 
 * @author Indrajith C
 */

 .block2 {
  padding-top: 20px;
  padding-bottom: 20px;
} 

.borderRadius2 {
  border-radius: 0.125rem !important;
}

.secondaryColor {
  background-color: #757575 !important;
  border-color: #757575 !important;
}

.primaryColor {
  background: #17a2b8 !important;
  border-color: #17a2b8 !important;
}

.btn {
  min-width: 7rem;
} 

/* ============= PRODUCT TITLE ========= */
.titleTextContainer {
  padding: 0 2.1rem 0.125rem 2.1rem;
  border-bottom: 1px solid #edf0f5;
  margin: 0 -0.938rem;
}

/** ========= PARENT CATEGORY  ================ **/

.dropdownMenu {
  max-height: 60vh;
  overflow-y: auto;
  width: 100%;
  background-color: #fff;
}

.dropdownMenu::-webkit-scrollbar-track {
  background: #fff !important;
}

.dropdown > button {
  background: transparent !important;
  text-align: left !important;
  color: black !important;
  display: flex !important;
  align-items: center !important;
}

.dropdown > button .clear {
  margin-left: auto;
  display: flex;
  padding: 0.125rem;
  border: 1px solid;
  border-radius: 2px;
  border-color: #b7b2b2;
}

.dropdown > button .clear> img {
  width: 20px;
  height: 20px;
}