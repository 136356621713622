/**
 * Copyright(c) 2020 Mozanta Technologies Private Ltd.
 *
 * All rights reserved.
 *
 * This software is the confidential and proprietary information of Mozanta
 * ("Confidential Information"). You shall not disclose such Confidential
 * Information and shall use it only in accordance with the terms of the
 * contract agreement you entered into with Mozanta.
 *
 * select site.module.css
 *
 * @author Anokh J Ajai
 *
 */



.selectSiteOuterWrapper {
  background: #f8f8f8 0% 0% no-repeat padding-box;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  height: 100vh;
}

.selectSiteOuterWrapper .spinner {
  width: 50px;
  height: 50px;
  margin-top: auto;
  margin-bottom: auto;
}
.selectSiteOuterWrapper .siteSelectHeader {
  width: 100%;
  display: flex;
  flex: 1;
  align-items: flex-start;
  text-align: left;
  z-index: 10;
}

.selectSiteOuterWrapper  .siteSelectTitle{
  text-align: center;
  font-weight: 700;
  font-size: 32px;
  margin-bottom: 2rem;
  display: flex;
  align-items: center;
  justify-content: center;
}

.selectSiteOuterWrapper .siteSelectDropdown {
  width: 100%;
  max-width: 400px;
  justify-content: center;
  border-radius: 30px;
}
.selectSiteOuterWrapper .submit{
  width: 100%;
  max-width:200px;
  margin-top: 2rem;
  border-radius: 25px;
}
.selectSiteOuterWrapper .submitImage img{
  width:20px;
  height:20px
}
.selectSiteOuterWrapper .siteSelectDropdown .dropDown {
  width: 25%;
}
.selectSiteOuterWrapper .siteSelectFooter {
  display: flex;
  width: 100%;
  flex: 1;
  align-items: center;
  z-index: 10;
  text-align: center;
}
.selectSiteOuterWrapper .siteSelectFooter .signOut{
  color :#869085 !important;
  text-align: center;
}
.selectSiteOuterWrapper .siteSelectFooter .signOut img{
  width:25px;
  height:25px;
  padding-left :5px;
}
