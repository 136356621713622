
/**
 * Copyright(c) 2020 Mozanta Technologies Private Ltd.
 *
 * All rights reserved.
 *
 * This software is the confidential and proprietary information of Mozanta
 * ("Confidential Information"). You shall not disclose such Confidential
 * Information and shall use it only in accordance with the terms of the
 * contract agreement you entered into with Mozanta.
 *
 * RoleManagement.module.css
 *
 * @author Naseef O
 *
 */

.block1 .buttonNewUser{
  color: #ffffff;
  width: 113px;
  border-radius: 2px;
  background-color: #e91e63;
  border: 0px;
  float: right;
}


/* block 2 */

.block2{
    padding-top: 20px;
    padding-bottom: 20px;
}

/** =========== ACTION AREA STYLES ============== **/
.block2 .areaActionRight {
    display: flex;
    justify-content: flex-start;
  }
  
  .block2 .borderRadius2 {
    border-radius: 0.125rem;
  }
  
  .block2 .areaActionRight > div:first-child {
    margin-right: 0.3rem;
  }
  .block2 .areaActionRight > div:last-child {
    display: flex;
  }
  
  /** ========= SEARCH FILED ================ **/
  .block2 .areaActionRight .search {
    border: 1px solid ;
    border-radius: 0.125rem;
  }
  
  .block2 .areaActionRight .search input {
    border: 0;
    width: 100%;
  }
  
  .block2 .areaActionRight .search div {
    border: 0;
    border-radius: 0;
    background-color: #e91e63;
    padding-left: 0.6rem;
    padding-right: 0.35rem;
  }
  
  .tableWrapper  {
    min-height: calc(100vh - 420px);
   }