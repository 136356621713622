/**
 * Copyright(c) 2020 Mozanta Technologies Private Ltd.
 * 
 * All rights reserved.                
 * 
 * This software is the confidential and proprietary information of Mozanta ("Confidential
 * Information"). You shall not disclose such Confidential Information and shall use it only in
 * accordance with the terms of the contract agreement you entered into with Mozanta.
 * 
 * @author Aardra S
 */

.block3 {
    padding: 1.25rem;
  }

  .block3 .noLabelFormGroup {
    margin-top: 1.75rem;
    padding: 0.375rem 0.75rem;
  }