/**
 * Copyright(c) 2020 Mozanta Technologies Private Ltd.
 * 
 * All rights reserved.
 * 
 * This software is the confidential and proprietary information of Mozanta ("Confidential
 * Information"). You shall not disclose such Confidential Information and shall use it only in
 * accordance with the terms of the contract agreement you entered into with Mozanta.
 * 
 * @author Indrajith C
 * 
 */
 .block1{
  padding-top: 20px;
  padding-bottom: 20px;
}
.block1 .navigationManagementnText{
  text-align: left;
  color: #333333;
  padding-bottom: 17px;
}

.block1 .buttonAddMenu{
  color: #ffffff;
  width: 113px;
  height: 35px;
  border-radius: 2px;
  background-color: #448aff;
  border: 0px;
  float: right;
}

.blockWrapper{
  min-height: calc(100vh - 385px);
} 
 
.borderRadius2 {
  border-radius: 0.125rem !important;
}

.secondaryColor {
  background-color: #757575 !important;
  border-color: #757575 !important;
}

.primaryColor {
  background: #17a2b8 !important;
  border-color: #17a2b8 !important;
}

.btn {
  min-width: 7rem;
}

.icon {
  width: 1em;
}

/* =============== step two =================== */

.form .formTextLabel {
  color: #000000CC;
  opacity: 0.4;
}
.block2 {
  padding-top: 20px;
  padding-bottom: 20px;
} 

.borderRadius2 {
  border-radius: 0.125rem !important;
}

.secondaryColor {
  background-color: #757575 !important;
  border-color: #757575 !important;
}

.primaryColor {
  background: #17a2b8 !important;
  border-color: #17a2b8 !important;
}


.btn {
  min-width: 7rem;
}

.tabContainer .tabHeader  {
  border-bottom: 1px solid #dfdfdf;
}

.tabContainer .tabHeader.active {
  border-bottom: 3px solid #17a2b8;
}

.collapseIcon > img {
  width: 15px;
  height: 15px;
  -webkit-transition: 0.6s ease-out;
  -moz-transition:  0.6s ease-out;
  transition:  0.6s ease-out;
}

.openCollapse {
  -webkit-transform: rotateZ(-90deg);
  -moz-transform: rotateZ(-90deg);
  transform: rotateZ(-90deg);
}

.closeCollapse { 
  -webkit-transform: rotateZ(90deg);
  -moz-transform: rotateZ(90deg);
  transform: rotateZ(90deg);
}


/** ========= SEARCH FILED ================ **/
.customerGroups .search {
  border-radius: 0.125rem;
}

.customerGroups .search input {
  border: 0;
  width: 100%;
}

.customerGroups .search div {
  border: 0;
  border-radius: 0; 
  padding-left: 0.6rem;
  padding-right: 0.35rem;
}

.customerGroups img.icon {
  width: 20px;
}

.customerGroups .rightDivider::before {
  position: absolute;
  background: #edf0f5;
  width: 1px;
  right: 0;
  height: calc( 100% - 32px );
  bottom: 0;
}

.customerGroups .searchListContainer {
  max-height: 330px;
  overflow-y: auto;
}

.customerGroups .selectedListContainer {
  max-height: 390px; 
}

.customerGroups .selectedListContainer .unselectProduct {
    position: absolute;
    right: 0.5rem;
    top: 0rem;
    display: none;
    background: #ffffff7a;
}

.customerGroups .selectedListContainer .selectedProduct:hover .unselectProduct {
    display: block;
}

.customerGroups .searchListTable tbody { 
  max-height: 330px;
  overflow-y: auto;
  display: block;
}

.customerGroups .searchListTable tbody tr,
.customerGroups .searchListTable thead tr { 
  display: table;
  width: 100%;
  table-layout: fixed;
}