/*
author : vikhil
font: lato
default font size :  14px/0.875rem
font size         :  16px/1rem
*/

@import url("https://fonts.googleapis.com/css?family=Lato:100,300,400,700&display=swap");

/*  ========== CUSTOM STYLES CLASSES ============ */
@import url("./custom.css");

#root {
  padding: 0px 32px;
  background-color: #f8f8f8;
  font-family: "Lato", sans-serif;
  font-size: 14px;
}

body {
  background-color: #f0f6f4 !important;
  font-family: "Lato", sans-serif !important;
  font-size: 14px !important;
}

a {
  color: grey !important;
}
.hideSideNav {
  display: none !important;
}

a:hover,
a:active,
a:focus {
  color: #229e79 !important;
  text-decoration: none;
}

.navbar-nav {
  display: block !important;
}
.navbar-nav .nav-item {
  display: inline-block;
  float: left;
  padding: 4px 15px !important;
  width: auto;
  font-size: 16px;
  color: #000;
}
.navbar-nav .nav-item .nav-link {
  font-weight: bold;
}
.navbar-nav .nav-item .nav-link.active {
  position: relative;
}
.navbar-nav .nav-item a.nav-link:active,
.navbar-nav .nav-item a.nav-link:focus {
  color: #000 !important;
}
.navbar-nav .nav-item .nav-link.active::after {
  content: "";
  position: absolute;
  left: 0;
  right: 0;
  bottom: -18px;
  width: 100%;
  height: 4px;
  background-color: #229e79;
  margin: 0 auto;
}
.active {
  color: #000 !important;
}

.fontsizeNormalBold {
  font-weight: bold;
  font-size: 14px;
  color: #212121;
}

.fontsizeMediumBold {
  font-weight: bold;
  font-size: 16px;
  color: #212121;
}

.fontsizeLargeBold {
  font-weight: bold;
  font-size: 18px;
  color: #212121;
}

.dropup,
.dropright,
.dropdown,
.dropleft {
  position: relative !important;
}

.secondaryButton {
  font-family: Lato;
  color: #ffffff;
  width: 113px;
  height: 35px;
  border-radius: 2px;
  background-color: #bdbdbd;
  border: 0px;
  float: right;
}
.primaryButton {
  font-family: Lato;
  color: #ffffff;
  width: 113px;
  height: 35px;
  border-radius: 2px;
  background-color: #e91e63;
  border: 0px;
  float: right;
}

.modalWrapper.modal {
  top: 45% !important;
}

.mainWrapper {
  margin-top: 85px;
}

/** fix for dashboard **/
#sideNavigationWrapper + .mainWrapper {
  margin-left: 310px;
}

.mainWrapperWidthfix {
  margin-top: 85px;
  margin-left: 0px;
}
.headerNavScroll {
  margin-left: 133px !important;
  margin-top: -72px !important;
  transition: ease 0.5s;
}
.headerWrapperScroll {
  height: 60px !important;
  transition: ease 0.5s;
  -webkit-box-shadow: 0px 7px 19px -11px #ccc;
  -moz-box-shadow: 0px 7px 19px -11px #ccc;
  box-shadow: 0px 7px 19px -11px #ccc;
}

#headerWrapper,
#menuScrollWrap2,
#sideNavigationWrapper #sideNavigationOpenWrapper {
  transition: ease 0.5s;
}

/* common page headers */

.commonPageHeaderWrapper {
  background: #ffffff;
  padding-top: 20px;
  margin-left: 0 !important;
  margin-right: 0 !important;
  margin-bottom: 0.625rem !important;
  color: #5d5d5d;
  margin-top: 10px;
  min-height: calc(100vh - 210px);
}
.common-page-card {
  background: #ffffff;
  margin: 0px !important;
  padding-top: 20px !important;
  padding-bottom: 20px !important;
  margin-bottom: 10px !important;
}

.pageHeader {
  box-shadow: 0px 11px 20px -12px rgba(0, 0, 0, 0.04);
  padding-bottom: 15px;
}
.pageHeader .pageText {
  font-size: 20px;
  color: #333333;
}
.pageHeader .buttonCancel {
  font-family: Lato;
  color: #ffffff;
  width: 113px;
  height: 35px;
  border-radius: 2px;
  background-color: #bdbdbd;
  border: 0px;
  float: right;
}
.pageHeader .buttonSave {
  font-family: Lato;
  color: #ffffff;
  width: 113px;
  height: 35px;
  border-radius: 2px;
  background-color: #e91e63;
  border: 0px;
  float: right;
}
.pageHeader .buttonGroup {
  padding-right: 20px;
}

.formTextLabel {
  color: black;
}

.formText {
  border-radius: 2px !important;
  border: 1px solid #b7b2b2 !important;
}
/* custom dropdown validation  */
.custom-dropdown-validation {
  background-position: right calc(0.375em + 0.85rem) center !important;
}
/* custom file input */
.customFileInput,
.customFileInput .custom-file-input {
  border-radius: 2px !important;
}

.customFileInput .custom-file-label {
  border-radius: 2px !important;
  border: 1px solid #b7b2b2;
}
.customFileInput .custom-file-label::after {
  display: none;
}

.customFileInput .custom-file-label {
  box-sizing: border-box;
  font-size: 16px;
  background-image: url(../../common/assets/images/svg/file-upload.svg);
  background-position: 4px 0px;
  background-repeat: no-repeat;
  padding: 12px 10px 12px 45px;
  transition: width 0.4s ease-in-out;
  background-size: 1.6rem 100%;
  cursor: pointer !important;
  border-radius: 2px;
  overflow: hidden;
}
/* custom table */

.custom-table {
  overflow-x: auto;
}
.custom-table thead {
  background-color: #edf0f5;
}
.custom-table thead th {
  font-size: 14px;
  font-weight: bold;
  text-align: left;
  min-width: 140px;
}
.custom-table tbody tr {
  text-align: left;
}

/* Custom date-time picker */

.rdt {
  position: relative;
}
.rdtPicker {
  display: none;
  position: absolute;
  width: 100%;
  padding: 4px;
  margin-top: 1px;
  z-index: 99999 !important;
  background: #ffff;
  box-shadow: 0px 0px 15px -5px #ccc;
}
.rdtOpen .rdtPicker {
  display: block;
}
.rdtStatic .rdtPicker {
  box-shadow: none;
  position: static;
}

.rdtPicker .rdtTimeToggle {
  text-align: center;
}

.rdtPicker table {
  width: 100%;
  margin: 0;
}
.rdtPicker td,
.rdtPicker th {
  text-align: center;
  height: 28px;
}
.rdtPicker td {
  cursor: pointer;
}

.rdtPicker td.rdtOld,
.rdtPicker td.rdtNew {
  color: #999999;
}
.rdtPicker td.rdtToday {
  position: relative;
}

.rdtPicker td.rdtActive,
.rdtPicker td.rdtActive:hover {
  background-color: #428bca;
  color: #fff;
}
.rdtPicker td.rdtActive.rdtToday:before {
  border-bottom-color: #000;
}
.rdtPicker td.rdtDisabled,
.rdtPicker td.rdtDisabled:hover {
  background: none;
  color: #999999;
  cursor: not-allowed;
}

.rdtPicker td span.rdtOld {
  color: #999999;
}
.rdtPicker td span.rdtDisabled,
.rdtPicker td span.rdtDisabled:hover {
  background: none;
  color: #999999;
  cursor: not-allowed;
}
.rdtPicker th {
  border-bottom: 1px solid #f9f9f9;
}
.rdtPicker .dow {
  width: 14.2857%;
  border-bottom: none;
  cursor: default;
}
.rdtPicker th.rdtSwitch {
  width: 100px;
}
.rdtPicker th.rdtNext,
.rdtPicker th.rdtPrev {
  font-size: 21px;
  vertical-align: top;
}

.rdtPrev span,
.rdtNext span {
  display: block;
  -webkit-touch-callout: none; /* iOS Safari */
  -webkit-user-select: none; /* Chrome/Safari/Opera */
  -khtml-user-select: none; /* Konqueror */
  -moz-user-select: none; /* Firefox */
  -ms-user-select: none; /* Internet Explorer/Edge */
  user-select: none;
}

.rdtPicker th.rdtDisabled,
.rdtPicker th.rdtDisabled:hover {
  background: none;
  color: #999999;
  cursor: not-allowed;
}
.rdtPicker thead tr:first-child th {
  cursor: pointer;
}
.rdtPicker thead tr:first-child th:hover {
  background: #eeeeee;
}

.rdtPicker tfoot {
  border-top: 1px solid #f9f9f9;
}

.rdtPicker button {
  border: none;
  background: none;
  cursor: pointer;
}
.rdtPicker button:hover {
  background-color: #eee;
}

.rdtPicker thead button {
  width: 100%;
  height: 100%;
}

td.rdtMonth,
td.rdtYear {
  height: 50px;
  width: 25%;
  cursor: pointer;
}
td.rdtMonth:hover,
td.rdtYear:hover {
  background: #f9f9f9;
  cursor: pointer;
  color: #e94675;
}

.rdtCounters {
  display: inline-block;
}

.rdtCounters > div {
  float: left;
}

.rdtCounter {
  height: 100px;
}

.rdtCounter {
  width: 40px;
}

.rdtCounterSeparator {
  line-height: 100px;
}

.rdtCounter .rdtBtn {
  height: 40%;
  line-height: 40px;
  cursor: pointer;
  display: block;

  -webkit-touch-callout: none; /* iOS Safari */
  -webkit-user-select: none; /* Chrome/Safari/Opera */
  -khtml-user-select: none; /* Konqueror */
  -moz-user-select: none; /* Firefox */
  -ms-user-select: none; /* Internet Explorer/Edge */
  user-select: none;
}
.rdtCounter .rdtBtn:hover {
  background: #eee;
}
.rdtCounter .rdtCount {
  height: 20%;
  font-size: 1.2em;
}

.rdtMilli {
  vertical-align: middle;
  padding-left: 8px;
  width: 48px;
}

.rdtMilli input {
  width: 100%;
  font-size: 1.2em;
  margin-top: 37px;
}

.rdtTime td {
  cursor: default;
}

.flexContainer {
  display: flex;
}
.flexContainer input {
  flex: 1;
}
.rdtPicker td.rdtActive,
.rdtPicker td.rdtActive:hover {
  background-color: #f9f9f9;
  color: #e94675;
}

.rdtPicker td.rdtDay:hover,
.rdtPicker td.rdtHour:hover,
.rdtPicker td.rdtMinute:hover,
.rdtPicker td.rdtSecond:hover,
.rdtPicker .rdtTimeToggle:hover {
  background: #f9f9f9;
  cursor: pointer;
  color: #e94675;
}
.rdtPicker td.rdtToday:before {
  content: "";
  display: inline-block;
  border-left: 7px solid transparent;
  border-bottom: 7px solid #ea5c8b;
  border-top-color: rgba(0, 0, 0, 0.2);
  position: absolute;
  bottom: 4px;
  right: 4px;
}
.rdt input {
  box-sizing: border-box;
  font-size: 16px;
  background-image: url(../../common/assets/images/svg/calander.svg);
  background-position: 10px 0px;
  background-repeat: no-repeat;
  padding: 12px 10px 12px 45px;
  transition: width 0.4s ease-in-out;
  background-size: 1.2rem 100%;
  cursor: pointer !important;
  overflow: hidden;
}

/* left picker */
.leftPicker .rdtPicker {
  left: 0;
}

.rdt .form-control[readonly] {
  background-color: #ffffff;
  border: 1px solid #b7b2b2;
}
.rdt .form-control {
  background-color: #ffffff;
}

/* Dropdown button  */
.commonDropDownWrapper {
  width: 100%;
  padding-right: 30px;
}

.commonDropDownWrapper button:hover,
.commonDropDownWrapper button:active {
  background-color: #ffff;
  color: black;
  border: 1px solid #b7b2b2;
}
.commonDropDownWrapper button {
  background-color: #ffff !important;
  color: black !important;
  box-shadow: none !important;
  border: 1px solid #b7b2b2;
  border-radius: 2px;
  text-align: left;
  min-width: 150px;
}

.commonDropDownWrapper button::after {
  float: right !important;
  margin-top: 10px;
}

.commonDropDownWrapper .dropdownMenuWrapper {
  border: none !important;
  box-shadow: 0px 3px 10px -4px #ccc;
}

.commonDropDownWrapper .dropdownMenuItem {
  padding: 10px !important;
  min-width: 150px;
}
.commonDropDownWrapper .dropdownMenuItem:hover {
  color: #e91e63;
  background-color: #f9f9f9;
}

.commonPointer {
  cursor: pointer;
}

.maxQuantitySwitch {
  cursor: pointer;
  margin: 0px 50px 0px 50px;
}

.cursor-disabled {
  cursor: not-allowed !important;
}

.outline-0 {
  outline: 0 !important;
}

.under-construction {
  font-size: 22px;
  padding-top: 15px;
}

.under-construction-img {
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}

.under-construction-row {
  background: white;
  margin: 0px !important;
  margin-bottom: 10px !important;
  min-height: calc(100vh - 218px);
  justify-content: center;
  align-items: center;
}

.fixed-body-height {
  min-height: calc(100vh - 218px);
}

.cursor-pointer {
  cursor: pointer;
}

.image-cropper-wrapper {
  overflow: hidden;
  width: 100%;
  display: flex;
  height: 440px;
  position: relative;
}

.dashboard .menu-item {
  border: 0;
  border-radius: 0;
  box-shadow: 0px 11px 20px -12px rgba(0, 0, 0, 0.04);
  -webkit-transition: all 0.3s cubic-bezier(0.165, 0.84, 0.44, 1);
  transition: all 0.3s cubic-bezier(0.165, 0.84, 0.44, 1);
}

.dashboard .menu-item:hover {
  box-shadow: 0 0 11px rgba(33, 33, 33, 0.2);
}

.dashboard .menu-item .description {
  margin-top: 0.5rem;
  line-height: 1.3;
}

.form-custom-inputs-disable {
  pointer-events: none;
  opacity: 1.5;
  background: #e9ecef;
}
.common-form-error {
  display: flex;
  align-content: center;
  width: 100%;
  font-size: 14px;
  color: #dc3545;
  font-weight: normal;
}

.link-info:hover {
  text-decoration: underline !important;
  color: blue !important;
}
.title-sm {
  font-size: 14px !important;
  font-weight: bold !important;
}
.btn {
  min-height: 39px;
}
.btn-sm {
  min-height: 38px;
    height: 38px;
}
.btn-width {
  min-width: 90px;
}
.btn-close,
.btn.btn-close {
  width: 26px;
  height: 26px;
  min-height: 24px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #fff;
  border: 1px solid #ddd;
  border-radius: 100%;
}
.btn-close img,
.btn-close svg {
  width: 16px;
}
.btn-primary {
  background-color: #229e79 !important;
  border-color: #229e79 !important;
}
.btn-outline-primary {
  border-color: #229e79 !important;
  color: #229e79 !important;
}
.btn-outline-primary:hover {
  border-color: #229e79 !important;
  background-color: #229e79 !important;
  color: #fff !important;
}
.show-more,
.btn.show-more {
  background-color: #ebebeb;
  color: #000;
  font-weight: bold;
  height: 50px;
  border-radius: 25px;
  border: 0;
  padding: 0.5rem 1.5rem;
}
.input-password {
  position: relative;
}
.input-password input {
  padding-right: 48px;
}
.password-eye {
  position: absolute;
  right: 0;
  top: 0;
  width: 38px;
  height: 38px;
  border: 0;
  border-radius: 0;
  outline: 0;
  background-color: transparent;
  background-image: url(../assets/images/svg/eye-hidden.svg);
  background-repeat: no-repeat;
  background-position: center center;
  background-size: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
}
.password-eye.active {
  background-image: url(../assets/images/svg/eye-visible.svg);
}
.password-eye img,
.password-eye svg {
  width: 24px;
  margin: 0 auto;
}
.password-eye:focus {
  outline: 0;
  box-shadow: none;
  border: 0;
}
.is-invalid ~ .password-eye {
  display: none;
}
.breadcrumb {
  padding: 0 !important;
  margin-bottom: 0 !important;
  background-color: transparent !important;
  border-radius: 0;
}
.breadcrumb .breadcrumb-item,
.breadcrumb .breadcrumb-item a {
  display: flex;
  align-items: center;
  line-height: 1;
}
.breadcrumb .breadcrumb-item img {
  width: 12px;
  margin-right: 0.2rem;
}

.dropdown-custom .dropdown-toggle {
  background-color: #fff;
  border-color: #ced4da;
  min-width: 100px;
  height: 38px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.dropdown-custom .dropdown-toggle.btn-sm {
  height: 32px;
}
.dropdown-custom .dropdown-toggle:after {
  border: 0;
  background: url(../assets/images/svg/angle-arrow-down.svg) center center;
  background-size: 10px;
  width: 10px;
  height: 10px;
}
.dropdown-custom.show .dropdown-toggle:after {
  transform: rotate(180deg);
}
.dropdown-custom .dropdown-toggle:hover {
  background-color: #f9f9f9;
}
.dropdown-custom .dropdown-toggle:focus,
.dropdown-custom .dropdown-toggle:not(:disabled):not(.disabled):active {
  background-color: #fff;
  border-color: #ced4da;
  box-shadow: none !important;
}
.dropdown-custom .dropdown-menu {
  padding: 0.25rem 0;
}
.dropdown-custom .dropdown-item {
  padding: 0.25rem 0.75rem;
  font-size: 14px;
}
.dropdown-custom.dropdown-number .dropdown-toggle {
  min-width: 60px;
}
.dropdown-custom.dropdown-number .dropdown-menu {
  min-width: 60px;
}

.custom-control {
  display: flex !important;
  font-size: 14px;
  min-height: 18px;
}
.custom-control .custom-control-label {
  cursor: pointer;
}
.custom-control .custom-control-text {
  margin-top: 1px;
}
.custom-checkbox .custom-control-label::before {
  border: 2px solid #b9b9b9 !important;
  width: 18px;
  height: 18px;
  border-radius: 2px !important;
  top: 3px;
}
.custom-checkbox .custom-control-label::after {
  width: 18px;
  height: 18px;
}
.custom-checkbox .custom-control-input:checked ~ .custom-control-label::before {
  background-color: #229e79;
  border: 2px solid #229e79 !important;
}
.custom-checkbox .custom-control-input:checked ~ .custom-control-label::after {
  background-image: none !important;
  top: 11px;
  left: -0.96rem;
}

table.table-vcenter th,
table.table-vcenter td {
  vertical-align: middle;
}
.btn-square,
.btn.btn-square {
  border: 0;
  padding: 0;
  width: 24px;
  height: 24px;
  min-height: 24px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.btn-square img,
.btn-square svg {
  width: 22px;
}
.btn-circle,
.btn.btn-circle {
  width: 33px;
  height: 33px;
  min-height: 33px;
  border-radius: 100%;
  background-color: #fff;
  border: 1px solid #ddd;
  padding: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}
.btn-circle:hover,
.btn.btn-circle:hover {
  background-color: #f2f2f2;
}
.btn-circle img,
.btn-circle svg {
  width: 16px;
}

.page-item.active .page-link {
  background-color: #229e79 !important;
  border-color: #229e79 !important;
  color: #fff !important;
}

.tab-item {
  background-color: #f4f4f4;
  margin-bottom: 10px;
}
.tab-item .tab-btn {
  cursor: pointer;
  display: flex;
  width: 100%;
  padding: 0.75rem 0;
  font-weight: bold;
  font-size: 16px;
}
.tab-item .tab-btn:after {
  content: "";
  width: 12px;
  margin-left: auto;
  background: url(../assets/images/svg/arrow-down-sm.svg) right center no-repeat;
  background-size: 12px;
}
.tab-item.active .tab-btn:after {
  transform: rotate(180deg);
}
.tab-item .collapse,
.tab-item .collapsing {
  margin-right: -15px;
  margin-left: -15px;
  background-color: #fff;
}
.tab-item .tab-content {
  padding: 1rem;
}
.tab-icon-col {
  flex: 0 0 40px;
  width: 40px;
}
.tab-content .form-control {
  background-color: #f7f7f7;
  border: 0;
  height: 32px;
  font-size: 14px;
}
.tab-content .form-control:disabled {
  background-color: #fff;
  color: #b2b2b2;
  padding: 0;
  height: auto;
  font-size: 14px;
}
.tag-outer {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  list-style: none;
  padding: 0;
  margin: 0;
}
.tag-outer .tag-list {
  margin-right: 1rem;
  margin-bottom: 1rem;
  position: relative;
}
.tag-outer .tag-item {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  width: auto;
  min-width: 100px;
  min-height: 40px;
  text-align: center;
  padding: 0.5rem 1rem;
  background-color: #fff;
  border: 1px solid #707070;
  border-radius: 20px;
  white-space: nowrap;
  outline: 0;
}
.tag-outer .tag-item:hover {
  background-color: #f2f2f2;
}
.tag-outer input.tag-item {
  background-color: #f2f2f2;
  padding-right: 35px;
}
.tag-outer .tag-item:active,
.tag-outer .tag-item:focus {
  outline: 0;
  box-shadow: none;
  border-color: #707070;
}

.tab-custom.nav-tabs .nav-item {
  margin-right: 1.5rem;
}
.tab-custom.nav-tabs .nav-link {
  border: 0;
  border-top-left-radius: 0;
  border-top-right-radius: 0;
  cursor: pointer;
  padding: 0.75rem 0.1rem;
}
.tab-custom.nav-tabs .nav-link.active {
  background-color: transparent;
  border-bottom: 3px solid #229e79;
}

/* styles for react-paginate component begin */
.pagination {
  display: flex;
  padding-left: 0;
  list-style: none;
  border-radius: 0.75rem;
}
.pagination li {
  border: 1px solid #229e79;
  padding: 10px;
}
.page {
  border: 1px solid #229e79;
  padding: 10px;
}
.pageLink {
  padding: 10px;
}
.activePage {
  background-color: #229e79;
  color: #fff !important;
}
.activePage a {
  color: #fff !important;
}
/* styles for react-paginate component ends here */

.custom-datepicker {
  display: flex;
  align-items: center;
  background-color: #ffffff;
  border: 1px solid #dbdbdb;
  border-radius: 5px;
  position: relative;
}
.custom-datepicker .rdt {
  position: static;
}
.custom-datepicker .rdtPicker {
  left: 0;
}
.custom-datepicker .rdt .form-control {
  background-color: transparent;
  border: 0;
  width: 100%;
  max-width: 180px;
}
.custom-datepicker .rdt .form-control:focus,
.custom-datepicker .rdt .form-control:active {
  border: 0;
  box-shadow: none !important;
  outline: 0;
}
.custom-datepicker .formTextLabel {
  margin-bottom: 0;
  margin-left: 0.5rem;
}

.pagination {
  display: flex;
  padding-left: 0;
  list-style: none;
  border-radius: 0.75rem;
}
.pagination li {
  border: 1px solid #229e79;
  padding: 3px 2px;
  margin: 0 2px;
  border-radius: 4px;
  cursor: pointer;
}
.pagination li.previous, .pagination li.next {
  padding: 3px 8px;
}
.pagination li.disabled {
  pointer-events: none;
}
.page {
  border: 1px solid #229e79;
  padding: 10px;
}
.pageLink {
  padding: 10px;
}
.activePage {
  background-color: #229e79;
  color: #fff !important;
}
.activePage a {
  color: #fff !important;
}

.table-common td img {
  max-width: 80px;
}
