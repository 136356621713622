
/**
 * Copyright(c) 2020 Mozanta Technologies Private Ltd.
 *
 * All rights reserved.
 *
 * This software is the confidential and proprietary information of Mozanta
 * ("Confidential Information"). You shall not disclose such Confidential
 * Information and shall use it only in accordance with the terms of the
 * contract agreement you entered into with Mozanta.
 *
 * OrderReport.module.css
 *
 * @author Naseef O
 *
 */

.block1 .buttonNewUser{
  color: #ffffff;
  width: 113px;
  border-radius: 2px;
  background-color: #e91e63;
  border: 0px;
  float: right;
}


/* block 2 */

.tableWrapper  {
  padding-bottom: 20px;
    background: #ffffff;
    margin: 0px !important;
    min-height: calc(100vh - 408px);
    margin-bottom: 10px !important;
}

.block2 .dropdownBtn {
  padding-right: 0px;
}
  

/* block 3 */

.block3 .dropdownBtn{
  padding: 0px;
}

.block3 .dropdownBtn button{
  float: right !important;
  font-size: 11px;
  min-width: 100px;
}
.block3  .dateFilterRow{
  border-radius: 4px;
  border: 1px solid #dff3f7;
  background-color: #fdfdfd;
  color: black;
}
.block3 .verticalLine {
  height: 15px;
  padding: 5px;
}
.block3 .verticalLineNone {
  padding: 5px;
  border-radius: 4px;

}
.block3 .verticalLine:hover {
  background-color: #17a2b8;
  border-radius: 4px;
  color: rgb(255, 255, 255);
}
.block3 .verticalLineNone:hover {
  background-color: #17a2b8;
  border-radius: 4px;
  color: rgb(255, 255, 255);
}