/**
 * Copyright(c) 2020 Mozanta Technologies Private Ltd.
 *
 * All rights reserved.
 *
 * This software is the confidential and proprietary information of Mozanta ("Confidential
 * Information"). You shall not disclose such Confidential Information and shall use it only in
 * accordance with the terms of the contract agreement you entered into with Mozanta.
 *
 * @author Amjad Rehman A
 */

.borderRadius2 {
  border-radius: 0.125rem !important;
}

.btnColor{
  background: #229E79 !important;
  border-color: #229E79 !important;
  min-width: 50%;
}

.block1{
  min-height: -webkit-fill-available;
}

.block2{
  display: flex;
  justify-content: center;
}