/**
 * Copyright(c) 2020 Mozanta Technologies Private Ltd.
 * 
 * All rights reserved.                
 * 
 * This software is the confidential and proprietary information of Mozanta ("Confidential
 * Information"). You shall not disclose such Confidential Information and shall use it only in
 * accordance with the terms of the contract agreement you entered into with Mozanta.
 * 
 * @author Aardra S
 */

.borderRadius2 {
  border-radius: 0.125rem;
}

.secondaryColor {
  background-color: #757575 !important;
  border-color: #757575 !important;
}

.primaryColor {
  background: #e91e63 !important;
  border-color: #e91e63 !important;
}

.btn1 {
  min-width: 6rem;
}

.btn2 {
  min-width: 9rem;
}

.btnRule{
   background-color: white !important;
  color:black!important;
  border-color: #b4bfca !important;
  min-width: 6rem;
}

.btnAdd{
  background-color:#dadada !important; 
  color:black!important;
  border-color: #808284!important;
  min-width: 7rem;
}

.badge{
  background-color:#dadada !important;  
  min-width: 7rem;
  min-height: 2.25rem;
  border-color: #808284!important;
}

.badgeTag{
  font-size: 14px;
}

.quesIcon{
  background-color:#ced4da !important;  
  min-width: 2.25rem;
  border-color: #808284!important;
}

.tabLink{
  cursor: pointer;
}

