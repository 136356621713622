/**
 * Copyright(c) 2020 Mozanta Technologies Private Ltd.
 * 
 * All rights reserved.
 * 
 * This software is the confidential and proprietary information of Mozanta ("Confidential
 * Information"). You shall not disclose such Confidential Information and shall use it only in
 * accordance with the terms of the contract agreement you entered into with Mozanta.
 * 
 * @author Indrajith C
 * 
 */


 .dropDown  button:hover, .dropDown  button:active{
    background-color: #ffff ;
    color: black;
    border: solid 1px #313131;

}
.dropDown  button{
    background-color: #ffff !important;
    color: black !important;
    box-shadow: none !important;
    border: solid 1px #313131;
    border-radius: 2px; 
    text-align: left;
    display: flex;
    align-items: center;
    padding: 0.1rem 0.2rem;
    height: unset !important;
    min-height: calc(1.5em + 0.75rem + 2px) !important;

}

.dropDown.disabled button{
    background-color: #edf0f5 !important;
    opacity: 0.65;
}

.dropDown  button> span[disabled] {
    color: #767f86 !important;
}

/*  ======== chip component =========   */
.dropDown button .chip {
    padding: 0.05rem 0.25rem 0.05rem 0.4rem;
    border-radius: 50px;
    border: 1px solid;
    border-color: #b7b2b2;
    display: flex;
    align-items: center;
    margin: 1px 0;
}

.dropDown button .chip:not(:last-child) {
    margin-right: 0.125rem;
}

.dropDown button span.chip > span.remove {
    border-radius: 50%;
    border: 1px solid;
    width: 20px;
    height: 20px;
    margin-left: auto;
    display: flex;
    border-color: #b7b2b2;
}
.dropDown button span.chip > span.remove>img{
    width: 18px;
    height: 18px;
}

/* =========== TYPEAHEAD ============ */
.dropDown button input.typeahead {
    border: none;
    display: flex;
    height: unset;
    width: auto;
    min-width: 150px;
    cursor: text;
    padding: 0.125rem 0.5rem;
    color: #495057;
} 
 
.dropDown button input.typeahead:focus {
    color:   #495057 !important;
    background-color: none !important;
    border-color: none !important;
    outline: 0;
    box-shadow: none !important;
}

.dropDown  button::after {
    margin-left: auto !important;
    content: unset;
}

.dropDown  button.hasDropdown::after {
    content: ' ';
  }

.dropDown .dropdownItem{
    border: none  !important;
    padding: 0.5rem 0.75rem !important;
    cursor: default !important;
}

.dropDown .dropdownItem:hover{
  color:#229E79 !important;
  background-color: #f9f9f9 !important;
}

.dropDown .dropdownItem:focus{ 
 outline: none !important;
 box-shadow: none !important;
}

.dropDown .dropdownItem.active {
  color:#f9f9f9 !important;
  background-color: #229E79  !important;
  border-top: 1px solid white !important;
}


.dropDown .dropdownItem[disabled] {
    color:#767f86 !important; 
  background-color: #f9f9f9 !important;
  cursor: not-allowed !important;
}

.dropDown .dropdownWrapper  {
    width: 100% !important;
    border: none !important;
    box-shadow: 0px 3px 10px -4px #ccc;
    max-height: 22.5rem;
    overflow-y: auto;
}

.dropDown.invalid  > button[type="button"]{
    border-color: #dc3545;
  padding-right: calc(1.5em + 0.75rem);
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='12' height='12' fill='none' stroke='%23dc3545' viewBox='0 0 12 12'%3e%3ccircle cx='6' cy='6' r='4.5'/%3e%3cpath stroke-linejoin='round' d='M5.8 3.6h.4L6 6.5z'/%3e%3ccircle cx='6' cy='8.2' r='.6' fill='%23dc3545' stroke='none'/%3e%3c/svg%3e");
  background-repeat: no-repeat;
  background-position: right calc(0.375em + 0.1875rem) center;
  background-size: calc(0.75em + 0.375rem) calc(0.75em + 0.375rem); 
}
