/**
 * Copyright(c) 2020 Mozanta Technologies Private Ltd.
 * 
 * All rights reserved.
 * 
 * This software is the confidential and proprietary information of Mozanta ("Confidential
 * Information"). You shall not disclose such Confidential Information and shall use it only in
 * accordance with the terms of the contract agreement you entered into with Mozanta.
 * 
 * @author Indrajith C
 * 
 */
 .block1{
  padding-top: 20px;
  padding-bottom: 20px;
}
.block1 .navigationManagementnText{
  text-align: left;
  color: #333333;
  padding-bottom: 17px;
}

.block1 .buttonAddMenu{
  color: #ffffff;
  width: 113px;
  height: 35px;
  border-radius: 2px;
  background-color: #448aff;
  border: 0px;
  float: right;
}

.blockWrapper{
  min-height: calc(100vh - 385px);
} 
 
.borderRadius2 {
  border-radius: 0.125rem !important;
}

.secondaryColor {
  background-color: #757575 !important;
  border-color: #757575 !important;
}

.primaryColor {
  background: #17a2b8 !important;
  border-color: #17a2b8 !important;
}

.btn {
  min-width: 7rem;
}

.icon {
  width: 1em;
}


/* ============= STEP FORM ========= */

.step .stepHeader {
  -webkit-touch-callout: none;  
  -webkit-user-select: none; 
   -khtml-user-select: none; 
     -moz-user-select: none; 
      -ms-user-select: none; 
          user-select: none;  
}

.step .stepHeader .stepContainer {
  list-style: none;
}
.step .stepHeader .stepContainer> .stepItem{
  cursor: pointer;
  opacity: 0.4;
}

.step .stepHeader .stepContainer> .stepItem>.index {
  min-width: 39px;
  min-height: 39px;
  max-width: 39px;
  max-height: 39px;
  font-weight: 700;
  font-size: 17px;
  border: 2px solid;
  border-color: #222222;
}

.step .stepHeader .stepContainer> .stepItem[active]  ,
  .step .stepHeader .stepContainer> .stepItem.active , 
  .step .stepHeader .stepContainer> .stepItem[completed]  ,
    .step .stepHeader .stepContainer> .stepItem.completed  { 
  opacity: 1;
  cursor: default;
}

.step .stepHeader .stepContainer> .stepItem[active] >.index ,
  .step .stepHeader .stepContainer> .stepItem.active >.index  {
  border-color: #6AADE0;
}

.step .stepHeader .stepContainer> .stepItem[completed] >.index ,
  .step .stepHeader .stepContainer> .stepItem.completed >.index  {
    border-color: #6AADE0; 
    background-color: #6AADE0; 
}

.step .stepHeader .stepContainer> .stepItem[completed] >.index>span ,
  .step .stepHeader .stepContainer> .stepItem.completed >.index>span {
  display: inline-block;
    transform: rotate(45deg);
    height: 20px;
    width: 10px;
    border-bottom: 3px solid white;
    border-right: 3px solid white;
    color: transparent;
    margin-top: -5px;
}

.step .stepHeader .stepContainer> .stepItem[disabled] ,
.step .stepHeader .stepContainer> .stepItem.disabled   {
  opacity: 0.4;
  cursor: not-allowed;
}

.step .stepHeader .stepContainer> .stepItem[disabled]>.index ,
.step .stepHeader .stepContainer> .stepItem.disabled >.index  {
  border-color: #222222;
}


/* =============== step two =================== */

.form .formTextLabel {
  color: #000000CC;
  opacity: 0.4;
}
.block2 {
  padding-top: 20px;
  padding-bottom: 20px;
} 

.borderRadius2 {
  border-radius: 0.125rem !important;
}

.secondaryColor {
  background-color: #757575 !important;
  border-color: #757575 !important;
}

.primaryColor {
  background: #17a2b8 !important;
  border-color: #17a2b8 !important;
}


.btn {
  min-width: 7rem;
}

.tabContainer .tabHeader  {
  border-bottom: 1px solid #dfdfdf;
}

.tabContainer .tabHeader.active {
  border-bottom: 3px solid #17a2b8;
}

.collapseIcon > img {
  width: 15px;
  height: 15px;
  -webkit-transition: 0.6s ease-out;
  -moz-transition:  0.6s ease-out;
  transition:  0.6s ease-out;
}

.openCollapse {
  -webkit-transform: rotateZ(-90deg);
  -moz-transform: rotateZ(-90deg);
  transform: rotateZ(-90deg);
}

.closeCollapse { 
  -webkit-transform: rotateZ(90deg);
  -moz-transform: rotateZ(90deg);
  transform: rotateZ(90deg);
}

.rightCheckbox >span {
  padding-right: 24px;
  z-index: 1;
}

.rightCheckbox > label {
  left: 8px;
}