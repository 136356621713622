/* New UI styles */
.SearchCol {
  position: relative;
}
.SearchCol input[type="text"] {
 padding-right: 48px;
 min-width: 220px;
 height: 38px;
 font-size: 14px;
}
.SearchCol .btn,
.SearchCol button {
  position: absolute;
  right: 0;
  top: 0;
  width: 38px;
  height: 38px;
  background-color: transparent;
  border: 0;
  display: flex;
  align-items: center;
  justify-content: center;
}
.SearchCol .btn img,
.SearchCol button img {
  width: 16px;
}
.SearchCol .btn:hover,
.SearchCol button:hover,
.SearchCol .btn:focus,
.SearchCol button:focus,
.SearchCol .btn:not(:disabled):not(.disabled):active,
.SearchCol button:not(:disabled):not(.disabled):active {
  background-color: transparent;
  outline: 0;
  border: 0;
  box-shadow: none !important;
}