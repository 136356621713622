/**
 * Copyright(c) 2020 Mozanta Technologies Private Ltd.
 *
 * All rights reserved.
 *
 * This software is the confidential and proprietary information of Mozanta ("Confidential
 * Information"). You shall not disclose such Confidential Information and shall use it only in
 * accordance with the terms of the contract agreement you entered into with Mozanta.
 *
 * @author Amjad Rehman A
 */

.borderRadius2 {
  border-radius: 0.125rem !important;
}

/** =========== ACTION AREA STYLES ============== **/
.areaActionRight {
  display: flex;
  justify-content: flex-end;
}

.secondaryColor,
.secondaryColor:focus,
.secondaryColor:active {
  background-color: #757575 !important;
  border-color: #757575 !important;
}

.secondaryColor:hover {
  background-color: #919191 !important;
  border-color: #919191 !important;
}

.border {
  border: 1px solid #c4c4c4;
}

.tableWrapper{
  min-height: calc(100vh - 365px);
}

.pointer{
  cursor: pointer;
}