/**
 * Copyright(c) 2020 Mozanta Technologies Private Ltd.
 * 
 * All rights reserved.
 * 
 * This software is the confidential and proprietary information of Mozanta ("Confidential
 * Information"). You shall not disclose such Confidential Information and shall use it only in
 * accordance with the terms of the contract agreement you entered into with Mozanta.
 * 
 * @author Indrajith C
 * 
 */

 .liveBtnWrapper .liveBtn{
  border: none;
  border-radius: 2px;
  text-transform: uppercase;
}
 .block1{
  padding-top: 20px;
  padding-bottom: 20px;
}
.block1 .navigationManagementnText{
  text-align: left;
  color: #333333;
  padding-bottom: 17px;
}

.block1 .buttonAddMenu{
  color: #ffffff;
  width: 113px;
  height: 35px;
  border-radius: 2px;
  background-color: #448aff;
  border: 0px;
  float: right;
}
.tableWrapper{
  min-height: calc(100vh - 385px);
}

/* ============= table actions ===================== */
.actions > span[disabled]  { 
  /* cursor: not-allowed; */
  pointer-events: none !important;
}

.actions > span[disabled] > img {
  opacity: 0.4; 
}
