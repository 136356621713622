
/**
 * Copyright(c) 2020 Mozanta Technologies Private Ltd.
 *
 * All rights reserved.
 *
 * This software is the confidential and proprietary information of Mozanta
 * ("Confidential Information"). You shall not disclose such Confidential
 * Information and shall use it only in accordance with the terms of the
 * contract agreement you entered into with Mozanta.
 *
 * PageCustomization.module.css
 *
 * @author Naseef O
 *
 */

.block2{
    padding-top: 20px;
    padding-bottom: 20px;
}
.block2 .pageSelectionText{
  
    text-align: left;
    color: #333333;
    padding-bottom: 17px;
}


.block2 .pageSelection  button:hover, .pageSelection  button:active{
    background-color: #ffff ;
    color: black;
    border: solid 1px #313131;

}
.block2 .pageSelection  button{
    background-color: #ffff !important;
    color: black !important;
    box-shadow: none !important;
    border: solid 1px #313131;
    border-radius: 2px;
    min-width: 230px;
    text-align: left;

}

.block2 .pageSelection  button::after {
    float: right !important;
    margin-top: 10px;
}
.block2 .pageSelection .dropdownItem{
    padding: 10px !important;
}
.block2 .pageSelection .dropdownItem:hover{
  color:#e91e63;
  background-color: #f9f9f9;
}

.block2 .pageSelection .dropdownWrapper  {
    width: 100% !important;
    border: none !important;
    box-shadow: 0px 3px 10px -4px #ccc;
    height: 194px;
    overflow: auto;
}

.block2 .pagePreview .pagePreviewText{
    padding-right: 20px;
    float: right;
    color: #333333;
    padding-bottom: 5px;
    
}
.block2 .pagePreview .selectedPageImg {
    height: 100px;
    object-fit: cover;
    display: flex;
    justify-content:center;
    border: 1px solid #e91e63;
}
.block2 .pagePreview  {
    display: flex;
    justify-content:center;
}

.block2 .pagePreviewWrapper .clickMe{
    display:  none;
}
.block2 .pagePreviewWrapper {
    position: relative;
    display: flex;
    width: 73px;
    justify-content: center;
}
.block2 .pagePreviewWrapper:hover .clickMe{
    display: flex;
    flex-direction: column;
    position: absolute;
    bottom: 44%;
    width: 73px;
    padding: 3px;
    z-index: 1;
    justify-content: center;
    align-items: center;
    background: white;
    text-align: center;
    font-weight: 500;
}
.liveBtnWrapper{
    display: flex;
    align-items: center;
    justify-content: flex-end;
}
 .liveBtnWrapper button{
    font-size: 11px;
}
 .liveBtn{
  border: none;
  border-radius: 2px;
  text-transform: uppercase;
}
.liveBtnPush{
    width: max-content;
    border: none;
    border-radius: 2px;
    text-transform: uppercase;
  }

 .liveBtn a{
  color: white !important;
}

.block2 .customLandingToggle{
  padding-top: 20px;
  padding-bottom: 20px;
}

.block3{
    padding-top: 20px;
    padding-bottom: 15px;
}
.block3 .buttonAdd{
    color: #ffffff;
    width: 113px;
    height: 35px;
    border-radius: 2px;
    background-color: #e91e63;
    border: 0px;
    float: right;
}
.block3 .buttonCancel{
    width: 113px;
    height: 35px;
    color: white;
    border-radius: 2px;
    border: 0px;
    float: right;
    margin-right: 10px !important;
}

.block3 .pageWidgetsText{
    padding-right: 6px;
    float: right;
    color: #333333;
}

.block3 .widgetsTable {
    padding-top: 20px;
}

.block3 .widgetsTable  thead{
    background-color: #edf0f5;
}

.block3 .widgetsTable  thead th{
    font-size: 14px;
    font-weight: bold;
    text-align: left;
}

.block3 .widgetsTable  tbody tr{
    text-align: left;
}

.widgetContentEditorPreviewCol{
    justify-content: center;
    align-items: center;
    justify-items: center;
}
.widgetContentEditorPreviewRow{
    justify-content: center;
    height: 100%;
    align-items: center;
    background: #f9f9f9;
    justify-items: center;
    margin-right: 0px !important;
}
.widgetContentEditorPreviewRow1{
    height: 73%;
}

.tableWrapper{
  min-height: calc(100vh - 459px);
}

.spinnerWrapper {
  text-align: center;
  color: #17a2b8;
}

.contentInfo{
    background: #FF9800;
    text-align: center;
    display: flex;
    justify-content: center;
    color: white;
    border-radius: 2px;
    padding: 10px 5px;
}


/* SEO block */
.seoText {
    font-size: 14px;
    font-weight: 600;
}
.seoText .value{
    font-weight: 500;
    padding-left: 10px;
    padding-top: 10px;
}
.selectedCategory{
    font-size: 18px;
    font-weight: 500;
    color: #F44336;
    justify-content: center;
    align-items: center;
    display: flex;
}