.headerWrapper{
    background: white;
    position: fixed;
    height: 75px;
    display: inline-grid;
    z-index: 100;
    left: 32px;
    right: 32px;
    top: 0px;
    padding: 10px 0px;
    border-bottom: 1px solid #f2f2f2
}
.menuWrap{
    display: block;
    width: 100%;
}
.menuWrap2{
    display: inline-block;
    width: auto;
    height: 32px;
    padding-left: 145px;
    position: absolute;
    top: 2px;
}

.logo{
    padding: 0px 5px 3px 3px;
    float: left;
    width: 136px;
    margin-top: -6px;
}
.logout{
    float: right;
    display: inline-block;
    font-weight: bold;
    cursor: pointer;
    padding-left: 20px;
}
.avatar{
    display: inline-block;
    float: left;
    width: 42px;
    height: 42px;
    border-radius: 100%;
    background-color: grey;
    padding: 1px;
    margin-top: -4px;
}
.avatar img{
    max-width: 100%;
    max-height: 100%;
}
.logout img{
    margin-left: 8px;
    width: 14px;
    height: 14px;
}
.languageSwitcher  button{
    background-color: #ffff !important;
    color: black !important;
    border: none;
    box-shadow: none !important;
    padding-left: 0.3125rem !important;

}
.languageSwitcher  button:hover, .languageSwitcher  button:active{
    background-color: #ffff ;
    color: black;
    border: none
}

.selectedLanguage{
    padding-left: .625rem;
    padding-top: 0.5rem;
}
/*  notification icon style fix */
.notification {
    float: right;
    margin-left: 15px;
    cursor: pointer;
}
.siteName {
    float: right;
    margin-left: 15px;
    color :#1781FF;
    cursor: default;
}
.changeSite:hover{
  cursor: pointer;
  text-decoration: underline;
}
.profileButton {
    padding-left: 0.75rem;
}
.profileButton .btnProfile {
    background-color: #ffff !important;
    color: black !important;
    border: none;
    box-shadow: none !important;
    padding: 0.1rem 0.5rem;

}
.profileButton .btnProfile:hover,
.profileButton .btnProfile:active {
    background-color: #ffff ;
    color: black;
    border: none
}
.profileButton .dropdownMenu {
    border: 1px solid #ddd;
    border-radius: 0;
    box-shadow: 1px 1px 4px rgba(0,0,0,0.05);
    background-color: #fff;
    min-width: 200px;
}
.profileButton .dropdownMenu:before {
    content:"";
    position: absolute;
    right: 11px;
    top: -10px;
    width: 0;
    height: 0;
    border-style: solid;
    border-width: 0 10px 10px 10px;
    border-color: transparent transparent #fff transparent;
    z-index:9999;
}
.profileButton .dropdownMenu:after {
    content:"";
    position: absolute;
    right: 11px;
    top: -11px;
    width: 0;
    height: 0;
    border-style: solid;
    border-width: 0 10px 10px 10px;
    border-color: transparent transparent #ddd transparent;
    z-index: 9998;
}
.profileButton .dropdownMenu .dropdownItem {
    background-color: #fff;
    padding: 0.4rem 0.75rem;
    font-size: 15px;
}
.profileButton .dropdownMenu .dropdownItem:hover {
    background-color: #229E79;
    color: #fff;
}
.profileData {
    display: flex;
    align-items: center;
}
.profileData figure {
    width: 32px;
    height: 32px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 0;
    margin-right: 0.5rem;
}
.profileData figure img {
    width: 100%;
    border-radius: 100%;
}
.profileData .profileArrow {
    width: 10px;
    opacity: 0.8;
    margin-left: 0.5rem;
    margin-top: 0.2rem;
}