/**
 * Copyright(c) 2020 Mozanta Technologies Private Ltd.
 *
 * All rights reserved.
 *
 * This software is the confidential and proprietary information of Mozanta ("Confidential
 * Information"). You shall not disclose such Confidential Information and shall use it only in
 * accordance with the terms of the contract agreement you entered into with Mozanta.
 *
 * @author amritha
 */

.borderRadius2 {
  border-radius: 0.125rem !important;
}

/** =========== ACTION AREA STYLES ============== **/
.areaActionRight {
  display: flex;
  justify-content: flex-end;
}

.secondaryColor,
.secondaryColor:focus,
.secondaryColor:active {
  background-color: #757575 !important;
  border-color: #757575 !important;
}

.tableWrapper{
  min-height: calc(10vh - 365px);
}


.displayData {
  background: #f9f9f9;
  padding: 1rem;
}

.dataTableList {
  width: 100%;
  list-style: none;
  padding: 0;
  margin: 0 auto;
  background-color: #fff;
  border-radius: .25rem;
  overflow: hidden;
}

@media (max-width: 992px) {
  .dataTableList {
    max-width: 100%;
  }
}
.dataTableList li {
  display: flex;
  flex-direction: row;
  padding: 0.75rem 1rem;
  border-bottom: 1px solid #e3e7eb;
}

.dataTableList li:hover {
  background-color: #fcfcfc;
}

.dataTableList li .dataTableTitle {
  width: 30%;
  flex: 0 0 30%;
  color: #8f9bb3;
  word-break: break-word;
}

.dataTableList li .dataTableText {
  width: calc(70% - 1rem);
  flex: 0 0 calc(70% - 1rem);
  padding-left: 1rem;
  word-break: break-word;
}