
/**
 * Copyright(c) 2020 Mozanta Technologies Private Ltd.
 *
 * All rights reserved.
 *
 * This software is the confidential and proprietary information of Mozanta
 * ("Confidential Information"). You shall not disclose such Confidential
 * Information and shall use it only in accordance with the terms of the
 * contract agreement you entered into with Mozanta.
 *
 * SiteTraficReport.module.css
 *
 * @author Naseef O
 *
 */

 .block1 {
   background: #ffffff;
   margin: 0px !important;
   padding-top: 10px !important;
   margin-bottom: 10px !important;
}

/* block 2 */
.block2{
  display: flex;
  justify-content: space-between;
}
.block2 .reportCard{
  background: white;
  margin-bottom: 10px;
  flex: 0 0 32.333333%;

}
.block2 .reportCard .headding{
  padding: 10px 5px;
  font-size: 16px;

}
.block2 .reportCard .value{
  padding: 10px 5px;
  font-size: 30px;
  font-weight: 300;
}
/* block 3 */

.block3{
    padding-bottom: 20px;
    background: #ffffff;
    margin: 0px !important;
    min-height: calc(100vh - 512px);
    margin-bottom: 10px !important;
}

.block3  .dateFilterRow{
  border-radius: 4px;
  border: 1px solid #dff3f7;
  background-color: #fdfdfd;
  color: black;
  width: 126px;
  float: right;
}

.block3 .verticalLine {
  height: 15px;
  padding: 5px;
}
.block3 .verticalLineNone {
  padding: 5px;
  border-radius: 4px;

}
.block3 .verticalLine:hover {
  background-color: #17a2b8;
  border-radius: 4px;
  color: rgb(255, 255, 255);
}

.block3 .verticalLineNone:hover {
  background-color: #17a2b8;
  border-radius: 4px;
  color: rgb(255, 255, 255);
}