/**
 * Copyright(c) 2020 Mozanta Technologies Private Ltd.
 * 
 * All rights reserved.                
 * 
 * This software is the confidential and proprietary information of Mozanta ("Confidential
 * Information"). You shall not disclose such Confidential Information and shall use it only in
 * accordance with the terms of the contract agreement you entered into with Mozanta.
 * 
 * @author Aneesh K
 */

.block2 {
    padding-top: 20px;
    padding-bottom: 20px;
}

.borderRadius2 {
    border-radius: 0.125rem !important;
}

/** =========== ACTION AREA STYLES ============== **/
.block2 .areaActionRight {
    display: flex;
    justify-content: flex-end;
}

.block2 .areaActionRight>div:first-child {
    margin-right: 0.3rem;
}

.block2 .areaActionRight>div:last-child {
    display: flex;
}

/** ========= SEARCH FILED ================ **/
.block2 .areaActionRight .search {
    border: 1px solid;
    border-radius: 0.125rem;
}

.block2 .areaActionRight .search input {
    border: 0;
    width: 100%;
    height: calc(1.5em + 0.75rem + 0px);
}

.block2 .areaActionRight .search div {
    border: 0;
    border-radius: 0;
    background-color: inherit;
    padding-left: 0.6rem;
    padding-right: 0.35rem;
}

/***** ========== MAIN ACTION AREA ============ **********/
.block3 {
    padding: 1.25rem 0;
}

/***** ============== MAIN ACTION COMPONENT ========= *******/
.block3 .product {
    position: relative;
}

/***** ============== ACTION COMPONENT BODY ========= *******/

.block3 .product .productBody .productTable thead {
    background-color: #edf0f5;
}

.block3 .product .productBody .productItem {
    display: flex;
    flex-direction: column;
}

.block3 .product .productBody .productTable .productName {
    max-width: 100px;
}

.block3 .product .productBody .productTable .productImage {
    max-height: 50px;
}

/***** ===================== PAGINATION ======================= ******/
.block3 .product .pagination {}

.block3 .product .pagination .item {
    height: 25px;
    width: 25px;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
}

.block3 .product .pagination .arrowLeft,
.block3 .product .pagination .arrowRight {
    border-radius: 50%;
    border: 2px solid;
}

.block3 .product .pagination .arrowLeft>span,
.block3 .product .pagination .arrowRight>span {
    border-bottom: 2px solid;
    border-right: 2px solid;
    width: 8px;
    height: 8px;
    margin-top: -0.5px;
}

.block3 .product .pagination .arrowLeft>span {
    transform: rotate(127deg);
    margin-right: -2.5px;
}

.block3 .product .pagination .arrowRight>span {
    transform: rotate(315deg);
    margin-left: -2.5px;
}

.block3 .product .pagination .arrowLeft,
.block3 .product .pagination .arrowRight,
.block3 .product .pagination .item[active] {
    color: #e91e63;
    font-weight: 600;
}

.block3 .product .pagination .item[active] {
    cursor: default;
}

.block3 .product .pagination .item[disabled] {
    cursor: not-allowed;
    color: #5d5d5d;
}

.tableWrapper {
    min-height: calc(100vh - 387px);
}