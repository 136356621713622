.searchBoxWrapper {
  position: absolute;
    top: 10%;
    left: 10%;
    right: 10%;
    z-index: 3;
}

.searchBoxWrapper input {
  box-shadow:
  0 2.8px 2.2px rgba(0, 0, 0, 0.034),
  0 6.7px 5.3px rgba(0, 0, 0, 0.048),
  0 12.5px 10px rgba(0, 0, 0, 0.06),
  0 22.3px 17.9px rgba(0, 0, 0, 0.072),
  0 41.8px 33.4px rgba(0, 0, 0, 0.086),
  0 100px 80px rgba(0, 0, 0, 0.12) !important;
border: 0;
border-radius: 50px;
border: 1px solid whitesmoke;
padding-right: 2.4rem;
}

.searchBoxWrapper input:focus {
  box-shadow:
  0 2.8px 2.2px rgba(0, 0, 0, 0.034),
  0 6.7px 5.3px rgba(0, 0, 0, 0.048),
  0 12.5px 10px rgba(0, 0, 0, 0.06),
  0 22.3px 17.9px rgba(0, 0, 0, 0.072),
  0 41.8px 33.4px rgba(0, 0, 0, 0.086),
  0 100px 80px rgba(0, 0, 0, 0.12) !important;
}

.searchBoxWrapper svg {
  position: absolute;
  right: 14px;
  top: 12px;
}

.marker {
  position: absolute;
    top: 60%;
    left: 30%;
    width: 30px;
    height: 30px;
    transform: translate(-50%, -50%);
    cursor: pointer;
}

.marker.draggable {
  cursor: grab;
}
.marker.dragging {
  cursor: grabbing;
}

.marker:hover {
  z-index: 1;
}

.pin {
  width: 20px;
  height: 20px;
  border-radius: 50% 50% 50% 0;
  background: #e91e63;
  position: absolute;
  transform: rotate(-45deg);
  left: 50%;
  top: 50%; 
  margin: -7px 0 0 -15px;
  border: 0.05em solid #ffffff; 
}
.pin:after {
  content: "";
  width: 14px;
  height: 14px;
  margin: 8px 0 0 8px; 
  position: absolute;
  border-radius: 50%;
}

.pin.selected {
background-color: #229E79;
}

.bounce {
  animation-name: bounce;
  animation-fill-mode: both;
  animation-duration: 1s;
}

.pulse { 
  border-radius: 50%;
  height: 14px;
  width: 14px;
  position: absolute;
  left: 50%;
  top: 50%;
  margin: 11px 0px 0px -12px;
  transform: rotateX(55deg);
  z-index: -2;
}

.pulse:after {
  content: "";
  border-radius: 50%;
  height: 40px;
  width: 40px;
  position: absolute;
  margin: -13px 0 0 -13px;
  animation: pulsate 1s ease-out;
  animation-iteration-count: infinite;
  opacity: 0;
  box-shadow: 0 0 1px 2px #e91e63;
  animation-delay: 1.1s;
}

@keyframes pulsate {
  0% {
    transform: scale(0.1, 0.1);
    opacity: 0;
  }

  50% {
    opacity: 1;
  }

  100% {
    transform: scale(1.2, 1.2);
    opacity: 0;
  }
}

@keyframes bounce {
  0% {
    opacity: 0;
    transform: translateY(-2000px) rotate(-45deg);
  }

  60% {
    opacity: 1;
    transform: translateY(30px) rotate(-45deg);
  }

  80% {
    transform: translateY(-10px) rotate(-45deg);
  }

  100% {
    transform: translateY(0) rotate(-45deg);
  }
}