/**
 * Copyright(c) 2020 Mozanta Technologies Private Ltd.
 * 
 * All rights reserved.                
 * 
 * This software is the confidential and proprietary information of Mozanta ("Confidential
 * Information"). You shall not disclose such Confidential Information and shall use it only in
 * accordance with the terms of the contract agreement you entered into with Mozanta.
 * 
 * @author Aardra S
 */

.block3 .store .storeBody .storeTable thead {
  background-color: #edf0f5;
}

.block1 .buttonNewStore{
    color: #ffffff;
    width: 160px;
    border-radius: 2px;
    border: 0px;
    float: right;
  }
  
  .liveBtnWrapper .liveBtn{
    border: none;
    border-radius: 2px;
    text-transform: uppercase;
  }