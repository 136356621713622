/* block 2 */
.block2{
    padding-top: 20px;
    padding-bottom: 20px;
}
.block2 .pageSelectionText{
  
    text-align: left;
    color: #333333;
    padding-bottom: 17px;
}


.block2 .pageSelection  button:hover, .pageSelection  button:active{
    background-color: #ffff ;
    color: black;
    border: solid 1px #313131;

}
.block2 .pageSelection  button{
    background-color: #ffff !important;
    color: black !important;
    box-shadow: none !important;
    border: solid 1px #313131;
    border-radius: 2px;
    min-width: 230px;
    text-align: left;

}

.block2 .pageSelection  button::after {
    float: right !important;
    margin-top: 10px;
}
.block2 .pageSelection .dropdownItem{
    padding: 10px !important;
}
.block2 .pageSelection .dropdownItem:hover{
  color:#e91e63;
  background-color: #f9f9f9;
}

.block2 .pageSelection .dropdownWrapper  {
    width: 100% !important;
    border: none !important;
    box-shadow: 0px 3px 10px -4px #ccc
}

/* block 3 */
.block3{
    padding-top: 10px;
    margin-bottom: 10px;
}
.block3 .layoutOptions{
    position: relative;
    justify-content: center;
    padding-top: 10px;
    padding-bottom: 10px;
}

.block3 .layoutOptions .leftArrow{
  position: absolute;
  left: 0;
  top: 45%;
  display: inline-block;
  cursor: pointer;
}

.block3 .layoutOptions .rightArrow{
    position: absolute;
    right: 0;
    top: 45%;
    display: inline-block;
    cursor: pointer;
}


.block3 .layoutOptions .layoutOptionsWrapper{
    display: flex;
    margin-left: 25px;
    margin-right: 25px;
    justify-content: center;
}
.block3 .layoutOptions .layoutsPreview .layoutsPreviewWrapper{
    padding: 10px;
    border-top: 4px solid #ffffff;
    height: 100%;
}
.block3 .layoutOptions .layoutsPreview .layoutsPreviewWrapper:hover{
    box-shadow: 0px 0px 5px -1px #ccc;
    cursor: pointer;
}
.block3 .layoutOptions .layoutsPreview .active{
    box-shadow: 0px 0px 5px -1px #ccc;
    cursor: pointer;
    border: 1px solid #ea3c64;
}
.block3 .layoutOptions .layoutsPreviewWrapper{
    position: relative;
    margin: 10px;
    
}
.block3 .liveBtn{
    border: none;
    border-radius: 2px;
    text-transform: uppercase;
}
.block3 .liveBtn a{
    color: white !important;
}

.block3 .layoutOptions .layoutsPreviewWrapper .layoutName{
    position: absolute;
    background-color: #f9f9f9;
    width: 100%;
    bottom: 0;
    padding-top: 3px;
    left: 0;
    right: 0;
    padding-bottom: 3px;
}
.block3 .layoutOptions .livePreviewWrapper:hover .layoutImg{
    background-color: #ccc;
    -webkit-filter: blur(5px);
    -moz-filter: blur(5px);
    -o-filter: blur(5px);
    -ms-filter: blur(5px);
    filter: blur(5px);
}
   
.block3 .layoutOptions .livePreviewWrapper .livePreview{
    display:  none;
}
.block3 .layoutOptions .livePreviewWrapper:hover .livePreview{
    display: flex;
    flex-direction: column;
    position: absolute;
    bottom: 50%;
    left: 0;
    right: 0;
    padding: 3px;
    z-index: 1;
    justify-content: center;
    align-items: center;
}
   


/* block 4 */

.block4{
    padding-top: 10px;
    padding-bottom: 10px;
}

.block4 .selectedLayout {
    padding-top: 10px;
    justify-content: center;
    margin: 10px;
}