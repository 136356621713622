.sideNavigationWrapper{
    width: 300px;
    float: left;
    position: fixed;
    top: 85px;
    background: white;
    left: 47px;
    padding: 5px 0px;
    height: 100% ;
    display: block ;
}

.navClick{
    position: absolute;
    right: -7px;
    z-index: 3;
    cursor: pointer; 
}
 .navClick:before {
    content: " ";
    background: white;
    position: absolute;
    width: 15px;
    height: 15px;
    border-radius: 50%;
    right: calc( 50% - 7px );
    top: calc( 50% - 7px );
    z-index: -1;
}

.navClickClose{
    position: fixed;
    left: 23px;
    top: 90px;
    z-index: 3;
    cursor: pointer;
}
.navClickCloseScrolled{
    position: fixed;
    top:70px !important;
    transition: ease 0.5s;
    left: 23px;
    top: 130px;
    z-index: 2;
}
.sideNavScroll{
    top:70px !important;
    transition: ease 0.5s;
    width: 300px;
    float: left;
    position: fixed;
    background: white;
    left: 46px;
    padding: 5px 10px;
    height: 100% ;
    display: block ;
}

.sideNavigations{
    padding: 0;
    margin: 0;
    display: block;
    width: 100%;
    clear: both;
    padding-top: 5px;
    overflow: hidden;
    overflow-y: auto !important;
    height: calc( 100% - 85px );
}

.sideNavigations span{  
    font-size: 16px;
    font-weight: 700;
    padding-bottom: 10px;
    padding: 6px 15px;
}

.sideNavItem{
    border: none !important; 
    float: left;
    color: grey;
    display: block;
    width: 100%;
    clear: both;
    padding-left: 25px !important;
    border-radius: 0 !important;
}
.sideNavItem:hover{
    cursor: pointer;
    border-left: 4px solid #229E79 !important;
    box-shadow: 0px 0px 5px -3px #ccc;
}
.sideNavItem.active{
    color: #229E79;
    background-color: #f9f9f9 ;
    border-left: 4px solid #229E79 !important;
    font-weight: 600;
}