/**
 * Copyright(c) 2020 Mozanta Technologies Private Ltd.
 * 
 * All rights reserved.                
 * 
 * This software is the confidential and proprietary information of Mozanta ("Confidential
 * Information"). You shall not disclose such Confidential Information and shall use it only in
 * accordance with the terms of the contract agreement you entered into with Mozanta.
 * 
 * @author Anokh J Ajai
 */

 .productBundleButtons {
   display: flex;
   align-items: center;
 }

 .productBundleButtons .btn{
  border : 1px solid #17a2b8;
  border-radius: 22px;
  width :32px;
  margin-right: 20px;
  cursor: pointer;
  margin-top: 10px;
 }  