@import url(https://fonts.googleapis.com/css?family=Lato:100,300,400,700&display=swap);

.footerWrapper{
    background: #ffffff;
    padding: 20px 10px 20px 10px;
    color: #5d5d5d;
}
.textUnderline{
    text-decoration: underline;
}

.menuWrap{
    display: block;
    width: 100%;
}

.headerWrapper{
    background: white;
    position: fixed;
    height: 75px;
    display: inline-grid;
    z-index: 100;
    left: 32px;
    right: 32px;
    top: 0px;
    padding: 10px 0px;
    border-bottom: 1px solid #f2f2f2;
    font-weight: 100;
}
/**
 * Copyright(c) 2020 Mozanta Technologies Private Ltd.
 *
 * All rights reserved.
 *
 * This software is the confidential and proprietary information of Mozanta
 * ("Confidential Information"). You shall not disclose such Confidential
 * Information and shall use it only in accordance with the terms of the
 * contract agreement you entered into with Mozanta.
 *
 * Login.module.css
 *
 * @author Naseef
 *
 */

.Login_loginWrapper1__2Gw5l{
    min-height: calc(100vh - 205px);
    display: flex;
}

.Login_link-info__xQgt2:hover{
  text-decoration: underline !important;
  color: blue !important;
}
/**
 * Copyright(c) 2020 Mozanta Technologies Private Ltd.
 *
 * All rights reserved.
 *
 * This software is the confidential and proprietary information of Mozanta
 * ("Confidential Information"). You shall not disclose such Confidential
 * Information and shall use it only in accordance with the terms of the
 * contract agreement you entered into with Mozanta.
 *
 * PasswordReset.module.css
 *
 * @author Harikrishnan A K
 *
 */

.PasswordReset_viewPassword__3sEKZ{
  position: relative;
}

.PasswordReset_viewPassword__3sEKZ .PasswordReset_iconShowPassword__3gRfN{
  position: absolute;
  top: 0.8rem;
  width: 2rem;
  height: 2rem;
  font-size: 1.5rem;
  padding: 0;
  right: 1.7rem;

}

.PasswordReset_loginWrapper1__1lIr3{
  min-height: calc(100vh - 205px);
  display: flex;
}

.PasswordReset_link-info__1Xv9J:hover{
text-decoration: underline !important;
color: blue !important;
}

.PasswordReset_passwordResetFormWrapper__3vBuS{
  max-height: unset !important;
}
/**
 * Copyright(c) 2020 Mozanta Technologies Private Ltd.
 *
 * All rights reserved.
 *
 * This software is the confidential and proprietary information of Mozanta
 * ("Confidential Information"). You shall not disclose such Confidential
 * Information and shall use it only in accordance with the terms of the
 * contract agreement you entered into with Mozanta.
 *
 * select site.module.css
 *
 * @author Anokh J Ajai
 *
 */



.SelectSite_selectSiteOuterWrapper__1Plm_ {
  background: #f8f8f8 0% 0% no-repeat padding-box;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  height: 100vh;
}

.SelectSite_selectSiteOuterWrapper__1Plm_ .SelectSite_spinner__psqoQ {
  width: 50px;
  height: 50px;
  margin-top: auto;
  margin-bottom: auto;
}
.SelectSite_selectSiteOuterWrapper__1Plm_ .SelectSite_siteSelectHeader__2bTGt {
  width: 100%;
  display: flex;
  flex: 1 1;
  align-items: flex-start;
  text-align: left;
  z-index: 10;
}

.SelectSite_selectSiteOuterWrapper__1Plm_  .SelectSite_siteSelectTitle__29vII{
  text-align: center;
  font-weight: 700;
  font-size: 32px;
  margin-bottom: 2rem;
  display: flex;
  align-items: center;
  justify-content: center;
}

.SelectSite_selectSiteOuterWrapper__1Plm_ .SelectSite_siteSelectDropdown__ujHK7 {
  width: 100%;
  max-width: 400px;
  justify-content: center;
  border-radius: 30px;
}
.SelectSite_selectSiteOuterWrapper__1Plm_ .SelectSite_submit__2evUA{
  width: 100%;
  max-width:200px;
  margin-top: 2rem;
  border-radius: 25px;
}
.SelectSite_selectSiteOuterWrapper__1Plm_ .SelectSite_submitImage__1iuWJ img{
  width:20px;
  height:20px
}
.SelectSite_selectSiteOuterWrapper__1Plm_ .SelectSite_siteSelectDropdown__ujHK7 .SelectSite_dropDown__3yAva {
  width: 25%;
}
.SelectSite_selectSiteOuterWrapper__1Plm_ .SelectSite_siteSelectFooter__2qPfX {
  display: flex;
  width: 100%;
  flex: 1 1;
  align-items: center;
  z-index: 10;
  text-align: center;
}
.SelectSite_selectSiteOuterWrapper__1Plm_ .SelectSite_siteSelectFooter__2qPfX .SelectSite_signOut__3HJTM{
  color :#869085 !important;
  text-align: center;
}
.SelectSite_selectSiteOuterWrapper__1Plm_ .SelectSite_siteSelectFooter__2qPfX .SelectSite_signOut__3HJTM img{
  width:25px;
  height:25px;
  padding-left :5px;
}

/**
 * Copyright(c) 2020 Mozanta Technologies Private Ltd.
 * 
 * All rights reserved.
 * 
 * This software is the confidential and proprietary information of Mozanta ("Confidential
 * Information"). You shall not disclose such Confidential Information and shall use it only in
 * accordance with the terms of the contract agreement you entered into with Mozanta.
 * 
 * @author Indrajith C
 * 
 */


.Select_dropDown__gv_jq button:hover,
.Select_dropDown__gv_jq button:active {
    background-color: #ffff;
    color: black;
    border: solid 1px #313131;

}

.Select_dropDown__gv_jq button {
    background-color: #ffff !important;
    color: black !important;
    box-shadow: none !important;
    border: solid 1px #313131;
    border-radius: 2px;
    text-align: left;
    display: flex;
    align-items: center;
}

.Select_dropDown__gv_jq  button[disabled] {
  background-color: #e9ecef !important;
}

.Select_dropDown__gv_jq[disabled] button,
.Select_dropDown__gv_jq.Select_disabled__dBcYN button {
    background-color: #E9ECEF !important;
    border: solid 1px #d0d3d6!important;
}

.Select_dropDown__gv_jq button>span[disabled] {
    color: #767f86 !important;
}

.Select_dropDown__gv_jq button::after {
    margin-left: auto !important;
}

.Select_dropDown__gv_jq .Select_dropdownItem__1bIft {
    border: none !important;
    padding: 0.5rem 0.75rem !important;
    cursor: default !important;
}

.Select_dropDown__gv_jq .Select_dropdownItem__1bIft:hover {
    color: #229E79 !important;
    background-color: #f9f9f9 !important;
}

.Select_dropDown__gv_jq .Select_dropdownItem__1bIft:focus {
    outline: none !important;
    box-shadow: none !important;
}

.Select_dropDown__gv_jq .Select_dropdownItem__1bIft[active] {
    color: #f9f9f9 !important;
    background-color: #229E79 !important;
    border-top: 1px solid white !important;
}


.Select_dropDown__gv_jq .Select_dropdownItem__1bIft[disabled] {
    color: #767f86 !important;
    background-color: #f9f9f9 !important;
    cursor: not-allowed !important;
}

.Select_dropDown__gv_jq .Select_dropdownWrapper__1_BEI {
    width: 100% !important;
    border: none !important;
    box-shadow: 0px 3px 10px -4px #ccc
}

.Select_dropDown__gv_jq.Select_invalid__RJXOy>button[type="button"] {
    border-color: #dc3545;
    padding-right: calc(1.5em + 0.75rem);
    background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='12' height='12' fill='none' stroke='%23dc3545' viewBox='0 0 12 12'%3e%3ccircle cx='6' cy='6' r='4.5'/%3e%3cpath stroke-linejoin='round' d='M5.8 3.6h.4L6 6.5z'/%3e%3ccircle cx='6' cy='8.2' r='.6' fill='%23dc3545' stroke='none'/%3e%3c/svg%3e");
    background-repeat: no-repeat;
    background-position: right calc(0.375em + 0.1875rem) center;
    background-size: calc(0.75em + 0.375rem) calc(0.75em + 0.375rem);
}
/**
 * Copyright(c) 2020 Mozanta Technologies Private Ltd.
 *
 * All rights reserved.
 *
 * This software is the confidential and proprietary information of Mozanta
 * ("Confidential Information"). You shall not disclose such Confidential
 * Information and shall use it only in accordance with the terms of the
 * contract agreement you entered into with Mozanta.
 *
 * CreatePassword.module.css
 *
 * @author Ajay J A
 *
 */

.CreatePassword_createPasswordWrapper1__3-G2-{
    min-height: calc(100vh - 205px);
    display: flex;
}

/** ============= COMMON STYLE CLASSES  GLOBAL ================ **/
/* common colors */
.color-black {
  color: #000 !important;
}
.color-white {
  color: #fff !important;
}
.bg-color-black {
  background-color: #000 !important;
}
.bg-color-white {
  background-color: #fff !important;
}

/*  primary color */
.color-primary-light {
  color: #f06291 !important;
}
.bg-color-primary-light {
  color: #fff !important;
  background-color: #f06291 !important;
}
.color-primary-main {
  color: #e91e63 !important;
}
.bg-color-primary-main {
  color: #fff !important;
  background-color: #e91e63 !important;
}
.color-primary-dark {
  color: #c2185b !important;
}
.bg-color-primary-dark {
  color: #fff !important;
  background-color: #c2185b !important;
}

/*  secondary color */
.bg-color-secondary {
  color: #fff !important;
  background-color: #bdbdbd !important;
  border-color: #bdbdbd !important;
}
.color-secondary-light {
  color: #e0e0e0 !important;
}
.bg-color-secondary-light {
  color: #fff !important;
  background-color: #e0e0e0 !important;
}
.color-secondary-main {
  color: #757575 !important;
}
.bg-color-secondary-main {
  color: #fff !important;
  background-color: #757575 !important;
}
.color-secondary-dark {
  color: #616161 !important;
}
.bg-color-secondary-dark {
  color: #fff !important;
  background-color: #616161 !important;
} 

/*  tertiary color */
.color-tertiary-light {
  color: #4ec8db !important;
}
.bg-color-tertiary-light {
  color: #fff !important;
  background-color: #4ec8db !important;
}
.color-tertiary-main {
  color: #229E79 !important;
}
.bg-color-tertiary-main {
  color: #fff !important;
  background-color: #229E79 !important;
}
.color-tertiary-dark {
  color: #127a87 !important;
}
.bg-color-tertiary-dark {
  color: #fff !important;
  background-color: #127a87 !important;
} 

/* danger colors */
.color-danger {
  color: #f44336 !important;
}
.color-warning {
  color: #ff9800 !important;
}
.color-info {
  color: #2196f3 !important;
}
.color-success {
  color: #4caf50 !important;
}
.bg-color-danger {
  background-color: #f44336 !important;
}
.bg-color-warning {
  background-color: #ff9800 !important;
}
.bg-color-info {
  background-color: #2196f3 !important;
}
.bg-color-success {
  background-color: #4caf50 !important;
}

/* gary colors */
.color-gray-50 {
  color: #fafafa !important;
}
.bg-color-gray-50 {
  background-color: #fafafa !important;
}
.color-gray-100 {
  color: #f5f5f5 !important;
}
.bg-color-gray-100 {
  background-color: #f5f5f5 !important;
}
.color-gray-200 {
  color: #eeeeee !important;
}
.bg-color-gray-200 {
  background-color: #eeeeee !important;
}
.color-gray-300 {
  color: #e0e0e0 !important;
}
.bg-color-gray-300 {
  background-color: #e0e0e0 !important;
}

/*  text color */
.text-primary {
  color: rgba(0, 0, 0, 0.87) !important;
}
.text-secondary {
  color:rgba(0, 0, 0, 0.54) !important;
}
.text-disabled {
  color:rgba(0, 0, 0, 0.38) !important;
}
.text-hint {
  color: rgba(0, 0, 0, 0.38) !important;
}

/* common shadows */
.shadows-0 {
  box-shadow: none !important;
}
.shadows-50 {
  box-shadow: 0px 2px 1px -1px rgba(0,0,0,0.2),0px 1px 1px 0px rgba(0,0,0,0.14),0px 1px 3px 0px rgba(0,0,0,0.12) !important;
}
.shadows-100 {
  box-shadow: 0px 3px 1px -2px rgba(0,0,0,0.2),0px 2px 2px 0px rgba(0,0,0,0.14),0px 1px 5px 0px rgba(0,0,0,0.12) !important;
}
.shadows-200 {
  box-shadow: 0px 3px 3px -2px rgba(0,0,0,0.2),0px 3px 4px 0px rgba(0,0,0,0.14),0px 1px 8px 0px rgba(0,0,0,0.12) !important;
}
.shadows-300 {
  box-shadow: 0px 2px 4px -1px rgba(0,0,0,0.2),0px 4px 5px 0px rgba(0,0,0,0.14),0px 1px 10px 0px rgba(0,0,0,0.12) !important;
}

/* shape */
.shape-borderRadius {
  border-radius: 0.125rem !important;
}

/* typography */
.typography-htmlFontSize {
  font-size: 16px !important;
}
.typography-fontSize {
  font-size: 14px !important;
}
.typography-fontWeightThin {
  font-weight: 100 !important;
}
.typography-fontWeightLight {
  font-weight: 300 !important;
}
.typography-fontWeightRegular {
  font-weight: 500 !important;
}
.typography-fontWeightBold {
  font-weight: 700 !important;
}

/* zIndex */
.zIndex-mobileStepper {
  z-index: 1000 !important;
}
.zIndex-speedDial {
  z-index: 1050 !important;
}
.zIndex-appBar {
  z-index: 1100 !important;
}
.zIndex-modal {
  z-index: 1300 !important;
}
.zIndex-tooltip {
  z-index: 1500 !important;
}

/*  bootstrap classes */
/* basic button  */
.btn {
  border-radius: 0.125rem ;
}

/**  primary button */
.btn-primary {
  color: #fff;
  background-color: #e91e63;
  border-color: #e91e63;
}

.btn-prqqimary:hover {
  color: #fff;
  background-color: #f06291;
  border-color: #f36091;
}

.btn-primary:focus, .btn-primary.focus {
  color: #fff;
  background-color: #f06291;
  border-color: #f36091;
  box-shadow: 0 0 0 0.2rem rgba(233, 30, 99, 0.5);
}

.btn-primary.disabled, .btn-primary:disabled {
  color: #fff;
  background-color: #e91e63;
  border-color: #e91e63;
}

.btn-primary:not(:disabled):not(.disabled):active, .btn-primary:not(:disabled):not(.disabled).active,
.show > .btn-primary.dropdown-toggle {
  color: #fff;
  background-color: #e91e63;
  border-color: #c2185b;
}

.btn-primary:not(:disabled):not(.disabled):active:focus, .btn-primary:not(:disabled):not(.disabled).active:focus,
.show > .btn-primary.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(233, 30, 99, 0.5);
}

/*  secondary button */
.btn-secondary {
  color: #fff;
  background-color: #757575;
  border-color: #757575;
}

.btn-secondary:hover {
  color: #fff;
  background-color: #e0e0e0;
  border-color: #757575;
}

.btn-secondary:focus, .btn-secondary.focus {
  color: #fff;
  background-color: #5a6268;
  border-color: #545b62;
  box-shadow: 0 0 0 0.2rem rgba(117, 117, 117, 0.5);
}

.btn-secondary.disabled, .btn-secondary:disabled {
  color: #fff;
  background-color: #e0e0e0;
  border-color: #e0e0e0;
}

.btn-secondary:not(:disabled):not(.disabled):active, .btn-secondary:not(:disabled):not(.disabled).active,
.show > .btn-secondary.dropdown-toggle {
  color: #fff;
  background-color: #545b62;
  border-color: #4e555b;
}

.btn-secondary:not(:disabled):not(.disabled):active:focus, .btn-secondary:not(:disabled):not(.disabled).active:focus,
.show > .btn-secondary.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(117, 117, 117, 0.5);
}

/*  tertiary button */
.btn-tertiary {
  color: #fff !important;
  background-color: #229E79 !important;
  border-color: #229E79 !important;
}

.btn-tertiary:hover {
  color: #fff !important;
  background-color: #19b2cb !important;
  border-color: #229E79 !important;
}

.btn-tertiary:focus, .btn-tertiary.focus {
  color: #fff !important;
  background-color: #19b2cb !important;
  border-color: #148e9e !important;
  box-shadow: 0 0 0 0.2rem rgba(23, 162, 184, 0.5) !important;
}

.btn-tertiary.disabled, .btn-tertiary:disabled {
  color: #fff !important;
  background-color: #19b2cb !important;
  border-color: #19b2cb !important;
}

.btn-tertiary:not(:disabled):not(.disabled):active, .btn-tertiary:not(:disabled):not(.disabled).active,
.show > .btn-tertiary.dropdown-toggle {
  color: #fff !important;
  background-color: #229E79 !important;
  border-color: #19b2cb !important;
}

.btn-tertiary:not(:disabled):not(.disabled):active:focus, .btn-tertiary:not(:disabled):not(.disabled).active:focus,
.show > .btn-tertiary.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(23, 162, 184, 0.5) !important;
}

/*  success button */
.btn-success {
  color: #fff;
  background-color: #4caf50;
  border-color: #4caf50;
}

.btn-success:hover {
  color: #fff;
  background-color: #66bb69;
  border-color: #4caf50;
}

.btn-success:focus, .btn-success.focus {
  color: #fff;
  background-color: #66bb69;
  border-color: #4caf50;
  box-shadow: 0 0 0 0.2rem rgba(76, 175, 80, 0.5);
}

.btn-success.disabled, .btn-success:disabled {
  color: #fff;
  background-color: #4caf50;
  border-color: #4caf50;
}

.btn-success:not(:disabled):not(.disabled):active, .btn-success:not(:disabled):not(.disabled).active,
.show > .btn-success.dropdown-toggle {
  color: #fff;
  background-color: #4caf50;
  border-color: #1c7430;
}

.btn-success:not(:disabled):not(.disabled):active:focus, .btn-success:not(:disabled):not(.disabled).active:focus,
.show > .btn-success.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(76, 175, 80, 0.5);
}

/* info button */
.btn-info {
  color: #fff;
  background-color: #2194f3;
  border-color: #2194f3;
}

.btn-info:hover {
  color: #fff;
  background-color: #42a4f5;
  border-color: #1f87e5;
}

.btn-info:focus, .btn-info.focus {
  color: #fff;
  background-color: #42a4f5;
  border-color: #1f87e5;
  box-shadow: 0 0 0 0.2rem rgba(33, 148, 243, 0.5);
}

.btn-info.disabled, .btn-info:disabled {
  color: #fff;
  background-color: #2194f3;
  border-color: #2194f3;
}

.btn-info:not(:disabled):not(.disabled):active, .btn-info:not(:disabled):not(.disabled).active,
.show > .btn-info.dropdown-toggle {
  color: #fff;
  background-color: #1f87e5;
  border-color: #1a75d2;
}

.btn-info:not(:disabled):not(.disabled):active:focus, .btn-info:not(:disabled):not(.disabled).active:focus,
.show > .btn-info.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(33, 148, 243, 0.5);
}

/*  warning button */
.btn-warning {
  color: #212529;
  background-color: #ff9800;
  border-color: #ff9800;
}

.btn-warning:hover {
  color: #212529;
  background-color: #ffa826;
  border-color: #fb8d00;
}

.btn-warning:focus, .btn-warning.focus {
  color: #212529;
  background-color: #ffa826;
  border-color: #fb8d00;
  box-shadow: 0 0 0 0.2rem rgba(255, 152, 0, 0.5);
}

.btn-warning.disabled, .btn-warning:disabled {
  color: #212529;
  background-color: #ff9800;
  border-color: #ff9800;
}

.btn-warning:not(:disabled):not(.disabled):active, .btn-warning:not(:disabled):not(.disabled).active,
.show > .btn-warning.dropdown-toggle {
  color: #212529;
  background-color: #fb8d00;
  border-color: #f57d00;
}

.btn-warning:not(:disabled):not(.disabled):active:focus, .btn-warning:not(:disabled):not(.disabled).active:focus,
.show > .btn-warning.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(255, 152, 0, 0.5);
}

/*  danger button */
.btn-danger {
  color: #fff;
  background-color: #f44336;
  border-color: #f44336;
}

.btn-danger:hover {
  color: #fff;
  background-color: #ef5350;
  border-color: #e53935;
}

.btn-danger:focus, .btn-danger.focus {
  color: #fff;
  background-color: #ef5350;
  border-color: #e53935;
  box-shadow: 0 0 0 0.2rem rgba(244, 67, 54, 0.5);
}

.btn-danger.disabled, .btn-danger:disabled {
  color: #fff;
  background-color: #f44336;
  border-color: #f44336;
}

.btn-danger:not(:disabled):not(.disabled):active, .btn-danger:not(:disabled):not(.disabled).active,
.show > .btn-danger.dropdown-toggle {
  color: #fff;
  background-color: #e53935;
  border-color: #d32f2f;
}

.btn-danger:not(:disabled):not(.disabled):active:focus, .btn-danger:not(:disabled):not(.disabled).active:focus,
.show > .btn-danger.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(244, 67, 54, 0.5);
}

/* light button */
.btn-light {
  color: #212529;
  background-color: #f8f9fa;
  border-color: #f8f9fa;
}

.btn-light:hover {
  color: #212529;
  background-color: #e2e6ea;
  border-color: #dae0e5;
}

.btn-light:focus, .btn-light.focus {
  color: #212529;
  background-color: #e2e6ea;
  border-color: #dae0e5;
  box-shadow: 0 0 0 0.2rem rgba(216, 217, 219, 0.5);
}

.btn-light.disabled, .btn-light:disabled {
  color: #212529;
  background-color: #f8f9fa;
  border-color: #f8f9fa;
}

.btn-light:not(:disabled):not(.disabled):active, .btn-light:not(:disabled):not(.disabled).active,
.show > .btn-light.dropdown-toggle {
  color: #212529;
  background-color: #dae0e5;
  border-color: #d3d9df;
}

.btn-light:not(:disabled):not(.disabled):active:focus, .btn-light:not(:disabled):not(.disabled).active:focus,
.show > .btn-light.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(216, 217, 219, 0.5);
}

/* dark button */
.btn-dark {
  color: #fff;
  background-color: #343a40;
  border-color: #343a40;
}

.btn-dark:hover {
  color: #fff;
  background-color: #23272b;
  border-color: #1d2124;
}

.btn-dark:focus, .btn-dark.focus {
  color: #fff;
  background-color: #23272b;
  border-color: #1d2124;
  box-shadow: 0 0 0 0.2rem rgba(82, 88, 93, 0.5);
}

.btn-dark.disabled, .btn-dark:disabled {
  color: #fff;
  background-color: #343a40;
  border-color: #343a40;
}

.btn-dark:not(:disabled):not(.disabled):active, .btn-dark:not(:disabled):not(.disabled).active,
.show > .btn-dark.dropdown-toggle {
  color: #fff;
  background-color: #1d2124;
  border-color: #171a1d;
}

.btn-dark:not(:disabled):not(.disabled):active:focus, .btn-dark:not(:disabled):not(.disabled).active:focus,
.show > .btn-dark.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(82, 88, 93, 0.5);
}



/*** ============ CUSTOM SWITCH [B]=================== */
.custom-switch {
  min-height: 0 !important;
}

.custom-control-label {
  display: flex;
  align-items: center;
}

.custom-switch .custom-control-label::before {
  border: 0;
  background-color: #c4c4c4;
  height: 0.6rem;
  top: 0.4rem;
  width: 1.65rem !important;
  cursor: pointer;
}

.custom-switch .custom-control-label::after {
  top: calc(0.4rem - 2.5px) !important;
  left: calc(-2.5rem + 3.5px) !important;
  width: calc(0.6rem + 5px) !important;
  height: calc(0.6rem + 5px) !important;
  background-color: #a2a2a2 !important;
  cursor: pointer;
}

.custom-switch.loading .custom-control-label::before,
.custom-switch.loading .custom-control-label::after {
  cursor: wait;
}

.custom-switch .custom-control-input:checked ~ .custom-control-label::before {
  background-color: #229e79 !important;
}
.custom-switch .custom-control-input:checked ~ .custom-control-label::after {
  background-color: #0B7455 !important;
}
.custom-switch .custom-control-input:focus ~ .custom-control-label::before {
  border: 0 !important;
  box-shadow: 0px 3px 3px -2px rgba(0, 0, 0, 0.2),
    0px 3px 4px 0px rgba(0, 0, 0, 0.14), 0px 1px 8px 0px rgba(0, 0, 0, 0.12);
}

.custom-switch .custom-control-input:disabled ~ .custom-control-label::before ,
.custom-switch .custom-control-input:disabled ~ .custom-control-label::after {
  opacity: 0.3 !important;
  /* cursor: not-allowed; */
  pointer-events: none !important;
}
/*** ============ CUSTOM SWITCH [E]=================== */

/*** ============ CUSTOM RADIO [B]=================== */
.custom-radio .custom-control-label::before {
  border: #f85a94 solid 2px !important;
}

.custom-radio .custom-control-input:checked ~ .custom-control-label::before {
background: none !important;
}

.custom-radio .custom-control-input:checked ~ .custom-control-label::after {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='16' height='16' viewBox='-5 -5 10 10'%3e%3ccircle r='5' fill='%23f85a94'/%3e%3c/svg%3e") !important; 
}

.custom-radio .custom-control-input:focus ~ .custom-control-label::before {
  border: #f85a94 solid 2px !important;
  box-shadow: 0px 3px 3px -2px rgba(0, 0, 0, 0.2),
    0px 3px 4px 0px rgba(0, 0, 0, 0.14), 0px 1px 8px 0px rgba(0, 0, 0, 0.12);
} 

.custom-radio .custom-control-input:not(:disabled):active ~ .custom-control-label::before {
  background-color: #f85a945d !important;
}
/*** ============ CUSTOM RADIO [E]=================== */

/*** ============ CUSTOM SCROLL BAR [S]============== */
.custom-scrollbar {
  scrollbar-color: #00000033 transparent;
  scrollbar-width: thin;
}

.custom-scrollbar::-webkit-scrollbar-track {
  border-radius: 6px;
  background: transparent;
}

.custom-scrollbar::-webkit-scrollbar {
  width: 12px;
}

.custom-scrollbar::-webkit-scrollbar-thumb {
  background-color: #00000033;
  border-radius: 10px;
  border: 2px solid #fff;
}
/*** ============ CUSTOM SCROLL BAR [E]============== */

/*** ============ LOGIN [S]============== */
.loginWrapper{
  display: block;
  margin: 80px auto;
  background-color: white;
  box-shadow: 0px 4px 5px 0px rgba(0, 0, 0, 0.18);
  border-radius: 0;
  min-width: 390px !important;
  max-width: 390px;
  min-height: 400px;
  max-height: 400px;
  padding: 30px;
}
.loginWrapper h3{
  margin: 0px ;
  font-size: 1.50rem;
  font-weight: 600;
}
.loginWrapper .loginContent{
  margin-top: 30px ;
  margin-bottom: 20px ;
}
.fixed-form-height{
  min-height: 81px;
  margin-bottom: 1px !important;
}
.fixed-form-height-pass{
  min-height: 86px;
  margin-bottom: 1px !important;
}
.pass-show-div{
  position: absolute;
  /* top: 202px; */
}
.btn-show{
  padding: 0px;
  height: 18px;
  width: 37px;
  font-size: 11px;
  font-weight: 200;
}
.loginWrapper .loginContent input{
  border-radius: 3px;
  border: solid 1px lightgrey;
  font-size: 12px;
}
.loginWrapper .forgotpass{
  float: right;
  font-size: 13px;
  padding: 2px 0px;
  margin-top: -2px;
  color: #229E79 !important;
}
.loginWrapper .checkmark{
  padding-left: 7px;
}
.loginWrapper .bluecolor{
  background-color: #229E79;
}
.loginWrapper .signup{
  padding-left: 4px;
}
.loginWrapper .loginContent .loginWrap{
  font-size: 12px;
  margin-top: 20px;
}
.loginWrapper .loginContent .loginWrap span{
  color: #229E79;
}
.checkbox{
  border-radius: 1px;
}
/*** ============ LOGIN [E]============== */
.pointer{
  cursor: pointer;
}
/*** ============ CUSTOM CHECKBOX [B]=================== */
.custom-checkbox .custom-control-label::before {
  display: inline-block; 
  background: white;
  position: absolute;
  border: 1px solid #b9b9b9 !important;
  border-radius: 0 !important;
}

.custom-checkbox  input {
  display: none !important;
}

.custom-checkbox .custom-control-input:checked ~ .custom-control-label::before {
  background-color: #707070;
  border: 1px solid #707070;
}

.custom-checkbox .custom-control-input:checked ~ .custom-control-label::after {
  content: "";
  position: absolute;
  height: 6px;
  width: 10px;
  border-left: 2px solid white;
  border-bottom: 2px solid white;
  top: 10px;
  left: -1rem;
  -webkit-transform: translate(-50%, -50%) rotate(-45deg);
          transform: translate(-50%, -50%) rotate(-45deg);
}

.custom-checkbox .custom-control-input:focus ~ .custom-control-label::before {
  border: #f85a94 solid 2px !important;
  box-shadow: 0px 3px 3px -2px rgba(0, 0, 0, 0.2),
    0px 3px 4px 0px rgba(0, 0, 0, 0.14), 0px 1px 8px 0px rgba(0, 0, 0, 0.12);
} 

.custom-checkbox .custom-control-input:not(:disabled):active ~ .custom-control-label::before {
  background-color: #f85a945d !important;
}
.custom-checkbox .custom-control-input:disabled:checked ~ .custom-control-label::before {
  background-color: #70707077 !important;
}
/** pending disable css */
/*** ============ CUSTOM CHECKBOX [E]=================== */

.focusedRow:hover {
  background-color: #edf0f5;
  cursor: pointer;
}

/*
author : vikhil
font: lato
default font size :  14px/0.875rem
font size         :  16px/1rem
*/

/*  ========== CUSTOM STYLES CLASSES ============ */

#root {
  padding: 0px 32px;
  background-color: #f8f8f8;
  font-family: "Lato", sans-serif;
  font-size: 14px;
}

body {
  background-color: #f0f6f4 !important;
  font-family: "Lato", sans-serif !important;
  font-size: 14px !important;
}

a {
  color: grey !important;
}
.hideSideNav {
  display: none !important;
}

a:hover,
a:active,
a:focus {
  color: #229e79 !important;
  text-decoration: none;
}

.navbar-nav {
  display: block !important;
}
.navbar-nav .nav-item {
  display: inline-block;
  float: left;
  padding: 4px 15px !important;
  width: auto;
  font-size: 16px;
  color: #000;
}
.navbar-nav .nav-item .nav-link {
  font-weight: bold;
}
.navbar-nav .nav-item .nav-link.active {
  position: relative;
}
.navbar-nav .nav-item a.nav-link:active,
.navbar-nav .nav-item a.nav-link:focus {
  color: #000 !important;
}
.navbar-nav .nav-item .nav-link.active::after {
  content: "";
  position: absolute;
  left: 0;
  right: 0;
  bottom: -18px;
  width: 100%;
  height: 4px;
  background-color: #229e79;
  margin: 0 auto;
}
.active {
  color: #000 !important;
}

.fontsizeNormalBold {
  font-weight: bold;
  font-size: 14px;
  color: #212121;
}

.fontsizeMediumBold {
  font-weight: bold;
  font-size: 16px;
  color: #212121;
}

.fontsizeLargeBold {
  font-weight: bold;
  font-size: 18px;
  color: #212121;
}

.dropup,
.dropright,
.dropdown,
.dropleft {
  position: relative !important;
}

.secondaryButton {
  font-family: Lato;
  color: #ffffff;
  width: 113px;
  height: 35px;
  border-radius: 2px;
  background-color: #bdbdbd;
  border: 0px;
  float: right;
}
.primaryButton {
  font-family: Lato;
  color: #ffffff;
  width: 113px;
  height: 35px;
  border-radius: 2px;
  background-color: #e91e63;
  border: 0px;
  float: right;
}

.modalWrapper.modal {
  top: 45% !important;
}

.mainWrapper {
  margin-top: 85px;
}

/** fix for dashboard **/
#sideNavigationWrapper + .mainWrapper {
  margin-left: 310px;
}

.mainWrapperWidthfix {
  margin-top: 85px;
  margin-left: 0px;
}
.headerNavScroll {
  margin-left: 133px !important;
  margin-top: -72px !important;
  -webkit-transition: ease 0.5s;
  transition: ease 0.5s;
}
.headerWrapperScroll {
  height: 60px !important;
  -webkit-transition: ease 0.5s;
  transition: ease 0.5s;
  box-shadow: 0px 7px 19px -11px #ccc;
}

#headerWrapper,
#menuScrollWrap2,
#sideNavigationWrapper #sideNavigationOpenWrapper {
  -webkit-transition: ease 0.5s;
  transition: ease 0.5s;
}

/* common page headers */

.commonPageHeaderWrapper {
  background: #ffffff;
  padding-top: 20px;
  margin-left: 0 !important;
  margin-right: 0 !important;
  margin-bottom: 0.625rem !important;
  color: #5d5d5d;
  margin-top: 10px;
  min-height: calc(100vh - 210px);
}
.common-page-card {
  background: #ffffff;
  margin: 0px !important;
  padding-top: 20px !important;
  padding-bottom: 20px !important;
  margin-bottom: 10px !important;
}

.pageHeader {
  box-shadow: 0px 11px 20px -12px rgba(0, 0, 0, 0.04);
  padding-bottom: 15px;
}
.pageHeader .pageText {
  font-size: 20px;
  color: #333333;
}
.pageHeader .buttonCancel {
  font-family: Lato;
  color: #ffffff;
  width: 113px;
  height: 35px;
  border-radius: 2px;
  background-color: #bdbdbd;
  border: 0px;
  float: right;
}
.pageHeader .buttonSave {
  font-family: Lato;
  color: #ffffff;
  width: 113px;
  height: 35px;
  border-radius: 2px;
  background-color: #e91e63;
  border: 0px;
  float: right;
}
.pageHeader .buttonGroup {
  padding-right: 20px;
}

.formTextLabel {
  color: black;
}

.formText {
  border-radius: 2px !important;
  border: 1px solid #b7b2b2 !important;
}
/* custom dropdown validation  */
.custom-dropdown-validation {
  background-position: right calc(0.375em + 0.85rem) center !important;
}
/* custom file input */
.customFileInput,
.customFileInput .custom-file-input {
  border-radius: 2px !important;
}

.customFileInput .custom-file-label {
  border-radius: 2px !important;
  border: 1px solid #b7b2b2;
}
.customFileInput .custom-file-label::after {
  display: none;
}

.customFileInput .custom-file-label {
  box-sizing: border-box;
  font-size: 16px;
  background-image: url(/static/media/file-upload.95247c12.svg);
  background-position: 4px 0px;
  background-repeat: no-repeat;
  padding: 12px 10px 12px 45px;
  -webkit-transition: width 0.4s ease-in-out;
  transition: width 0.4s ease-in-out;
  background-size: 1.6rem 100%;
  cursor: pointer !important;
  border-radius: 2px;
  overflow: hidden;
}
/* custom table */

.custom-table {
  overflow-x: auto;
}
.custom-table thead {
  background-color: #edf0f5;
}
.custom-table thead th {
  font-size: 14px;
  font-weight: bold;
  text-align: left;
  min-width: 140px;
}
.custom-table tbody tr {
  text-align: left;
}

/* Custom date-time picker */

.rdt {
  position: relative;
}
.rdtPicker {
  display: none;
  position: absolute;
  width: 100%;
  padding: 4px;
  margin-top: 1px;
  z-index: 99999 !important;
  background: #ffff;
  box-shadow: 0px 0px 15px -5px #ccc;
}
.rdtOpen .rdtPicker {
  display: block;
}
.rdtStatic .rdtPicker {
  box-shadow: none;
  position: static;
}

.rdtPicker .rdtTimeToggle {
  text-align: center;
}

.rdtPicker table {
  width: 100%;
  margin: 0;
}
.rdtPicker td,
.rdtPicker th {
  text-align: center;
  height: 28px;
}
.rdtPicker td {
  cursor: pointer;
}

.rdtPicker td.rdtOld,
.rdtPicker td.rdtNew {
  color: #999999;
}
.rdtPicker td.rdtToday {
  position: relative;
}

.rdtPicker td.rdtActive,
.rdtPicker td.rdtActive:hover {
  background-color: #428bca;
  color: #fff;
}
.rdtPicker td.rdtActive.rdtToday:before {
  border-bottom-color: #000;
}
.rdtPicker td.rdtDisabled,
.rdtPicker td.rdtDisabled:hover {
  background: none;
  color: #999999;
  cursor: not-allowed;
}

.rdtPicker td span.rdtOld {
  color: #999999;
}
.rdtPicker td span.rdtDisabled,
.rdtPicker td span.rdtDisabled:hover {
  background: none;
  color: #999999;
  cursor: not-allowed;
}
.rdtPicker th {
  border-bottom: 1px solid #f9f9f9;
}
.rdtPicker .dow {
  width: 14.2857%;
  border-bottom: none;
  cursor: default;
}
.rdtPicker th.rdtSwitch {
  width: 100px;
}
.rdtPicker th.rdtNext,
.rdtPicker th.rdtPrev {
  font-size: 21px;
  vertical-align: top;
}

.rdtPrev span,
.rdtNext span {
  display: block;
  -webkit-touch-callout: none; /* iOS Safari */
  -webkit-user-select: none; /* Chrome/Safari/Opera */ /* Konqueror */
  -moz-user-select: none; /* Firefox */
  -ms-user-select: none; /* Internet Explorer/Edge */
  user-select: none;
}

.rdtPicker th.rdtDisabled,
.rdtPicker th.rdtDisabled:hover {
  background: none;
  color: #999999;
  cursor: not-allowed;
}
.rdtPicker thead tr:first-child th {
  cursor: pointer;
}
.rdtPicker thead tr:first-child th:hover {
  background: #eeeeee;
}

.rdtPicker tfoot {
  border-top: 1px solid #f9f9f9;
}

.rdtPicker button {
  border: none;
  background: none;
  cursor: pointer;
}
.rdtPicker button:hover {
  background-color: #eee;
}

.rdtPicker thead button {
  width: 100%;
  height: 100%;
}

td.rdtMonth,
td.rdtYear {
  height: 50px;
  width: 25%;
  cursor: pointer;
}
td.rdtMonth:hover,
td.rdtYear:hover {
  background: #f9f9f9;
  cursor: pointer;
  color: #e94675;
}

.rdtCounters {
  display: inline-block;
}

.rdtCounters > div {
  float: left;
}

.rdtCounter {
  height: 100px;
}

.rdtCounter {
  width: 40px;
}

.rdtCounterSeparator {
  line-height: 100px;
}

.rdtCounter .rdtBtn {
  height: 40%;
  line-height: 40px;
  cursor: pointer;
  display: block;

  -webkit-touch-callout: none; /* iOS Safari */
  -webkit-user-select: none; /* Chrome/Safari/Opera */ /* Konqueror */
  -moz-user-select: none; /* Firefox */
  -ms-user-select: none; /* Internet Explorer/Edge */
  user-select: none;
}
.rdtCounter .rdtBtn:hover {
  background: #eee;
}
.rdtCounter .rdtCount {
  height: 20%;
  font-size: 1.2em;
}

.rdtMilli {
  vertical-align: middle;
  padding-left: 8px;
  width: 48px;
}

.rdtMilli input {
  width: 100%;
  font-size: 1.2em;
  margin-top: 37px;
}

.rdtTime td {
  cursor: default;
}

.flexContainer {
  display: flex;
}
.flexContainer input {
  flex: 1 1;
}
.rdtPicker td.rdtActive,
.rdtPicker td.rdtActive:hover {
  background-color: #f9f9f9;
  color: #e94675;
}

.rdtPicker td.rdtDay:hover,
.rdtPicker td.rdtHour:hover,
.rdtPicker td.rdtMinute:hover,
.rdtPicker td.rdtSecond:hover,
.rdtPicker .rdtTimeToggle:hover {
  background: #f9f9f9;
  cursor: pointer;
  color: #e94675;
}
.rdtPicker td.rdtToday:before {
  content: "";
  display: inline-block;
  border-left: 7px solid transparent;
  border-bottom: 7px solid #ea5c8b;
  border-top-color: rgba(0, 0, 0, 0.2);
  position: absolute;
  bottom: 4px;
  right: 4px;
}
.rdt input {
  box-sizing: border-box;
  font-size: 16px;
  background-image: url(/static/media/calander.4ab0f2bb.svg);
  background-position: 10px 0px;
  background-repeat: no-repeat;
  padding: 12px 10px 12px 45px;
  -webkit-transition: width 0.4s ease-in-out;
  transition: width 0.4s ease-in-out;
  background-size: 1.2rem 100%;
  cursor: pointer !important;
  overflow: hidden;
}

/* left picker */
.leftPicker .rdtPicker {
  left: 0;
}

.rdt .form-control[readonly] {
  background-color: #ffffff;
  border: 1px solid #b7b2b2;
}
.rdt .form-control {
  background-color: #ffffff;
}

/* Dropdown button  */
.commonDropDownWrapper {
  width: 100%;
  padding-right: 30px;
}

.commonDropDownWrapper button:hover,
.commonDropDownWrapper button:active {
  background-color: #ffff;
  color: black;
  border: 1px solid #b7b2b2;
}
.commonDropDownWrapper button {
  background-color: #ffff !important;
  color: black !important;
  box-shadow: none !important;
  border: 1px solid #b7b2b2;
  border-radius: 2px;
  text-align: left;
  min-width: 150px;
}

.commonDropDownWrapper button::after {
  float: right !important;
  margin-top: 10px;
}

.commonDropDownWrapper .dropdownMenuWrapper {
  border: none !important;
  box-shadow: 0px 3px 10px -4px #ccc;
}

.commonDropDownWrapper .dropdownMenuItem {
  padding: 10px !important;
  min-width: 150px;
}
.commonDropDownWrapper .dropdownMenuItem:hover {
  color: #e91e63;
  background-color: #f9f9f9;
}

.commonPointer {
  cursor: pointer;
}

.maxQuantitySwitch {
  cursor: pointer;
  margin: 0px 50px 0px 50px;
}

.cursor-disabled {
  cursor: not-allowed !important;
}

.outline-0 {
  outline: 0 !important;
}

.under-construction {
  font-size: 22px;
  padding-top: 15px;
}

.under-construction-img {
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}

.under-construction-row {
  background: white;
  margin: 0px !important;
  margin-bottom: 10px !important;
  min-height: calc(100vh - 218px);
  justify-content: center;
  align-items: center;
}

.fixed-body-height {
  min-height: calc(100vh - 218px);
}

.cursor-pointer {
  cursor: pointer;
}

.image-cropper-wrapper {
  overflow: hidden;
  width: 100%;
  display: flex;
  height: 440px;
  position: relative;
}

.dashboard .menu-item {
  border: 0;
  border-radius: 0;
  box-shadow: 0px 11px 20px -12px rgba(0, 0, 0, 0.04);
  -webkit-transition: all 0.3s cubic-bezier(0.165, 0.84, 0.44, 1);
  transition: all 0.3s cubic-bezier(0.165, 0.84, 0.44, 1);
}

.dashboard .menu-item:hover {
  box-shadow: 0 0 11px rgba(33, 33, 33, 0.2);
}

.dashboard .menu-item .description {
  margin-top: 0.5rem;
  line-height: 1.3;
}

.form-custom-inputs-disable {
  pointer-events: none;
  opacity: 1.5;
  background: #e9ecef;
}
.common-form-error {
  display: flex;
  align-content: center;
  width: 100%;
  font-size: 14px;
  color: #dc3545;
  font-weight: normal;
}

.link-info:hover {
  text-decoration: underline !important;
  color: blue !important;
}
.title-sm {
  font-size: 14px !important;
  font-weight: bold !important;
}
.btn {
  min-height: 39px;
}
.btn-sm {
  min-height: 38px;
    height: 38px;
}
.btn-width {
  min-width: 90px;
}
.btn-close,
.btn.btn-close {
  width: 26px;
  height: 26px;
  min-height: 24px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #fff;
  border: 1px solid #ddd;
  border-radius: 100%;
}
.btn-close img,
.btn-close svg {
  width: 16px;
}
.btn-primary {
  background-color: #229e79 !important;
  border-color: #229e79 !important;
}
.btn-outline-primary {
  border-color: #229e79 !important;
  color: #229e79 !important;
}
.btn-outline-primary:hover {
  border-color: #229e79 !important;
  background-color: #229e79 !important;
  color: #fff !important;
}
.show-more,
.btn.show-more {
  background-color: #ebebeb;
  color: #000;
  font-weight: bold;
  height: 50px;
  border-radius: 25px;
  border: 0;
  padding: 0.5rem 1.5rem;
}
.input-password {
  position: relative;
}
.input-password input {
  padding-right: 48px;
}
.password-eye {
  position: absolute;
  right: 0;
  top: 0;
  width: 38px;
  height: 38px;
  border: 0;
  border-radius: 0;
  outline: 0;
  background-color: transparent;
  background-image: url(/static/media/eye-hidden.69ef7732.svg);
  background-repeat: no-repeat;
  background-position: center center;
  background-size: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
}
.password-eye.active {
  background-image: url(/static/media/eye-visible.01202f6a.svg);
}
.password-eye img,
.password-eye svg {
  width: 24px;
  margin: 0 auto;
}
.password-eye:focus {
  outline: 0;
  box-shadow: none;
  border: 0;
}
.is-invalid ~ .password-eye {
  display: none;
}
.breadcrumb {
  padding: 0 !important;
  margin-bottom: 0 !important;
  background-color: transparent !important;
  border-radius: 0;
}
.breadcrumb .breadcrumb-item,
.breadcrumb .breadcrumb-item a {
  display: flex;
  align-items: center;
  line-height: 1;
}
.breadcrumb .breadcrumb-item img {
  width: 12px;
  margin-right: 0.2rem;
}

.dropdown-custom .dropdown-toggle {
  background-color: #fff;
  border-color: #ced4da;
  min-width: 100px;
  height: 38px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.dropdown-custom .dropdown-toggle.btn-sm {
  height: 32px;
}
.dropdown-custom .dropdown-toggle:after {
  border: 0;
  background: url(/static/media/angle-arrow-down.01467893.svg) center center;
  background-size: 10px;
  width: 10px;
  height: 10px;
}
.dropdown-custom.show .dropdown-toggle:after {
  -webkit-transform: rotate(180deg);
          transform: rotate(180deg);
}
.dropdown-custom .dropdown-toggle:hover {
  background-color: #f9f9f9;
}
.dropdown-custom .dropdown-toggle:focus,
.dropdown-custom .dropdown-toggle:not(:disabled):not(.disabled):active {
  background-color: #fff;
  border-color: #ced4da;
  box-shadow: none !important;
}
.dropdown-custom .dropdown-menu {
  padding: 0.25rem 0;
}
.dropdown-custom .dropdown-item {
  padding: 0.25rem 0.75rem;
  font-size: 14px;
}
.dropdown-custom.dropdown-number .dropdown-toggle {
  min-width: 60px;
}
.dropdown-custom.dropdown-number .dropdown-menu {
  min-width: 60px;
}

.custom-control {
  display: flex !important;
  font-size: 14px;
  min-height: 18px;
}
.custom-control .custom-control-label {
  cursor: pointer;
}
.custom-control .custom-control-text {
  margin-top: 1px;
}
.custom-checkbox .custom-control-label::before {
  border: 2px solid #b9b9b9 !important;
  width: 18px;
  height: 18px;
  border-radius: 2px !important;
  top: 3px;
}
.custom-checkbox .custom-control-label::after {
  width: 18px;
  height: 18px;
}
.custom-checkbox .custom-control-input:checked ~ .custom-control-label::before {
  background-color: #229e79;
  border: 2px solid #229e79 !important;
}
.custom-checkbox .custom-control-input:checked ~ .custom-control-label::after {
  background-image: none !important;
  top: 11px;
  left: -0.96rem;
}

table.table-vcenter th,
table.table-vcenter td {
  vertical-align: middle;
}
.btn-square,
.btn.btn-square {
  border: 0;
  padding: 0;
  width: 24px;
  height: 24px;
  min-height: 24px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.btn-square img,
.btn-square svg {
  width: 22px;
}
.btn-circle,
.btn.btn-circle {
  width: 33px;
  height: 33px;
  min-height: 33px;
  border-radius: 100%;
  background-color: #fff;
  border: 1px solid #ddd;
  padding: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}
.btn-circle:hover,
.btn.btn-circle:hover {
  background-color: #f2f2f2;
}
.btn-circle img,
.btn-circle svg {
  width: 16px;
}

.page-item.active .page-link {
  background-color: #229e79 !important;
  border-color: #229e79 !important;
  color: #fff !important;
}

.tab-item {
  background-color: #f4f4f4;
  margin-bottom: 10px;
}
.tab-item .tab-btn {
  cursor: pointer;
  display: flex;
  width: 100%;
  padding: 0.75rem 0;
  font-weight: bold;
  font-size: 16px;
}
.tab-item .tab-btn:after {
  content: "";
  width: 12px;
  margin-left: auto;
  background: url(/static/media/arrow-down-sm.8a02366a.svg) right center no-repeat;
  background-size: 12px;
}
.tab-item.active .tab-btn:after {
  -webkit-transform: rotate(180deg);
          transform: rotate(180deg);
}
.tab-item .collapse,
.tab-item .collapsing {
  margin-right: -15px;
  margin-left: -15px;
  background-color: #fff;
}
.tab-item .tab-content {
  padding: 1rem;
}
.tab-icon-col {
  flex: 0 0 40px;
  width: 40px;
}
.tab-content .form-control {
  background-color: #f7f7f7;
  border: 0;
  height: 32px;
  font-size: 14px;
}
.tab-content .form-control:disabled {
  background-color: #fff;
  color: #b2b2b2;
  padding: 0;
  height: auto;
  font-size: 14px;
}
.tag-outer {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  list-style: none;
  padding: 0;
  margin: 0;
}
.tag-outer .tag-list {
  margin-right: 1rem;
  margin-bottom: 1rem;
  position: relative;
}
.tag-outer .tag-item {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  width: auto;
  min-width: 100px;
  min-height: 40px;
  text-align: center;
  padding: 0.5rem 1rem;
  background-color: #fff;
  border: 1px solid #707070;
  border-radius: 20px;
  white-space: nowrap;
  outline: 0;
}
.tag-outer .tag-item:hover {
  background-color: #f2f2f2;
}
.tag-outer input.tag-item {
  background-color: #f2f2f2;
  padding-right: 35px;
}
.tag-outer .tag-item:active,
.tag-outer .tag-item:focus {
  outline: 0;
  box-shadow: none;
  border-color: #707070;
}

.tab-custom.nav-tabs .nav-item {
  margin-right: 1.5rem;
}
.tab-custom.nav-tabs .nav-link {
  border: 0;
  border-top-left-radius: 0;
  border-top-right-radius: 0;
  cursor: pointer;
  padding: 0.75rem 0.1rem;
}
.tab-custom.nav-tabs .nav-link.active {
  background-color: transparent;
  border-bottom: 3px solid #229e79;
}

/* styles for react-paginate component begin */
.pagination {
  display: flex;
  padding-left: 0;
  list-style: none;
  border-radius: 0.75rem;
}
.pagination li {
  border: 1px solid #229e79;
  padding: 10px;
}
.page {
  border: 1px solid #229e79;
  padding: 10px;
}
.pageLink {
  padding: 10px;
}
.activePage {
  background-color: #229e79;
  color: #fff !important;
}
.activePage a {
  color: #fff !important;
}
/* styles for react-paginate component ends here */

.custom-datepicker {
  display: flex;
  align-items: center;
  background-color: #ffffff;
  border: 1px solid #dbdbdb;
  border-radius: 5px;
  position: relative;
}
.custom-datepicker .rdt {
  position: static;
}
.custom-datepicker .rdtPicker {
  left: 0;
}
.custom-datepicker .rdt .form-control {
  background-color: transparent;
  border: 0;
  width: 100%;
  max-width: 180px;
}
.custom-datepicker .rdt .form-control:focus,
.custom-datepicker .rdt .form-control:active {
  border: 0;
  box-shadow: none !important;
  outline: 0;
}
.custom-datepicker .formTextLabel {
  margin-bottom: 0;
  margin-left: 0.5rem;
}

.pagination {
  display: flex;
  padding-left: 0;
  list-style: none;
  border-radius: 0.75rem;
}
.pagination li {
  border: 1px solid #229e79;
  padding: 3px 2px;
  margin: 0 2px;
  border-radius: 4px;
  cursor: pointer;
}
.pagination li.previous, .pagination li.next {
  padding: 3px 8px;
}
.pagination li.disabled {
  pointer-events: none;
}
.page {
  border: 1px solid #229e79;
  padding: 10px;
}
.pageLink {
  padding: 10px;
}
.activePage {
  background-color: #229e79;
  color: #fff !important;
}
.activePage a {
  color: #fff !important;
}

.table-common td img {
  max-width: 80px;
}

/**
 * Copyright(c) 2020 Mozanta Technologies Private Ltd.
 *
 * All rights reserved.
 *
 * This software is the confidential and proprietary information of Mozanta ("Confidential
 * Information"). You shall not disclose such Confidential Information and shall use it only in
 * accordance with the terms of the contract agreement you entered into with Mozanta.
 *
 * @author amritha
 */

.b2bCustomer_borderRadius2__20UwQ {
  border-radius: 0.125rem !important;
}

/** =========== ACTION AREA STYLES ============== **/
.b2bCustomer_areaActionRight__1SSYO {
  display: flex;
  justify-content: flex-end;
}

.b2bCustomer_secondaryColor__3jlOK,
.b2bCustomer_secondaryColor__3jlOK:focus,
.b2bCustomer_secondaryColor__3jlOK:active {
  background-color: #757575 !important;
  border-color: #757575 !important;
}

.b2bCustomer_tableWrapper__2gL7l{
  min-height: calc(10vh - 365px);
}


.b2bCustomer_displayData__27Z_n {
  background: #f9f9f9;
  padding: 1rem;
}

.b2bCustomer_dataTableList__1QO3m {
  width: 100%;
  list-style: none;
  padding: 0;
  margin: 0 auto;
  background-color: #fff;
  border-radius: .25rem;
  overflow: hidden;
}

@media (max-width: 992px) {
  .b2bCustomer_dataTableList__1QO3m {
    max-width: 100%;
  }
}
.b2bCustomer_dataTableList__1QO3m li {
  display: flex;
  flex-direction: row;
  padding: 0.75rem 1rem;
  border-bottom: 1px solid #e3e7eb;
}

.b2bCustomer_dataTableList__1QO3m li:hover {
  background-color: #fcfcfc;
}

.b2bCustomer_dataTableList__1QO3m li .b2bCustomer_dataTableTitle__38LdE {
  width: 30%;
  flex: 0 0 30%;
  color: #8f9bb3;
  word-break: break-word;
}

.b2bCustomer_dataTableList__1QO3m li .b2bCustomer_dataTableText__36aLo {
  width: calc(70% - 1rem);
  flex-grow: 0;
  flex-shrink: 0;
  flex-basis: calc(70% - 1rem);
  padding-left: 1rem;
  word-break: break-word;
}
/**
 * Copyright(c) 2020 Mozanta Technologies Private Ltd.
 * 
 * All rights reserved.                
 * 
 * This software is the confidential and proprietary information of Mozanta ("Confidential
 * Information"). You shall not disclose such Confidential Information and shall use it only in
 * accordance with the terms of the contract agreement you entered into with Mozanta.
 * 
 * @author Indrajith C
 */

 .CustomerDataManagement_block2__3UwHZ {
  padding-top: 20px;
  padding-bottom: 20px;
}

.CustomerDataManagement_block2__3UwHZ button {
  background: none;
  color: black;
  border-radius: 2px;
  border: 1px solid #e0e0e0;
}

.CustomerDataManagement_block2__3UwHZ .CustomerDataManagement_dropdownBtn__moFz5 {
  padding: 0px;
}

.CustomerDataManagement_block2__3UwHZ .CustomerDataManagement_dropdownBtn__moFz5 button {
  float: right !important;
}

.CustomerDataManagement_borderRadius2__2afpU {
  border-radius: 0.125rem !important;
}

.CustomerDataManagement_secondaryColor__2EkUa {
  background-color: #757575 !important;
  border-color: #757575 !important;
}

.CustomerDataManagement_primaryColor__nMDGl {
  background: #e91e63 !important;
  border-color: #e91e63 !important;
}

.CustomerDataManagement_btn__11OEZ {
  min-width: 7rem;
}

/* ============= PRODUCT TITLE ========= */
.CustomerDataManagement_titleTextContainer__3pz1K {
  padding: 0 2.1rem 0.125rem 2.1rem;
  border-bottom: 1px solid #edf0f5;
  margin: 0 -0.938rem;
}

.CustomerDataManagement_titleTextContainer__3pz1K .CustomerDataManagement_pageTitleText__2DQjx{
  text-align: left;
  color: #333333;
  padding-bottom: 17px;
}
 
 

/** =========== ACTION AREA STYLES ============== **/
.CustomerDataManagement_block2__3UwHZ .CustomerDataManagement_areaActionRight__10rO_ {
  display: flex;
  justify-content: flex-end;
}

.CustomerDataManagement_block2__3UwHZ .CustomerDataManagement_borderRadius2__2afpU {
  border-radius: 0.125rem;
}

.CustomerDataManagement_block2__3UwHZ .CustomerDataManagement_areaActionRight__10rO_ > div:first-child {
  margin-right: 0.3rem;
}
.CustomerDataManagement_block2__3UwHZ .CustomerDataManagement_areaActionRight__10rO_ > div:last-child {
  display: flex;
}

/** ========= SEARCH FILED ================ **/
.CustomerDataManagement_block2__3UwHZ .CustomerDataManagement_areaActionRight__10rO_ .CustomerDataManagement_search__kZepE {
  border: 1px solid;
  border-radius: 0.125rem;
}

.CustomerDataManagement_block2__3UwHZ .CustomerDataManagement_areaActionRight__10rO_ .CustomerDataManagement_search__kZepE input {
  border: 0;
  width: 100%;
  height: calc(1.5em + 0.75rem + 0px);
}

.CustomerDataManagement_block2__3UwHZ .CustomerDataManagement_areaActionRight__10rO_ .CustomerDataManagement_search__kZepE div {
  border: 0;
  border-radius: 0;
  background-color: inherit;
  padding-left: 0.6rem;
  padding-right: 0.35rem;
}

/***** ========== MAIN ACTION AREA ============ **********/
.CustomerDataManagement_block3__2E_XP {
  padding: 1.25rem 0;
}

/***** ===================== PAGINATION ======================= ******/
 .CustomerDataManagement_pagination__mCcKS { }

 .CustomerDataManagement_pagination__mCcKS .CustomerDataManagement_item__wjaj2 {
  height: 25px;
  width: 25px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}

 .CustomerDataManagement_pagination__mCcKS .CustomerDataManagement_arrowLeft__2LUNs,  .CustomerDataManagement_pagination__mCcKS .CustomerDataManagement_arrowRight__3j_9N {
  border-radius: 50%;
  border: 2px solid;
}

 .CustomerDataManagement_pagination__mCcKS .CustomerDataManagement_arrowLeft__2LUNs > span,  .CustomerDataManagement_pagination__mCcKS .CustomerDataManagement_arrowRight__3j_9N > span {
  border-bottom: 2px solid;
  border-right: 2px solid;
  width: 8px;
  height: 8px; 
  margin-top: -0.5px;
}

 .CustomerDataManagement_pagination__mCcKS .CustomerDataManagement_arrowLeft__2LUNs > span{ 
  -webkit-transform: rotate(127deg); 
          transform: rotate(127deg); 
  margin-right: -2.5px;
}

 .CustomerDataManagement_pagination__mCcKS .CustomerDataManagement_arrowRight__3j_9N > span{ 
  -webkit-transform: rotate(315deg); 
          transform: rotate(315deg); 
  margin-left: -2.5px;
}

 .CustomerDataManagement_pagination__mCcKS .CustomerDataManagement_arrowLeft__2LUNs ,  .CustomerDataManagement_pagination__mCcKS .CustomerDataManagement_arrowRight__3j_9N,  .CustomerDataManagement_pagination__mCcKS .CustomerDataManagement_item__wjaj2[active] {
  color: #e91e63;
  font-weight: 600;
}

 .CustomerDataManagement_pagination__mCcKS .CustomerDataManagement_item__wjaj2[active] {
  cursor: default;
}

 .CustomerDataManagement_pagination__mCcKS .CustomerDataManagement_item__wjaj2[disabled] {
  cursor: not-allowed;
  color: #5d5d5d;
}
.CustomerDataManagement_tableWrapper__1dDyY  {
  min-height: calc(100vh - 393px);
 }

 .CustomerDataManagement_display-data__1lOQp {
    background: #F4F4F4;
    padding: 1rem;
  }
  
  .CustomerDataManagement_data-table-list__1DKI0 {
    width: 100%;
    max-width: 90%;
    list-style: none;
    padding: 0;
    margin: 0 auto;
    background-color: #fff;
    border-radius: .25rem;
    overflow: hidden;
  }
  
  @media (max-width: 992px) {
    .CustomerDataManagement_data-table-list__1DKI0 {
      max-width: 100%;
    }
  }
  
  .CustomerDataManagement_data-table-list__1DKI0 li {
    display: flex;
    flex-direction: row;
    padding: 1rem;
    border-bottom: 1px solid #e3e7eb;
  }
  
  .CustomerDataManagement_data-table-list__1DKI0 li:hover {
    background-color: #fcfcfc;
  }
  
  .CustomerDataManagement_data-table-list__1DKI0 li .CustomerDataManagement_data-table-title__1jBBf {
    width: 30%;
    flex: 0 0 30%;
    color: #8f9bb3;
    word-break: break-word;
  }
  
  .CustomerDataManagement_data-table-list__1DKI0 li .CustomerDataManagement_data-table-text__1wZWb {
    width: calc(70% - 1rem);
    flex-grow: 0;
    flex-shrink: 0;
    flex-basis: calc(70% - 1rem);
    padding-left: 1rem;
    word-break: break-word;
  }
  
  /* New UI styles */
.CustomerDataManagement_SearchCol__S4sq2 {
  position: relative;
}
.CustomerDataManagement_SearchCol__S4sq2 input[type="text"] {
 padding-right: 48px;
 min-width: 220px;
 height: 38px;
 font-size: 14px;
}
.CustomerDataManagement_SearchCol__S4sq2 .CustomerDataManagement_btn__11OEZ,
.CustomerDataManagement_SearchCol__S4sq2 button {
  position: absolute;
  right: 0;
  top: 0;
  width: 38px;
  height: 38px;
  background-color: transparent;
  border: 0;
  display: flex;
  align-items: center;
  justify-content: center;
}
.CustomerDataManagement_SearchCol__S4sq2 .CustomerDataManagement_btn__11OEZ img,
.CustomerDataManagement_SearchCol__S4sq2 button img {
  width: 16px;
}
.CustomerDataManagement_SearchCol__S4sq2 .CustomerDataManagement_btn__11OEZ:hover,
.CustomerDataManagement_SearchCol__S4sq2 button:hover,
.CustomerDataManagement_SearchCol__S4sq2 .CustomerDataManagement_btn__11OEZ:focus,
.CustomerDataManagement_SearchCol__S4sq2 button:focus,
.CustomerDataManagement_SearchCol__S4sq2 .CustomerDataManagement_btn__11OEZ:not(:disabled):not(.CustomerDataManagement_disabled__2AusN):active,
.CustomerDataManagement_SearchCol__S4sq2 button:not(:disabled):not(.CustomerDataManagement_disabled__2AusN):active {
  background-color: transparent;
  outline: 0;
  border: 0;
  box-shadow: none !important;
}
/* New UI styles */
.B2BCustomerListUI_SearchCol__1WSvx {
  position: relative;
}
.B2BCustomerListUI_SearchCol__1WSvx input[type="text"] {
 padding-right: 48px;
 min-width: 220px;
 height: 38px;
 font-size: 14px;
}
.B2BCustomerListUI_SearchCol__1WSvx .B2BCustomerListUI_btn__30sWs,
.B2BCustomerListUI_SearchCol__1WSvx button {
  position: absolute;
  right: 0;
  top: 0;
  width: 38px;
  height: 38px;
  background-color: transparent;
  border: 0;
  display: flex;
  align-items: center;
  justify-content: center;
}
.B2BCustomerListUI_SearchCol__1WSvx .B2BCustomerListUI_btn__30sWs img,
.B2BCustomerListUI_SearchCol__1WSvx button img {
  width: 16px;
}
.B2BCustomerListUI_SearchCol__1WSvx .B2BCustomerListUI_btn__30sWs:hover,
.B2BCustomerListUI_SearchCol__1WSvx button:hover,
.B2BCustomerListUI_SearchCol__1WSvx .B2BCustomerListUI_btn__30sWs:focus,
.B2BCustomerListUI_SearchCol__1WSvx button:focus,
.B2BCustomerListUI_SearchCol__1WSvx .B2BCustomerListUI_btn__30sWs:not(:disabled):not(.B2BCustomerListUI_disabled__21Zzr):active,
.B2BCustomerListUI_SearchCol__1WSvx button:not(:disabled):not(.B2BCustomerListUI_disabled__21Zzr):active {
  background-color: transparent;
  outline: 0;
  border: 0;
  box-shadow: none !important;
}
.B2BCustomerDetailsUI_ContentOuter__26it4 h6 {
  font-size: 14px;
}
/**
 * Copyright(c) 2021 Mozanta Technologies Private Ltd.
 * 
 * All rights reserved.                
 * 
 * This software is the confidential and proprietary information of Mozanta ("Confidential
 * Information"). You shall not disclose such Confidential Information and shall use it only in
 * accordance with the terms of the contract agreement you entered into with Mozanta.
 * 
 * @author Amritha
 */

 .SalesRepsManagement_block2__2wWUo {
    padding-top: 20px;
    padding-bottom: 20px;
  }
  
  .SalesRepsManagement_block2__2wWUo button {
    background: none;
    color: black;
    border-radius: 2px;
    border: 1px solid #e0e0e0;
  }
  
  .SalesRepsManagement_block2__2wWUo .SalesRepsManagement_dropdownBtn__bnTN5 {
    padding: 0px;
  }
  
  .SalesRepsManagement_block2__2wWUo .SalesRepsManagement_dropdownBtn__bnTN5 button {
    float: right !important;
  }
  
  .SalesRepsManagement_borderRadius2__1EOxQ {
    border-radius: 0.125rem !important;
  }
  
  .SalesRepsManagement_secondaryColor__2EVIE {
    background-color: #757575 !important;
    border-color: #757575 !important;
  }
  
  .SalesRepsManagement_primaryColor__2eevU {
    background: #e91e63 !important;
    border-color: #e91e63 !important;
  }
  
  .SalesRepsManagement_btn__1tHjI {
    min-width: 7rem;
  }
  
  /* ============= PRODUCT TITLE ========= */
  .SalesRepsManagement_titleTextContainer__jDYPk {
    padding: 0 2.1rem 0.125rem 2.1rem;
    border-bottom: 1px solid #edf0f5;
    margin: 0 -0.938rem;
  }
  
  .SalesRepsManagement_titleTextContainer__jDYPk .SalesRepsManagement_pageTitleText__9-NK1{
    text-align: left;
    color: #333333;
    padding-bottom: 17px;
  }
   
   
  
  /** =========== ACTION AREA STYLES ============== **/
  .SalesRepsManagement_block2__2wWUo .SalesRepsManagement_areaActionRight__3h8Hf {
    display: flex;
    justify-content: flex-end;
  }
  
  .SalesRepsManagement_block2__2wWUo .SalesRepsManagement_borderRadius2__1EOxQ {
    border-radius: 0.125rem;
  }
  
  .SalesRepsManagement_block2__2wWUo .SalesRepsManagement_areaActionRight__3h8Hf > div:first-child {
    margin-right: 0.3rem;
  }
  .SalesRepsManagement_block2__2wWUo .SalesRepsManagement_areaActionRight__3h8Hf > div:last-child {
    display: flex;
  }
  
  /** ========= SEARCH FILED ================ **/
  .SalesRepsManagement_block2__2wWUo .SalesRepsManagement_areaActionRight__3h8Hf .SalesRepsManagement_search__5BVxj {
    border: 1px solid;
    border-radius: 0.125rem;
  }
  
  .SalesRepsManagement_block2__2wWUo .SalesRepsManagement_areaActionRight__3h8Hf .SalesRepsManagement_search__5BVxj input {
    border: 0;
    width: 100%;
    height: calc(1.5em + 0.75rem + 0px);
  }
  
  .SalesRepsManagement_block2__2wWUo .SalesRepsManagement_areaActionRight__3h8Hf .SalesRepsManagement_search__5BVxj div {
    border: 0;
    border-radius: 0;
    background-color: inherit;
    padding-left: 0.6rem;
    padding-right: 0.35rem;
  }
  
  /***** ========== MAIN ACTION AREA ============ **********/
  .SalesRepsManagement_block3__1F1iU {
    padding: 1.25rem 0;
  }
  
  /***** ===================== PAGINATION ======================= ******/
   .SalesRepsManagement_pagination__17dCf { }
  
   .SalesRepsManagement_pagination__17dCf .SalesRepsManagement_item__JdPZn {
    height: 25px;
    width: 25px;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
  }
  
   .SalesRepsManagement_pagination__17dCf .SalesRepsManagement_arrowLeft__4QIpS,  .SalesRepsManagement_pagination__17dCf .SalesRepsManagement_arrowRight__21Bvn {
    border-radius: 50%;
    border: 2px solid;
  }
  
   .SalesRepsManagement_pagination__17dCf .SalesRepsManagement_arrowLeft__4QIpS > span,  .SalesRepsManagement_pagination__17dCf .SalesRepsManagement_arrowRight__21Bvn > span {
    border-bottom: 2px solid;
    border-right: 2px solid;
    width: 8px;
    height: 8px; 
    margin-top: -0.5px;
  }
  
   .SalesRepsManagement_pagination__17dCf .SalesRepsManagement_arrowLeft__4QIpS > span{ 
    -webkit-transform: rotate(127deg); 
            transform: rotate(127deg); 
    margin-right: -2.5px;
  }
  
   .SalesRepsManagement_pagination__17dCf .SalesRepsManagement_arrowRight__21Bvn > span{ 
    -webkit-transform: rotate(315deg); 
            transform: rotate(315deg); 
    margin-left: -2.5px;
  }
  
   .SalesRepsManagement_pagination__17dCf .SalesRepsManagement_arrowLeft__4QIpS ,  .SalesRepsManagement_pagination__17dCf .SalesRepsManagement_arrowRight__21Bvn,  .SalesRepsManagement_pagination__17dCf .SalesRepsManagement_item__JdPZn[active] {
    color: #e91e63;
    font-weight: 600;
  }
  
   .SalesRepsManagement_pagination__17dCf .SalesRepsManagement_item__JdPZn[active] {
    cursor: default;
  }
  
   .SalesRepsManagement_pagination__17dCf .SalesRepsManagement_item__JdPZn[disabled] {
    cursor: not-allowed;
    color: #5d5d5d;
  }
  .SalesRepsManagement_tableWrapper__1R980  {
    min-height: calc(100vh - 393px);
   }
  
   .SalesRepsManagement_display-data__3t9Iq {
      background: #F4F4F4;
      padding: 1rem;
    }
    
    .SalesRepsManagement_data-table-list__QmFiM {
      width: 100%;
      max-width: 90%;
      list-style: none;
      padding: 0;
      margin: 0 auto;
      background-color: #fff;
      border-radius: .25rem;
      overflow: hidden;
    }
    
    @media (max-width: 992px) {
      .SalesRepsManagement_data-table-list__QmFiM {
        max-width: 100%;
      }
    }
    
    .SalesRepsManagement_data-table-list__QmFiM li {
      display: flex;
      flex-direction: row;
      padding: 1rem;
      border-bottom: 1px solid #e3e7eb;
    }
    
    .SalesRepsManagement_data-table-list__QmFiM li:hover {
      background-color: #fcfcfc;
    }
    
    .SalesRepsManagement_data-table-list__QmFiM li .SalesRepsManagement_data-table-title__5Zok1 {
      width: 30%;
      flex: 0 0 30%;
      color: #8f9bb3;
      word-break: break-word;
    }
    
    .SalesRepsManagement_data-table-list__QmFiM li .SalesRepsManagement_data-table-text__1YrcS {
      width: calc(70% - 1rem);
      flex-grow: 0;
      flex-shrink: 0;
      flex-basis: calc(70% - 1rem);
      padding-left: 1rem;
      word-break: break-word;
    }
    
    /* New UI styles */
  .SalesRepsManagement_SearchCol__3HzPm {
    position: relative;
  }
  .SalesRepsManagement_SearchCol__3HzPm input[type="text"] {
   padding-right: 48px;
   min-width: 220px;
   height: 38px;
   font-size: 14px;
  }
  .SalesRepsManagement_SearchCol__3HzPm .SalesRepsManagement_btn__1tHjI,
  .SalesRepsManagement_SearchCol__3HzPm button {
    position: absolute;
    right: 0;
    top: 0;
    width: 38px;
    height: 38px;
    background-color: transparent;
    border: 0;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .SalesRepsManagement_SearchCol__3HzPm .SalesRepsManagement_btn__1tHjI img,
  .SalesRepsManagement_SearchCol__3HzPm button img {
    width: 16px;
  }
  .SalesRepsManagement_SearchCol__3HzPm .SalesRepsManagement_btn__1tHjI:hover,
  .SalesRepsManagement_SearchCol__3HzPm button:hover,
  .SalesRepsManagement_SearchCol__3HzPm .SalesRepsManagement_btn__1tHjI:focus,
  .SalesRepsManagement_SearchCol__3HzPm button:focus,
  .SalesRepsManagement_SearchCol__3HzPm .SalesRepsManagement_btn__1tHjI:not(:disabled):not(.SalesRepsManagement_disabled__Hro7g):active,
  .SalesRepsManagement_SearchCol__3HzPm button:not(:disabled):not(.SalesRepsManagement_disabled__Hro7g):active {
    background-color: transparent;
    outline: 0;
    border: 0;
    box-shadow: none !important;
  }
/**
 * Copyright(c) 2021 Mozanta Technologies Private Ltd.
 *
 * All rights reserved.
 *
 * This software is the confidential and proprietary information of Mozanta ("Confidential
 * Information"). You shall not disclose such Confidential Information and shall use it only in
 * accordance with the terms of the contract agreement you entered into with Mozanta.
 *
 * @author amritha
 */

.ViewSalesRep_borderRadius2__3KWYn {
  border-radius: 0.125rem !important;
}

/** =========== ACTION AREA STYLES ============== **/
.ViewSalesRep_areaActionRight__61a9A {
  display: flex;
  justify-content: flex-end;
}

.ViewSalesRep_secondaryColor__2msmC,
.ViewSalesRep_secondaryColor__2msmC:focus,
.ViewSalesRep_secondaryColor__2msmC:active {
  background-color: #757575 !important;
  border-color: #757575 !important;
}

.ViewSalesRep_tableWrapper__3gkfp{
  min-height: calc(10vh - 365px);
}


.ViewSalesRep_displayData__2vEnG {
  background: #f9f9f9;
  padding: 1rem;
}

.ViewSalesRep_dataTableList__1Y3Xk {
  width: 100%;
  list-style: none;
  padding: 0;
  margin: 0 auto;
  background-color: #fff;
  border-radius: .25rem;
  overflow: hidden;
}

@media (max-width: 992px) {
  .ViewSalesRep_dataTableList__1Y3Xk {
    max-width: 100%;
  }
}
.ViewSalesRep_dataTableList__1Y3Xk li {
  display: flex;
  flex-direction: row;
  padding: 0.75rem 1rem;
  border-bottom: 1px solid #e3e7eb;
}

.ViewSalesRep_dataTableList__1Y3Xk li:hover {
  background-color: #fcfcfc;
}

.ViewSalesRep_dataTableList__1Y3Xk li .ViewSalesRep_dataTableTitle__QzH5w {
  width: 30%;
  flex: 0 0 30%;
  color: #8f9bb3;
  word-break: break-word;
}

.ViewSalesRep_dataTableList__1Y3Xk li .ViewSalesRep_dataTableText__fDDTV {
  width: calc(70% - 1rem);
  flex-grow: 0;
  flex-shrink: 0;
  flex-basis: calc(70% - 1rem);
  padding-left: 1rem;
  word-break: break-word;
}
/**
 * Copyright(c) 2020 Mozanta Technologies Private Ltd.
 * 
 * All rights reserved.                
 * 
 * This software is the confidential and proprietary information of Mozanta ("Confidential
 * Information"). You shall not disclose such Confidential Information and shall use it only in
 * accordance with the terms of the contract agreement you entered into with Mozanta.
 * 
 * @author Indrajith C
 */

 .BadgesManagement_block2__1wsrT {
  padding-top: 20px;
  padding-bottom: 20px;
}

.BadgesManagement_block2__1wsrT {
  padding-top: 20px;
  padding-bottom: 20px;
} 

.BadgesManagement_borderRadius2__1k13l {
  border-radius: 0.125rem !important;
}

.BadgesManagement_secondaryColor__2_pDg {
  background-color: #757575 !important;
  border-color: #757575 !important;
}

.BadgesManagement_primaryColor__3LYSm {
  background: #e91e63 !important;
  border-color: #e91e63 !important;
}

.BadgesManagement_btn__1Q8b3 {
  min-width: 7rem;
}

/* ============= PRODUCT TITLE ========= */
.BadgesManagement_titleTextContainer__30K-r {
  padding: 0 2.1rem 0.125rem 2.1rem;
  border-bottom: 1px solid #edf0f5;
  margin: 0 -0.938rem;
}

.BadgesManagement_titleTextContainer__30K-r .BadgesManagement_pageTitleText__1fTjL{
  text-align: left;
  color: #333333;
  padding-bottom: 17px;
}
 
 

/** =========== ACTION AREA STYLES ============== **/
.BadgesManagement_block2__1wsrT .BadgesManagement_areaActionRight__Bno2z {
  display: flex;
  justify-content: flex-end;
}

.BadgesManagement_block2__1wsrT .BadgesManagement_borderRadius2__1k13l {
  border-radius: 0.125rem;
}

.BadgesManagement_block2__1wsrT .BadgesManagement_areaActionRight__Bno2z > div:first-child {
  margin-right: 0.3rem;
}
.BadgesManagement_block2__1wsrT .BadgesManagement_areaActionRight__Bno2z > div:last-child {
  display: flex;
}

/** ========= SEARCH FILED ================ **/
.BadgesManagement_block2__1wsrT .BadgesManagement_areaActionRight__Bno2z .BadgesManagement_search__3zGDo {
  border: 1px solid;
  border-radius: 0.125rem;
}

.BadgesManagement_block2__1wsrT .BadgesManagement_areaActionRight__Bno2z .BadgesManagement_search__3zGDo input {
  border: 0;
  width: 100%;
  height: calc(1.5em + 0.75rem + 0px);
}

.BadgesManagement_block2__1wsrT .BadgesManagement_areaActionRight__Bno2z .BadgesManagement_search__3zGDo div {
  border: 0;
  border-radius: 0;
  background-color: inherit;
  padding-left: 0.6rem;
  padding-right: 0.35rem;
}

/***** ========== MAIN ACTION AREA ============ **********/
.BadgesManagement_block3__2_4jo {
  padding: 1.25rem 0;
}
.BadgesManagement_block3__2_4jo .BadgesManagement_coupon__1PxsI .BadgesManagement_couponBody__22U4X .BadgesManagement_couponTable__3SucK .BadgesManagement_couponID__3oeri {
  max-width: 100px;
}
.BadgesManagement_block3__2_4jo .BadgesManagement_coupon__1PxsI .BadgesManagement_couponBody__22U4X .BadgesManagement_couponTable__3SucK thead {
  background-color: #edf0f5;
}


/***** ===================== PAGINATION ======================= ******/

 .BadgesManagement_pagination__X34h0 .BadgesManagement_item__IlwNl {
  height: 25px;
  width: 25px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}

 .BadgesManagement_pagination__X34h0 .BadgesManagement_arrowLeft__3L-Ir,  .BadgesManagement_pagination__X34h0 .BadgesManagement_arrowRight__294ZO {
  border-radius: 50%;
  border: 2px solid;
}

 .BadgesManagement_pagination__X34h0 .BadgesManagement_arrowLeft__3L-Ir > span,  .BadgesManagement_pagination__X34h0 .BadgesManagement_arrowRight__294ZO > span {
  border-bottom: 2px solid;
  border-right: 2px solid;
  width: 8px;
  height: 8px; 
  margin-top: -0.5px;
}

 .BadgesManagement_pagination__X34h0 .BadgesManagement_arrowLeft__3L-Ir > span{ 
  -webkit-transform: rotate(127deg); 
          transform: rotate(127deg); 
  margin-right: -2.5px;
}

 .BadgesManagement_pagination__X34h0 .BadgesManagement_arrowRight__294ZO > span{ 
  -webkit-transform: rotate(315deg); 
          transform: rotate(315deg); 
  margin-left: -2.5px;
}

 .BadgesManagement_pagination__X34h0 .BadgesManagement_arrowLeft__3L-Ir ,  .BadgesManagement_pagination__X34h0 .BadgesManagement_arrowRight__294ZO,  .BadgesManagement_pagination__X34h0 .BadgesManagement_item__IlwNl[active] {
  color: #e91e63;
  font-weight: 600;
}

 .BadgesManagement_pagination__X34h0 .BadgesManagement_item__IlwNl[active] {
  cursor: default;
}

 .BadgesManagement_pagination__X34h0 .BadgesManagement_item__IlwNl[disabled] {
  cursor: not-allowed;
  color: #5d5d5d;
}

.BadgesManagement_tableWrapper__3lVuu{
  min-height: calc(100vh - 402px);
}
/**
 * Copyright(c) 2020 Mozanta Technologies Private Ltd.
 * 
 * All rights reserved.
 * 
 * This software is the confidential and proprietary information of Mozanta ("Confidential
 * Information"). You shall not disclose such Confidential Information and shall use it only in
 * accordance with the terms of the contract agreement you entered into with Mozanta.
 * 
 * @author Indrajith C
 * 
 */


 .MultiSelect_dropDown__WDaCs  button:hover, .MultiSelect_dropDown__WDaCs  button:active{
    background-color: #ffff ;
    color: black;
    border: solid 1px #313131;

}
.MultiSelect_dropDown__WDaCs  button{
    background-color: #ffff !important;
    color: black !important;
    box-shadow: none !important;
    border: solid 1px #313131;
    border-radius: 2px; 
    text-align: left;
    display: flex;
    align-items: center;
    padding: 0.1rem 0.2rem;
    height: unset !important;
    min-height: calc(1.5em + 0.75rem + 2px) !important;

}

.MultiSelect_dropDown__WDaCs.MultiSelect_disabled__38l5w button{
    background-color: #edf0f5 !important;
    opacity: 0.65;
}

.MultiSelect_dropDown__WDaCs  button> span[disabled] {
    color: #767f86 !important;
}

/*  ======== chip component =========   */
.MultiSelect_dropDown__WDaCs button .MultiSelect_chip__2wpmZ {
    padding: 0.05rem 0.25rem 0.05rem 0.4rem;
    border-radius: 50px;
    border: 1px solid;
    border-color: #b7b2b2;
    display: flex;
    align-items: center;
    margin: 1px 0;
}

.MultiSelect_dropDown__WDaCs button .MultiSelect_chip__2wpmZ:not(:last-child) {
    margin-right: 0.125rem;
}

.MultiSelect_dropDown__WDaCs button span.MultiSelect_chip__2wpmZ > span.MultiSelect_remove__6TTQb {
    border-radius: 50%;
    border: 1px solid;
    width: 20px;
    height: 20px;
    margin-left: auto;
    display: flex;
    border-color: #b7b2b2;
}
.MultiSelect_dropDown__WDaCs button span.MultiSelect_chip__2wpmZ > span.MultiSelect_remove__6TTQb>img{
    width: 18px;
    height: 18px;
}

/* =========== TYPEAHEAD ============ */
.MultiSelect_dropDown__WDaCs button input.MultiSelect_typeahead__2xo8t {
    border: none;
    display: flex;
    height: unset;
    width: auto;
    min-width: 150px;
    cursor: text;
    padding: 0.125rem 0.5rem;
    color: #495057;
} 
 
.MultiSelect_dropDown__WDaCs button input.MultiSelect_typeahead__2xo8t:focus {
    color:   #495057 !important;
    background-color: none !important;
    border-color: none !important;
    outline: 0;
    box-shadow: none !important;
}

.MultiSelect_dropDown__WDaCs  button::after {
    margin-left: auto !important;
    content: unset;
}

.MultiSelect_dropDown__WDaCs  button.MultiSelect_hasDropdown__1s6y_::after {
    content: ' ';
  }

.MultiSelect_dropDown__WDaCs .MultiSelect_dropdownItem__1Jg_C{
    border: none  !important;
    padding: 0.5rem 0.75rem !important;
    cursor: default !important;
}

.MultiSelect_dropDown__WDaCs .MultiSelect_dropdownItem__1Jg_C:hover{
  color:#229E79 !important;
  background-color: #f9f9f9 !important;
}

.MultiSelect_dropDown__WDaCs .MultiSelect_dropdownItem__1Jg_C:focus{ 
 outline: none !important;
 box-shadow: none !important;
}

.MultiSelect_dropDown__WDaCs .MultiSelect_dropdownItem__1Jg_C.MultiSelect_active__B3nJC {
  color:#f9f9f9 !important;
  background-color: #229E79  !important;
  border-top: 1px solid white !important;
}


.MultiSelect_dropDown__WDaCs .MultiSelect_dropdownItem__1Jg_C[disabled] {
    color:#767f86 !important; 
  background-color: #f9f9f9 !important;
  cursor: not-allowed !important;
}

.MultiSelect_dropDown__WDaCs .MultiSelect_dropdownWrapper__3NlAM  {
    width: 100% !important;
    border: none !important;
    box-shadow: 0px 3px 10px -4px #ccc;
    max-height: 22.5rem;
    overflow-y: auto;
}

.MultiSelect_dropDown__WDaCs.MultiSelect_invalid__1nqFH  > button[type="button"]{
    border-color: #dc3545;
  padding-right: calc(1.5em + 0.75rem);
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='12' height='12' fill='none' stroke='%23dc3545' viewBox='0 0 12 12'%3e%3ccircle cx='6' cy='6' r='4.5'/%3e%3cpath stroke-linejoin='round' d='M5.8 3.6h.4L6 6.5z'/%3e%3ccircle cx='6' cy='8.2' r='.6' fill='%23dc3545' stroke='none'/%3e%3c/svg%3e");
  background-repeat: no-repeat;
  background-position: right calc(0.375em + 0.1875rem) center;
  background-size: calc(0.75em + 0.375rem) calc(0.75em + 0.375rem); 
}

/**
 * Copyright(c) 2020 Mozanta Technologies Private Ltd.
 * 
 * All rights reserved.
 * 
 * This software is the confidential and proprietary information of Mozanta ("Confidential
 * Information"). You shall not disclose such Confidential Information and shall use it only in
 * accordance with the terms of the contract agreement you entered into with Mozanta.
 * 
 * @author Indrajith C
 * 
 */
.SimpleProductSelector_chipWidth__2M4t8 {
  max-width: 153px;
}
/**
 * Copyright(c) 2020 Mozanta Technologies Private Ltd.
 * 
 * All rights reserved.
 * 
 * This software is the confidential and proprietary information of Mozanta ("Confidential
 * Information"). You shall not disclose such Confidential Information and shall use it only in
 * accordance with the terms of the contract agreement you entered into with Mozanta.
 * 
 * @author Indrajith C
 * 
 */
 .BadgeForm_noLabelFormGroup__26wMY {
  margin-top: 1.75rem;
  padding: 0.375rem 0.75rem;
} 
/**
 * Copyright(c) 2020 Mozanta Technologies Private Ltd.
 * 
 * All rights reserved.
 * 
 * This software is the confidential and proprietary information of Mozanta ("Confidential
 * Information"). You shall not disclose such Confidential Information and shall use it only in
 * accordance with the terms of the contract agreement you entered into with Mozanta.
 * 
 * @author Indrajith C
 * 
 */ 
.AddBadge_borderRadius2__2DbcW {
  border-radius: 0.125rem !important;
}

.AddBadge_secondaryColor__1h61y {
  background-color: #757575 !important;
  border-color: #757575 !important;
}

.AddBadge_primaryColor__2hKFT {
  background: #17a2b8 !important;
  border-color: #17a2b8 !important;
}

.AddBadge_btn__1dvva {
  min-width: 7rem;
}

 .AddBadge_block1__H8TEj{
  padding-top: 20px;
  padding-bottom: 20px;
}
.AddBadge_block1__H8TEj .AddBadge_navigationManagementnText__34vhR{
  text-align: left;
  color: #333333;
  padding-bottom: 17px;
}

.AddBadge_block1__H8TEj .AddBadge_buttonAddCoupon__2mubz{
  color: #ffffff;
  width: 113px;
  height: 35px;
  border-radius: 2px;
  background-color: #448aff;
  border: 0px;
  float: right;
}
.AddBadge_blockWrapper__3lzW_{
  min-height: calc(100vh - 385px);
}
/**
 * Copyright(c) 2020 Mozanta Technologies Private Ltd.
 * 
 * All rights reserved.
 * 
 * This software is the confidential and proprietary information of Mozanta ("Confidential
 * Information"). You shall not disclose such Confidential Information and shall use it only in
 * accordance with the terms of the contract agreement you entered into with Mozanta.
 * 
 * @author Aardra S
 * 
 */ 
.EditBadge_borderRadius2__2EkxN {
  border-radius: 0.125rem !important;
}

.EditBadge_secondaryColor__2MW0O {
  background-color: #757575 !important;
  border-color: #757575 !important;
}

.EditBadge_primaryColor__2Rr5n {
  background: #17a2b8 !important;
  border-color: #17a2b8 !important;
}

.EditBadge_btn__1GC8u {
  min-width: 7rem;
}


/**
 * Copyright(c) 2020 Mozanta Technologies Private Ltd.
 * 
 * All rights reserved.                
 * 
 * This software is the confidential and proprietary information of Mozanta ("Confidential
 * Information"). You shall not disclose such Confidential Information and shall use it only in
 * accordance with the terms of the contract agreement you entered into with Mozanta.
 * 
 * @author Indrajith C
 */

.BrandManagement_block2__3nPjN {
  padding-top: 20px;
  padding-bottom: 20px;
}

/** =========== ACTION AREA STYLES ============== **/
.BrandManagement_block2__3nPjN .BrandManagement_areaActionRight__2hJ0Q {
  display: flex;
  justify-content: flex-end;
}

.BrandManagement_block2__3nPjN .BrandManagement_borderRadius2__2Mt0P {
  border-radius: 0.125rem;
}

.BrandManagement_block2__3nPjN .BrandManagement_areaActionRight__2hJ0Q > div:first-child {
  margin-right: 0.3rem;
}
.BrandManagement_block2__3nPjN .BrandManagement_areaActionRight__2hJ0Q > div:last-child {
  display: flex;
}

/** ========= SEARCH FILED ================ **/
.BrandManagement_block2__3nPjN .BrandManagement_areaActionRight__2hJ0Q .BrandManagement_search__260Em {
  border: 1px solid;
  border-radius: 0.125rem;
}

.BrandManagement_block2__3nPjN .BrandManagement_areaActionRight__2hJ0Q .BrandManagement_search__260Em input {
  border: 0;
  width: 100%;
  height: calc(1.5em + 0.75rem + 0px);
}

.BrandManagement_block2__3nPjN .BrandManagement_areaActionRight__2hJ0Q .BrandManagement_search__260Em div {
  border: 0;
  border-radius: 0;
  background-color: inherit;
  padding-left: 0.6rem;
  padding-right: 0.35rem;
}

/***** ========== MAIN ACTION AREA ============ **********/
.BrandManagement_block3__2PNR2 {
  padding: 1.25rem 0;
}
.BrandManagement_tableWrapper__1ao0i{
  min-height: calc(100vh - 385px);
}
/**
 * Copyright(c) 2020 Mozanta Technologies Private Ltd.
 * 
 * All rights reserved.                
 * 
 * This software is the confidential and proprietary information of Mozanta ("Confidential
 * Information"). You shall not disclose such Confidential Information and shall use it only in
 * accordance with the terms of the contract agreement you entered into with Mozanta.
 * 
 * @author Indrajith C
 */

 .BrandForm_block2__1i0TZ {
  padding-top: 20px;
  padding-bottom: 20px;
} 

.BrandForm_borderRadius2__F_ZPd {
  border-radius: 0.125rem !important;
}

.BrandForm_secondaryColor__o2vRI {
  background-color: #757575 !important;
  border-color: #757575 !important;
}

.BrandForm_primaryColor__NVH2T {
  background: #e91e63 !important;
  border-color: #e91e63 !important;
}

.BrandForm_btn__39Y3C {
  min-width: 7rem;
}

.BrandForm_icon__2ZN8p {
  width: 1.25rem;
}

/* ========== FORM AREA ============ */
.BrandForm_block3__25Ttm {
  padding: 1.25rem;
}

/* ============= PRODUCT TITLE ========= */
.BrandForm_titleTextContainer__21p3R {
  padding: 0 2.1rem 0.125rem 2.1rem;
  border-bottom: 1px solid #edf0f5;
  margin: 0 -0.938rem;
}

.BrandForm_titleTextContainer__21p3R .BrandForm_pageTitleText__Uj4f-{
  text-align: left;
  color: #333333;
  padding-bottom: 17px;
}
/**
 * Copyright(c) 2020 Mozanta Technologies Private Ltd.
 * 
 * All rights reserved.
 * 
 * This software is the confidential and proprietary information of Mozanta ("Confidential
 * Information"). You shall not disclose such Confidential Information and shall use it only in
 * accordance with the terms of the contract agreement you entered into with Mozanta.
 * 
 * @author Indrajith C
 * 
 */
 .BrandCreatorView_block1__24l5O{
  padding-top: 20px;
  padding-bottom: 20px;
}
.BrandCreatorView_block1__24l5O .BrandCreatorView_pageSelectionText__10juN{
  text-align: left;
  color: #333333;
  padding-bottom: 17px;
}

.BrandCreatorView_block1__24l5O .BrandCreatorView_buttonAddMenu__30yOW{
  color: #ffffff;
  width: 113px;
  height: 35px;
  border-radius: 2px;
  background-color: #448aff;
  border: 0px;
  float: right;
}

.BrandCreatorView_blockWrapper__1JCG8{
  min-height: calc(100vh - 385px);
} 
 
.BrandCreatorView_borderRadius2__1L35C {
  border-radius: 0.125rem !important;
}

.BrandCreatorView_secondaryColor__11Cmj {
  background-color: #757575 !important;
  border-color: #757575 !important;
}

.BrandCreatorView_primaryColor__19ZEj {
  background: #17a2b8 !important;
  border-color: #17a2b8 !important;
}

.BrandCreatorView_customColorForDelete__36QlL {
  background: #FF2C16 !important;
  border-color: #FF2C16 !important;
}

.BrandCreatorView_customColorForDelete__36QlL:hover {
  background: #F20000 !important;
  border-color: #FF2C16 !important;
}

.BrandCreatorView_btn__1OzfB {
  min-width: 7rem;
}

.BrandCreatorView_icon__3khBO {
  width: 1.25rem;
}
/**
 * Copyright(c) 2020 Mozanta Technologies Private Ltd.
 * 
 * All rights reserved.                
 * 
 * This software is the confidential and proprietary information of Mozanta ("Confidential
 * Information"). You shall not disclose such Confidential Information and shall use it only in
 * accordance with the terms of the contract agreement you entered into with Mozanta.
 * 
 * @author Indrajith C
 */

.ImageSelection_uploadImagePreview__3yPn1 {
    max-width: 150px;
    margin-right: 1rem;
    position: relative;
  }
.ImageSelection_uploadImagePreviewIcon__3DeC6 {
    max-width: 150px;
}
.ImageSelection_uploadImagePreviewClose__3r3zl{
  display: none;
}
.ImageSelection_uploadImagePreview__3yPn1:hover .ImageSelection_uploadImagePreviewClose__3r3zl {
  display: flex;
  border-radius: 50%;
  position: absolute;
  top: -4px;
  right: -4px;
  background: #b3b3b3;
  font-size: 11px;
  padding: 0px 5px;
  font-weight: 600;
  cursor: pointer;
  z-index: 3;
}

.ImageSelection_uploadImagePreview__3yPn1:last-child {
      margin-right: 0;
}

.ImageSelection_uploadImagePreview__3yPn1 .ImageSelection_hoverImageUpload__38W5x {
  z-index: 2;
  cursor: pointer;
  position: absolute;
    top: 0;
    left: 0;
    display: none;
}

.ImageSelection_uploadImagePreview__3yPn1 .ImageSelection_hoverImageUpload__38W5x label {
  border: 0;
}

.ImageSelection_uploadImagePreview__3yPn1 .ImageSelection_hoverImageUpload__38W5x label img {
  opacity: 0.6;
  border: none;
}

.ImageSelection_uploadImagePreview__3yPn1:hover .ImageSelection_hoverImageUpload__38W5x {
  display: flex;
}
/**
 * Copyright(c) 2020 Mozanta Technologies Private Ltd.
 * 
 * All rights reserved.                
 * 
 * This software is the confidential and proprietary information of Mozanta ("Confidential
 * Information"). You shall not disclose such Confidential Information and shall use it only in
 * accordance with the terms of the contract agreement you entered into with Mozanta.
 * 
 * @author Indrajith C
 */

 .ProductRankManagement_block2__LAlMZ {
    padding-top: 20px;
    padding-bottom: 20px;
  }
  
.ProductRankManagement_borderRadius2__1dXqf {
  border-radius: 0.125rem !important;
}

  /** =========== ACTION AREA STYLES ============== **/
  .ProductRankManagement_block2__LAlMZ .ProductRankManagement_areaActionRight__29k6K {
    display: flex;
    justify-content: flex-end;
  }
  
  .ProductRankManagement_block2__LAlMZ .ProductRankManagement_areaActionRight__29k6K > div:first-child {
    margin-right: 0.3rem;
  }
  .ProductRankManagement_block2__LAlMZ .ProductRankManagement_areaActionRight__29k6K > div:last-child {
    display: flex;
  }
  
  /** ========= SEARCH FILED ================ **/
  .ProductRankManagement_block2__LAlMZ .ProductRankManagement_areaActionRight__29k6K .ProductRankManagement_search__1No4c {
    border: 1px solid;
    border-radius: 0.125rem;
  }
  
  .ProductRankManagement_block2__LAlMZ .ProductRankManagement_areaActionRight__29k6K .ProductRankManagement_search__1No4c input {
    border: 0;
    width: 100%;
    height: calc(1.5em + 0.75rem + 0px);
  }
  
  .ProductRankManagement_block2__LAlMZ .ProductRankManagement_areaActionRight__29k6K .ProductRankManagement_search__1No4c div {
    border: 0;
    border-radius: 0;
    background-color: inherit;
    padding-left: 0.6rem;
    padding-right: 0.35rem;
  }
  
  /***** ========== MAIN ACTION AREA ============ **********/
  .ProductRankManagement_block3__20oij {
    padding: 1.25rem 0;
  }
  
  /***** ============== MAIN ACTION COMPONENT ========= *******/
  .ProductRankManagement_block3__20oij .ProductRankManagement_product__2uYfU {
    position: relative;
  }
  
  /***** ============== ACTION COMPONENT BODY ========= *******/
  
  .ProductRankManagement_block3__20oij .ProductRankManagement_product__2uYfU .ProductRankManagement_productBody__3wzSp .ProductRankManagement_productTable__3FvYV thead {
    background-color: #edf0f5;
  }
  
  .ProductRankManagement_block3__20oij .ProductRankManagement_product__2uYfU .ProductRankManagement_productBody__3wzSp .ProductRankManagement_productItem__2-qxO {
    display: flex;
    flex-direction: column;
  }

  .ProductRankManagement_block3__20oij .ProductRankManagement_product__2uYfU .ProductRankManagement_productBody__3wzSp .ProductRankManagement_productTable__3FvYV .ProductRankManagement_productName__3iPPh {
    max-width: 100px;
  }

  .ProductRankManagement_block3__20oij .ProductRankManagement_product__2uYfU .ProductRankManagement_productBody__3wzSp .ProductRankManagement_productTable__3FvYV .ProductRankManagement_productImage__1z6zI {
    max-height: 50px;
  }

  /***** ===================== PAGINATION ======================= ******/
  .ProductRankManagement_block3__20oij .ProductRankManagement_product__2uYfU .ProductRankManagement_pagination__1l0NR { }
  
  .ProductRankManagement_block3__20oij .ProductRankManagement_product__2uYfU .ProductRankManagement_pagination__1l0NR .ProductRankManagement_item__3POKg {
    height: 25px;
    width: 25px;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
  }

  .ProductRankManagement_block3__20oij .ProductRankManagement_product__2uYfU .ProductRankManagement_pagination__1l0NR .ProductRankManagement_arrowLeft__XXKFH, .ProductRankManagement_block3__20oij .ProductRankManagement_product__2uYfU .ProductRankManagement_pagination__1l0NR .ProductRankManagement_arrowRight__1AOB0 {
    border-radius: 50%;
    border: 2px solid;
  }

  .ProductRankManagement_block3__20oij .ProductRankManagement_product__2uYfU .ProductRankManagement_pagination__1l0NR .ProductRankManagement_arrowLeft__XXKFH > span, .ProductRankManagement_block3__20oij .ProductRankManagement_product__2uYfU .ProductRankManagement_pagination__1l0NR .ProductRankManagement_arrowRight__1AOB0 > span {
    border-bottom: 2px solid;
    border-right: 2px solid;
    width: 8px;
    height: 8px; 
    margin-top: -0.5px;
  }

  .ProductRankManagement_block3__20oij .ProductRankManagement_product__2uYfU .ProductRankManagement_pagination__1l0NR .ProductRankManagement_arrowLeft__XXKFH > span{ 
    -webkit-transform: rotate(127deg); 
            transform: rotate(127deg); 
    margin-right: -2.5px;
  }

  .ProductRankManagement_block3__20oij .ProductRankManagement_product__2uYfU .ProductRankManagement_pagination__1l0NR .ProductRankManagement_arrowRight__1AOB0 > span{ 
    -webkit-transform: rotate(315deg); 
            transform: rotate(315deg); 
    margin-left: -2.5px;
  }

  .ProductRankManagement_block3__20oij .ProductRankManagement_product__2uYfU .ProductRankManagement_pagination__1l0NR .ProductRankManagement_arrowLeft__XXKFH , .ProductRankManagement_block3__20oij .ProductRankManagement_product__2uYfU .ProductRankManagement_pagination__1l0NR .ProductRankManagement_arrowRight__1AOB0, .ProductRankManagement_block3__20oij .ProductRankManagement_product__2uYfU .ProductRankManagement_pagination__1l0NR .ProductRankManagement_item__3POKg[active] {
    color: #e91e63;
    font-weight: 600;
  }

  .ProductRankManagement_block3__20oij .ProductRankManagement_product__2uYfU .ProductRankManagement_pagination__1l0NR .ProductRankManagement_item__3POKg[active] {
    cursor: default;
  }

  .ProductRankManagement_block3__20oij .ProductRankManagement_product__2uYfU .ProductRankManagement_pagination__1l0NR .ProductRankManagement_item__3POKg[disabled] {
    cursor: not-allowed;
    color: #5d5d5d;
  }
  .ProductRankManagement_tableWrapper__36Dua{
    min-height: calc(100vh - 387px);
  }

  .ProductRankManagement_pageTitleText__NyRHB{
    text-align: left;
    color: #333333;
    padding-bottom: 17px;
  }
/**
 * Copyright(c) 2020 Mozanta Technologies Private Ltd.
 * 
 * All rights reserved.                
 * 
 * This software is the confidential and proprietary information of Mozanta ("Confidential
 * Information"). You shall not disclose such Confidential Information and shall use it only in
 * accordance with the terms of the contract agreement you entered into with Mozanta.
 * 
 * @author Indrajith C
 */

 .ProductSelection_block2__3ulSz {
  padding-top: 20px;
  padding-bottom: 20px;
} 

.ProductSelection_borderRadius2__Zteqi {
  border-radius: 0.125rem !important;
}

.ProductSelection_secondaryColor__1ihZk {
  background-color: #757575 !important;
  border-color: #757575 !important;
}

.ProductSelection_primaryColor__2IYup {
  background: #e91e63 !important;
  border-color: #e91e63 !important;
}

.ProductSelection_btn__1_ZWy {
  min-width: 7rem;
}

.ProductSelection_icon__3WWU6 {
  width: 1.25rem;
}

/* ============= PRODUCT TITLE ========= */
.ProductSelection_titleTextContainer__6qGu_ {
  padding: 0 2.1rem 0.125rem 2.1rem;
  border-bottom: 1px solid #edf0f5;
  margin: 0 -0.938rem;
}

/* =============== PRODUCT AREA =============== */
.ProductSelection_block4__1q3s4 {
   padding: 1.25rem;
}

.ProductSelection_block4__1q3s4 .ProductSelection_borderBottom__1kfdf {
  border-bottom: 1px solid ;
}


/** ========= SEARCH FILED ================ **/
.ProductSelection_block4__1q3s4 .ProductSelection_search__2KU4a {
  border: 1px solid #e91e63;
  border-radius: 0.125rem;
}

.ProductSelection_block4__1q3s4 .ProductSelection_search__2KU4a input {
  border: 0;
  width: 100%;
}

.ProductSelection_block4__1q3s4 .ProductSelection_search__2KU4a div {
  border: 0;
  border-radius: 0;
  background-color: #e91e63;
  padding-left: 0.6rem;
  padding-right: 0.35rem;
}

.ProductSelection_block4__1q3s4 img.ProductSelection_icon__3WWU6 {
  width: 20px;
}

.ProductSelection_block4__1q3s4 .ProductSelection_rightDivider__1vvxy::before {
  position: absolute;
  background: #edf0f5;
  width: 1px;
  right: 0;
  height: calc( 100% - 32px );
  bottom: 0;
}

.ProductSelection_block4__1q3s4 .ProductSelection_searchListContainer__2byCP {
  height: 350px;
  overflow-y: auto;
}

.ProductSelection_block4__1q3s4 .ProductSelection_selectedListContainer__2yPJh {
  height: 390px; 
}

.ProductSelection_block4__1q3s4 .ProductSelection_selectedListContainer__2yPJh .ProductSelection_unselectProduct__3dR0v {
    position: absolute;
    right: 0.5rem;
    top: 0rem;
    display: none;
    background: #ffffff7a;
}

.ProductSelection_block4__1q3s4 .ProductSelection_selectedListContainer__2yPJh .ProductSelection_selectedProduct__2YSp3:hover .ProductSelection_unselectProduct__3dR0v {
    display: block;
}

/** ========= MEDIA QUERY ============ **/
@media (min-width: 576px) {
  .ProductSelection_block4__1q3s4 .ProductSelection_rightDivider__1vvxy::before  {
    content: " ";
  }
}
/**
 * Copyright(c) 2020 Mozanta Technologies Private Ltd.
 * 
 * All rights reserved.                
 * 
 * This software is the confidential and proprietary information of Mozanta ("Confidential
 * Information"). You shall not disclose such Confidential Information and shall use it only in
 * accordance with the terms of the contract agreement you entered into with Mozanta.
 * 
 * @author Ajay J A
 */

.CacheClearManagement_collapseIcon__v_yiO>img {
  width: 15px;
  height: 15px;
  -webkit-transition: 0.6s ease-out;
  transition: 0.6s ease-out;
  float: right;
}

.CacheClearManagement_openCollapse__3YpHt {
  -webkit-transform: rotateZ(-90deg);
  transform: rotateZ(-90deg);
}

.CacheClearManagement_closeCollapse__2bP8P {
  -webkit-transform: rotateZ(90deg);
  transform: rotateZ(90deg);
}

.CacheClearManagement_block2__2AlJ8 {
  padding-top: 20px;
  padding-bottom: 20px;
}

.CacheClearManagement_borderRadius2__XhW6r {
  border-radius: 0.125rem !important;
}

.CacheClearManagement_secondaryColor__3ZZuU {
  background-color: #757575 !important;
  border-color: #757575 !important;
}

.CacheClearManagement_primaryColor__2vwCi {
  background: #17a2b8 !important;
  border-color: #17a2b8 !important;
}


.CacheClearManagement_btn__1TCvL {
  min-width: 7rem;
}

.CacheClearManagement_removeCacheSwitch__3tdR5{
    margin-left: 0px;
}
/**
 * Copyright(c) 2020 Mozanta Technologies Private Ltd.
 * 
 * All rights reserved.                
 * 
 * This software is the confidential and proprietary information of Mozanta ("Confidential
 * Information"). You shall not disclose such Confidential Information and shall use it only in
 * accordance with the terms of the contract agreement you entered into with Mozanta.
 * 
 * @author Indrajith C
 */


 .CampaignListView_liveBtnWrapper__Rm512 .CampaignListView_liveBtn__3_HPe{
  border: none;
  border-radius: 2px;
  text-transform: uppercase;
}

 .CampaignListView_block2__10yEU {
    padding-top: 20px;
    padding-bottom: 20px;
  }
  
  .CampaignListView_block2__10yEU {
    padding-top: 20px;
    padding-bottom: 20px;
  } 
  
  .CampaignListView_borderRadius2__jBZFY {
    border-radius: 0.125rem !important;
  }
  
  .CampaignListView_secondaryColor__n7_NM {
    background-color: #757575 !important;
    border-color: #757575 !important;
  }
  
  .CampaignListView_primaryColor__slQ1q {
    background: #e91e63 !important;
    border-color: #e91e63 !important;
  }
  
  .CampaignListView_btn__2uAXM {
    min-width: 7rem;
  }
  
  /* ============= PRODUCT TITLE ========= */
  .CampaignListView_titleTextContainer__sKvS0 {
    padding: 0 2.1rem 0.125rem 2.1rem;
    border-bottom: 1px solid #edf0f5;
    margin: 0 -0.938rem;
  }
  
  .CampaignListView_titleTextContainer__sKvS0 .CampaignListView_pageTitleText__BYr0-{
    text-align: left;
    color: #333333;
    padding-bottom: 17px;
  }
   
   

  /** =========== ACTION AREA STYLES ============== **/
  .CampaignListView_block2__10yEU .CampaignListView_areaActionRight__3ff9k {
    display: flex;
    justify-content: flex-end;
  }
  
  .CampaignListView_block2__10yEU .CampaignListView_borderRadius2__jBZFY {
    border-radius: 0.125rem;
  }
  
  .CampaignListView_block2__10yEU .CampaignListView_areaActionRight__3ff9k > div:first-child {
    margin-right: 0.3rem;
  }
  .CampaignListView_block2__10yEU .CampaignListView_areaActionRight__3ff9k > div:last-child {
    display: flex;
  }
  
  /** ========= SEARCH FILED ================ **/
  .CampaignListView_block2__10yEU .CampaignListView_areaActionRight__3ff9k .CampaignListView_search__2-Ovs {
    border: 1px solid;
    border-radius: 0.125rem;
  }
  
  .CampaignListView_block2__10yEU .CampaignListView_areaActionRight__3ff9k .CampaignListView_search__2-Ovs input {
    border: 0;
    width: 100%;
    height: calc(1.5em + 0.75rem + 0px);
  }
  
  .CampaignListView_block2__10yEU .CampaignListView_areaActionRight__3ff9k .CampaignListView_search__2-Ovs div {
    border: 0;
    border-radius: 0;
    background-color: inherit;
    padding-left: 0.6rem;
    padding-right: 0.35rem;
  }
  
  /***** ========== MAIN ACTION AREA ============ **********/
  .CampaignListView_block3__2cvxq {
    padding: 1.25rem 0;
  }
  
  /***** ============== MAIN ACTION COMPONENT ========= *******/
  .CampaignListView_block3__2cvxq .CampaignListView_campaign__1xz9A {
    position: relative;
  }
  
  /***** ===================== PAGINATION ======================= ******/
   .CampaignListView_pagination__kVnG6 { }
  
   .CampaignListView_pagination__kVnG6 .CampaignListView_item__1nVB3 {
    height: 25px;
    width: 25px;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
  }

   .CampaignListView_pagination__kVnG6 .CampaignListView_arrowLeft__ooUJ7,  .CampaignListView_pagination__kVnG6 .CampaignListView_arrowRight__3TXqn {
    border-radius: 50%;
    border: 2px solid;
  }

   .CampaignListView_pagination__kVnG6 .CampaignListView_arrowLeft__ooUJ7 > span,  .CampaignListView_pagination__kVnG6 .CampaignListView_arrowRight__3TXqn > span {
    border-bottom: 2px solid;
    border-right: 2px solid;
    width: 8px;
    height: 8px; 
    margin-top: -0.5px;
  }

   .CampaignListView_pagination__kVnG6 .CampaignListView_arrowLeft__ooUJ7 > span{ 
    -webkit-transform: rotate(127deg); 
            transform: rotate(127deg); 
    margin-right: -2.5px;
  }

   .CampaignListView_pagination__kVnG6 .CampaignListView_arrowRight__3TXqn > span{ 
    -webkit-transform: rotate(315deg); 
            transform: rotate(315deg); 
    margin-left: -2.5px;
  }

   .CampaignListView_pagination__kVnG6 .CampaignListView_arrowLeft__ooUJ7 ,  .CampaignListView_pagination__kVnG6 .CampaignListView_arrowRight__3TXqn,  .CampaignListView_pagination__kVnG6 .CampaignListView_item__1nVB3[active] {
    color: #e91e63;
    font-weight: 600;
  }

   .CampaignListView_pagination__kVnG6 .CampaignListView_item__1nVB3[active] {
    cursor: default;
  }

   .CampaignListView_pagination__kVnG6 .CampaignListView_item__1nVB3[disabled] {
    cursor: not-allowed;
    color: #5d5d5d;
  }

  .CampaignListView_tableWrapper__BaKBD{
    min-height: calc(100vh - 365px);
  }

  .CampaignListView_sortSelector__3PBs4 { 
    min-width: 200px;
  }
  .CampaignListView_activeSelector__RQfRp { 
    min-width: 110px;
  }
/**
 * Copyright(c) 2020 Mozanta Technologies Private Ltd.
 * 
 * All rights reserved.
 * 
 * This software is the confidential and proprietary information of Mozanta ("Confidential
 * Information"). You shall not disclose such Confidential Information and shall use it only in
 * accordance with the terms of the contract agreement you entered into with Mozanta.
 * 
 * @author Indrajith C
 */

 
 .styles_datePickerComponent__3DsAM .styles_datePicker__19V3I { 
   z-index: 99;
   background: #fff;
   padding: 10px;
   border: 1px solid #ced4da;
   border-radius: 2px;
   min-width: 280px;
   margin-top: 0.125rem;
 }

 .styles_datePickerComponent__3DsAM input {
   box-sizing: border-box;
   font-size: 16px;
   background-image: url(/static/media/calander.4ab0f2bb.svg);
   background-position: 4px 0px;
   background-repeat: no-repeat;
   padding: 12px 10px 12px 45px;
   -webkit-transition: width 0.4s ease-in-out;
   transition: width 0.4s ease-in-out;
   background-size: 1.6rem 100%;
   cursor: pointer !important;
   border-radius: 2px;
   overflow: hidden;
 }
/**
 * Copyright(c) 2020 Mozanta Technologies Private Ltd.
 * 
 * All rights reserved.
 * 
 * This software is the confidential and proprietary information of Mozanta ("Confidential
 * Information"). You shall not disclose such Confidential Information and shall use it only in
 * accordance with the terms of the contract agreement you entered into with Mozanta.
 * 
 * @author Indrajith C
 * 
 */
 .CampaignStepOneView_block1__1d5oQ{
  padding-top: 20px;
  padding-bottom: 20px;
}
.CampaignStepOneView_block1__1d5oQ .CampaignStepOneView_navigationManagementnText__3H7IC{
  text-align: left;
  color: #333333;
  padding-bottom: 17px;
}

.CampaignStepOneView_block1__1d5oQ .CampaignStepOneView_buttonAddMenu__1zzll{
  color: #ffffff;
  width: 113px;
  height: 35px;
  border-radius: 2px;
  background-color: #448aff;
  border: 0px;
  float: right;
}

.CampaignStepOneView_blockWrapper__22m0S{
  min-height: calc(100vh - 385px);
} 
 
.CampaignStepOneView_borderRadius2__1Ajnf {
  border-radius: 0.125rem !important;
}

.CampaignStepOneView_secondaryColor__KvTCl {
  background-color: #757575 !important;
  border-color: #757575 !important;
}

.CampaignStepOneView_primaryColor__2vyhI {
  background: #17a2b8 !important;
  border-color: #17a2b8 !important;
}

.CampaignStepOneView_btn__2aUDw {
  min-width: 7rem;
}

.CampaignStepOneView_icon__3FWKi {
  width: 1em;
}

/* =============== step one =================== */

.CampaignStepOneView_form__227m6 .CampaignStepOneView_formTextLabel__2sOCU {
  color: #000000CC;
  opacity: 0.4;
}
.CampaignStepOneView_block2__2z6bH {
  padding-top: 20px;
  padding-bottom: 20px;
} 

.CampaignStepOneView_borderRadius2__1Ajnf {
  border-radius: 0.125rem !important;
}

.CampaignStepOneView_secondaryColor__KvTCl {
  background-color: #757575 !important;
  border-color: #757575 !important;
}

.CampaignStepOneView_primaryColor__2vyhI {
  background: #17a2b8 !important;
  border-color: #17a2b8 !important;
}


.CampaignStepOneView_btn__2aUDw {
  min-width: 7rem;
}
/**
 * Copyright(c) 2020 Mozanta Technologies Private Ltd.
 * 
 * All rights reserved.
 * 
 * This software is the confidential and proprietary information of Mozanta ("Confidential
 * Information"). You shall not disclose such Confidential Information and shall use it only in
 * accordance with the terms of the contract agreement you entered into with Mozanta.
 * 
 * @author Indrajith C
 * 
 */
 .CouponForm_noLabelFormGroup__10k4n {
  margin-top: 1.75rem;
  padding: 0.375rem 0.75rem;
} 
/**
 * Copyright(c) 2020 Mozanta Technologies Private Ltd.
 * 
 * All rights reserved.
 * 
 * This software is the confidential and proprietary information of Mozanta ("Confidential
 * Information"). You shall not disclose such Confidential Information and shall use it only in
 * accordance with the terms of the contract agreement you entered into with Mozanta.
 * 
 * @author Indrajith C
 * 
 */ 
.AddCoupon_borderRadius2__2HFqs {
  border-radius: 0.125rem !important;
}

.AddCoupon_secondaryColor__3q_ul {
  background-color: #757575 !important;
  border-color: #757575 !important;
}

.AddCoupon_primaryColor__20U9G {
  background: #17a2b8 !important;
  border-color: #17a2b8 !important;
}

.AddCoupon_btn__3djRA {
  min-width: 7rem;
}

 .AddCoupon_block1__2bcpG{
  padding-top: 20px;
  padding-bottom: 20px;
}
.AddCoupon_block1__2bcpG .AddCoupon_navigationManagementnText__3kfyV{
  text-align: left;
  color: #333333;
  padding-bottom: 17px;
}

.AddCoupon_block1__2bcpG .AddCoupon_buttonAddCoupon__m-QxB{
  color: #ffffff;
  width: 113px;
  height: 35px;
  border-radius: 2px;
  background-color: #448aff;
  border: 0px;
  float: right;
}

/**
 * Copyright(c) 2020 Mozanta Technologies Private Ltd.
 * 
 * All rights reserved.
 * 
 * This software is the confidential and proprietary information of Mozanta ("Confidential
 * Information"). You shall not disclose such Confidential Information and shall use it only in
 * accordance with the terms of the contract agreement you entered into with Mozanta.
 * 
 * @author Indrajith C
 * 
 */
 .Coupons_block1__1aJW5{
  padding-top: 20px;
  padding-bottom: 20px;
}
.Coupons_block1__1aJW5 .Coupons_navigationManagementnText__aTDO1{
  text-align: left;
  color: #333333;
  padding-bottom: 17px;
}

.Coupons_block1__1aJW5 .Coupons_buttonAddMenu__1QB6U{
  color: #ffffff;
  width: 113px;
  height: 35px;
  border-radius: 2px;
  background-color: #448aff;
  border: 0px;
  float: right;
}

.Coupons_blockWrapper__g0L-m{
  min-height: calc(100vh - 385px);
} 
 
.Coupons_borderRadius2__3OfKl {
  border-radius: 0.125rem !important;
}

.Coupons_secondaryColor__3kytk {
  background-color: #757575 !important;
  border-color: #757575 !important;
}

.Coupons_primaryColor__3uaAD {
  background: #17a2b8 !important;
  border-color: #17a2b8 !important;
}

.Coupons_btn__3tClG {
  min-width: 7rem;
}

.Coupons_icon__1pP4f {
  width: 1em;
}

/* =============== step two =================== */

.Coupons_form__2eZaK .Coupons_formTextLabel__1mKOT {
  color: #000000CC;
  opacity: 0.4;
}
.Coupons_block2__3VM0U {
  padding-top: 20px;
  padding-bottom: 20px;
} 

.Coupons_borderRadius2__3OfKl {
  border-radius: 0.125rem !important;
}

.Coupons_secondaryColor__3kytk {
  background-color: #757575 !important;
  border-color: #757575 !important;
}

.Coupons_primaryColor__3uaAD {
  background: #17a2b8 !important;
  border-color: #17a2b8 !important;
}


.Coupons_btn__3tClG {
  min-width: 7rem;
}

.Coupons_tabContainer__1kJmd .Coupons_tabHeader__JA7kE  {
  border-bottom: 1px solid #dfdfdf;
}

.Coupons_tabContainer__1kJmd .Coupons_tabHeader__JA7kE.Coupons_active__2s9wC {
  border-bottom: 3px solid #17a2b8;
}

.Coupons_collapseIcon__2zC0v > img {
  width: 15px;
  height: 15px;
  -webkit-transition: 0.6s ease-out;
  transition:  0.6s ease-out;
}

.Coupons_openCollapse__3ne_L {
  -webkit-transform: rotateZ(-90deg);
  transform: rotateZ(-90deg);
}

.Coupons_closeCollapse__3qJp8 { 
  -webkit-transform: rotateZ(90deg);
  transform: rotateZ(90deg);
}


/** ========= SEARCH FILED ================ **/
.Coupons_coupons__VNr3S .Coupons_search__1U1aI {
  border-radius: 0.125rem;
}

.Coupons_coupons__VNr3S .Coupons_search__1U1aI input {
  border: 0;
  width: 100%;
}

.Coupons_coupons__VNr3S .Coupons_search__1U1aI div {
  border: 0;
  border-radius: 0; 
  padding-left: 0.6rem;
  padding-right: 0.35rem;
}

.Coupons_coupons__VNr3S img.Coupons_icon__1pP4f {
  width: 20px;
}

.Coupons_coupons__VNr3S .Coupons_rightDivider__3Nv01::before {
  position: absolute;
  background: #edf0f5;
  width: 1px;
  right: 0;
  height: calc( 100% - 32px );
  bottom: 0;
}

.Coupons_coupons__VNr3S .Coupons_searchListContainer__uQOW1 {
  max-height: 330px;
  overflow-y: auto;
}

.Coupons_coupons__VNr3S .Coupons_selectedListContainer__2luXB {
  max-height: 390px; 
}

.Coupons_coupons__VNr3S .Coupons_selectedListContainer__2luXB .Coupons_unselectProduct__2amQk {
    position: absolute;
    right: 0.5rem;
    top: 0rem;
    display: none;
    background: #ffffff7a;
}

.Coupons_coupons__VNr3S .Coupons_selectedListContainer__2luXB .Coupons_selectedProduct__1rgWO:hover .Coupons_unselectProduct__2amQk {
    display: block;
}

.Coupons_coupons__VNr3S .Coupons_searchListTable__3y8EO tbody { 
  max-height: 330px;
  overflow-y: auto;
  display: block;
}

.Coupons_coupons__VNr3S .Coupons_searchListTable__3y8EO tbody tr,
.Coupons_coupons__VNr3S .Coupons_searchListTable__3y8EO thead tr { 
  display: table;
  width: 100%;
  table-layout: fixed;
}
/**
 * Copyright(c) 2020 Mozanta Technologies Private Ltd.
 * 
 * All rights reserved.                
 * 
 * This software is the confidential and proprietary information of Mozanta ("Confidential
 * Information"). You shall not disclose such Confidential Information and shall use it only in
 * accordance with the terms of the contract agreement you entered into with Mozanta.
 * 
 * @author Sreelekshmi Prasannan
 */

 .CustomerSelection_block2__2W4_6 {
  padding-top: 20px;
  padding-bottom: 20px;
} 

.CustomerSelection_borderRadius2__4nH64 {
  border-radius: 0.125rem !important;
}

.CustomerSelection_secondaryColor__3xcRR {
  background-color: #757575 !important;
  border-color: #757575 !important;
}

.CustomerSelection_primaryColor__3FagP {
  background: #e91e63 !important;
  border-color: #e91e63 !important;
}

.CustomerSelection_btn__3dulq {
  min-width: 7rem;
}

.CustomerSelection_icon__rApr4 {
  width: 1.25rem;
}

/* ============= CUSTOMER TITLE ========= */
.CustomerSelection_titleTextContainer__20WsV {
  padding: 0 2.1rem 0.125rem 2.1rem;
  border-bottom: 1px solid #edf0f5;
  margin: 0 -0.938rem;
}

/* =============== CUSTOMER AREA =============== */
.CustomerSelection_block4__2-RtT {
   padding: 1.25rem;
}

.CustomerSelection_block4__2-RtT .CustomerSelection_borderBottom__nw3ad {
  border-bottom: 1px solid ;
}


/** ========= SEARCH FIELD ================ **/
.CustomerSelection_block4__2-RtT .CustomerSelection_search__2KV42 {
  border: 1px solid #9fa1a5;
  border-radius: 0.125rem;
}

.CustomerSelection_block4__2-RtT .CustomerSelection_search__2KV42 input {
  border: 0;
  width: 100%;
}

.CustomerSelection_block4__2-RtT .CustomerSelection_search__2KV42 div {
  border: 0;
  border-radius: 0;
  padding-left: 0.6rem;
  padding-right: 0.35rem;
}

.CustomerSelection_block4__2-RtT img.CustomerSelection_icon__rApr4 {
  width: 20px;
}

.CustomerSelection_block4__2-RtT .CustomerSelection_rightDivider__3vqnS::before {
  position: absolute;
  background: #edf0f5;
  width: 1px;
  right: 0;
  height: calc( 100% - 32px );
  bottom: 0;
}

.CustomerSelection_block4__2-RtT .CustomerSelection_searchListContainer__3Dwnw {
  height: 350px;
  overflow-y: auto;
}

.CustomerSelection_block4__2-RtT .CustomerSelection_selectedListContainer__3uruX {
  height: 390px; 
}

.CustomerSelection_block4__2-RtT .CustomerSelection_selectedListContainer__3uruX .CustomerSelection_unselectProduct__3shHC {
    position: absolute;
    right: 0.5rem;
    top: 0rem;
    display: none;
    background: #e91e63;
}

.CustomerSelection_block4__2-RtT .CustomerSelection_selectedListContainer__3uruX .CustomerSelection_selectedProduct__RJ1Hf:hover .CustomerSelection_unselectProduct__3shHC {
    display: block;
}


/**
 * Copyright(c) 2020 Mozanta Technologies Private Ltd.
 * 
 * All rights reserved.                
 * 
 * This software is the confidential and proprietary information of Mozanta ("Confidential
 * Information"). You shall not disclose such Confidential Information and shall use it only in
 * accordance with the terms of the contract agreement you entered into with Mozanta.
 * 
 * @author Sreelekshmi Prasannan
 */

 .CustomerGroupCreatorView_block2__Yl-Ef {
  padding-top: 20px;
  padding-bottom: 20px;
} 

.CustomerGroupCreatorView_borderRadius2__3shyg {
  border-radius: 0.125rem !important;
}

.CustomerGroupCreatorView_secondaryColor__bPul2 {
  background-color: #757575 !important;
  border-color: #757575 !important;
}

.CustomerGroupCreatorView_primaryColor__3W-i3 {
  background: #e91e63 !important;
  border-color: #e91e63 !important;
}

.CustomerGroupCreatorView_btn__1Mf8e {
  min-width: 7rem;
}



/* ========== FORM AREA ============ */
.CustomerGroupCreatorView_block3__2qC35 {
  padding: 1.25rem;
}
/**
 * Copyright(c) 2020 Mozanta Technologies Private Ltd.
 * 
 * All rights reserved.
 * 
 * This software is the confidential and proprietary information of Mozanta ("Confidential
 * Information"). You shall not disclose such Confidential Information and shall use it only in
 * accordance with the terms of the contract agreement you entered into with Mozanta.
 * 
 * @author Indrajith C
 * 
 */
 .CustomerGroups_block1__3DY3l{
  padding-top: 20px;
  padding-bottom: 20px;
}
.CustomerGroups_block1__3DY3l .CustomerGroups_navigationManagementnText__1sSuY{
  text-align: left;
  color: #333333;
  padding-bottom: 17px;
}

.CustomerGroups_block1__3DY3l .CustomerGroups_buttonAddMenu__36c37{
  color: #ffffff;
  width: 113px;
  height: 35px;
  border-radius: 2px;
  background-color: #448aff;
  border: 0px;
  float: right;
}

.CustomerGroups_blockWrapper__1rola{
  min-height: calc(100vh - 385px);
} 
 
.CustomerGroups_borderRadius2__2tMEO {
  border-radius: 0.125rem !important;
}

.CustomerGroups_secondaryColor__1Zknb {
  background-color: #757575 !important;
  border-color: #757575 !important;
}

.CustomerGroups_primaryColor__IdSJw {
  background: #17a2b8 !important;
  border-color: #17a2b8 !important;
}

.CustomerGroups_btn__zyfyB {
  min-width: 7rem;
}

.CustomerGroups_icon__1qdMk {
  width: 1em;
}

/* =============== step two =================== */

.CustomerGroups_form__1m57e .CustomerGroups_formTextLabel__2b2HH {
  color: #000000CC;
  opacity: 0.4;
}
.CustomerGroups_block2__3mupf {
  padding-top: 20px;
  padding-bottom: 20px;
} 

.CustomerGroups_borderRadius2__2tMEO {
  border-radius: 0.125rem !important;
}

.CustomerGroups_secondaryColor__1Zknb {
  background-color: #757575 !important;
  border-color: #757575 !important;
}

.CustomerGroups_primaryColor__IdSJw {
  background: #17a2b8 !important;
  border-color: #17a2b8 !important;
}


.CustomerGroups_btn__zyfyB {
  min-width: 7rem;
}

.CustomerGroups_tabContainer__mZaU2 .CustomerGroups_tabHeader__joGem  {
  border-bottom: 1px solid #dfdfdf;
}

.CustomerGroups_tabContainer__mZaU2 .CustomerGroups_tabHeader__joGem.CustomerGroups_active__2LMq_ {
  border-bottom: 3px solid #17a2b8;
}

.CustomerGroups_collapseIcon__1JKM8 > img {
  width: 15px;
  height: 15px;
  -webkit-transition: 0.6s ease-out;
  transition:  0.6s ease-out;
}

.CustomerGroups_openCollapse__16938 {
  -webkit-transform: rotateZ(-90deg);
  transform: rotateZ(-90deg);
}

.CustomerGroups_closeCollapse__2rs-b { 
  -webkit-transform: rotateZ(90deg);
  transform: rotateZ(90deg);
}


/** ========= SEARCH FILED ================ **/
.CustomerGroups_customerGroups__374Zk .CustomerGroups_search__JcRYO {
  border-radius: 0.125rem;
}

.CustomerGroups_customerGroups__374Zk .CustomerGroups_search__JcRYO input {
  border: 0;
  width: 100%;
}

.CustomerGroups_customerGroups__374Zk .CustomerGroups_search__JcRYO div {
  border: 0;
  border-radius: 0; 
  padding-left: 0.6rem;
  padding-right: 0.35rem;
}

.CustomerGroups_customerGroups__374Zk img.CustomerGroups_icon__1qdMk {
  width: 20px;
}

.CustomerGroups_customerGroups__374Zk .CustomerGroups_rightDivider__1Froo::before {
  position: absolute;
  background: #edf0f5;
  width: 1px;
  right: 0;
  height: calc( 100% - 32px );
  bottom: 0;
}

.CustomerGroups_customerGroups__374Zk .CustomerGroups_searchListContainer__3FIEm {
  max-height: 330px;
  overflow-y: auto;
}

.CustomerGroups_customerGroups__374Zk .CustomerGroups_selectedListContainer__1Mm2C {
  max-height: 390px; 
}

.CustomerGroups_customerGroups__374Zk .CustomerGroups_selectedListContainer__1Mm2C .CustomerGroups_unselectProduct__3gscE {
    position: absolute;
    right: 0.5rem;
    top: 0rem;
    display: none;
    background: #ffffff7a;
}

.CustomerGroups_customerGroups__374Zk .CustomerGroups_selectedListContainer__1Mm2C .CustomerGroups_selectedProduct___T0vg:hover .CustomerGroups_unselectProduct__3gscE {
    display: block;
}

.CustomerGroups_customerGroups__374Zk .CustomerGroups_searchListTable__6iJi4 tbody { 
  max-height: 330px;
  overflow-y: auto;
  display: block;
}

.CustomerGroups_customerGroups__374Zk .CustomerGroups_searchListTable__6iJi4 tbody tr,
.CustomerGroups_customerGroups__374Zk .CustomerGroups_searchListTable__6iJi4 thead tr { 
  display: table;
  width: 100%;
  table-layout: fixed;
}
/**
 * Copyright(c) 2020 Mozanta Technologies Private Ltd.
 * 
 * All rights reserved.
 * 
 * This software is the confidential and proprietary information of Mozanta ("Confidential
 * Information"). You shall not disclose such Confidential Information and shall use it only in
 * accordance with the terms of the contract agreement you entered into with Mozanta.
 * 
 * @author Indrajith C
 * 
 */
 .CampaignCreatorView_block1__3-6_A{
  padding-top: 20px;
  padding-bottom: 20px;
}
.CampaignCreatorView_block1__3-6_A .CampaignCreatorView_navigationManagementnText__1Hl73{
  text-align: left;
  color: #333333;
  padding-bottom: 17px;
}

.CampaignCreatorView_block1__3-6_A .CampaignCreatorView_buttonAddMenu__2BES0{
  color: #ffffff;
  width: 113px;
  height: 35px;
  border-radius: 2px;
  background-color: #448aff;
  border: 0px;
  float: right;
}

.CampaignCreatorView_blockWrapper__22zKB{
  min-height: calc(100vh - 385px);
} 
 
.CampaignCreatorView_borderRadius2__3RAt_ {
  border-radius: 0.125rem !important;
}

.CampaignCreatorView_secondaryColor__8MdSx {
  background-color: #757575 !important;
  border-color: #757575 !important;
}

.CampaignCreatorView_primaryColor__2Rdb5 {
  background: #17a2b8 !important;
  border-color: #17a2b8 !important;
}

.CampaignCreatorView_btn__1RSX5 {
  min-width: 7rem;
}

.CampaignCreatorView_icon__duiyd {
  width: 1em;
}


/* ============= STEP FORM ========= */

.CampaignCreatorView_step__3b2Vz .CampaignCreatorView_stepHeader__3lp5h {
  -webkit-touch-callout: none;  
  -webkit-user-select: none; 
     -moz-user-select: none; 
      -ms-user-select: none; 
          user-select: none;  
}

.CampaignCreatorView_step__3b2Vz .CampaignCreatorView_stepHeader__3lp5h .CampaignCreatorView_stepContainer__1nfnV {
  list-style: none;
}
.CampaignCreatorView_step__3b2Vz .CampaignCreatorView_stepHeader__3lp5h .CampaignCreatorView_stepContainer__1nfnV> .CampaignCreatorView_stepItem__KC8Rl{
  cursor: pointer;
  opacity: 0.4;
}

.CampaignCreatorView_step__3b2Vz .CampaignCreatorView_stepHeader__3lp5h .CampaignCreatorView_stepContainer__1nfnV> .CampaignCreatorView_stepItem__KC8Rl>.CampaignCreatorView_index__3MsuV {
  min-width: 39px;
  min-height: 39px;
  max-width: 39px;
  max-height: 39px;
  font-weight: 700;
  font-size: 17px;
  border: 2px solid;
  border-color: #222222;
}

.CampaignCreatorView_step__3b2Vz .CampaignCreatorView_stepHeader__3lp5h .CampaignCreatorView_stepContainer__1nfnV> .CampaignCreatorView_stepItem__KC8Rl[active]  ,
  .CampaignCreatorView_step__3b2Vz .CampaignCreatorView_stepHeader__3lp5h .CampaignCreatorView_stepContainer__1nfnV> .CampaignCreatorView_stepItem__KC8Rl.CampaignCreatorView_active__1DEBc , 
  .CampaignCreatorView_step__3b2Vz .CampaignCreatorView_stepHeader__3lp5h .CampaignCreatorView_stepContainer__1nfnV> .CampaignCreatorView_stepItem__KC8Rl[completed]  ,
    .CampaignCreatorView_step__3b2Vz .CampaignCreatorView_stepHeader__3lp5h .CampaignCreatorView_stepContainer__1nfnV> .CampaignCreatorView_stepItem__KC8Rl.CampaignCreatorView_completed__3VvLi  { 
  opacity: 1;
  cursor: default;
}

.CampaignCreatorView_step__3b2Vz .CampaignCreatorView_stepHeader__3lp5h .CampaignCreatorView_stepContainer__1nfnV> .CampaignCreatorView_stepItem__KC8Rl[active] >.CampaignCreatorView_index__3MsuV ,
  .CampaignCreatorView_step__3b2Vz .CampaignCreatorView_stepHeader__3lp5h .CampaignCreatorView_stepContainer__1nfnV> .CampaignCreatorView_stepItem__KC8Rl.CampaignCreatorView_active__1DEBc >.CampaignCreatorView_index__3MsuV  {
  border-color: #6AADE0;
}

.CampaignCreatorView_step__3b2Vz .CampaignCreatorView_stepHeader__3lp5h .CampaignCreatorView_stepContainer__1nfnV> .CampaignCreatorView_stepItem__KC8Rl[completed] >.CampaignCreatorView_index__3MsuV ,
  .CampaignCreatorView_step__3b2Vz .CampaignCreatorView_stepHeader__3lp5h .CampaignCreatorView_stepContainer__1nfnV> .CampaignCreatorView_stepItem__KC8Rl.CampaignCreatorView_completed__3VvLi >.CampaignCreatorView_index__3MsuV  {
    border-color: #6AADE0; 
    background-color: #6AADE0; 
}

.CampaignCreatorView_step__3b2Vz .CampaignCreatorView_stepHeader__3lp5h .CampaignCreatorView_stepContainer__1nfnV> .CampaignCreatorView_stepItem__KC8Rl[completed] >.CampaignCreatorView_index__3MsuV>span ,
  .CampaignCreatorView_step__3b2Vz .CampaignCreatorView_stepHeader__3lp5h .CampaignCreatorView_stepContainer__1nfnV> .CampaignCreatorView_stepItem__KC8Rl.CampaignCreatorView_completed__3VvLi >.CampaignCreatorView_index__3MsuV>span {
  display: inline-block;
    -webkit-transform: rotate(45deg);
            transform: rotate(45deg);
    height: 20px;
    width: 10px;
    border-bottom: 3px solid white;
    border-right: 3px solid white;
    color: transparent;
    margin-top: -5px;
}

.CampaignCreatorView_step__3b2Vz .CampaignCreatorView_stepHeader__3lp5h .CampaignCreatorView_stepContainer__1nfnV> .CampaignCreatorView_stepItem__KC8Rl[disabled] ,
.CampaignCreatorView_step__3b2Vz .CampaignCreatorView_stepHeader__3lp5h .CampaignCreatorView_stepContainer__1nfnV> .CampaignCreatorView_stepItem__KC8Rl.CampaignCreatorView_disabled__1NB_T   {
  opacity: 0.4;
  cursor: not-allowed;
}

.CampaignCreatorView_step__3b2Vz .CampaignCreatorView_stepHeader__3lp5h .CampaignCreatorView_stepContainer__1nfnV> .CampaignCreatorView_stepItem__KC8Rl[disabled]>.CampaignCreatorView_index__3MsuV ,
.CampaignCreatorView_step__3b2Vz .CampaignCreatorView_stepHeader__3lp5h .CampaignCreatorView_stepContainer__1nfnV> .CampaignCreatorView_stepItem__KC8Rl.CampaignCreatorView_disabled__1NB_T >.CampaignCreatorView_index__3MsuV  {
  border-color: #222222;
}


/* =============== step two =================== */

.CampaignCreatorView_form__3TDHe .CampaignCreatorView_formTextLabel__iC7aE {
  color: #000000CC;
  opacity: 0.4;
}
.CampaignCreatorView_block2__gpk1W {
  padding-top: 20px;
  padding-bottom: 20px;
} 

.CampaignCreatorView_borderRadius2__3RAt_ {
  border-radius: 0.125rem !important;
}

.CampaignCreatorView_secondaryColor__8MdSx {
  background-color: #757575 !important;
  border-color: #757575 !important;
}

.CampaignCreatorView_primaryColor__2Rdb5 {
  background: #17a2b8 !important;
  border-color: #17a2b8 !important;
}


.CampaignCreatorView_btn__1RSX5 {
  min-width: 7rem;
}

.CampaignCreatorView_tabContainer__2Gjcq .CampaignCreatorView_tabHeader__TIRel  {
  border-bottom: 1px solid #dfdfdf;
}

.CampaignCreatorView_tabContainer__2Gjcq .CampaignCreatorView_tabHeader__TIRel.CampaignCreatorView_active__1DEBc {
  border-bottom: 3px solid #17a2b8;
}

.CampaignCreatorView_collapseIcon__32wa6 > img {
  width: 15px;
  height: 15px;
  -webkit-transition: 0.6s ease-out;
  transition:  0.6s ease-out;
}

.CampaignCreatorView_openCollapse__3sg2F {
  -webkit-transform: rotateZ(-90deg);
  transform: rotateZ(-90deg);
}

.CampaignCreatorView_closeCollapse__3MaW0 { 
  -webkit-transform: rotateZ(90deg);
  transform: rotateZ(90deg);
}

.CampaignCreatorView_rightCheckbox__F7r4w >span {
  padding-right: 24px;
  z-index: 1;
}

.CampaignCreatorView_rightCheckbox__F7r4w > label {
  left: 8px;
}
/**
 * Copyright(c) 2020 Mozanta Technologies Private Ltd.
 *
 * All rights reserved.
 *
 * This software is the confidential and proprietary information of Mozanta ("Confidential
 * Information"). You shall not disclose such Confidential Information and shall use it only in
 * accordance with the terms of the contract agreement you entered into with Mozanta.
 *
 * @author Roshna Accu
 */


.PromotionCreatorView_block2__1e79V {
  padding-top: 20px;
  padding-bottom: 20px;
}

.PromotionCreatorView_borderRadius2__1gpjS {
  border-radius: 0.125rem !important;
}

.PromotionCreatorView_secondaryColor__l4TMQ {
  background-color: #757575 !important;
  border-color: #757575 !important;
}

.PromotionCreatorView_primaryColor__D3JC3 {
  background: #17a2b8 !important;
  border-color: #17a2b8 !important;
}


.PromotionCreatorView_btn__1SWu0 {
  min-width: 7rem;
}




/**
 * Copyright(c) 2020 Mozanta Technologies Private Ltd.
 *
 * All rights reserved.
 *
 * This software is the confidential and proprietary information of Mozanta ("Confidential
 * Information"). You shall not disclose such Confidential Information and shall use it only in
 * accordance with the terms of the contract agreement you entered into with Mozanta.
 *
 * @author Roshna Accu
 */


/* ========== FORM AREA ============ */
.PromotionForm_block3__1f3e0 {
  padding: 1.25rem;
}

.PromotionForm_block3__1f3e0 .PromotionForm_noLabelFormGroup__2NeO6 {
  margin-top: 1.75rem;
  padding: 0.375rem 0.75rem;
}

.PromotionForm_productOptionSelector__2-gJs .PromotionForm_error__2SJt2{
      display: block;
      width: 100%;
      margin-top: 0.25rem;
      font-size: 80%;
      color: #dc3545;
  }

/**
 * Copyright(c) 2020 Mozanta Technologies Private Ltd.
 * 
 * All rights reserved.                
 * 
 * This software is the confidential and proprietary information of Mozanta ("Confidential
 * Information"). You shall not disclose such Confidential Information and shall use it only in
 * accordance with the terms of the contract agreement you entered into with Mozanta.
 * 
 * @author Anokh J Ajai
 */

 .ProductBundle_productBundleButtons__2P6Uv {
   display: flex;
   align-items: center;
 }

 .ProductBundle_productBundleButtons__2P6Uv .ProductBundle_btn__25pFS{
  border : 1px solid #17a2b8;
  border-radius: 22px;
  width :32px;
  margin-right: 20px;
  cursor: pointer;
  margin-top: 10px;
 }  
/**
 * Copyright(c) 2020 Mozanta Technologies Private Ltd.
 * 
 * All rights reserved.                
 * 
 * This software is the confidential and proprietary information of Mozanta ("Confidential
 * Information"). You shall not disclose such Confidential Information and shall use it only in
 * accordance with the terms of the contract agreement you entered into with Mozanta.
 * 
 * @author Indrajith C
 */

.TreeCategorySelector_categoryTree__1SvoD {
  width: 100%;
}
.TreeCategorySelector_categoryTreeItem__2Xngq {
}
.TreeCategorySelector_categoryTreeItem__2Xngq .TreeCategorySelector_toggleButton__3k6dJ {
}

.TreeCategorySelector_categoryTreeItem__2Xngq .TreeCategorySelector_noDescendants__J9iWM {
  padding-left: 20px;
}

.TreeCategorySelector_categoryTreeItem__2Xngq .TreeCategorySelector_isOpen__1DSLo {
  color: rgba(64, 177, 196);;
}

.TreeCategorySelector_categoryTreeItem__2Xngq .TreeCategorySelector_isClosed__3NiUW {
}

.TreeCategorySelector_categoryTreeItem__2Xngq img.TreeCategorySelector_icon__2DQFY {
  width: 20px;
}

.TreeCategorySelector_categoryTreeItem__2Xngq .TreeCategorySelector_toggleButton__3k6dJ > img {
  width: 20px;
}

.TreeCategorySelector_categoryTreeItem__2Xngq .TreeCategorySelector_categoryContent__2Aj24 .TreeCategorySelector_categoryTable__1RBR4 thead {
  background-color: #edf0f5;
}

.TreeCategorySelector_categoryTreeItem__2Xngq .TreeCategorySelector_loading__wCE-J {
  cursor: wait !important;
}

.TreeCategorySelector_categoryTreeItem__2Xngq .TreeCategorySelector_treeLineAnimationRootComponent__SWZQZ > span {
  min-width: 1rem;
  position: relative;
}

/* ============== for tree line ============= */
.TreeCategorySelector_categoryTreeItem__2Xngq .TreeCategorySelector_treeLineAnimationRootComponent__SWZQZ > span:last-of-type::after {
  background: rgba(64, 177, 196, 0.1);
  position: absolute;
  left: 50%;
  content: " ";
  height: 2px;
  top: 50%;
  width: 50%;
  right: 0;
}
 
/** =========== using tag name directly because of css module dynamic class name for child component */
.TreeCategorySelector_categoryTreeItem__2Xngq .TreeCategorySelector_treeLineAnimationRootComponent__SWZQZ > span:before {
  background:rgba(64, 177, 196, 0.1);
  position: absolute;
  left: 50%;
  content: " ";
  height: 100%;
  width: 2px;
  bottom: 0;
}

.TreeCategorySelector_categoryTreeItem__2Xngq .TreeCategorySelector_treeLineAnimationRootComponent__SWZQZ .TreeCategorySelector_itemTitle__2hFHx {
  background: #fff;
  z-index: 1;
  margin: 12px 0;
}

/** ========== fix for td height equal to tr ============= */
.TreeCategorySelector_categoryTreeItem__2Xngq .TreeCategorySelector_tableTd__TP2fa {
 height: 0px;
}

/**
 * Copyright(c) 2020 Mozanta Technologies Private Ltd.
 * 
 * All rights reserved.                
 * 
 * This software is the confidential and proprietary information of Mozanta ("Confidential
 * Information"). You shall not disclose such Confidential Information and shall use it only in
 * accordance with the terms of the contract agreement you entered into with Mozanta.
 * 
 * @author Indrajith C
 */

 .CategorySelector_block2__3YM1b {
  padding-top: 20px;
  padding-bottom: 20px;
} 

.CategorySelector_borderRadius2__3Timc {
  border-radius: 0.125rem !important;
}

.CategorySelector_secondaryColor__6JN83 {
  background-color: #757575 !important;
  border-color: #757575 !important;
}

.CategorySelector_primaryColor__3wT1k {
  background: #17a2b8 !important;
  border-color: #17a2b8 !important;
}

.CategorySelector_btn__2KWRX {
  min-width: 7rem;
} 

/* ============= PRODUCT TITLE ========= */
.CategorySelector_titleTextContainer__1mZRg {
  padding: 0 2.1rem 0.125rem 2.1rem;
  border-bottom: 1px solid #edf0f5;
  margin: 0 -0.938rem;
}

/** ========= PARENT CATEGORY  ================ **/

.CategorySelector_dropdownMenu__1SjuH {
  max-height: 60vh;
  overflow-y: auto;
  width: 100%;
  background-color: #fff;
}

.CategorySelector_dropdownMenu__1SjuH::-webkit-scrollbar-track {
  background: #fff !important;
}

.CategorySelector_dropdown__15O6a > button {
  background: transparent !important;
  text-align: left !important;
  color: black !important;
  display: flex !important;
  align-items: center !important;
}

.CategorySelector_dropdown__15O6a > button .CategorySelector_clear__3PASe {
  margin-left: auto;
  display: flex;
  padding: 0.125rem;
  border: 1px solid;
  border-radius: 2px;
  border-color: #b7b2b2;
}

.CategorySelector_dropdown__15O6a > button .CategorySelector_clear__3PASe> img {
  width: 20px;
  height: 20px;
}

.CategorySelector_dropdown__15O6a[disabled] button,
.CategorySelector_dropdown__15O6a.CategorySelector_disabled__1Q2Rv button {
    background-color: #E9ECEF !important;
    border: solid 1px #d0d3d6!important;
}
/**
 * Copyright(c) 2020 Mozanta Technologies Private Ltd.
 * 
 * All rights reserved.
 * 
 * This software is the confidential and proprietary information of Mozanta ("Confidential
 * Information"). You shall not disclose such Confidential Information and shall use it only in
 * accordance with the terms of the contract agreement you entered into with Mozanta.
 * 
 * @author Indrajith C
 * 
 */
.SimpleBrandSelector_chipWidth__1A8aW {
  max-width: 153px;
}
/*  ======== chip component =========   */
.ItemGroupSelector_chip__3gWu0 {
    padding: 10px;
    border-radius: 50px;
    border: 1px solid;
    border-color: #b7b2b2;
    align-items: center;
    margin: 1px 0;
    margin-top: 10px;
}

.ItemGroupSelector_chip__3gWu0:not(:last-child) {
    margin-right: 0.125rem;
}

.ItemGroupSelector_span__2OHQJ.ItemGroupSelector_chip__3gWu0 > span.ItemGroupSelector_remove__1yw17 {
    border-radius: 50%;
    border: 1px solid;
    width: 20px;
    height: 20px;
    margin-left: auto;
    display: flex;
    border-color: #b7b2b2;
    cursor: pointer;
}
.ItemGroupSelector_span__2OHQJ.ItemGroupSelector_chip__3gWu0 > span.ItemGroupSelector_remove__1yw17>img{
    width: 18px;
    height: 18px;
    cursor: pointer;
}
/**
 * Copyright(c) 2020 Mozanta Technologies Private Ltd.
 * 
 * All rights reserved.
 * 
 * This software is the confidential and proprietary information of Mozanta ("Confidential
 * Information"). You shall not disclose such Confidential Information and shall use it only in
 * accordance with the terms of the contract agreement you entered into with Mozanta.
 * 
 * @author Indrajith C
 * 
 */
 .Promotions_block1__3COnp{
  padding-top: 20px;
  padding-bottom: 20px;
}
.Promotions_block1__3COnp .Promotions_navigationManagementnText__2hl7e{
  text-align: left;
  color: #333333;
  padding-bottom: 17px;
}

.Promotions_block1__3COnp .Promotions_buttonAddMenu__1s_86{
  color: #ffffff;
  width: 113px;
  height: 35px;
  border-radius: 2px;
  background-color: #448aff;
  border: 0px;
  float: right;
}

.Promotions_blockWrapper__17Wh8{
  min-height: calc(100vh - 385px);
} 
 
.Promotions_borderRadius2__J4bp9 {
  border-radius: 0.125rem !important;
}

.Promotions_secondaryColor__2Sr1j {
  background-color: #757575 !important;
  border-color: #757575 !important;
}

.Promotions_primaryColor__wqh1A {
  background: #17a2b8 !important;
  border-color: #17a2b8 !important;
}

.Promotions_btn__3ZRes {
  min-width: 7rem;
}

.Promotions_icon__1YmNC {
  width: 1em;
}

/* =============== step two =================== */

.Promotions_form__3A0Mu .Promotions_formTextLabel__2BNiX {
  color: #000000CC;
  opacity: 0.4;
}
.Promotions_block2__1NJ3e {
  padding-top: 20px;
  padding-bottom: 20px;
} 

.Promotions_borderRadius2__J4bp9 {
  border-radius: 0.125rem !important;
}

.Promotions_secondaryColor__2Sr1j {
  background-color: #757575 !important;
  border-color: #757575 !important;
}

.Promotions_primaryColor__wqh1A {
  background: #17a2b8 !important;
  border-color: #17a2b8 !important;
}


.Promotions_btn__3ZRes {
  min-width: 7rem;
}

.Promotions_tabContainer__1f1jO .Promotions_tabHeader__27CYO  {
  border-bottom: 1px solid #dfdfdf;
}

.Promotions_tabContainer__1f1jO .Promotions_tabHeader__27CYO.Promotions_active__3b1TV {
  border-bottom: 3px solid #17a2b8;
}

.Promotions_collapseIcon__3ZCZO > img {
  width: 15px;
  height: 15px;
  -webkit-transition: 0.6s ease-out;
  transition:  0.6s ease-out;
}

.Promotions_openCollapse__gH9mT {
  -webkit-transform: rotateZ(-90deg);
  transform: rotateZ(-90deg);
}

.Promotions_closeCollapse__2y2xf { 
  -webkit-transform: rotateZ(90deg);
  transform: rotateZ(90deg);
}


/** ========= SEARCH FILED ================ **/
.Promotions_promotions__30aTH .Promotions_search__2-GOc {
  border-radius: 0.125rem;
}

.Promotions_promotions__30aTH .Promotions_search__2-GOc input {
  border: 0;
  width: 100%;
}

.Promotions_promotions__30aTH .Promotions_search__2-GOc div {
  border: 0;
  border-radius: 0; 
  padding-left: 0.6rem;
  padding-right: 0.35rem;
}

.Promotions_promotions__30aTH img.Promotions_icon__1YmNC {
  width: 20px;
}

.Promotions_promotions__30aTH .Promotions_rightDivider__2Q6Bj::before {
  position: absolute;
  background: #edf0f5;
  width: 1px;
  right: 0;
  height: calc( 100% - 32px );
  bottom: 0;
}

.Promotions_promotions__30aTH .Promotions_searchListContainer__3WFq8 {
  max-height: 330px;
  overflow-y: auto;
}

.Promotions_promotions__30aTH .Promotions_selectedListContainer__3zYCc {
  max-height: 390px; 
}

.Promotions_promotions__30aTH .Promotions_selectedListContainer__3zYCc .Promotions_unselectProduct__ru7KR {
    position: absolute;
    right: 0.5rem;
    top: 0rem;
    display: none;
    background: #ffffff7a;
}

.Promotions_promotions__30aTH .Promotions_selectedListContainer__3zYCc .Promotions_selectedProduct__lX5Jt:hover .Promotions_unselectProduct__ru7KR {
    display: block;
}

.Promotions_promotions__30aTH .Promotions_searchListTable__1POZh tbody { 
  max-height: 330px;
  overflow-y: auto;
  display: block;
}

.Promotions_promotions__30aTH .Promotions_searchListTable__1POZh tbody tr,
.Promotions_promotions__30aTH .Promotions_searchListTable__1POZh thead tr { 
  display: table;
  width: 100%;
  table-layout: fixed;
}
/**
 * Copyright(c) 2020 Mozanta Technologies Private Ltd.
 * 
 * All rights reserved.                
 * 
 * This software is the confidential and proprietary information of Mozanta ("Confidential
 * Information"). You shall not disclose such Confidential Information and shall use it only in
 * accordance with the terms of the contract agreement you entered into with Mozanta.
 * 
 * @author Roshna Accu
 */

 .CartManagement_liveBtnWrapper__1oaGX .CartManagement_liveBtn__2FZpb{
  border: none;
  border-radius: 2px;
  text-transform: uppercase;
}

.CartManagement_collapseIcon__2cI2r>img {
  width: 15px;
  height: 15px;
  -webkit-transition: 0.6s ease-out;
  transition: 0.6s ease-out;
  float: right;
}

.CartManagement_openCollapse__29oI6 {
  -webkit-transform: rotateZ(-90deg);
  transform: rotateZ(-90deg);
}

.CartManagement_closeCollapse__7Tc7r {
  -webkit-transform: rotateZ(90deg);
  transform: rotateZ(90deg);
}

.CartManagement_block2__5O5hq {
  padding-top: 20px;
  padding-bottom: 20px;
}

.CartManagement_borderRadius2__2P2ST {
  border-radius: 0.125rem !important;
}

.CartManagement_secondaryColor__1LulQ {
  background-color: #757575 !important;
  border-color: #757575 !important;
}

.CartManagement_primaryColor__2SYeb {
  background: #17a2b8 !important;
  border-color: #17a2b8 !important;
}


.CartManagement_btn__pkDZv {
  min-width: 7rem;
}
/**
 * Copyright(c) 2020 Mozanta Technologies Private Ltd.
 * 
 * All rights reserved.                
 * 
 * This software is the confidential and proprietary information of Mozanta ("Confidential
 * Information"). You shall not disclose such Confidential Information and shall use it only in
 * accordance with the terms of the contract agreement you entered into with Mozanta.
 * 
 * @author Indrajith C
 */

.Category_categoryTreeItem__ATthQ {
}

.Category_categoryTreeItem__ATthQ .Category_toggleButton__3juUJ {
}

.Category_categoryTreeItem__ATthQ .Category_noDescendants__3jr7Y {
  padding-left: 20px;
}

.Category_categoryTreeItem__ATthQ .Category_isOpen__213Wy {
  color: rgba(64, 177, 196);;
}

.Category_categoryTreeItem__ATthQ .Category_isClosed__18eGr {
}

.Category_categoryTreeItem__ATthQ img.Category_icon__GVD8r {
  width: 20px;
}

.Category_categoryTreeItem__ATthQ .Category_toggleButton__3juUJ > img {
  width: 20px;
}

.Category_categoryTreeItem__ATthQ .Category_categoryContent__KL192 .Category_categoryTable__12xki thead {
  background-color: #edf0f5;
}

.Category_categoryTreeItem__ATthQ .Category_loading__1IekZ {
  cursor: wait !important;
}

.Category_categoryTreeItem__ATthQ .Category_treeLineAnimationRootComponent__3DZCD > span {
  min-width: 1rem;
  position: relative;
}

/* ============== for tree line ============= */
.Category_categoryTreeItem__ATthQ .Category_treeLineAnimationRootComponent__3DZCD > span:last-of-type::after {
  background: rgba(64, 177, 196, 0.1);
  position: absolute;
  left: 50%;
  content: " ";
  height: 2px;
  top: 50%;
  width: 50%;
  right: 0;
}
 
/** =========== using tag name directly because of css module dynamic class name for child component */
.Category_categoryTreeItem__ATthQ .Category_treeLineAnimationRootComponent__3DZCD > span:before {
  background:rgba(64, 177, 196, 0.1);
  position: absolute;
  left: 50%;
  content: " ";
  height: 100%;
  width: 2px;
  bottom: 0;
}

.Category_categoryTreeItem__ATthQ .Category_treeLineAnimationRootComponent__3DZCD .Category_itemTitle__2zdTw {
  background: #fff;
  z-index: 1;
  margin: 12px 0;
}

/** ========== fix for td height equal to tr ============= */
.Category_categoryTreeItem__ATthQ .Category_tableTd__3T-ri {
 height: 0px;
}

/* ============= table actions ===================== */
.Category_categoryTreeItem__ATthQ .Category_actions__lQPvk > span[disabled]  { 
  /* cursor: not-allowed; */
  pointer-events: none !important;
}

.Category_categoryTreeItem__ATthQ .Category_actions__lQPvk > span[disabled] > img {
  opacity: 0.4; 
}

/*** ============= BASIC FONT CLASSES ***/
.Category_categoryTreeItem__ATthQ .Category_fontWeightLight__2BYjy {
  font-weight: 300;
}

.Category_categoryTreeItem__ATthQ .Category_fontWeightRegular__3Jk1c {
  font-weight: 400;
}

.Category_categoryTreeItem__ATthQ .Category_fontWeightMedium__f09IR {
  font-weight: 500;
}

.Category_categoryTreeItem__ATthQ .Category_fontWeightBold__1XbdY {
  font-weight: 700;
}
/**
 * Copyright(c) 2020 Mozanta Technologies Private Ltd.
 * 
 * All rights reserved.                
 * 
 * This software is the confidential and proprietary information of Mozanta ("Confidential
 * Information"). You shall not disclose such Confidential Information and shall use it only in
 * accordance with the terms of the contract agreement you entered into with Mozanta.
 * 
 * @author Indrajith C
 */

.CategoryManagement_liveBtnWrapper__13l3M .CategoryManagement_liveBtn__3Y1jc{
  border: none;
  border-radius: 2px;
  text-transform: uppercase;
}

.CategoryManagement_block2__A1ldy {
  padding-top: 20px;
  padding-bottom: 20px;
}

/** =========== ACTION AREA STYLES ============== **/
.CategoryManagement_block2__A1ldy .CategoryManagement_areaActionRight__12HEl {
  display: flex;
  justify-content: flex-end;
}

.CategoryManagement_block2__A1ldy .CategoryManagement_borderRadius2__PCWz- {
  border-radius: 0.125rem;
}

.CategoryManagement_block2__A1ldy .CategoryManagement_areaActionRight__12HEl > div:first-child {
  margin-right: 0.3rem;
}
.CategoryManagement_block2__A1ldy .CategoryManagement_areaActionRight__12HEl > div:last-child {
  display: flex;
}

/** ========= SEARCH FILED ================ **/
.CategoryManagement_block2__A1ldy .CategoryManagement_areaActionRight__12HEl .CategoryManagement_search__314tO {
  border: 1px solid;
  border-radius: 0.125rem;
}

.CategoryManagement_block2__A1ldy .CategoryManagement_areaActionRight__12HEl .CategoryManagement_search__314tO input {
  border: 0;
  width: 100%;
  height: calc(1.5em + 0.75rem + 0px);
}

.CategoryManagement_block2__A1ldy .CategoryManagement_areaActionRight__12HEl .CategoryManagement_search__314tO div {
  border: 0;
  border-radius: 0;
  background-color: inherit;
  padding-left: 0.6rem;
  padding-right: 0.35rem;
}

/***** ========== MAIN ACTION AREA ============ **********/
.CategoryManagement_block3__HAZfQ {
  padding: 1.25rem 0;
}

/***** ============== MAIN ACTION COMPONENT ========= *******/
.CategoryManagement_block3__HAZfQ .CategoryManagement_categoryTree__23R5w {
  position: relative;
}

/***** ============== ACTION COMPONENT HEADER ========= *******/
.CategoryManagement_block3__HAZfQ .CategoryManagement_categoryTree__23R5w .CategoryManagement_categoryTreeHead__3FWOx {
  padding-bottom: 1rem;
}

.CategoryManagement_block3__HAZfQ .CategoryManagement_categoryTree__23R5w .CategoryManagement_categoryTreeHead__3FWOx .CategoryManagement_componentTitle__1I3zG {
  font-weight: 600;
  font-size: 16x;
  color: #212121;
}

/***** ============== ACTION COMPONENT BODY ========= *******/
.CategoryManagement_block3__HAZfQ .CategoryManagement_categoryTree__23R5w .CategoryManagement_categoryTreeBody__MAigg .CategoryManagement_categoryTreeTable__20AGf thead {
  background-color: #edf0f5;
}

.CategoryManagement_block3__HAZfQ .CategoryManagement_categoryTree__23R5w .CategoryManagement_categoryTreeBody__MAigg .CategoryManagement_categoryTreeItem__2srI1 {
  display: flex;
  flex-direction: column;
}
.CategoryManagement_tableWrapper__1XXuk{
  min-height: calc(100vh - 385px);
}
/**
 * Copyright(c) 2020 Mozanta Technologies Private Ltd.
 * 
 * All rights reserved.                
 * 
 * This software is the confidential and proprietary information of Mozanta ("Confidential
 * Information"). You shall not disclose such Confidential Information and shall use it only in
 * accordance with the terms of the contract agreement you entered into with Mozanta.
 * 
 * @author Indrajith C
 */

 .ParentCategory_block2__upwTC {
  padding-top: 20px;
  padding-bottom: 20px;
} 

.ParentCategory_borderRadius2__25Muq {
  border-radius: 0.125rem !important;
}

.ParentCategory_secondaryColor__3ohsK {
  background-color: #757575 !important;
  border-color: #757575 !important;
}

.ParentCategory_primaryColor__canVc {
  background: #17a2b8 !important;
  border-color: #17a2b8 !important;
}

.ParentCategory_btn__JP1f1 {
  min-width: 7rem;
} 

/* ============= PRODUCT TITLE ========= */
.ParentCategory_titleTextContainer__tOMwm {
  padding: 0 2.1rem 0.125rem 2.1rem;
  border-bottom: 1px solid #edf0f5;
  margin: 0 -0.938rem;
}

/** ========= PARENT CATEGORY  ================ **/

.ParentCategory_dropdownMenu__12qGN {
  max-height: 60vh;
  overflow-y: auto;
  width: 100%;
  background-color: #fff;
}

.ParentCategory_dropdownMenu__12qGN::-webkit-scrollbar-track {
  background: #fff !important;
}

.ParentCategory_dropdown__3i34g > button {
  background: transparent !important;
  text-align: left !important;
  color: black !important;
  display: flex !important;
  align-items: center !important;
}

.ParentCategory_dropdown__3i34g > button .ParentCategory_clear__2FwOP {
  margin-left: auto;
  display: flex;
  padding: 0.125rem;
  border: 1px solid;
  border-radius: 2px;
  border-color: #b7b2b2;
}

.ParentCategory_dropdown__3i34g > button .ParentCategory_clear__2FwOP> img {
  width: 20px;
  height: 20px;
}
/**
 * Copyright(c) 2020 Mozanta Technologies Private Ltd.
 * 
 * All rights reserved.                
 * 
 * This software is the confidential and proprietary information of Mozanta ("Confidential
 * Information"). You shall not disclose such Confidential Information and shall use it only in
 * accordance with the terms of the contract agreement you entered into with Mozanta.
 * 
 * @author Indrajith C
 */

 .CategoryForm_block2__17WPE {
  padding-top: 20px;
  padding-bottom: 20px;
} 

.CategoryForm_borderRadius2__UevFH {
  border-radius: 0.125rem !important;
}

.CategoryForm_secondaryColor__2g7mt {
  background-color: #757575 !important;
  border-color: #757575 !important;
}

.CategoryForm_primaryColor__nVikf {
  background: #e91e63 !important;
  border-color: #e91e63 !important;
}

.CategoryForm_btn__3Ol1x {
  min-width: 7rem;
}

.CategoryForm_icon__2kVb- {
  width: 1.25rem;
}

/* ========== FORM AREA ============ */
.CategoryForm_block3__32Kfx {
  padding: 1.25rem;
}

/* ============= PRODUCT TITLE ========= */
.CategoryForm_titleTextContainer__TfYdX {
  padding: 0 2.1rem 0.125rem 2.1rem;
  border-bottom: 1px solid #edf0f5;
  margin: 0 -0.938rem;
}

.CategoryForm_titleTextContainer__TfYdX .CategoryForm_pageTitleText__14R4z{
  text-align: left;
  color: #333333;
  padding-bottom: 17px;
}
/**
 * Copyright(c) 2020 Mozanta Technologies Private Ltd.
 * 
 * All rights reserved.                
 * 
 * This software is the confidential and proprietary information of Mozanta ("Confidential
 * Information"). You shall not disclose such Confidential Information and shall use it only in
 * accordance with the terms of the contract agreement you entered into with Mozanta.
 * 
 * @author Indrajith C
 */

 .CategorySelection_block2__3UbMw {
  padding-top: 20px;
  padding-bottom: 20px;
} 

.CategorySelection_borderRadius2__2_8fB {
  border-radius: 0.125rem !important;
}

.CategorySelection_secondaryColor__1XKD7 {
  background-color: #757575 !important;
  border-color: #757575 !important;
}

.CategorySelection_primaryColor__2DcHc {
  background: #e91e63 !important;
  border-color: #e91e63 !important;
}

.CategorySelection_btn__3FzVV {
  min-width: 7rem;
}

.CategorySelection_icon__2-nUm {
  width: 1.25rem;
}

/* ============= PRODUCT TITLE ========= */
.CategorySelection_titleTextContainer__3cywh {
  padding: 0 2.1rem 0.125rem 2.1rem;
  border-bottom: 1px solid #edf0f5;
  margin: 0 -0.938rem;
}

/* =============== PRODUCT AREA =============== */
.CategorySelection_block4__1A0ai {
  padding: 1.25rem;
}

.CategorySelection_block4__1A0ai .CategorySelection_borderBottom__1tRUd {
border-bottom: 1px solid ;
}


/** ========= SEARCH FILED ================ **/
.CategorySelection_block4__1A0ai .CategorySelection_search__1nuTJ {
border: 1px solid #e91e63;
border-radius: 0.125rem;
}

.CategorySelection_block4__1A0ai .CategorySelection_search__1nuTJ input {
border: 0;
width: 100%;
}

.CategorySelection_block4__1A0ai .CategorySelection_search__1nuTJ div {
border: 0;
border-radius: 0;
background-color: #e91e63;
padding-left: 0.6rem;
padding-right: 0.35rem;
}

.CategorySelection_block4__1A0ai img.CategorySelection_icon__2-nUm {
width: 20px;
}

.CategorySelection_block4__1A0ai .CategorySelection_rightDivider__2sdLC::before {
position: absolute;
background: #edf0f5;
width: 1px;
right: 0;
height: calc( 100% - 32px );
bottom: 0;
}

.CategorySelection_block4__1A0ai .CategorySelection_searchListContainer__1cDys {
height: 350px;
overflow-y: auto;
}

.CategorySelection_block4__1A0ai .CategorySelection_selectedListContainer__3tGAj {
height: 390px; 
}

.CategorySelection_block4__1A0ai .CategorySelection_selectedListContainer__3tGAj .CategorySelection_maxWidthIcon__zA1iY {
  max-width: calc( 100% - 22px );
}

/** ========= MEDIA QUERY ============ **/
@media (min-width: 576px) {
.CategorySelection_block4__1A0ai .CategorySelection_rightDivider__2sdLC::before  {
  content: " ";
}
}

.CategorySelection_block4__1A0ai .CategorySelection_borderBottom__1tRUd {
border-bottom: 1px solid ;
}

/**
 * Copyright(c) 2020 Mozanta Technologies Private Ltd.
 * 
 * All rights reserved.                
 * 
 * This software is the confidential and proprietary information of Mozanta ("Confidential
 * Information"). You shall not disclose such Confidential Information and shall use it only in
 * accordance with the terms of the contract agreement you entered into with Mozanta.
 * 
 * @author Indrajith C
 */

 .AddCategory_block2__3OgOK {
  padding-top: 20px;
  padding-bottom: 20px;
} 

.AddCategory_borderRadius2__1hjgU {
  border-radius: 0.125rem !important;
}

.AddCategory_secondaryColor__vwUNI {
  background-color: #757575 !important;
  border-color: #757575 !important;
}

.AddCategory_primaryColor__1swhk {
  background: #17a2b8 !important;
  border-color: #17a2b8 !important;
}


.AddCategory_btn__2MgEZ {
  min-width: 7rem;
}


/* ========== FORM AREA ============ */
.AddCategory_block3__xBCH1 {
  padding: 1.25rem;
}

/* ============= PRODUCT TITLE ========= */
.AddCategory_titleTextContainer__1Z0Kf {
  padding: 0 2.1rem 0.125rem 2.1rem;
  border-bottom: 1px solid #edf0f5;
  margin: 0 -0.938rem;
}

.AddCategory_titleTextContainer__1Z0Kf .AddCategory_pageTitleText__LHlDZ{
  text-align: left;
  color: #333333;
  padding-bottom: 17px;
}
 


/** ========= PARENT CATEGORY  ================ **/
.AddCategory_block5__2sSXd {
padding: 1.25rem;
}
/**
 * Copyright(c) 2020 Mozanta Technologies Private Ltd.
 * 
 * All rights reserved.                
 * 
 * This software is the confidential and proprietary information of Mozanta ("Confidential
 * Information"). You shall not disclose such Confidential Information and shall use it only in
 * accordance with the terms of the contract agreement you entered into with Mozanta.
 * 
 * @author Indrajith C
 */

 .EditCategory_block2__BJ49T {
  padding-top: 20px;
  padding-bottom: 20px;
} 

.EditCategory_borderRadius2__25y7p {
  border-radius: 0.125rem !important;
}

.EditCategory_secondaryColor__OHgVt {
  background-color: #757575 !important;
  border-color: #757575 !important;
}

.EditCategory_primaryColor__3ASQy {
  background: #17a2b8 !important;
  border-color: #17a2b8 !important;
}

.EditCategory_customColorForDelete__2ghYm {
  background: #FF2C16 !important;
  border-color: #FF2C16 !important;
}

.EditCategory_customColorForDelete__2ghYm:hover {
  background: #F20000 !important;
  border-color: #FF2C16 !important;
}


.EditCategory_btn__2r7tT {
  min-width: 7rem;
}


/* ========== FORM AREA ============ */
.EditCategory_block3__1MTRg {
  padding: 1.25rem;
}

/* ============= PRODUCT TITLE ========= */
.EditCategory_titleTextContainer__qL9mQ {
  padding: 0 2.1rem 0.125rem 2.1rem;
  border-bottom: 1px solid #edf0f5;
  margin: 0 -0.938rem;
}

.EditCategory_titleTextContainer__qL9mQ .EditCategory_pageTitleText__3eq18{
  text-align: left;
  color: #333333;
  padding-bottom: 17px;
}
 


/** ========= PARENT CATEGORY  ================ **/
.EditCategory_block5__2Q8kq {
padding: 1.25rem;
}

.EditCategory_mw25__2oHj2 {
  min-width: 25%;
}
/**
 * Copyright(c) 2020 Mozanta Technologies Private Ltd.
 *
 * All rights reserved.
 *
 * This software is the confidential and proprietary information of Mozanta
 * ("Confidential Information"). You shall not disclose such Confidential
 * Information and shall use it only in accordance with the terms of the
 * contract agreement you entered into with Mozanta.
 *
 * RoleManagement.module.css
 *
 * @author Anokh J Ajai
 *
 */



.SearchFacet_block2__99a-A {
    padding-top: 20px;
    padding-bottom: 20px;
}

/** =========== ACTION AREA STYLES ============== **/
.SearchFacet_block2__99a-A .SearchFacet_areaActionRight__1YEWc {
    display: flex;
    justify-content: flex-end;
}

.SearchFacet_block2__99a-A .SearchFacet_borderRadius2__7cWT0 {
    border-radius: 0.125rem;
}

.SearchFacet_block2__99a-A .SearchFacet_areaActionRight__1YEWc>div:first-child {
    margin-right: 0.3rem;
}

.SearchFacet_block2__99a-A .SearchFacet_areaActionRight__1YEWc>div:last-child {
    display: flex;
}

/** ========= SEARCH FILED ================ **/
.SearchFacet_block2__99a-A .SearchFacet_areaActionRight__1YEWc .SearchFacet_search__2BcMg {
    border: 1px solid;
    border-radius: 0.125rem;
}

.SearchFacet_block2__99a-A .SearchFacet_areaActionRight__1YEWc .SearchFacet_search__2BcMg input {
    border: 0;
    width: 100%;
    height: calc(1.5em + 0.75rem + 0px);
}

.SearchFacet_block2__99a-A .SearchFacet_areaActionRight__1YEWc .SearchFacet_search__2BcMg div {
    border: 0;
    border-radius: 0;
    background-color: inherit;
    padding-left: 0.6rem;
    padding-right: 0.35rem;
}

.SearchFacet_tableWrapper__22Nnc {
    min-height: calc(100vh - 420px);
}

.SearchFacet_buttonGreen__3qQHA {
    background-color: white !important;
    color: green !important;
    border-color: green !important;
    min-width: 85px !important;
}

.SearchFacet_buttonRed__1ybPs {
    background-color: white !important;
    color: red !important;
    border-color: red !important;
    min-width: 85px !important;
}

.SearchFacet_spinnerStyle__2_-8P {
    width: 20px !important;
    height: 20px !important;
}

/**
 * Copyright(c) 2020 Mozanta Technologies Private Ltd.
 *
 * All rights reserved.
 *
 * This software is the confidential and proprietary information of Mozanta
 * ("Confidential Information"). You shall not disclose such Confidential
 * Information and shall use it only in accordance with the terms of the
 * contract agreement you entered into with Mozanta.
 *
 * RoleManagement.module.css
 *
 * @author Anokh J Ajai
 *
 */

  

 .SortingRules_block2__1oyYc {
  padding-top: 20px;
  padding-bottom: 20px;
}

/** =========== ACTION AREA STYLES ============== **/
.SortingRules_block2__1oyYc .SortingRules_areaActionRight__2J9Bt {
  display: flex;
  justify-content: flex-end;
}

.SortingRules_block2__1oyYc .SortingRules_borderRadius2__3Oq3f {
  border-radius: 0.125rem;
}

.SortingRules_block2__1oyYc .SortingRules_areaActionRight__2J9Bt > div:first-child {
  margin-right: 0.3rem;
}
.SortingRules_block2__1oyYc .SortingRules_areaActionRight__2J9Bt > div:last-child {
  display: flex;
}

/** ========= SEARCH FILED ================ **/
.SortingRules_block2__1oyYc .SortingRules_areaActionRight__2J9Bt .SortingRules_search__1E3Ji {
  border: 1px solid;
  border-radius: 0.125rem;
}

.SortingRules_block2__1oyYc .SortingRules_areaActionRight__2J9Bt .SortingRules_search__1E3Ji input {
  border: 0;
  width: 100%;
  height: calc(1.5em + 0.75rem + 0px);
}

.SortingRules_block2__1oyYc .SortingRules_areaActionRight__2J9Bt .SortingRules_search__1E3Ji div {
  border: 0;
  border-radius: 0;
  background-color: inherit;
  padding-left: 0.6rem;
  padding-right: 0.35rem;
}

  .SortingRules_tableWrapper__2VTCZ  {
    min-height: calc(100vh - 420px);
   }

   .SortingRules_buttonGreen__6dxdy{
     background-color: white !important;
     color:green!important;
    border-color: green !important;
    min-width: 85px !important;
   }
   .SortingRules_buttonRed__1S490{
     background-color: white !important;
     color:red !important;
    border-color: red !important; 
    min-width: 85px !important;
   }

   .SortingRules_spinnerStyle__1SZiz{
     width:20px !important;
     height:20px !important;
   }
.ViewCategory_ContentOuter__3Hbo6 h6 {
  font-size: 14px;
}
.ViewCategory_BtnClose__2MqX7 {
  position: absolute;
  right: 6px;
  top: 7px;
  z-index: 10;
}

.ViewCategory_block3__1SlqJ .ViewCategory_product__3TluX .ViewCategory_productBody__3QPQF .ViewCategory_productTable__3IdVL .ViewCategory_productImage__3hC2B {
    max-height: 50px;
  }

  /***** ===================== PAGINATION ======================= ******/
  .ViewCategory_block3__1SlqJ .ViewCategory_product__3TluX .ViewCategory_pagination__1V8Ih { }
  
  .ViewCategory_block3__1SlqJ .ViewCategory_product__3TluX .ViewCategory_pagination__1V8Ih .ViewCategory_item__1HklI {
    height: 25px;
    width: 25px;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
  }

  .ViewCategory_block3__1SlqJ .ViewCategory_product__3TluX .ViewCategory_pagination__1V8Ih .ViewCategory_arrowLeft__2tFGW, .ViewCategory_block3__1SlqJ .ViewCategory_product__3TluX .ViewCategory_pagination__1V8Ih .ViewCategory_arrowRight__2HxGD {
    border-radius: 50%;
    border: 2px solid;
  }

  .ViewCategory_block3__1SlqJ .ViewCategory_product__3TluX .ViewCategory_pagination__1V8Ih .ViewCategory_arrowLeft__2tFGW > span, .ViewCategory_block3__1SlqJ .ViewCategory_product__3TluX .ViewCategory_pagination__1V8Ih .ViewCategory_arrowRight__2HxGD > span {
    border-bottom: 2px solid;
    border-right: 2px solid;
    width: 8px;
    height: 8px; 
    margin-top: -0.5px;
  }

  .ViewCategory_block3__1SlqJ .ViewCategory_product__3TluX .ViewCategory_pagination__1V8Ih .ViewCategory_arrowLeft__2tFGW > span{ 
    -webkit-transform: rotate(127deg); 
            transform: rotate(127deg); 
    margin-right: -2.5px;
  }

  .ViewCategory_block3__1SlqJ .ViewCategory_product__3TluX .ViewCategory_pagination__1V8Ih .ViewCategory_arrowRight__2HxGD > span{ 
    -webkit-transform: rotate(315deg); 
            transform: rotate(315deg); 
    margin-left: -2.5px;
  }

  .ViewCategory_block3__1SlqJ .ViewCategory_product__3TluX .ViewCategory_pagination__1V8Ih .ViewCategory_arrowLeft__2tFGW , .ViewCategory_block3__1SlqJ .ViewCategory_product__3TluX .ViewCategory_pagination__1V8Ih .ViewCategory_arrowRight__2HxGD, .ViewCategory_block3__1SlqJ .ViewCategory_product__3TluX .ViewCategory_pagination__1V8Ih .ViewCategory_item__1HklI[active] {
    color: #e91e63;
    font-weight: 600;
  }

  .ViewCategory_block3__1SlqJ .ViewCategory_product__3TluX .ViewCategory_pagination__1V8Ih .ViewCategory_item__1HklI[active] {
    cursor: default;
  }

  .ViewCategory_block3__1SlqJ .ViewCategory_product__3TluX .ViewCategory_pagination__1V8Ih .ViewCategory_item__1HklI[disabled] {
    cursor: not-allowed;
    color: #5d5d5d;
  }
  .ViewCategory_tableWrapper__1WggH{
    min-height: calc(100vh - 387px);
  }
/**
 * Copyright(c) 2020 Mozanta Technologies Private Ltd.
 * 
 * All rights reserved.
 * 
 * This software is the confidential and proprietary information of Mozanta ("Confidential
 * Information"). You shall not disclose such Confidential Information and shall use it only in
 * accordance with the terms of the contract agreement you entered into with Mozanta.
 * 
 * @author Indrajith C
 * 
 */ 
 .ChangePassword_borderRadius2__2vGzH {
    border-radius: 0.125rem !important;
  }
  
  .ChangePassword_secondaryColor__3b8y_ {
    background-color: #757575 !important;
    border-color: #757575 !important;
  }
  
  .ChangePassword_primaryColor__1-iGb {
    background: #17a2b8 !important;
    border-color: #17a2b8 !important;
  }
  
  .ChangePassword_btn__XWyDQ {
    min-width: 7rem;
  }
  
   .ChangePassword_block1__3ZPsV{
    padding-top: 20px;
    padding-bottom: 20px;
  }
  .ChangePassword_block1__3ZPsV .ChangePassword_navigationManagementnText__2uaRt{
    text-align: left;
    color: #333333;
    padding-bottom: 17px;
  }
  
  .ChangePassword_block1__3ZPsV .ChangePassword_buttonAddCoupon__17Sw2{
    color: #ffffff;
    width: 113px;
    height: 35px;
    border-radius: 2px;
    background-color: #448aff;
    border: 0px;
    float: right;
  }
  
  .ChangePassword_changePasswordOuter__1mDWf {
    margin: 3rem auto;
  }
  .ChangePassword_changePasswordBox__3C4UT {
    background: #f9f9f9;
    padding: 2rem;
    border-radius: 6px;
    border: 1px solid #ebebeb;
  }
/**
 * Copyright(c) 2020 Mozanta Technologies Private Ltd.
 * 
 * All rights reserved.                
 * 
 * This software is the confidential and proprietary information of Mozanta ("Confidential
 * Information"). You shall not disclose such Confidential Information and shall use it only in
 * accordance with the terms of the contract agreement you entered into with Mozanta.
 * 
 * @author Indrajith C
 */


 .CouponsManagement_liveBtnWrapper__2r6ss .CouponsManagement_liveBtn__1lw0e{
  border: none;
  border-radius: 2px;
  text-transform: uppercase;
}

 .CouponsManagement_block2__1_F1s {
  padding-top: 20px;
  padding-bottom: 20px;
}

.CouponsManagement_block2__1_F1s {
  padding-top: 20px;
  padding-bottom: 20px;
} 

.CouponsManagement_borderRadius2__3z_v9 {
  border-radius: 0.125rem !important;
}

.CouponsManagement_secondaryColor__2VbCa {
  background-color: #757575 !important;
  border-color: #757575 !important;
}

.CouponsManagement_primaryColor__2pDek {
  background: #e91e63 !important;
  border-color: #e91e63 !important;
}

.CouponsManagement_btn__2iFVX {
  min-width: 7rem;
}

/* ============= PRODUCT TITLE ========= */
.CouponsManagement_titleTextContainer__3LZYk {
  padding: 0 2.1rem 0.125rem 2.1rem;
  border-bottom: 1px solid #edf0f5;
  margin: 0 -0.938rem;
}

.CouponsManagement_titleTextContainer__3LZYk .CouponsManagement_pageTitleText__lRGAq{
  text-align: left;
  color: #333333;
  padding-bottom: 17px;
}
 
 

/** =========== ACTION AREA STYLES ============== **/
.CouponsManagement_block2__1_F1s .CouponsManagement_areaActionRight__3Fz67 {
  display: flex;
  justify-content: flex-end;
}

.CouponsManagement_block2__1_F1s .CouponsManagement_borderRadius2__3z_v9 {
  border-radius: 0.125rem;
}

.CouponsManagement_block2__1_F1s .CouponsManagement_areaActionRight__3Fz67 > div:first-child {
  margin-right: 0.3rem;
}
.CouponsManagement_block2__1_F1s .CouponsManagement_areaActionRight__3Fz67 > div:last-child {
  display: flex;
}

/** ========= SEARCH FILED ================ **/
.CouponsManagement_block2__1_F1s .CouponsManagement_areaActionRight__3Fz67 .CouponsManagement_search__1fWuT {
  border: 1px solid;
  border-radius: 0.125rem;
}

.CouponsManagement_block2__1_F1s .CouponsManagement_areaActionRight__3Fz67 .CouponsManagement_search__1fWuT input {
  border: 0;
  width: 100%;
  height: calc(1.5em + 0.75rem + 0px);
}

.CouponsManagement_block2__1_F1s .CouponsManagement_areaActionRight__3Fz67 .CouponsManagement_search__1fWuT div {
  border: 0;
  border-radius: 0;
  background-color: inherit;
  padding-left: 0.6rem;
  padding-right: 0.35rem;
}

/***** ========== MAIN ACTION AREA ============ **********/
.CouponsManagement_block3__18Mfn {
  padding: 1.25rem 0;
}
.CouponsManagement_block3__18Mfn .CouponsManagement_coupon__2Jzmk .CouponsManagement_couponBody__2QszK .CouponsManagement_couponTable__3t9M- .CouponsManagement_couponID__qSp23 {
  max-width: 100px;
}
.CouponsManagement_block3__18Mfn .CouponsManagement_coupon__2Jzmk .CouponsManagement_couponBody__2QszK .CouponsManagement_couponTable__3t9M- thead {
  background-color: #edf0f5;
}


/***** ===================== PAGINATION ======================= ******/
 .CouponsManagement_pagination__2CX2E { }

 .CouponsManagement_pagination__2CX2E .CouponsManagement_item__300IA {
  height: 25px;
  width: 25px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}

 .CouponsManagement_pagination__2CX2E .CouponsManagement_arrowLeft__sdwM3,  .CouponsManagement_pagination__2CX2E .CouponsManagement_arrowRight__2xtDD {
  border-radius: 50%;
  border: 2px solid;
}

 .CouponsManagement_pagination__2CX2E .CouponsManagement_arrowLeft__sdwM3 > span,  .CouponsManagement_pagination__2CX2E .CouponsManagement_arrowRight__2xtDD > span {
  border-bottom: 2px solid;
  border-right: 2px solid;
  width: 8px;
  height: 8px; 
  margin-top: -0.5px;
}

 .CouponsManagement_pagination__2CX2E .CouponsManagement_arrowLeft__sdwM3 > span{ 
  -webkit-transform: rotate(127deg); 
          transform: rotate(127deg); 
  margin-right: -2.5px;
}

 .CouponsManagement_pagination__2CX2E .CouponsManagement_arrowRight__2xtDD > span{ 
  -webkit-transform: rotate(315deg); 
          transform: rotate(315deg); 
  margin-left: -2.5px;
}

 .CouponsManagement_pagination__2CX2E .CouponsManagement_arrowLeft__sdwM3 ,  .CouponsManagement_pagination__2CX2E .CouponsManagement_arrowRight__2xtDD,  .CouponsManagement_pagination__2CX2E .CouponsManagement_item__300IA[active] {
  color: #e91e63;
  font-weight: 600;
}

 .CouponsManagement_pagination__2CX2E .CouponsManagement_item__300IA[active] {
  cursor: default;
}

 .CouponsManagement_pagination__2CX2E .CouponsManagement_item__300IA[disabled] {
  cursor: not-allowed;
  color: #5d5d5d;
}

.CouponsManagement_tableWrapper__1fSBU{
  min-height: calc(100vh - 402px);
}
/**
 * Copyright(c) 2020 Mozanta Technologies Private Ltd.
 * 
 * All rights reserved.
 * 
 * This software is the confidential and proprietary information of Mozanta ("Confidential
 * Information"). You shall not disclose such Confidential Information and shall use it only in
 * accordance with the terms of the contract agreement you entered into with Mozanta.
 * 
 * @author Aardra S
 * 
 */ 
.EditCoupon_borderRadius2__3Waas {
  border-radius: 0.125rem !important;
}

.EditCoupon_secondaryColor__1cBC6 {
  background-color: #757575 !important;
  border-color: #757575 !important;
}

.EditCoupon_primaryColor__74d6- {
  background: #17a2b8 !important;
  border-color: #17a2b8 !important;
}

.EditCoupon_btn__3G8Im {
  min-width: 7rem;
}

/**
 * Copyright(c) 2020 Mozanta Technologies Private Ltd.
 * 
 * All rights reserved.                
 * 
 * This software is the confidential and proprietary information of Mozanta ("Confidential
 * Information"). You shall not disclose such Confidential Information and shall use it only in
 * accordance with the terms of the contract agreement you entered into with Mozanta.
 * 
 * @author Indrajith C
 */

 .CustomerDataManagement_block2__W-ymZ {
  padding-top: 20px;
  padding-bottom: 20px;
}

.CustomerDataManagement_block2__W-ymZ button {
  background: none;
  color: black;
  border-radius: 2px;
  border: 1px solid #e0e0e0;
}

.CustomerDataManagement_block2__W-ymZ .CustomerDataManagement_dropdownBtn__2Habf {
  padding: 0px;
}

.CustomerDataManagement_block2__W-ymZ .CustomerDataManagement_dropdownBtn__2Habf button {
  float: right !important;
}

.CustomerDataManagement_borderRadius2__1eR4z {
  border-radius: 0.125rem !important;
}

.CustomerDataManagement_secondaryColor__jTYyl {
  background-color: #757575 !important;
  border-color: #757575 !important;
}

.CustomerDataManagement_primaryColor__2tYwP {
  background: #e91e63 !important;
  border-color: #e91e63 !important;
}

.CustomerDataManagement_btn__DwYE5 {
  min-width: 7rem;
}

/* ============= PRODUCT TITLE ========= */
.CustomerDataManagement_titleTextContainer__cgMHO {
  padding: 0 2.1rem 0.125rem 2.1rem;
  border-bottom: 1px solid #edf0f5;
  margin: 0 -0.938rem;
}

.CustomerDataManagement_titleTextContainer__cgMHO .CustomerDataManagement_pageTitleText__hinhq{
  text-align: left;
  color: #333333;
  padding-bottom: 17px;
}
 
 

/** =========== ACTION AREA STYLES ============== **/
.CustomerDataManagement_block2__W-ymZ .CustomerDataManagement_areaActionRight__3aylB {
  display: flex;
  justify-content: flex-end;
}

.CustomerDataManagement_block2__W-ymZ .CustomerDataManagement_borderRadius2__1eR4z {
  border-radius: 0.125rem;
}

.CustomerDataManagement_block2__W-ymZ .CustomerDataManagement_areaActionRight__3aylB > div:first-child {
  margin-right: 0.3rem;
}
.CustomerDataManagement_block2__W-ymZ .CustomerDataManagement_areaActionRight__3aylB > div:last-child {
  display: flex;
}

/** ========= SEARCH FILED ================ **/
.CustomerDataManagement_block2__W-ymZ .CustomerDataManagement_areaActionRight__3aylB .CustomerDataManagement_search__PJDjr {
  border: 1px solid;
  border-radius: 0.125rem;
}

.CustomerDataManagement_block2__W-ymZ .CustomerDataManagement_areaActionRight__3aylB .CustomerDataManagement_search__PJDjr input {
  border: 0;
  width: 100%;
  height: calc(1.5em + 0.75rem + 0px);
}

.CustomerDataManagement_block2__W-ymZ .CustomerDataManagement_areaActionRight__3aylB .CustomerDataManagement_search__PJDjr div {
  border: 0;
  border-radius: 0;
  background-color: inherit;
  padding-left: 0.6rem;
  padding-right: 0.35rem;
}

/***** ========== MAIN ACTION AREA ============ **********/
.CustomerDataManagement_block3__35_It {
  padding: 1.25rem 0;
}

/***** ===================== PAGINATION ======================= ******/
 .CustomerDataManagement_pagination__CxGWW { }

 .CustomerDataManagement_pagination__CxGWW .CustomerDataManagement_item__1RfNI {
  height: 25px;
  width: 25px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}

 .CustomerDataManagement_pagination__CxGWW .CustomerDataManagement_arrowLeft__3df6V,  .CustomerDataManagement_pagination__CxGWW .CustomerDataManagement_arrowRight__3c9Hy {
  border-radius: 50%;
  border: 2px solid;
}

 .CustomerDataManagement_pagination__CxGWW .CustomerDataManagement_arrowLeft__3df6V > span,  .CustomerDataManagement_pagination__CxGWW .CustomerDataManagement_arrowRight__3c9Hy > span {
  border-bottom: 2px solid;
  border-right: 2px solid;
  width: 8px;
  height: 8px; 
  margin-top: -0.5px;
}

 .CustomerDataManagement_pagination__CxGWW .CustomerDataManagement_arrowLeft__3df6V > span{ 
  -webkit-transform: rotate(127deg); 
          transform: rotate(127deg); 
  margin-right: -2.5px;
}

 .CustomerDataManagement_pagination__CxGWW .CustomerDataManagement_arrowRight__3c9Hy > span{ 
  -webkit-transform: rotate(315deg); 
          transform: rotate(315deg); 
  margin-left: -2.5px;
}

 .CustomerDataManagement_pagination__CxGWW .CustomerDataManagement_arrowLeft__3df6V ,  .CustomerDataManagement_pagination__CxGWW .CustomerDataManagement_arrowRight__3c9Hy,  .CustomerDataManagement_pagination__CxGWW .CustomerDataManagement_item__1RfNI[active] {
  color: #e91e63;
  font-weight: 600;
}

 .CustomerDataManagement_pagination__CxGWW .CustomerDataManagement_item__1RfNI[active] {
  cursor: default;
}

 .CustomerDataManagement_pagination__CxGWW .CustomerDataManagement_item__1RfNI[disabled] {
  cursor: not-allowed;
  color: #5d5d5d;
}
.CustomerDataManagement_tableWrapper__2WBZS  {
  min-height: calc(100vh - 393px);
 }
/**
 * Copyright(c) 2020 Mozanta Technologies Private Ltd.
 * 
 * All rights reserved.                
 * 
 * This software is the confidential and proprietary information of Mozanta ("Confidential
 * Information"). You shall not disclose such Confidential Information and shall use it only in
 * accordance with the terms of the contract agreement you entered into with Mozanta.
 * 
 * @author Indrajith C
 */

 .CustomerGroupManagement_block2__15EC1 {
  padding-top: 20px;
  padding-bottom: 20px;
}

.CustomerGroupManagement_block2__15EC1 {
  padding-top: 20px;
  padding-bottom: 20px;
} 

.CustomerGroupManagement_borderRadius2__2fj7- {
  border-radius: 0.125rem !important;
}

.CustomerGroupManagement_secondaryColor__2XzS9 {
  background-color: #757575 !important;
  border-color: #757575 !important;
}

.CustomerGroupManagement_primaryColor__1_iDa {
  background: #e91e63 !important;
  border-color: #e91e63 !important;
}

.CustomerGroupManagement_btn__2qtLH {
  min-width: 7rem;
}

/* ============= PRODUCT TITLE ========= */
.CustomerGroupManagement_titleTextContainer__3aGfY {
  padding: 0 2.1rem 0.125rem 2.1rem;
  border-bottom: 1px solid #edf0f5;
  margin: 0 -0.938rem;
}

.CustomerGroupManagement_titleTextContainer__3aGfY .CustomerGroupManagement_pageTitleText__1FniU{
  text-align: left;
  color: #333333;
  padding-bottom: 17px;
}
 
 

/** =========== ACTION AREA STYLES ============== **/
.CustomerGroupManagement_block2__15EC1 .CustomerGroupManagement_areaActionRight__3xlY4 {
  display: flex;
  justify-content: flex-end;
}

.CustomerGroupManagement_block2__15EC1 .CustomerGroupManagement_borderRadius2__2fj7- {
  border-radius: 0.125rem;
}

.CustomerGroupManagement_block2__15EC1 .CustomerGroupManagement_areaActionRight__3xlY4 > div:first-child {
  margin-right: 0.3rem;
}
.CustomerGroupManagement_block2__15EC1 .CustomerGroupManagement_areaActionRight__3xlY4 > div:last-child {
  display: flex;
}

/** ========= SEARCH FILED ================ **/
.CustomerGroupManagement_block2__15EC1 .CustomerGroupManagement_areaActionRight__3xlY4 .CustomerGroupManagement_search__l_T-m {
  border: 1px solid;
  border-radius: 0.125rem;
}

.CustomerGroupManagement_block2__15EC1 .CustomerGroupManagement_areaActionRight__3xlY4 .CustomerGroupManagement_search__l_T-m input {
  border: 0;
  width: 100%;
  height: calc(1.5em + 0.75rem + 0px);
}

.CustomerGroupManagement_block2__15EC1 .CustomerGroupManagement_areaActionRight__3xlY4 .CustomerGroupManagement_search__l_T-m div {
  border: 0;
  border-radius: 0;
  background-color: inherit;
  padding-left: 0.6rem;
  padding-right: 0.35rem;
}

/***** ========== MAIN ACTION AREA ============ **********/
.CustomerGroupManagement_block3__Ia-nH {
  padding: 1.25rem 0;
}

/***** ===================== PAGINATION ======================= ******/
 .CustomerGroupManagement_pagination__1vGzU { }

 .CustomerGroupManagement_pagination__1vGzU .CustomerGroupManagement_item__3zaK6 {
  height: 25px;
  width: 25px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}

 .CustomerGroupManagement_pagination__1vGzU .CustomerGroupManagement_arrowLeft__2F-9Z,  .CustomerGroupManagement_pagination__1vGzU .CustomerGroupManagement_arrowRight__myta8 {
  border-radius: 50%;
  border: 2px solid;
}

 .CustomerGroupManagement_pagination__1vGzU .CustomerGroupManagement_arrowLeft__2F-9Z > span,  .CustomerGroupManagement_pagination__1vGzU .CustomerGroupManagement_arrowRight__myta8 > span {
  border-bottom: 2px solid;
  border-right: 2px solid;
  width: 8px;
  height: 8px; 
  margin-top: -0.5px;
}

 .CustomerGroupManagement_pagination__1vGzU .CustomerGroupManagement_arrowLeft__2F-9Z > span{ 
  -webkit-transform: rotate(127deg); 
          transform: rotate(127deg); 
  margin-right: -2.5px;
}

 .CustomerGroupManagement_pagination__1vGzU .CustomerGroupManagement_arrowRight__myta8 > span{ 
  -webkit-transform: rotate(315deg); 
          transform: rotate(315deg); 
  margin-left: -2.5px;
}

 .CustomerGroupManagement_pagination__1vGzU .CustomerGroupManagement_arrowLeft__2F-9Z ,  .CustomerGroupManagement_pagination__1vGzU .CustomerGroupManagement_arrowRight__myta8,  .CustomerGroupManagement_pagination__1vGzU .CustomerGroupManagement_item__3zaK6[active] {
  color: #e91e63;
  font-weight: 600;
}

 .CustomerGroupManagement_pagination__1vGzU .CustomerGroupManagement_item__3zaK6[active] {
  cursor: default;
}

 .CustomerGroupManagement_pagination__1vGzU .CustomerGroupManagement_item__3zaK6[disabled] {
  cursor: not-allowed;
  color: #5d5d5d;
}

.CustomerGroupManagement_tableWrapper__2iysg{
  min-height: calc(100vh - 383px);
}
/**
 * Copyright(c) 2020 Mozanta Technologies Private Ltd.
 * 
 * All rights reserved.                
 * 
 * This software is the confidential and proprietary information of Mozanta ("Confidential
 * Information"). You shall not disclose such Confidential Information and shall use it only in
 * accordance with the terms of the contract agreement you entered into with Mozanta.
 * 
 * @author Sreelekshmi Prasannan
 */

 .CustomerGroupForm_block2__1pZ9u {
  padding-top: 20px;
  padding-bottom: 20px;
} 

.CustomerGroupForm_borderRadius2__2pxdY {
  border-radius: 0.125rem !important;
}

.CustomerGroupForm_secondaryColor__1OqLN {
  background-color: #757575 !important;
  border-color: #757575 !important;
}

.CustomerGroupForm_primaryColor__ju-iW {
  background: #e91e63 !important;
  border-color: #e91e63 !important;
}

.CustomerGroupForm_btn__2bf08 {
  min-width: 7rem;
}

.CustomerGroupForm_icon__3QhAV {
  width: 1.25rem;
}

/* ========== FORM AREA ============ */
.CustomerGroupForm_block3__1mglo {
  padding: 1.25rem;
}

.CustomerGroupForm_noLabelFormGroup__1aKzX {
  margin-top: 29px;
  height: calc(1.5em + 0.75rem + 2px);
} 
.CustomerGroupForm_formTextLabelCustomer__3PHz_{
  font-weight: 700;
}
/**
 * Copyright(c) 2020 Mozanta Technologies Private Ltd.
 * 
 * All rights reserved.                
 * 
 * This software is the confidential and proprietary information of Mozanta ("Confidential
 * Information"). You shall not disclose such Confidential Information and shall use it only in
 * accordance with the terms of the contract agreement you entered into with Mozanta.
 * 
 * @author Sreelekshmi Prasannan
 */

 .AddCustomerGroup_block2__3ZL0V {
  padding-top: 20px;
  padding-bottom: 20px;
} 

.AddCustomerGroup_borderRadius2__3xFPR {
  border-radius: 0.125rem !important;
}

.AddCustomerGroup_secondaryColor__1_TCI {
  background-color: #757575 !important;
  border-color: #757575 !important;
}

.AddCustomerGroup_primaryColor__97wos {
  background: #e91e63 !important;
  border-color: #e91e63 !important;
}

.AddCustomerGroup_btn__1Av9T {
  min-width: 7rem;
}



/* ========== FORM AREA ============ */
.AddCustomerGroup_block3__2plyj {
  padding: 1.25rem;
}
/**
 * Copyright(c) 2020 Mozanta Technologies Private Ltd.
 * 
 * All rights reserved.                
 * 
 * This software is the confidential and proprietary information of Mozanta ("Confidential
 * Information"). You shall not disclose such Confidential Information and shall use it only in
 * accordance with the terms of the contract agreement you entered into with Mozanta.
 * 
 * @author Aardra S
 */

.CustomURLManagement_borderRadius2__3oFA4 {
  border-radius: 0.125rem;
}

.CustomURLManagement_secondaryColor__I_Wob {
  background-color: #757575 !important;
  border-color: #757575 !important;
}

.CustomURLManagement_primaryColor__1ey_h {
  background: #e91e63 !important;
  border-color: #e91e63 !important;
}

.CustomURLManagement_btn1__1lEBB {
  min-width: 6rem;
}

.CustomURLManagement_btn2__1fYlt {
  min-width: 9rem;
}

.CustomURLManagement_btnRule___I0by{
   background-color: white !important;
  color:black!important;
  border-color: #b4bfca !important;
  min-width: 6rem;
}

.CustomURLManagement_btnAdd__1hhCd{
  background-color:#dadada !important; 
  color:black!important;
  border-color: #808284!important;
  min-width: 7rem;
}

.CustomURLManagement_badge__2k895{
  background-color:#dadada !important;  
  min-width: 7rem;
  min-height: 2.25rem;
  border-color: #808284!important;
}

.CustomURLManagement_badgeTag__6iFpD{
  font-size: 14px;
}

.CustomURLManagement_quesIcon__2d6jR{
  background-color:#ced4da !important;  
  min-width: 2.25rem;
  border-color: #808284!important;
}

.CustomURLManagement_tabLink__3SGY1{
  cursor: pointer;
}



/**
 * Copyright(c) 2020 Mozanta Technologies Private Ltd.
 *
 * All rights reserved.
 *
 * This software is the confidential and proprietary information of Mozanta
 * ("Confidential Information"). You shall not disclose such Confidential
 * Information and shall use it only in accordance with the terms of the
 * contract agreement you entered into with Mozanta.
 *
 * PageCustomization.module.css
 *
 * @author Hari A K
 *
 */

 .FacetCustomization_block2__2nLj5{
  padding-top: 20px;
  padding-bottom: 20px;
}
.FacetCustomization_block2__2nLj5 .FacetCustomization_pageSelectionText__39xwY{

  text-align: left;
  color: #333333;
  padding-bottom: 17px;
}


.FacetCustomization_block2__2nLj5 .FacetCustomization_pageSelection__35SF0  button:hover, .FacetCustomization_pageSelection__35SF0  button:active{
  background-color: #ffff ;
  color: black;
  border: solid 1px #313131;

}
.FacetCustomization_block2__2nLj5 .FacetCustomization_pageSelection__35SF0  button{
  background-color: #ffff !important;
  color: black !important;
  box-shadow: none !important;
  border: solid 1px #313131;
  border-radius: 2px;
  min-width: 230px;
  text-align: left;

}

.FacetCustomization_block2__2nLj5 .FacetCustomization_pageSelection__35SF0  button::after {
  float: right !important;
  margin-top: 10px;
}
.FacetCustomization_block2__2nLj5 .FacetCustomization_pageSelection__35SF0 .FacetCustomization_dropdownItem__1Gl97{
  padding: 10px !important;
}
.FacetCustomization_block2__2nLj5 .FacetCustomization_pageSelection__35SF0 .FacetCustomization_dropdownItem__1Gl97:hover{
color:#e91e63;
background-color: #f9f9f9;
}

.FacetCustomization_block2__2nLj5 .FacetCustomization_pageSelection__35SF0 .FacetCustomization_dropdownWrapper__19wP5  {
  width: 100% !important;
  border: none !important;
  box-shadow: 0px 3px 10px -4px #ccc;
  height: 194px;
  overflow: auto;
}

.FacetCustomization_block2__2nLj5 .FacetCustomization_pagePreview__25zB2 .FacetCustomization_pagePreviewText__cLY1T{
  padding-right: 20px;
  float: right;
  color: #333333;
  padding-bottom: 5px;
  
}
.FacetCustomization_block2__2nLj5 .FacetCustomization_pagePreview__25zB2 .FacetCustomization_selectedPageImg__21_dc {
  height: 100px;
  object-fit: cover;
  display: flex;
  justify-content:center;
  border: 1px solid #e91e63;
}
.FacetCustomization_block2__2nLj5 .FacetCustomization_pagePreview__25zB2  {
  display: flex;
  justify-content:center;
}

.FacetCustomization_block2__2nLj5 .FacetCustomization_pagePreviewWrapper__bfFVb .FacetCustomization_clickMe__3N8eK{
  display:  none;
}
.FacetCustomization_block2__2nLj5 .FacetCustomization_pagePreviewWrapper__bfFVb {
  position: relative;
  display: flex;
  width: 73px;
  justify-content: center;
}
.FacetCustomization_block2__2nLj5 .FacetCustomization_pagePreviewWrapper__bfFVb:hover .FacetCustomization_clickMe__3N8eK{
  display: flex;
  flex-direction: column;
  position: absolute;
  bottom: 44%;
  width: 73px;
  padding: 3px;
  z-index: 1;
  justify-content: center;
  align-items: center;
  background: white;
  text-align: center;
  font-weight: 500;
}
.FacetCustomization_liveBtnWrapper__1k2-v{
  display: flex;
  align-items: center;
  justify-content: flex-end;
}
.FacetCustomization_liveBtnWrapper__1k2-v button{
  font-size: 11px;
}
.FacetCustomization_liveBtn__2z-Df{
border: none;
border-radius: 2px;
text-transform: uppercase;
}
.FacetCustomization_liveBtnPush__3eVrs{
  width: -webkit-max-content;
  width: -moz-max-content;
  width: max-content;
  border: none;
  border-radius: 2px;
  text-transform: uppercase;
}

.FacetCustomization_liveBtn__2z-Df a{
color: white !important;
}

.FacetCustomization_block2__2nLj5 .FacetCustomization_customLandingToggle__1M6G6{
padding-top: 20px;
padding-bottom: 20px;
}

.FacetCustomization_block3__2Qnmw{
  padding-top: 20px;
  padding-bottom: 15px;
}
.FacetCustomization_block3__2Qnmw .FacetCustomization_buttonAdd__pYBUE{
  color: #ffffff;
  width: 113px;
  height: 35px;
  border-radius: 2px;
  background-color: #e91e63;
  border: 0px;
  float: right;
}
.FacetCustomization_block3__2Qnmw .FacetCustomization_buttonCancel__1REZB{
  width: 113px;
  height: 35px;
  color: white;
  border-radius: 2px;
  border: 0px;
  float: right;
  margin-right: 10px !important;
}

.FacetCustomization_block3__2Qnmw .FacetCustomization_pageWidgetsText__1VwQE{
  padding-right: 6px;
  float: right;
  color: #333333;
}

.FacetCustomization_block3__2Qnmw .FacetCustomization_widgetsTable__2Ebeu {
  padding-top: 20px;
}

.FacetCustomization_block3__2Qnmw .FacetCustomization_widgetsTable__2Ebeu  thead{
  background-color: #edf0f5;
}

.FacetCustomization_block3__2Qnmw .FacetCustomization_widgetsTable__2Ebeu  thead th{
  font-size: 14px;
  font-weight: bold;
  text-align: left;
}

.FacetCustomization_block3__2Qnmw .FacetCustomization_widgetsTable__2Ebeu  tbody tr{
  text-align: left;
}

.FacetCustomization_widgetContentEditorPreviewCol__1_AtS{
  justify-content: center;
  align-items: center;
  justify-items: center;
}
.FacetCustomization_widgetContentEditorPreviewRow__2wLcP{
  justify-content: center;
  height: 100%;
  align-items: center;
  background: #f9f9f9;
  justify-items: center;
  margin-right: 0px !important;
}
.FacetCustomization_widgetContentEditorPreviewRow1__8ksde{
  height: 73%;
}

.FacetCustomization_tableWrapper__3rziR{
min-height: calc(100vh - 459px);
}

.FacetCustomization_spinnerWrapper__vHKRJ {
text-align: center;
color: #17a2b8;
}

.FacetCustomization_contentInfo__3jkbk{
  background: #FF9800;
  text-align: center;
  display: flex;
  justify-content: center;
  color: white;
  border-radius: 2px;
  padding: 10px 5px;
}


/* SEO block */
.FacetCustomization_seoText__2C2Xu {
  font-size: 14px;
  font-weight: 600;
}
.FacetCustomization_seoText__2C2Xu .FacetCustomization_value__2s3vc{
  font-weight: 500;
  padding-left: 10px;
  padding-top: 10px;
}
.FacetCustomization_selectedCategory__3yWdA{
  font-size: 18px;
  font-weight: 500;
  color: #F44336;
  justify-content: center;
  align-items: center;
  display: flex;
}
/**
 * Copyright(c) 2020 Mozanta Technologies Private Ltd.
 *
 * All rights reserved.
 *
 * This software is the confidential and proprietary information of Mozanta ("Confidential
 * Information"). You shall not disclose such Confidential Information and shall use it only in
 * accordance with the terms of the contract agreement you entered into with Mozanta.
 *
 * @author HariKrishnan A K
 */

 .FacetEditor_borderRadius2__1CtFJ {
  border-radius: 0.125rem !important;
}

/** =========== ACTION AREA STYLES ============== **/
.FacetEditor_areaActionRight__if0sj {
  display: flex;
  justify-content: flex-end;
}

.FacetEditor_secondaryColor__2ZlJJ,
.FacetEditor_secondaryColor__2ZlJJ:focus,
.FacetEditor_secondaryColor__2ZlJJ:active {
  background-color: #757575 !important;
  border-color: #757575 !important;
}

.FacetEditor_tableWrapper__2dAEH{
  min-height: calc(100vh - 365px);
}
.Footer_footerWrapper__sTjj0{
    background: #ffffff;
    padding: 20px 10px 20px 10px;
    color: #5d5d5d;
}
.Footer_textUnderline__1CoH-{
    text-decoration: underline;
}
/**
 * Copyright(c) 2020 Mozanta Technologies Private Ltd.
 * 
 * All rights reserved.                
 * 
 * This software is the confidential and proprietary information of Mozanta ("Confidential
 * Information"). You shall not disclose such Confidential Information and shall use it only in
 * accordance with the terms of the contract agreement you entered into with Mozanta.
 * 
 * @author Anagha
 */

 .fulfillmentMethod_block2__1UDVN {
    padding-top: 20px;
    padding-bottom: 20px;
  }
  
.fulfillmentMethod_borderRadius2__1levP {
  border-radius: 0.125rem !important;
}

  /** =========== ACTION AREA STYLES ============== **/
  .fulfillmentMethod_block2__1UDVN .fulfillmentMethod_areaActionRight__1K3yp {
    display: flex;
    justify-content: flex-end;
  }
  
  .fulfillmentMethod_block2__1UDVN .fulfillmentMethod_areaActionRight__1K3yp > div:first-child {
    margin-right: 0.3rem;
  }
  .fulfillmentMethod_block2__1UDVN .fulfillmentMethod_areaActionRight__1K3yp > div:last-child {
    display: flex;
  }
  
  /** ========= SEARCH FILED ================ **/
  .fulfillmentMethod_block2__1UDVN .fulfillmentMethod_areaActionRight__1K3yp .fulfillmentMethod_search__UNiPf {
    border: 1px solid;
    border-radius: 0.125rem;
  }
  
  .fulfillmentMethod_block2__1UDVN .fulfillmentMethod_areaActionRight__1K3yp .fulfillmentMethod_search__UNiPf input {
    border: 0;
    width: 100%;
    height: calc(1.5em + 0.75rem + 0px);
  }
  
  .fulfillmentMethod_block2__1UDVN .fulfillmentMethod_areaActionRight__1K3yp .fulfillmentMethod_search__UNiPf div {
    border: 0;
    border-radius: 0;
    background-color: inherit;
    padding-left: 0.6rem;
    padding-right: 0.35rem;
  }
  
  /***** ========== MAIN ACTION AREA ============ **********/
  .fulfillmentMethod_block3__YK0NZ {
    padding: 1.25rem 0;
  }
  
  /***** ============== MAIN ACTION COMPONENT ========= *******/
  .fulfillmentMethod_block3__YK0NZ .fulfillmentMethod_product__23Zko {
    position: relative;
  }
  
  /***** ============== ACTION COMPONENT BODY ========= *******/
  
  .fulfillmentMethod_block3__YK0NZ .fulfillmentMethod_product__23Zko .fulfillmentMethod_productBody__1rmw1 .fulfillmentMethod_productTable__224Ft thead {
    background-color: #edf0f5;
  }
  
  .fulfillmentMethod_block3__YK0NZ .fulfillmentMethod_product__23Zko .fulfillmentMethod_productBody__1rmw1 .fulfillmentMethod_productItem__2102a {
    display: flex;
    flex-direction: column;
  }

  .fulfillmentMethod_block3__YK0NZ .fulfillmentMethod_product__23Zko .fulfillmentMethod_productBody__1rmw1 .fulfillmentMethod_productTable__224Ft .fulfillmentMethod_productName__1XdDA {
    max-width: 100px;
  }

  .fulfillmentMethod_block3__YK0NZ .fulfillmentMethod_product__23Zko .fulfillmentMethod_productBody__1rmw1 .fulfillmentMethod_productTable__224Ft .fulfillmentMethod_productImage__-HzgN {
    max-height: 50px;
  }

  /***** ===================== PAGINATION ======================= ******/
  .fulfillmentMethod_block3__YK0NZ .fulfillmentMethod_product__23Zko .fulfillmentMethod_pagination__3tw9u { }
  
  .fulfillmentMethod_block3__YK0NZ .fulfillmentMethod_product__23Zko .fulfillmentMethod_pagination__3tw9u .fulfillmentMethod_item__3Lu0l {
    height: 25px;
    width: 25px;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
  }

  .fulfillmentMethod_block3__YK0NZ .fulfillmentMethod_product__23Zko .fulfillmentMethod_pagination__3tw9u .fulfillmentMethod_arrowLeft__iuVeo, .fulfillmentMethod_block3__YK0NZ .fulfillmentMethod_product__23Zko .fulfillmentMethod_pagination__3tw9u .fulfillmentMethod_arrowRight__2qKbq {
    border-radius: 50%;
    border: 2px solid;
  }

  .fulfillmentMethod_block3__YK0NZ .fulfillmentMethod_product__23Zko .fulfillmentMethod_pagination__3tw9u .fulfillmentMethod_arrowLeft__iuVeo > span, .fulfillmentMethod_block3__YK0NZ .fulfillmentMethod_product__23Zko .fulfillmentMethod_pagination__3tw9u .fulfillmentMethod_arrowRight__2qKbq > span {
    border-bottom: 2px solid;
    border-right: 2px solid;
    width: 8px;
    height: 8px; 
    margin-top: -0.5px;
  }

  .fulfillmentMethod_block3__YK0NZ .fulfillmentMethod_product__23Zko .fulfillmentMethod_pagination__3tw9u .fulfillmentMethod_arrowLeft__iuVeo > span{ 
    -webkit-transform: rotate(127deg); 
            transform: rotate(127deg); 
    margin-right: -2.5px;
  }

  .fulfillmentMethod_block3__YK0NZ .fulfillmentMethod_product__23Zko .fulfillmentMethod_pagination__3tw9u .fulfillmentMethod_arrowRight__2qKbq > span{ 
    -webkit-transform: rotate(315deg); 
            transform: rotate(315deg); 
    margin-left: -2.5px;
  }

  .fulfillmentMethod_block3__YK0NZ .fulfillmentMethod_product__23Zko .fulfillmentMethod_pagination__3tw9u .fulfillmentMethod_arrowLeft__iuVeo , .fulfillmentMethod_block3__YK0NZ .fulfillmentMethod_product__23Zko .fulfillmentMethod_pagination__3tw9u .fulfillmentMethod_arrowRight__2qKbq, .fulfillmentMethod_block3__YK0NZ .fulfillmentMethod_product__23Zko .fulfillmentMethod_pagination__3tw9u .fulfillmentMethod_item__3Lu0l[active] {
    color: #e91e63;
    font-weight: 600;
  }

  .fulfillmentMethod_block3__YK0NZ .fulfillmentMethod_product__23Zko .fulfillmentMethod_pagination__3tw9u .fulfillmentMethod_item__3Lu0l[active] {
    cursor: default;
  }

  .fulfillmentMethod_block3__YK0NZ .fulfillmentMethod_product__23Zko .fulfillmentMethod_pagination__3tw9u .fulfillmentMethod_item__3Lu0l[disabled] {
    cursor: not-allowed;
    color: #5d5d5d;
  }
  .fulfillmentMethod_tableWrapper__CEqlv{
    min-height: calc(100vh - 387px);
  }

/**
 * Copyright(c) 2020 Mozanta Technologies Private Ltd.
 *
 * All rights reserved.
 *
 * This software is the confidential and proprietary information of Mozanta
 * ("Confidential Information"). You shall not disclose such Confidential
 * Information and shall use it only in accordance with the terms of the
 * contract agreement you entered into with Mozanta.
 *
 * Editfulfillment.module.css
 *
 * @author Anagha
 *
 */

 .EditfulfillmentMethod_block1__3cihj .EditfulfillmentMethod_buttonNewUser__2UVYA{
    color: #ffffff;
    width: 113px;
    border-radius: 2px;
    background-color: #e91e63;
    border: 0px;
    float: right;
  }
  
  
  /* block 2 */
  
    /** =========== ACTION AREA STYLES ============== **/
    .EditfulfillmentMethod_block2__2KS6s .EditfulfillmentMethod_areaActionRight__3OuLg {
      display: flex;
      justify-content: flex-end;
    }
    
    .EditfulfillmentMethod_block2__2KS6s .EditfulfillmentMethod_borderRadius2__1JS9Y {
      border-radius: 0.125rem;
    }
    
    .EditfulfillmentMethod_block2__2KS6s .EditfulfillmentMethod_areaActionRight__3OuLg > div:first-child {
      margin-right: 0.3rem;
    }
    .EditfulfillmentMethod_block2__2KS6s .EditfulfillmentMethod_areaActionRight__3OuLg > div:last-child {
      display: flex;
    }
    
    /** ========= SEARCH FILED ================ **/
    .EditfulfillmentMethod_block2__2KS6s .EditfulfillmentMethod_areaActionRight__3OuLg .EditfulfillmentMethod_search__1ToSi {
      border: 1px solid;
      border-radius: 0.125rem;
    }
    
    .EditfulfillmentMethod_block2__2KS6s .EditfulfillmentMethod_areaActionRight__3OuLg .EditfulfillmentMethod_search__1ToSi input {
      border: 0;
      width: 100%;
      height: calc(1.5em + 0.75rem + 0px);
    }
    
    .EditfulfillmentMethod_block2__2KS6s .EditfulfillmentMethod_areaActionRight__3OuLg .EditfulfillmentMethod_search__1ToSi div {
      border: 0;
      border-radius: 0;
      background-color: inherit;
      padding-left: 0.6rem;
      padding-right: 0.35rem;
    }
    .EditfulfillmentMethod_tableWrapper__2JgoS{
      min-height: calc(100vh - 424px);
    }
.Header_headerWrapper__3ty7P{
    background: white;
    position: fixed;
    height: 75px;
    display: inline-grid;
    z-index: 100;
    left: 32px;
    right: 32px;
    top: 0px;
    padding: 10px 0px;
    border-bottom: 1px solid #f2f2f2
}
.Header_menuWrap__2XouH{
    display: block;
    width: 100%;
}
.Header_menuWrap2__3-Mpd{
    display: inline-block;
    width: auto;
    height: 32px;
    padding-left: 145px;
    position: absolute;
    top: 2px;
}

.Header_logo__VQCZ1{
    padding: 0px 5px 3px 3px;
    float: left;
    width: 136px;
    margin-top: -6px;
}
.Header_logout__wKRvF{
    float: right;
    display: inline-block;
    font-weight: bold;
    cursor: pointer;
    padding-left: 20px;
}
.Header_avatar__1BmqD{
    display: inline-block;
    float: left;
    width: 42px;
    height: 42px;
    border-radius: 100%;
    background-color: grey;
    padding: 1px;
    margin-top: -4px;
}
.Header_avatar__1BmqD img{
    max-width: 100%;
    max-height: 100%;
}
.Header_logout__wKRvF img{
    margin-left: 8px;
    width: 14px;
    height: 14px;
}
.Header_languageSwitcher__2cQEG  button{
    background-color: #ffff !important;
    color: black !important;
    border: none;
    box-shadow: none !important;
    padding-left: 0.3125rem !important;

}
.Header_languageSwitcher__2cQEG  button:hover, .Header_languageSwitcher__2cQEG  button:active{
    background-color: #ffff ;
    color: black;
    border: none
}

.Header_selectedLanguage__2KACH{
    padding-left: .625rem;
    padding-top: 0.5rem;
}
/*  notification icon style fix */
.Header_notification__1Ajo4 {
    float: right;
    margin-left: 15px;
    cursor: pointer;
}
.Header_siteName__2U-8r {
    float: right;
    margin-left: 15px;
    color :#1781FF;
    cursor: default;
}
.Header_changeSite__2MIg4:hover{
  cursor: pointer;
  text-decoration: underline;
}
.Header_profileButton__2O8Q7 {
    padding-left: 0.75rem;
}
.Header_profileButton__2O8Q7 .Header_btnProfile__2EzTt {
    background-color: #ffff !important;
    color: black !important;
    border: none;
    box-shadow: none !important;
    padding: 0.1rem 0.5rem;

}
.Header_profileButton__2O8Q7 .Header_btnProfile__2EzTt:hover,
.Header_profileButton__2O8Q7 .Header_btnProfile__2EzTt:active {
    background-color: #ffff ;
    color: black;
    border: none
}
.Header_profileButton__2O8Q7 .Header_dropdownMenu__2BmEn {
    border: 1px solid #ddd;
    border-radius: 0;
    box-shadow: 1px 1px 4px rgba(0,0,0,0.05);
    background-color: #fff;
    min-width: 200px;
}
.Header_profileButton__2O8Q7 .Header_dropdownMenu__2BmEn:before {
    content:"";
    position: absolute;
    right: 11px;
    top: -10px;
    width: 0;
    height: 0;
    border-style: solid;
    border-width: 0 10px 10px 10px;
    border-color: transparent transparent #fff transparent;
    z-index:9999;
}
.Header_profileButton__2O8Q7 .Header_dropdownMenu__2BmEn:after {
    content:"";
    position: absolute;
    right: 11px;
    top: -11px;
    width: 0;
    height: 0;
    border-style: solid;
    border-width: 0 10px 10px 10px;
    border-color: transparent transparent #ddd transparent;
    z-index: 9998;
}
.Header_profileButton__2O8Q7 .Header_dropdownMenu__2BmEn .Header_dropdownItem__33Lmj {
    background-color: #fff;
    padding: 0.4rem 0.75rem;
    font-size: 15px;
}
.Header_profileButton__2O8Q7 .Header_dropdownMenu__2BmEn .Header_dropdownItem__33Lmj:hover {
    background-color: #229E79;
    color: #fff;
}
.Header_profileData__3FDXR {
    display: flex;
    align-items: center;
}
.Header_profileData__3FDXR figure {
    width: 32px;
    height: 32px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 0;
    margin-right: 0.5rem;
}
.Header_profileData__3FDXR figure img {
    width: 100%;
    border-radius: 100%;
}
.Header_profileData__3FDXR .Header_profileArrow__2r63r {
    width: 10px;
    opacity: 0.8;
    margin-left: 0.5rem;
    margin-top: 0.2rem;
}
/* block 1 */

.InventoryManagement_block1__2tE-Z {
  padding-top: 20px;
  padding-bottom: 20px;
}
.InventoryManagement_block1__2tE-Z .InventoryManagement_navigationManagementnText__2gQrq {
  text-align: left;
  color: #333333;
  padding-bottom: 17px;
}

.InventoryManagement_block1__2tE-Z .InventoryManagement_buttonAddMenu__3YDWD {
  color: #ffffff;
  width: 113px;
  height: 35px;
  border-radius: 2px;
  background-color: #448aff;
  border: 0px;
  float: right;
}
.InventoryManagement_tableWrapper__1HR0x {
  min-height: calc(100vh - 385px);
}
.InventoryManagement_pagination__2iuW4 .InventoryManagement_item__1zcue {
  height: 25px;
  width: 25px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}

.InventoryManagement_pagination__2iuW4 .InventoryManagement_arrowLeft__FnOBJ,
.InventoryManagement_pagination__2iuW4 .InventoryManagement_arrowRight__HdTYD {
  border-radius: 50%;
  border: 2px solid;
}

.InventoryManagement_pagination__2iuW4 .InventoryManagement_arrowLeft__FnOBJ > span,
.InventoryManagement_pagination__2iuW4 .InventoryManagement_arrowRight__HdTYD > span {
  border-bottom: 2px solid;
  border-right: 2px solid;
  width: 8px;
  height: 8px;
  margin-top: -0.5px;
}

.InventoryManagement_pagination__2iuW4 .InventoryManagement_arrowLeft__FnOBJ > span {
  -webkit-transform: rotate(127deg);
          transform: rotate(127deg);
  margin-right: -2.5px;
}

.InventoryManagement_pagination__2iuW4 .InventoryManagement_arrowRight__HdTYD > span {
  -webkit-transform: rotate(315deg);
          transform: rotate(315deg);
  margin-left: -2.5px;
}

.InventoryManagement_pagination__2iuW4 .InventoryManagement_arrowLeft__FnOBJ,
.InventoryManagement_pagination__2iuW4 .InventoryManagement_arrowRight__HdTYD,
.InventoryManagement_pagination__2iuW4 .InventoryManagement_item__1zcue[active] {
  color: #e91e63;
  font-weight: 600;
}

.InventoryManagement_pagination__2iuW4 .InventoryManagement_item__1zcue[active] {
  cursor: default;
}

.InventoryManagement_pagination__2iuW4 .InventoryManagement_item__1zcue[disabled] {
  cursor: not-allowed;
  color: #5d5d5d;
}
.InventoryManagement_tableWrapper__1HR0x {
  min-height: calc(100vh - 387px);
}

/** ========= SEARCH FILED ================ **/
.InventoryManagement_block1__2tE-Z .InventoryManagement_areaActionRight__1DtZk {
  display: flex;
  justify-content: flex-end;
}

.InventoryManagement_block1__2tE-Z .InventoryManagement_areaActionRight__1DtZk .InventoryManagement_search__3FTgb {
  border: 1px solid;
  border-radius: 0.125rem;
}

.InventoryManagement_block1__2tE-Z .InventoryManagement_areaActionRight__1DtZk .InventoryManagement_search__3FTgb input {
  border: 0;
  width: 100%;
  height: calc(1.5em + 0.75rem + 0px);
}

.InventoryManagement_block1__2tE-Z .InventoryManagement_areaActionRight__1DtZk .InventoryManagement_search__3FTgb div {
  border: 0;
  border-radius: 0;
  background-color: inherit;
  padding-left: 0.6rem;
  padding-right: 0.35rem;
}
.InventoryManagement_searchContainer__2dArA{
  display: flex;
  align-items: flex-end;
  margin: 0 0 1rem 0.3rem;
}

.InventoryManagement_block2__1ebDo {
  padding-top: 20px;
  padding-bottom: 20px;
}

.InventoryManagement_block2__1ebDo button {
  background: none;
  color: black;
  border-radius: 2px;
  border: 1px solid #e0e0e0;
}

.InventoryManagement_block2__1ebDo .InventoryManagement_dropdownBtn__28BE5 {
  padding: 0px;
}

.InventoryManagement_block2__1ebDo .InventoryManagement_dropdownBtn__28BE5 button {
  float: right !important;
}

/**
 * Copyright(c) 2020 Mozanta Technologies Private Ltd.
 * 
 * All rights reserved.                
 * 
 * This software is the confidential and proprietary information of Mozanta ("Confidential
 * Information"). You shall not disclose such Confidential Information and shall use it only in
 * accordance with the terms of the contract agreement you entered into with Mozanta.
 * 
 * @author Amjad Rehman A
 */

 .JobConfiguration_areaActionRight__3iiNU {
  display: flex;
  justify-content: flex-end;
  }


  .JobConfiguration_block1__17JkJ .JobConfiguration_buttonNewJob__2xmuV{
    color: #ffffff;
    width: 113px;
    border-radius: 2px;
    background-color: #e91e63;
    border: 0px;
    float: right;
  }
  .JobConfiguration_block1__17JkJ .JobConfiguration_buttonStopJob__37cvs{
    color: #ffffff;
    width: 113px;
    border-radius: 2px;
    background-color: #e91e63;
    border: 0px;
  }
  .JobConfiguration_block1__17JkJ .JobConfiguration_buttonDisable__2-_YK{
    color: #ffffff;
    width: 113px;
    border-radius: 2px;
    background-color: #e91e63;
    border: 0px;
    margin-left: 15px;
    margin-right: 15px;
  }
  .JobConfiguration_block1__17JkJ .JobConfiguration_buttonDelete__1lEXW{
    color: #ffffff;
    width: 113px;
    border-radius: 2px;
    background-color: #e91e63;
    border: 0px;
  }
  .JobConfiguration_jobButtons__3Ox8j{
    float: right;
  }

/** =========== ACTION AREA STYLES ============== **/
  .JobConfiguration_block2__2P0Ic .JobConfiguration_areaActionRight__3iiNU {
    display: flex;
    justify-content: flex-end;
  }
  
  .JobConfiguration_block2__2P0Ic .JobConfiguration_borderRadius2__2-2k5 {
    border-radius: 0.125rem;
  }
  
  .JobConfiguration_block2__2P0Ic .JobConfiguration_areaActionRight__3iiNU > div:first-child {
    margin-right: 0.3rem;
  }
  .JobConfiguration_block2__2P0Ic .JobConfiguration_areaActionRight__3iiNU > div:last-child {
    display: flex;
  }
  
/** ========= SEARCH FILED ================ **/
  .JobConfiguration_block2__2P0Ic .JobConfiguration_areaActionRight__3iiNU .JobConfiguration_search__37zod {
    border: 1px solid;
    border-radius: 0.125rem;
  }
  
  .JobConfiguration_block2__2P0Ic .JobConfiguration_areaActionRight__3iiNU .JobConfiguration_search__37zod input {
    border: 0;
    width: 222px;
    height: calc(1.5em + 0.75rem + 0px);
  }
  
  .JobConfiguration_block2__2P0Ic .JobConfiguration_areaActionRight__3iiNU .JobConfiguration_search__37zod div {
    border: 0;
    border-radius: 0;
    background-color: inherit;
    padding-left: 0.6rem;
    padding-right: 0.35rem;
  }
  .JobConfiguration_tableWrapper__3kFBd{
    min-height: calc(100vh - 424px);
  }

  /** =========== TABLE ============== **/
  .JobConfiguration_jobTable__2IX4E .JobConfiguration_table__1BuJI th .JobConfiguration_table__1BuJI td {
    width: 0.75rem;
  }

  .JobConfiguration_jobTable__2IX4E {
    overflow-x: auto ;
  }
  .JobConfiguration_jobTable__2IX4E thead{
    background-color: #edf0f5;
  }
  .JobConfiguration_tableWrapper__3kFBd{
    min-height: calc(100vh - 365px);
  }
   
  /** =========== PAGINATION ============== **/
  .JobConfiguration_pagination__2uSdc .JobConfiguration_item__2FsJG {
   height: 25px;
   width: 25px;
   display: flex;
   justify-content: center;
   align-items: center;
   cursor: pointer;
 }

  .JobConfiguration_pagination__2uSdc .JobConfiguration_arrowLeft__2yJVw,  .JobConfiguration_pagination__2uSdc .JobConfiguration_arrowRight__U7dvp {
   border-radius: 50%;
   border: 2px solid;
 }

  .JobConfiguration_pagination__2uSdc .JobConfiguration_arrowLeft__2yJVw > span,  .JobConfiguration_pagination__2uSdc .JobConfiguration_arrowRight__U7dvp > span {
   border-bottom: 2px solid;
   border-right: 2px solid;
   width: 8px;
   height: 8px; 
   margin-top: -0.5px;
 }

  .JobConfiguration_pagination__2uSdc .JobConfiguration_arrowLeft__2yJVw > span{ 
   -webkit-transform: rotate(127deg); 
           transform: rotate(127deg); 
   margin-right: -2.5px;
 }

  .JobConfiguration_pagination__2uSdc .JobConfiguration_arrowRight__U7dvp > span{ 
   -webkit-transform: rotate(315deg); 
           transform: rotate(315deg); 
   margin-left: -2.5px;
 }

  .JobConfiguration_pagination__2uSdc .JobConfiguration_arrowLeft__2yJVw ,  .JobConfiguration_pagination__2uSdc .JobConfiguration_arrowRight__U7dvp,  .JobConfiguration_pagination__2uSdc .JobConfiguration_item__2FsJG[active] {
   color: #e91e63;
   font-weight: 600;
 }

  .JobConfiguration_pagination__2uSdc .JobConfiguration_item__2FsJG[active] {
   cursor: default;
 }

  .JobConfiguration_pagination__2uSdc .JobConfiguration_item__2FsJG[disabled] {
   cursor: not-allowed;
   color: #5d5d5d;
 }

 .JobConfiguration_borderRadius2__2-2k5 {
  border-radius: 0.125rem !important;
 }

/** =========== ACTION AREA STYLES ============== **/
 .JobConfiguration_areaActionRight__3iiNU {
  display: flex;
  justify-content: flex-end;
 }

 .JobConfiguration_secondaryColor__2ENJ7,
 .JobConfiguration_secondaryColor__2ENJ7:focus,
 .JobConfiguration_secondaryColor__2ENJ7:active {
  background-color: #757575 !important;
  border-color: #757575 !important;
 }

 .JobConfiguration_secondaryColor__2ENJ7:hover {
  background-color: #919191 !important;
  border-color: #919191 !important;
 }

 .JobConfiguration_border__GqOV0 {
  border: 1px solid #c4c4c4;
 }

 .JobConfiguration_tableWrapper__3kFBd {
  min-height: calc(100vh - 365px);
 }

 .JobConfiguration_sectionHead__1w6oU {
  font-weight: bold;
  margin-bottom: 1rem;
 }
.JobConfiguration_borderRadius2__2-2k5 {
  border-radius: 0.125rem !important;
}

/** =========== ACTION AREA STYLES ============== **/
.JobConfiguration_areaActionRight__3iiNU {
  display: flex;
  justify-content: flex-end;
}

.JobConfiguration_secondaryColor__2ENJ7,
.JobConfiguration_secondaryColor__2ENJ7:focus,
.JobConfiguration_secondaryColor__2ENJ7:active {
  background-color: #757575 !important;
  border-color: #757575 !important;
}

.JobConfiguration_secondaryColor__2ENJ7:hover {
  background-color: #919191 !important;
  border-color: #919191 !important;
}

.JobConfiguration_border__GqOV0 {
  border: 1px solid #c4c4c4;
}

.JobConfiguration_tableWrapper__3kFBd {
  min-height: calc(100vh - 365px);
}

.JobConfiguration_sectionHead__1w6oU {
  font-weight: bold;
  margin-bottom: 1rem;
}


.JobConfiguration_block3__3zDXc .JobConfiguration_jobTaskConfig__1IsDi {
  padding-bottom: 1rem;
}

.JobConfiguration_block3__3zDXc .JobConfiguration_jobTaskConfig__1IsDi .JobConfiguration_componentTitle__3DEWG {
  font-weight: 600;
  font-size: 16x;
  color: #212121;
}

.JobConfiguration_block3__3zDXc .JobConfiguration_jobTaskConfig__1IsDi .JobConfiguration_jobTaskConfigBody__3MLCa .JobConfiguration_jobTaskConfigTable__3JOHn thead {
  background-color: #edf0f5;
}

.JobConfiguration_block3__3zDXc .JobConfiguration_jobTaskConfig__1IsDi .JobConfiguration_jobTaskConfigBody__3MLCa .JobConfiguration_jobTaskConfigItem__1E2NR {
  display: flex;
  flex-direction: column;
}
.JobConfiguration_textTruncate__1s2Bb{
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
    width: 300px;
    cursor: pointer;
}
/* block 2 */
.LayoutSettings_block2__2B71E{
    padding-top: 20px;
    padding-bottom: 20px;
}
.LayoutSettings_block2__2B71E .LayoutSettings_pageSelectionText__1KwnA{
  
    text-align: left;
    color: #333333;
    padding-bottom: 17px;
}


.LayoutSettings_block2__2B71E .LayoutSettings_pageSelection__3CPdK  button:hover, .LayoutSettings_pageSelection__3CPdK  button:active{
    background-color: #ffff ;
    color: black;
    border: solid 1px #313131;

}
.LayoutSettings_block2__2B71E .LayoutSettings_pageSelection__3CPdK  button{
    background-color: #ffff !important;
    color: black !important;
    box-shadow: none !important;
    border: solid 1px #313131;
    border-radius: 2px;
    min-width: 230px;
    text-align: left;

}

.LayoutSettings_block2__2B71E .LayoutSettings_pageSelection__3CPdK  button::after {
    float: right !important;
    margin-top: 10px;
}
.LayoutSettings_block2__2B71E .LayoutSettings_pageSelection__3CPdK .LayoutSettings_dropdownItem__3qwY1{
    padding: 10px !important;
}
.LayoutSettings_block2__2B71E .LayoutSettings_pageSelection__3CPdK .LayoutSettings_dropdownItem__3qwY1:hover{
  color:#e91e63;
  background-color: #f9f9f9;
}

.LayoutSettings_block2__2B71E .LayoutSettings_pageSelection__3CPdK .LayoutSettings_dropdownWrapper__cwzKr  {
    width: 100% !important;
    border: none !important;
    box-shadow: 0px 3px 10px -4px #ccc
}

/* block 3 */
.LayoutSettings_block3__1QT3m{
    padding-top: 10px;
    margin-bottom: 10px;
}
.LayoutSettings_block3__1QT3m .LayoutSettings_layoutOptions__9-VUF{
    position: relative;
    justify-content: center;
    padding-top: 10px;
    padding-bottom: 10px;
}

.LayoutSettings_block3__1QT3m .LayoutSettings_layoutOptions__9-VUF .LayoutSettings_leftArrow__2jEvr{
  position: absolute;
  left: 0;
  top: 45%;
  display: inline-block;
  cursor: pointer;
}

.LayoutSettings_block3__1QT3m .LayoutSettings_layoutOptions__9-VUF .LayoutSettings_rightArrow__IyhSN{
    position: absolute;
    right: 0;
    top: 45%;
    display: inline-block;
    cursor: pointer;
}


.LayoutSettings_block3__1QT3m .LayoutSettings_layoutOptions__9-VUF .LayoutSettings_layoutOptionsWrapper__1JEuc{
    display: flex;
    margin-left: 25px;
    margin-right: 25px;
    justify-content: center;
}
.LayoutSettings_block3__1QT3m .LayoutSettings_layoutOptions__9-VUF .LayoutSettings_layoutsPreview__1zCEL .LayoutSettings_layoutsPreviewWrapper__39Pna{
    padding: 10px;
    border-top: 4px solid #ffffff;
    height: 100%;
}
.LayoutSettings_block3__1QT3m .LayoutSettings_layoutOptions__9-VUF .LayoutSettings_layoutsPreview__1zCEL .LayoutSettings_layoutsPreviewWrapper__39Pna:hover{
    box-shadow: 0px 0px 5px -1px #ccc;
    cursor: pointer;
}
.LayoutSettings_block3__1QT3m .LayoutSettings_layoutOptions__9-VUF .LayoutSettings_layoutsPreview__1zCEL .LayoutSettings_active__7Ergz{
    box-shadow: 0px 0px 5px -1px #ccc;
    cursor: pointer;
    border: 1px solid #ea3c64;
}
.LayoutSettings_block3__1QT3m .LayoutSettings_layoutOptions__9-VUF .LayoutSettings_layoutsPreviewWrapper__39Pna{
    position: relative;
    margin: 10px;
    
}
.LayoutSettings_block3__1QT3m .LayoutSettings_liveBtn__3kXaR{
    border: none;
    border-radius: 2px;
    text-transform: uppercase;
}
.LayoutSettings_block3__1QT3m .LayoutSettings_liveBtn__3kXaR a{
    color: white !important;
}

.LayoutSettings_block3__1QT3m .LayoutSettings_layoutOptions__9-VUF .LayoutSettings_layoutsPreviewWrapper__39Pna .LayoutSettings_layoutName__1-c_I{
    position: absolute;
    background-color: #f9f9f9;
    width: 100%;
    bottom: 0;
    padding-top: 3px;
    left: 0;
    right: 0;
    padding-bottom: 3px;
}
.LayoutSettings_block3__1QT3m .LayoutSettings_layoutOptions__9-VUF .LayoutSettings_livePreviewWrapper__1WLRq:hover .LayoutSettings_layoutImg__38uWP{
    background-color: #ccc;
    -webkit-filter: blur(5px);
    -moz-filter: blur(5px);
    -o-filter: blur(5px);
    -ms-filter: blur(5px);
    filter: blur(5px);
}
   
.LayoutSettings_block3__1QT3m .LayoutSettings_layoutOptions__9-VUF .LayoutSettings_livePreviewWrapper__1WLRq .LayoutSettings_livePreview__1fbpC{
    display:  none;
}
.LayoutSettings_block3__1QT3m .LayoutSettings_layoutOptions__9-VUF .LayoutSettings_livePreviewWrapper__1WLRq:hover .LayoutSettings_livePreview__1fbpC{
    display: flex;
    flex-direction: column;
    position: absolute;
    bottom: 50%;
    left: 0;
    right: 0;
    padding: 3px;
    z-index: 1;
    justify-content: center;
    align-items: center;
}
   


/* block 4 */

.LayoutSettings_block4__3Nx_G{
    padding-top: 10px;
    padding-bottom: 10px;
}

.LayoutSettings_block4__3Nx_G .LayoutSettings_selectedLayout__3p4cM {
    padding-top: 10px;
    justify-content: center;
    margin: 10px;
}

/**
 * Copyright(c) 2020 Mozanta Technologies Private Ltd.
 *
 * All rights reserved.
 *
 * This software is the confidential and proprietary information of Mozanta
 * ("Confidential Information"). You shall not disclose such Confidential
 * Information and shall use it only in accordance with the terms of the
 * contract agreement you entered into with Mozanta.
 *
 * PageCustomization.module.css
 *
 * @author Naseef O
 *
 */

.PageCustomization_block2__2p2HQ{
    padding-top: 20px;
    padding-bottom: 20px;
}
.PageCustomization_block2__2p2HQ .PageCustomization_pageSelectionText__2pWAZ{
  
    text-align: left;
    color: #333333;
    padding-bottom: 17px;
}


.PageCustomization_block2__2p2HQ .PageCustomization_pageSelection__2ualD  button:hover, .PageCustomization_pageSelection__2ualD  button:active{
    background-color: #ffff ;
    color: black;
    border: solid 1px #313131;

}
.PageCustomization_block2__2p2HQ .PageCustomization_pageSelection__2ualD  button{
    background-color: #ffff !important;
    color: black !important;
    box-shadow: none !important;
    border: solid 1px #313131;
    border-radius: 2px;
    min-width: 230px;
    text-align: left;

}

.PageCustomization_block2__2p2HQ .PageCustomization_pageSelection__2ualD  button::after {
    float: right !important;
    margin-top: 10px;
}
.PageCustomization_block2__2p2HQ .PageCustomization_pageSelection__2ualD .PageCustomization_dropdownItem__3qbTT{
    padding: 10px !important;
}
.PageCustomization_block2__2p2HQ .PageCustomization_pageSelection__2ualD .PageCustomization_dropdownItem__3qbTT:hover{
  color:#e91e63;
  background-color: #f9f9f9;
}

.PageCustomization_block2__2p2HQ .PageCustomization_pageSelection__2ualD .PageCustomization_dropdownWrapper__2sisG  {
    width: 100% !important;
    border: none !important;
    box-shadow: 0px 3px 10px -4px #ccc;
    height: 194px;
    overflow: auto;
}

.PageCustomization_block2__2p2HQ .PageCustomization_pagePreview__1kiTc .PageCustomization_pagePreviewText__2EvMy{
    padding-right: 20px;
    float: right;
    color: #333333;
    padding-bottom: 5px;
    
}
.PageCustomization_block2__2p2HQ .PageCustomization_pagePreview__1kiTc .PageCustomization_selectedPageImg__3aTMg {
    height: 100px;
    object-fit: cover;
    display: flex;
    justify-content:center;
    border: 1px solid #e91e63;
}
.PageCustomization_block2__2p2HQ .PageCustomization_pagePreview__1kiTc  {
    display: flex;
    justify-content:center;
}

.PageCustomization_block2__2p2HQ .PageCustomization_pagePreviewWrapper__OWWs8 .PageCustomization_clickMe__28A7F{
    display:  none;
}
.PageCustomization_block2__2p2HQ .PageCustomization_pagePreviewWrapper__OWWs8 {
    position: relative;
    display: flex;
    width: 73px;
    justify-content: center;
}
.PageCustomization_block2__2p2HQ .PageCustomization_pagePreviewWrapper__OWWs8:hover .PageCustomization_clickMe__28A7F{
    display: flex;
    flex-direction: column;
    position: absolute;
    bottom: 44%;
    width: 73px;
    padding: 3px;
    z-index: 1;
    justify-content: center;
    align-items: center;
    background: white;
    text-align: center;
    font-weight: 500;
}
.PageCustomization_liveBtnWrapper__341DW{
    display: flex;
    align-items: center;
    justify-content: flex-end;
}
 .PageCustomization_liveBtnWrapper__341DW button{
    font-size: 11px;
}
 .PageCustomization_liveBtn__1Ha-G{
  border: none;
  border-radius: 2px;
  text-transform: uppercase;
}
.PageCustomization_liveBtnPush__1REpQ{
    width: -webkit-max-content;
    width: -moz-max-content;
    width: max-content;
    border: none;
    border-radius: 2px;
    text-transform: uppercase;
  }

 .PageCustomization_liveBtn__1Ha-G a{
  color: white !important;
}

.PageCustomization_block2__2p2HQ .PageCustomization_customLandingToggle__2Pwfs{
  padding-top: 20px;
  padding-bottom: 20px;
}

.PageCustomization_block3__2etoP{
    padding-top: 20px;
    padding-bottom: 15px;
}
.PageCustomization_block3__2etoP .PageCustomization_buttonAdd__10fV_{
    color: #ffffff;
    width: 113px;
    height: 35px;
    border-radius: 2px;
    background-color: #e91e63;
    border: 0px;
    float: right;
}
.PageCustomization_block3__2etoP .PageCustomization_buttonCancel__1XPS_{
    width: 113px;
    height: 35px;
    color: white;
    border-radius: 2px;
    border: 0px;
    float: right;
    margin-right: 10px !important;
}

.PageCustomization_block3__2etoP .PageCustomization_pageWidgetsText__2MNaY{
    padding-right: 6px;
    float: right;
    color: #333333;
}

.PageCustomization_block3__2etoP .PageCustomization_widgetsTable__32IK0 {
    padding-top: 20px;
}

.PageCustomization_block3__2etoP .PageCustomization_widgetsTable__32IK0  thead{
    background-color: #edf0f5;
}

.PageCustomization_block3__2etoP .PageCustomization_widgetsTable__32IK0  thead th{
    font-size: 14px;
    font-weight: bold;
    text-align: left;
}

.PageCustomization_block3__2etoP .PageCustomization_widgetsTable__32IK0  tbody tr{
    text-align: left;
}

.PageCustomization_widgetContentEditorPreviewCol__Ahexf{
    justify-content: center;
    align-items: center;
    justify-items: center;
}
.PageCustomization_widgetContentEditorPreviewRow__mzOZ2{
    justify-content: center;
    height: 100%;
    align-items: center;
    background: #f9f9f9;
    justify-items: center;
    margin-right: 0px !important;
}
.PageCustomization_widgetContentEditorPreviewRow1___N-Tj{
    height: 73%;
}

.PageCustomization_tableWrapper__2yzvP{
  min-height: calc(100vh - 459px);
}

.PageCustomization_spinnerWrapper__2OrCC {
  text-align: center;
  color: #17a2b8;
}

.PageCustomization_contentInfo__jL95Z{
    background: #FF9800;
    text-align: center;
    display: flex;
    justify-content: center;
    color: white;
    border-radius: 2px;
    padding: 10px 5px;
}


/* SEO block */
.PageCustomization_seoText__3Osm2 {
    font-size: 14px;
    font-weight: 600;
}
.PageCustomization_seoText__3Osm2 .PageCustomization_value__7MvOA{
    font-weight: 500;
    padding-left: 10px;
    padding-top: 10px;
}
.PageCustomization_selectedCategory__2wH9A{
    font-size: 18px;
    font-weight: 500;
    color: #F44336;
    justify-content: center;
    align-items: center;
    display: flex;
}
/**
 * Copyright(c) 2020 Mozanta Technologies Private Ltd.
 * 
 * All rights reserved.                
 * 
 * This software is the confidential and proprietary information of Mozanta ("Confidential
 * Information"). You shall not disclose such Confidential Information and shall use it only in
 * accordance with the terms of the contract agreement you entered into with Mozanta.
 * 
 * @author Naseef O
 */


.CategorySelector_borderRadius2__3w2B6 {
  border-radius: 0.125rem !important;
}

.CategorySelector_secondaryColor__370dV {
  background-color: #757575 !important;
  border-color: #757575 !important;
}

.CategorySelector_primaryColor__1eb-Q {
  background: #17a2b8 !important;
  border-color: #17a2b8 !important;
}

.CategorySelector_btn__124FP {
  min-width: 7rem;
} 

/* ============= PRODUCT TITLE ========= */
.CategorySelector_titleTextContainer__mjyGE {
  padding: 0 2.1rem 0.125rem 2.1rem;
  border-bottom: 1px solid #edf0f5;
  margin: 0 -0.938rem;
}

/** ========= PARENT CATEGORY  ================ **/

.CategorySelector_dropdownMenu__F9GIt {
  max-height: 60vh;
  overflow-y: auto;
  width: 100%;
  background-color: #fff;
}

.CategorySelector_dropdownMenu__F9GIt::-webkit-scrollbar-track {
  background: #fff !important;
}

.CategorySelector_dropdown__3p9Iq > button {
  background: transparent !important;
  text-align: left !important;
  color: black !important;
  display: flex !important;
  align-items: center !important;
}

.CategorySelector_dropdown__3p9Iq > button .CategorySelector_clear__pS9ah {
  margin-left: auto;
  display: flex;
  padding: 0.125rem;
  border: 1px solid;
  border-radius: 2px;
  border-color: #b7b2b2;
}

.CategorySelector_dropdown__3p9Iq > button .CategorySelector_clear__pS9ah> img {
  width: 20px;
  height: 20px;
}


.CategorySelector_CategorySelector__29ltM  button::after {
  float: right !important;
  margin-top: 10px;
}
.CategorySelector_CategorySelector__29ltM .CategorySelector_dropdownItem__2gbG3{
  padding: 10px !important;
}
.CategorySelector_CategorySelector__29ltM .CategorySelector_dropdownItem__2gbG3:hover{
color:#e91e63;
background-color: #f9f9f9;
}

.CategorySelector_CategorySelector__29ltM .CategorySelector_dropdownWrapper__26yJs  {
  width: 100% !important;
  border: none !important;
  box-shadow: 0px 3px 10px -4px #ccc;
}



.CategorySelector_CategorySelector__29ltM  button:hover, .CategorySelector_CategorySelector__29ltM  button:active{
  background-color: #ffff ;
  color: black;
  border: solid 1px #313131;

}
.CategorySelector_CategorySelector__29ltM  button{
  background-color: #ffff !important;
  color: black !important;
  box-shadow: none !important;
  border: solid 1px #313131;
  border-radius: 2px;
  min-width: 230px;
  text-align: left;
  display: flex;
  justify-content: space-between;

}
.CategorySelector_imgWrapper__2_WI8{
  justify-content: space-between;
  display: contents;
}

/**
 * Copyright(c) 2020 Mozanta Technologies Private Ltd.
 *
 * All rights reserved.
 *
 * This software is the confidential and proprietary information of Mozanta
 * ("Confidential Information"). You shall not disclose such Confidential
 * Information and shall use it only in accordance with the terms of the
 * contract agreement you entered into with Mozanta.
 *
 * Table.module.css
 *
 * @author Naseef O
 *
 */


.Table_spinnerWrapper__2Hx6_{
	width: 1.5rem !important;
	height: 1.5rem !important;
	color: #17a2b8;
}



/**
 * Copyright(c) 2020 Mozanta Technologies Private Ltd.
 *
 * All rights reserved.
 *
 * This software is the confidential and proprietary information of Mozanta
 * ("Confidential Information"). You shall not disclose such Confidential
 * Information and shall use it only in accordance with the terms of the
 * contract agreement you entered into with Mozanta.
 *
 * ModifyCarousel.module.css
 *
 * @author Naseef O
 *
 */

.WidgetContentEditor_title__2kDY5{
    padding-top: 20px;   
    font-size: 20px;
    font-weight: bold;
    padding-bottom: 20px;   
}
.WidgetContentEditor_subTitle__RlQfX{
    font-size: 18px;
    font-weight: bold;
}

.WidgetContentEditor_widgetContentEditorPreviewCol__JM7GC{
    justify-content: center;
    align-items: center;
    justify-items: center;
}
.WidgetContentEditor_widgetContentEditorPreviewRow__3hz-h{
    justify-content: center;
    height: 100%;
    align-items: center;
    background: #f9f9f9;
    justify-items: center;
    margin-right: 0px !important;
}
.WidgetContentEditor_widgetContentEditorPreviewRow1__8_fVH{
    height: 73%;
}
.WidgetContentEditor_block2__jC_sL{
    justify-content: start;
    padding-top: 10px;
}
.WidgetContentEditor_block2__jC_sL .WidgetContentEditor_widgetContentEditorDropdownBtn__x_-4O button {
    width:100% !important;
}
.WidgetContentEditor_block2__jC_sL .WidgetContentEditor_widgetContentEditorDropdownMenu__3OsbO  {
    width:100% !important;
}
.WidgetContentEditor_block2__jC_sL .WidgetContentEditor_widgetContentEditorDropdownBtn__x_-4O  {
    padding: 0px;
}
.WidgetContentEditor_block3__3C-M1{
    justify-content: start;
    padding-top: 10px;
}
.WidgetContentEditor_block4__Ro-IG{
    display: flex;
    align-content: center;
    width: 100%;
    justify-content: flex-end;
}
.WidgetContentEditor_block4__Ro-IG .WidgetContentEditor_mediaError__3A4Wo{
    display: flex;
    align-content: center;
    width: 100%;
    justify-content: flex-end;
    font-size: 16px;
    color: #dc3545;
    font-weight: normal;
}


/**
 * Copyright(c) 2020 Mozanta Technologies Private Ltd.
 * 
 * All rights reserved.                
 * 
 * This software is the confidential and proprietary information of Mozanta ("Confidential
 * Information"). You shall not disclose such Confidential Information and shall use it only in
 * accordance with the terms of the contract agreement you entered into with Mozanta.
 * 
 * @author Naseef O
 */

.ImageSelection_uploadImagePreview__qsKid {
    min-width: 150px;
    min-height: 150px;
    max-width: 150px;
    max-height: 150px;
    position: relative;
    justify-content: center;
    align-items: center;
    background: #f9f9f9;
    justify-items: center;
    display:flex;
  }
.ImageSelection_uploadImagePreviewIcon__1kc76 {
    max-width: 150px;
    min-width: 150px;
}
.ImageSelection_uploadImagePreviewEdit__F2Nlw{
  display: none;
}
.ImageSelection_uploadImagePreviewClose__1Na49{
  display: none;
}
.ImageSelection_uploadImagePreview__qsKid:hover .ImageSelection_uploadImagePreviewClose__1Na49 {
  display: flex;
  position: absolute;
  top: 2px;
  right: 2px;
  padding: 0px 1px;
  cursor: pointer;
  background: white;
  border-radius: 50%;
}

.ImageSelection_uploadImagePreview__qsKid:last-child {
      margin-right: 0;
}


/**
 * Copyright(c) 2020 Mozanta Technologies Private Ltd.
 * 
 * All rights reserved.                
 * 
 * This software is the confidential and proprietary information of Mozanta ("Confidential
 * Information"). You shall not disclose such Confidential Information and shall use it only in
 * accordance with the terms of the contract agreement you entered into with Mozanta.
 * 
 * @author Naseef O
 */

.FeaturedProductsEditor_uploadImagePreview__Gbagu {
    min-width: 150px;
    min-height: 150px;
    max-width: 150px;
    max-height: 150px;
    position: relative;
    justify-content: center;
    align-items: center;
    background: #000000;
    justify-items: center;
    display:flex;
    
  }
.FeaturedProductsEditor_uploadImagePreviewIcon__2mgl0 {
    max-width: 150px;
    min-width: 150px;
}
.FeaturedProductsEditor_uploadImagePreviewEdit__255Ku{
  display: none;
}
.FeaturedProductsEditor_uploadImagePreviewClose__3sSKd{
  display: none;
}
.FeaturedProductsEditor_uploadImagePreview__Gbagu:hover .FeaturedProductsEditor_uploadImagePreviewClose__3sSKd {
  display: flex;
  position: absolute;
  top: 2px;
  right: 2px;
  padding: 0px 1px;
  cursor: pointer;
  background: white;
  border-radius: 50%;
}

.FeaturedProductsEditor_uploadImagePreview__Gbagu:last-child {
      margin-right: 0;
}
.FeaturedProductsEditor_uploadImagePreview__Gbagu .FeaturedProductsEditor_img__3ZTMO {
     object-fit: cover;
}


.FeaturedProductsEditor_labelImg__NQk5b{
    width: 30px;
    height: 30px;
    margin-right: 10px !important;
    margin-left: 5px !important;
}

.FeaturedProductsEditor_block2__Bc9sg .FeaturedProductsEditor_search__CC2H7 {
  border: 1px solid #229E79;
  border-radius: 0.125rem;
}

.FeaturedProductsEditor_block2__Bc9sg .FeaturedProductsEditor_search__CC2H7 input {
  border: 0;
  width: 100%;
}

.FeaturedProductsEditor_block2__Bc9sg .FeaturedProductsEditor_search__CC2H7 div {
  border: 0;
  border-radius: 0;
  background-color: #229E79;
  padding-left: 0.6rem;
  padding-right: 0.35rem;
}

.FeaturedProductsEditor_block2__Bc9sg img.FeaturedProductsEditor_icon__Tkpfb {
  width: 20px;
}
.FeaturedProductsEditor_block3__3VsGR .FeaturedProductsEditor_title__3ytrB{
  font-size: 20px;
  font-weight: bold;
}
/* block 1 */

.orderManagement_block1__3nDNf {
  padding-bottom: 10px;
}

/** =========== ACTION AREA STYLES ============== **/
.orderManagement_block1__3nDNf .orderManagement_areaActionRight__2Hmtc {
  display: flex;
  justify-content: flex-end;
}

.orderManagement_block1__3nDNf .orderManagement_borderRadius2__2E3Yp {
  border-radius: 0.125rem;
}

.orderManagement_block1__3nDNf .orderManagement_areaActionRight__2Hmtc > div:first-child {
  margin-right: 0.3rem;
}
.orderManagement_block2__1GHm3 .orderManagement_areaActionRight__2Hmtc > div:last-child {
  display: flex;
}

/** ========= SEARCH FILED ================ **/
.orderManagement_block1__3nDNf .orderManagement_areaActionRight__2Hmtc .orderManagement_search__315MV {
  border: 1px solid #DBDBDB;
  border-radius: 5px;
  height: 40px;
}

.orderManagement_block1__3nDNf .orderManagement_areaActionRight__2Hmtc .orderManagement_search__315MV input {
  border: 0;
  width: 100%;
  height: calc(1.5em + 0.75rem + 0px);
}

.orderManagement_block1__3nDNf .orderManagement_areaActionRight__2Hmtc .orderManagement_search__315MV input:focus, 
.orderManagement_block1__3nDNf .orderManagement_areaActionRight__2Hmtc .orderManagement_search__315MV input:active {
  border: 0;
  outline: 0;
  box-shadow: none;
}

.orderManagement_block1__3nDNf .orderManagement_areaActionRight__2Hmtc .orderManagement_search__315MV div {
  border: 0;
  border-radius: 0;
  background-color: inherit;
  padding-left: 0.6rem;
  padding-right: 0.6rem;
}

/* block2 */

.orderManagement_block2__1GHm3 button {
  background: none;
  color: black;
  border-radius: 5px;
  border: 1px solid #e0e0e0;
}

.orderManagement_block2__1GHm3 .orderManagement_dropdownBtn__ml6ss {
  padding: 0px;
}

.orderManagement_block2__1GHm3 button:after {
  border: solid #707070;
  border-width: 0 2px 2px 0;
  display: inline-block;
  padding: 2px;
  transform: rotate(45deg);
  -webkit-transform: rotate(45deg);
  margin-top: 8px;
}

.orderManagement_block2__1GHm3 .orderManagement_dropdownBtn__ml6ss button {
  float: right !important;
}
.orderManagement_tableWrapper__10JGq {
  min-height: calc(100vh - 342px);
}

.orderManagement_searchContainer__99w4u {
  display: flex;
  align-items: center;
}

/***** ===================== PAGINATION ======================= ******/
.orderManagement_pagination__oUfQI {
}

.orderManagement_pagination__oUfQI .orderManagement_item__cuCVP {
  height: 25px;
  width: 25px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}

.orderManagement_pagination__oUfQI .orderManagement_arrowLeft__28lYQ,
.orderManagement_pagination__oUfQI .orderManagement_arrowRight__1iH90 {
  border-radius: 50%;
  border: 2px solid;
}

.orderManagement_pagination__oUfQI .orderManagement_arrowLeft__28lYQ > span,
.orderManagement_pagination__oUfQI .orderManagement_arrowRight__1iH90 > span {
  border-bottom: 2px solid;
  border-right: 2px solid;
  width: 8px;
  height: 8px;
  margin-top: -0.5px;
}

.orderManagement_pagination__oUfQI .orderManagement_arrowLeft__28lYQ > span {
  -webkit-transform: rotate(127deg);
          transform: rotate(127deg);
  margin-right: -2.5px;
}

.orderManagement_pagination__oUfQI .orderManagement_arrowRight__1iH90 > span {
  -webkit-transform: rotate(315deg);
          transform: rotate(315deg);
  margin-left: -2.5px;
}

.orderManagement_pagination__oUfQI .orderManagement_arrowLeft__28lYQ,
.orderManagement_pagination__oUfQI .orderManagement_arrowRight__1iH90,
.orderManagement_pagination__oUfQI .orderManagement_item__cuCVP[active] {
  color: #e91e63;
  font-weight: 600;
}

.orderManagement_pagination__oUfQI .orderManagement_item__cuCVP[active] {
  cursor: default;
}

.orderManagement_pagination__oUfQI .orderManagement_item__cuCVP[disabled] {
  cursor: not-allowed;
  color: #5d5d5d;
}

.orderManagement_borderRadius2__2E3Yp {
  border-radius: 0.125rem !important;
}

.orderManagement_btnColor__2128t {
  background: #229E79 !important;
  border-color: #229E79 !important;
}

.orderManagement_block2__1GHm3 {
  display: flex;
  justify-content: center;
}

/**
 * Copyright(c) 2020 Mozanta Technologies Private Ltd.
 *
 * All rights reserved.
 *
 * This software is the confidential and proprietary information of Mozanta ("Confidential
 * Information"). You shall not disclose such Confidential Information and shall use it only in
 * accordance with the terms of the contract agreement you entered into with Mozanta.
 *
 * @author Amjad Rehman A
 */

.OrderDetails_borderRadius2__36VXh {
  border-radius: 0.125rem !important;
}

.OrderDetails_btnColor__1v3-v{
  background: #229E79 !important;
  border-color: #229E79 !important;
  min-width: 50%;
}

.OrderDetails_block1__2Zs3M{
  min-height: -webkit-fill-available;
}

.OrderDetails_block2__36Agn{
  display: flex;
  justify-content: center;
}

/**
 * Copyright(c) 2020 Mozanta Technologies Private Ltd.
 *
 * All rights reserved.
 *
 * This software is the confidential and proprietary information of Mozanta
 * ("Confidential Information"). You shall not disclose such Confidential
 * Information and shall use it only in accordance with the terms of the
 * contract agreement you entered into with Mozanta.
 *
 * OrderReport.module.css
 *
 * @author Naseef O
 *
 */

.OrderReport_block1__2o402 .OrderReport_buttonNewUser__24l05{
  color: #ffffff;
  width: 113px;
  border-radius: 2px;
  background-color: #e91e63;
  border: 0px;
  float: right;
}


/* block 2 */

.OrderReport_tableWrapper__3h8Xv  {
  padding-bottom: 20px;
    background: #ffffff;
    margin: 0px !important;
    min-height: calc(100vh - 408px);
    margin-bottom: 10px !important;
}

.OrderReport_block2__Hmit9 .OrderReport_dropdownBtn__3tRCL {
  padding-right: 0px;
}
  

/* block 3 */

.OrderReport_block3__1vmBq .OrderReport_dropdownBtn__3tRCL{
  padding: 0px;
}

.OrderReport_block3__1vmBq .OrderReport_dropdownBtn__3tRCL button{
  float: right !important;
  font-size: 11px;
  min-width: 100px;
}
.OrderReport_block3__1vmBq  .OrderReport_dateFilterRow__1ReaY{
  border-radius: 4px;
  border: 1px solid #dff3f7;
  background-color: #fdfdfd;
  color: black;
}
.OrderReport_block3__1vmBq .OrderReport_verticalLine__2yw7x {
  height: 15px;
  padding: 5px;
}
.OrderReport_block3__1vmBq .OrderReport_verticalLineNone__I0Xua {
  padding: 5px;
  border-radius: 4px;

}
.OrderReport_block3__1vmBq .OrderReport_verticalLine__2yw7x:hover {
  background-color: #17a2b8;
  border-radius: 4px;
  color: rgb(255, 255, 255);
}
.OrderReport_block3__1vmBq .OrderReport_verticalLineNone__I0Xua:hover {
  background-color: #17a2b8;
  border-radius: 4px;
  color: rgb(255, 255, 255);
}
/* block 1 */

.BatchManagement_block1__2dgwZ {
    padding-bottom: 10px;
  }
  
  /** =========== ACTION AREA STYLES ============== **/
  .BatchManagement_block1__2dgwZ .BatchManagement_areaActionRight__3goNF {
    display: flex;
    justify-content: flex-end;
  }
  
  .BatchManagement_block1__2dgwZ .BatchManagement_borderRadius2__3q_5- {
    border-radius: 0.125rem;
  }
  
  .BatchManagement_block1__2dgwZ .BatchManagement_areaActionRight__3goNF > div:first-child {
    margin-right: 0.3rem;
  }
  .BatchManagement_block2__3EmZX .BatchManagement_areaActionRight__3goNF > div:last-child {
    display: flex;
  }
  
  /** ========= SEARCH FILED ================ **/
  .BatchManagement_block1__2dgwZ .BatchManagement_areaActionRight__3goNF .BatchManagement_search__47BDa {
    border: 1px solid #DBDBDB;
    border-radius: 5px;
    height: 40px;
  }
  
  .BatchManagement_block1__2dgwZ .BatchManagement_areaActionRight__3goNF .BatchManagement_search__47BDa input {
    border: 0;
    width: 100%;
    height: calc(1.5em + 0.75rem + 0px);
  }
  
  .BatchManagement_block1__2dgwZ .BatchManagement_areaActionRight__3goNF .BatchManagement_search__47BDa input:focus, 
  .BatchManagement_block1__2dgwZ .BatchManagement_areaActionRight__3goNF .BatchManagement_search__47BDa input:active {
    border: 0;
    outline: 0;
    box-shadow: none;
  }
  
  .BatchManagement_block1__2dgwZ .BatchManagement_areaActionRight__3goNF .BatchManagement_search__47BDa div {
    border: 0;
    border-radius: 0;
    background-color: inherit;
    padding-left: 0.6rem;
    padding-right: 0.6rem;
  }
  
  /* block2 */
  
  .BatchManagement_block2__3EmZX button {
    background: none;
    color: black;
    border-radius: 5px;
    border: 1px solid #e0e0e0;
  }
  
  .BatchManagement_block2__3EmZX .BatchManagement_dropdownBtn__2PxmZ {
    padding: 0px;
  }
  
  .BatchManagement_block2__3EmZX button:after {
    border: solid #707070;
    border-width: 0 2px 2px 0;
    display: inline-block;
    padding: 2px;
    transform: rotate(45deg);
    -webkit-transform: rotate(45deg);
    margin-top: 8px;
  }
  
  .BatchManagement_block2__3EmZX .BatchManagement_dropdownBtn__2PxmZ button {
    float: right !important;
  }
  .BatchManagement_tableWrapper__1ZhUo {
    min-height: calc(100vh - 342px);
  }
  
  .BatchManagement_searchContainer__3wwD_ {
    display: flex;
    align-items: center;
  }
  
  /***** ===================== PAGINATION ======================= ******/
  .BatchManagement_pagination__1u-cA {
  }
  
  .BatchManagement_pagination__1u-cA .BatchManagement_item__1woFZ {
    height: 25px;
    width: 25px;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
  }
  
  .BatchManagement_pagination__1u-cA .BatchManagement_arrowLeft__2Q8Vc,
  .BatchManagement_pagination__1u-cA .BatchManagement_arrowRight__1ghHF {
    border-radius: 50%;
    border: 2px solid;
  }
  
  .BatchManagement_pagination__1u-cA .BatchManagement_arrowLeft__2Q8Vc > span,
  .BatchManagement_pagination__1u-cA .BatchManagement_arrowRight__1ghHF > span {
    border-bottom: 2px solid;
    border-right: 2px solid;
    width: 8px;
    height: 8px;
    margin-top: -0.5px;
  }
  
  .BatchManagement_pagination__1u-cA .BatchManagement_arrowLeft__2Q8Vc > span {
    -webkit-transform: rotate(127deg);
            transform: rotate(127deg);
    margin-right: -2.5px;
  }
  
  .BatchManagement_pagination__1u-cA .BatchManagement_arrowRight__1ghHF > span {
    -webkit-transform: rotate(315deg);
            transform: rotate(315deg);
    margin-left: -2.5px;
  }
  
  .BatchManagement_pagination__1u-cA .BatchManagement_arrowLeft__2Q8Vc,
  .BatchManagement_pagination__1u-cA .BatchManagement_arrowRight__1ghHF,
  .BatchManagement_pagination__1u-cA .BatchManagement_item__1woFZ[active] {
    color: #e91e63;
    font-weight: 600;
  }
  
  .BatchManagement_pagination__1u-cA .BatchManagement_item__1woFZ[active] {
    cursor: default;
  }
  
  .BatchManagement_pagination__1u-cA .BatchManagement_item__1woFZ[disabled] {
    cursor: not-allowed;
    color: #5d5d5d;
  }
  
  .BatchManagement_borderRadius2__3q_5- {
    border-radius: 0.125rem !important;
  }
  
  .BatchManagement_btnColor__3EoiT {
    background: #229E79 !important;
    border-color: #229E79 !important;
  }
  
  .BatchManagement_block2__3EmZX {
    display: flex;
    justify-content: center;
  }
/* block 1 */

.CustomerAuditManagement_block1__2rafH {
    padding-bottom: 10px;
  }
  
  /** =========== ACTION AREA STYLES ============== **/
  .CustomerAuditManagement_block1__2rafH .CustomerAuditManagement_areaActionRight__FrxZO {
    display: flex;
    justify-content: flex-end;
  }
  
  .CustomerAuditManagement_block1__2rafH .CustomerAuditManagement_borderRadius2__2KD4n {
    border-radius: 0.125rem;
  }
  
  .CustomerAuditManagement_block1__2rafH .CustomerAuditManagement_areaActionRight__FrxZO > div:first-child {
    margin-right: 0.3rem;
  }
  .CustomerAuditManagement_block2__1NAqh .CustomerAuditManagement_areaActionRight__FrxZO > div:last-child {
    display: flex;
  }
  
  /** ========= SEARCH FILED ================ **/
  .CustomerAuditManagement_block1__2rafH .CustomerAuditManagement_areaActionRight__FrxZO .CustomerAuditManagement_search__3PL6R {
    border: 1px solid #DBDBDB;
    border-radius: 5px;
    height: 40px;
  }
  
  .CustomerAuditManagement_block1__2rafH .CustomerAuditManagement_areaActionRight__FrxZO .CustomerAuditManagement_search__3PL6R input {
    border: 0;
    width: 100%;
    height: calc(1.5em + 0.75rem + 0px);
  }
  
  .CustomerAuditManagement_block1__2rafH .CustomerAuditManagement_areaActionRight__FrxZO .CustomerAuditManagement_search__3PL6R input:focus, 
  .CustomerAuditManagement_block1__2rafH .CustomerAuditManagement_areaActionRight__FrxZO .CustomerAuditManagement_search__3PL6R input:active {
    border: 0;
    outline: 0;
    box-shadow: none;
  }
  
  .CustomerAuditManagement_block1__2rafH .CustomerAuditManagement_areaActionRight__FrxZO .CustomerAuditManagement_search__3PL6R div {
    border: 0;
    border-radius: 0;
    background-color: inherit;
    padding-left: 0.6rem;
    padding-right: 0.6rem;
  }
  
  /* block2 */
  
  .CustomerAuditManagement_block2__1NAqh button {
    background: none;
    color: black;
    border-radius: 5px;
    border: 1px solid #e0e0e0;
  }
  
  .CustomerAuditManagement_block2__1NAqh .CustomerAuditManagement_dropdownBtn__1QAFN {
    padding: 0px;
  }
  
  .CustomerAuditManagement_block2__1NAqh button:after {
    border: solid #707070;
    border-width: 0 2px 2px 0;
    display: inline-block;
    padding: 2px;
    transform: rotate(45deg);
    -webkit-transform: rotate(45deg);
    margin-top: 8px;
  }
  
  .CustomerAuditManagement_block2__1NAqh .CustomerAuditManagement_dropdownBtn__1QAFN button {
    float: right !important;
  }
  .CustomerAuditManagement_tableWrapper__1w6b2 {
    min-height: calc(100vh - 342px);
  }
  
  .CustomerAuditManagement_searchContainer__3in1u {
    display: flex;
    align-items: center;
  }
  
  /***** ===================== PAGINATION ======================= ******/
  .CustomerAuditManagement_pagination__2N4YM {
  }
  
  .CustomerAuditManagement_pagination__2N4YM .CustomerAuditManagement_item__1DjmM {
    height: 25px;
    width: 25px;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
  }
  
  .CustomerAuditManagement_pagination__2N4YM .CustomerAuditManagement_arrowLeft__3TEIB,
  .CustomerAuditManagement_pagination__2N4YM .CustomerAuditManagement_arrowRight__1L9j0 {
    border-radius: 50%;
    border: 2px solid;
  }
  
  .CustomerAuditManagement_pagination__2N4YM .CustomerAuditManagement_arrowLeft__3TEIB > span,
  .CustomerAuditManagement_pagination__2N4YM .CustomerAuditManagement_arrowRight__1L9j0 > span {
    border-bottom: 2px solid;
    border-right: 2px solid;
    width: 8px;
    height: 8px;
    margin-top: -0.5px;
  }
  
  .CustomerAuditManagement_pagination__2N4YM .CustomerAuditManagement_arrowLeft__3TEIB > span {
    -webkit-transform: rotate(127deg);
            transform: rotate(127deg);
    margin-right: -2.5px;
  }
  
  .CustomerAuditManagement_pagination__2N4YM .CustomerAuditManagement_arrowRight__1L9j0 > span {
    -webkit-transform: rotate(315deg);
            transform: rotate(315deg);
    margin-left: -2.5px;
  }
  
  .CustomerAuditManagement_pagination__2N4YM .CustomerAuditManagement_arrowLeft__3TEIB,
  .CustomerAuditManagement_pagination__2N4YM .CustomerAuditManagement_arrowRight__1L9j0,
  .CustomerAuditManagement_pagination__2N4YM .CustomerAuditManagement_item__1DjmM[active] {
    color: #e91e63;
    font-weight: 600;
  }
  
  .CustomerAuditManagement_pagination__2N4YM .CustomerAuditManagement_item__1DjmM[active] {
    cursor: default;
  }
  
  .CustomerAuditManagement_pagination__2N4YM .CustomerAuditManagement_item__1DjmM[disabled] {
    cursor: not-allowed;
    color: #5d5d5d;
  }
  
  .CustomerAuditManagement_borderRadius2__2KD4n {
    border-radius: 0.125rem !important;
  }
  
  .CustomerAuditManagement_btnColor__T9uJl {
    background: #229E79 !important;
    border-color: #229E79 !important;
  }
  
  .CustomerAuditManagement_block2__1NAqh {
    display: flex;
    justify-content: center;
  }
/**
 * Copyright(c) 2020 Mozanta Technologies Private Ltd.
 * 
 * All rights reserved.                
 * 
 * This software is the confidential and proprietary information of Mozanta ("Confidential
 * Information"). You shall not disclose such Confidential Information and shall use it only in
 * accordance with the terms of the contract agreement you entered into with Mozanta.
 * 
 * @author Naseef O
 */


.CombinedWidgets_block2__2XqQR .CombinedWidgets_search__3_nCe {
    border: 1px solid #229E79;
    border-radius: 0.125rem;
  }
  
  .CombinedWidgets_block2__2XqQR .CombinedWidgets_search__3_nCe input {
    border: 0;
    width: 100%;
  }
  
  .CombinedWidgets_block2__2XqQR .CombinedWidgets_search__3_nCe div {
    border: 0;
    border-radius: 0;
    background-color: #229E79;
    padding-left: 0.6rem;
    padding-right: 0.35rem;
  }
  
  .CombinedWidgets_block2__2XqQR img.CombinedWidgets_icon__ps0N7 {
    width: 20px;
  }
  .CombinedWidgets_labelImg__2SLHF{
    width: 30px;
    height: 30px;
    margin-right: 10px !important;
    margin-left: 5px !important;
}
.CombinedWidgets_block3__3xAxf{
  min-height: calc(100vh - 420px); 
}

.CombinedWidgets_block3__3xAxf .CombinedWidgets_categorySelector__3voLy{
  height: 350px;
  overflow: scroll;
}
/**
 * Copyright(c) 2020 Mozanta Technologies Private Ltd.
 *
 * All rights reserved.
 *
 * This software is the confidential and proprietary information of Mozanta
 * ("Confidential Information"). You shall not disclose such Confidential
 * Information and shall use it only in accordance with the terms of the
 * contract agreement you entered into with Mozanta.
 *
 * @author Hima-Mohan
 */

.PageMetaTags_borderRadius2__2k-FE {
  border-radius: 0.125rem;
}

.PageMetaTags_secondaryColor__frDhr {
  background-color: #757575 !important;
  border-color: #757575 !important;
}

.PageMetaTags_primaryColor__1lzI3 {
  background: #e91e63 !important;
  border-color: #e91e63 !important;
}

.PageMetaTags_btn1__1D8S8 {
  min-width: 6rem;
}

.PageMetaTags_btn2__3h2DN {
  min-width: 9rem;
}

.PageMetaTags_btnRule__2Ubty{
   background-color: white !important;
  color:black!important;
  border-color: #b4bfca !important;
  min-width: 6rem;
}

.PageMetaTags_btnAdd__1BpMM{
  background-color:#dadada !important; 
  color:black!important;
  border-color: #808284!important;
  min-width: 7rem;
}

.PageMetaTags_badge__2dzhq{
  background-color:#dadada !important;  
  min-width: 7rem;
  min-height: 2.25rem;
  border-color: #808284!important;
}

.PageMetaTags_badgeTag__gys3c{
  font-size: 14px;
}

.PageMetaTags_quesIcon__3lOhw{
  background-color:#ced4da !important;  
  min-width: 2.25rem;
  border-color: #808284!important;
}

.PageMetaTags_tabLink__b19TJ{
  cursor: pointer;
}


/**
 * Copyright(c) 2020 Mozanta Technologies Private Ltd.
 *
 * All rights reserved.
 *
 * This software is the confidential and proprietary information of Mozanta ("Confidential
 * Information"). You shall not disclose such Confidential Information and shall use it only in
 * accordance with the terms of the contract agreement you entered into with Mozanta.
 *
 * @author Angela
 */

.PaymentClasses_borderRadius2__2Y78V {
  border-radius: 0.125rem !important;
}

/** =========== ACTION AREA STYLES ============== **/
.PaymentClasses_areaActionRight__1UjKc {
  display: flex;
  justify-content: flex-end;
}

.PaymentClasses_secondaryColor__1m3CK,
.PaymentClasses_secondaryColor__1m3CK:focus,
.PaymentClasses_secondaryColor__1m3CK:active {
  background-color: #757575 !important;
  border-color: #757575 !important;
}

.PaymentClasses_tableWrapper__3GZFn{
  min-height: calc(100vh - 365px);
}

/**
 * Copyright(c) 2020 Mozanta Technologies Private Ltd.
 *
 * All rights reserved.
 *
 * This software is the confidential and proprietary information of Mozanta
 * ("Confidential Information"). You shall not disclose such Confidential
 * Information and shall use it only in accordance with the terms of the
 * contract agreement you entered into with Mozanta.
 *
 * PermissionManagement.module.css
 *
 * @author Naseef O
 *
 */



/* block 2 */

.PermissionManagement_block2__LgXbT{
    padding-top: 20px;
    padding-bottom: 20px;
}

.PermissionManagement_block2__LgXbT .PermissionManagement_buttonAdd__2rJYc{
  color: #ffffff;
  width: 113px;
  height: 35px;
  border-radius: 2px;
  background-color: #e91e63;
  border: 0px;
  float: right;
}

.PermissionManagement_tableWrapper__1rWYe{
  min-height: calc(100vh - 373px);
}
.PriceBookManagement_liveBtnWrapper__34fE- .PriceBookManagement_liveBtn__2p11Z{
  border: none;
  border-radius: 2px;
  text-transform: uppercase;
}

/* block 1 */

.PriceBookManagement_block1__XLjxY {
  padding-top: 20px;
  padding-bottom: 20px;
}
.PriceBookManagement_block1__XLjxY .PriceBookManagement_navigationManagementnText__pmwnq {
  text-align: left;
  color: #333333;
  padding-bottom: 17px;
}

.PriceBookManagement_block1__XLjxY .PriceBookManagement_buttonAddMenu__2Wsc5 {
  color: #ffffff;
  width: 113px;
  height: 35px;
  border-radius: 2px;
  background-color: #448aff;
  border: 0px;
  float: right;
}

.PriceBookManagement_tableWrapper__URFxi {
  min-height: calc(100vh - 385px);
}

.PriceBookManagement_borderRadius2__7oqnt {
  border-radius: 0.125rem !important;
}

.PriceBookManagement_rightButton__3Lr1C {
  display: flex;
  align-content: flex-end;
  align-items: flex-end;
}

/***** ===================== PAGINATION ======================= ******/
.PriceBookManagement_pagination__v4hyD { }
  
.PriceBookManagement_pagination__v4hyD .PriceBookManagement_item__Pjjtf {
  height: 25px;
  width: 25px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}

.PriceBookManagement_pagination__v4hyD .PriceBookManagement_arrowLeft__2hZDz, .PriceBookManagement_pagination__v4hyD .PriceBookManagement_arrowRight__3zd6A {
  border-radius: 50%;
  border: 2px solid;
}

.PriceBookManagement_pagination__v4hyD .PriceBookManagement_arrowLeft__2hZDz > span, .PriceBookManagement_pagination__v4hyD .PriceBookManagement_arrowRight__3zd6A > span {
  border-bottom: 2px solid;
  border-right: 2px solid;
  width: 8px;
  height: 8px; 
  margin-top: -0.5px;
}

.PriceBookManagement_pagination__v4hyD .PriceBookManagement_arrowLeft__2hZDz > span{ 
  -webkit-transform: rotate(127deg); 
          transform: rotate(127deg); 
  margin-right: -2.5px;
}

.PriceBookManagement_pagination__v4hyD .PriceBookManagement_arrowRight__3zd6A > span{ 
  -webkit-transform: rotate(315deg); 
          transform: rotate(315deg); 
  margin-left: -2.5px;
}

.PriceBookManagement_pagination__v4hyD .PriceBookManagement_arrowLeft__2hZDz , .PriceBookManagement_pagination__v4hyD .PriceBookManagement_arrowRight__3zd6A, .PriceBookManagement_pagination__v4hyD .PriceBookManagement_item__Pjjtf[active] {
  color: #e91e63;
  font-weight: 600;
}

.PriceBookManagement_pagination__v4hyD .PriceBookManagement_item__Pjjtf[active] {
  cursor: default;
}

.PriceBookManagement_pagination__v4hyD .PriceBookManagement_item__Pjjtf[disabled] {
  cursor: not-allowed;
  color: #5d5d5d;
}

  /** =========== ACTION AREA STYLES ============== **/
  .PriceBookManagement_block1__XLjxY .PriceBookManagement_areaActionRight__1vcEx {
    display: flex;
    justify-content: flex-end;
  }
  
  .PriceBookManagement_block1__XLjxY .PriceBookManagement_borderRadius2__7oqnt {
    border-radius: 0.125rem;
  }
  
  .PriceBookManagement_block1__XLjxY .PriceBookManagement_areaActionRight__1vcEx > div:first-child {
    margin-right: 0.3rem;
  }
  .PriceBookManagement_block2__1qf9l .PriceBookManagement_areaActionRight__1vcEx > div:last-child {
    display: flex;
  }
  
  /** ========= SEARCH FILED ================ **/
  .PriceBookManagement_block1__XLjxY .PriceBookManagement_areaActionRight__1vcEx .PriceBookManagement_search__1lL2P {
    border: 1px solid #ced4da;
    border-bottom-left-radius: 0.25rem;
    border-top-left-radius: 0.25rem;
    
  }
  
  .PriceBookManagement_block1__XLjxY .PriceBookManagement_areaActionRight__1vcEx .PriceBookManagement_search__1lL2P input {
    border: 0;
    width: 100%;
    height: calc(1.5em + 0.75rem + 0px);
  }
  
  .PriceBookManagement_block1__XLjxY .PriceBookManagement_areaActionRight__1vcEx .PriceBookManagement_search__1lL2P div {
    border: 0;
    border-radius: 0;
    background-color: inherit;
    padding-left: 0.6rem;
    padding-right: 0.35rem;
  }
  
  .PriceBookManagement_searchContainer__1zM_D{
    display: flex;
    align-items: flex-end;
    margin: 0 0 1rem 0.3rem;
  }

  .PriceBookManagement_block2__1qf9l {
    padding-top: 20px;
    padding-bottom: 20px;
  }
  
  .PriceBookManagement_block2__1qf9l button {
    background: none;
    color: black;
    border-radius: 2px;
    border: 1px solid #e0e0e0;
  }
  
  .PriceBookManagement_block2__1qf9l .PriceBookManagement_dropdownBtn__2SA8w {
    padding: 0px;
  }
  
  .PriceBookManagement_block2__1qf9l .PriceBookManagement_dropdownBtn__2SA8w button {
    float: right !important;
  }
/**
 * Copyright(c) 2020 Mozanta Technologies Private Ltd.
 * 
 * All rights reserved.                
 * 
 * This software is the confidential and proprietary information of Mozanta ("Confidential
 * Information"). You shall not disclose such Confidential Information and shall use it only in
 * accordance with the terms of the contract agreement you entered into with Mozanta.
 * 
 * @author Indrajith C
 */

 .ProductManagement_liveBtnWrapper__1gS9U .ProductManagement_liveBtn__12usH{
  border: none;
  border-radius: 2px;
  text-transform: uppercase;
}

 .ProductManagement_block2__2bBGF {
    padding-top: 20px;
    padding-bottom: 20px;
  }
  
.ProductManagement_borderRadius2__1vSbh {
  border-radius: 0.125rem !important;
}

  /** =========== ACTION AREA STYLES ============== **/
  .ProductManagement_block2__2bBGF .ProductManagement_areaActionRight__PwN58 {
    display: flex;
    justify-content: flex-end;
  }
  
  .ProductManagement_block2__2bBGF .ProductManagement_areaActionRight__PwN58 > div:first-child {
    margin-right: 0.3rem;
  }
  .ProductManagement_block2__2bBGF .ProductManagement_areaActionRight__PwN58 > div:last-child {
    display: flex;
  }
  
  /** ========= SEARCH FILED ================ **/
  .ProductManagement_block2__2bBGF .ProductManagement_areaActionRight__PwN58 .ProductManagement_search__2srzx {
    border: 1px solid #ced4da;
    border-bottom-left-radius: 0.25rem;
    border-top-left-radius: 0.25rem;
  }
  
  .ProductManagement_block2__2bBGF .ProductManagement_areaActionRight__PwN58 .ProductManagement_search__2srzx input {
    border: 0;
    width: 100%;
    height: calc(1.5em + 0.75rem + 0px);
  }
  
  .ProductManagement_block2__2bBGF .ProductManagement_areaActionRight__PwN58 .ProductManagement_search__2srzx div {
    border: 0;
    border-radius: 0;
    background-color: inherit;
    padding-left: 0.6rem;
    padding-right: 0.35rem;
  }
  
  /***** ========== MAIN ACTION AREA ============ **********/
  .ProductManagement_block3__2UO6L {
    padding: 1.25rem 0;
  }
  
  /***** ============== MAIN ACTION COMPONENT ========= *******/
  .ProductManagement_block3__2UO6L .ProductManagement_product__1hQMS {
    position: relative;
  }
  
  /***** ============== ACTION COMPONENT BODY ========= *******/
  
  .ProductManagement_block3__2UO6L .ProductManagement_product__1hQMS .ProductManagement_productBody__3jiPb .ProductManagement_productTable__2tDq8 thead {
    background-color: #edf0f5;
  }
  
  .ProductManagement_block3__2UO6L .ProductManagement_product__1hQMS .ProductManagement_productBody__3jiPb .ProductManagement_productItem__1GYBU {
    display: flex;
    flex-direction: column;
  }

  .ProductManagement_block3__2UO6L .ProductManagement_product__1hQMS .ProductManagement_productBody__3jiPb .ProductManagement_productTable__2tDq8 .ProductManagement_productName__n0WRf {
    max-width: 100px;
  }

  .ProductManagement_block3__2UO6L .ProductManagement_product__1hQMS .ProductManagement_productBody__3jiPb .ProductManagement_productTable__2tDq8 .ProductManagement_productImage__3Bqwo {
    max-height: 50px;
  }

  /***** ===================== PAGINATION ======================= ******/
  .ProductManagement_block3__2UO6L .ProductManagement_product__1hQMS .ProductManagement_pagination__3_Zsp { }
  
  .ProductManagement_block3__2UO6L .ProductManagement_product__1hQMS .ProductManagement_pagination__3_Zsp .ProductManagement_item__3bPK1 {
    height: 25px;
    width: 25px;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
  }

  .ProductManagement_block3__2UO6L .ProductManagement_product__1hQMS .ProductManagement_pagination__3_Zsp .ProductManagement_arrowLeft__3V8Ca, .ProductManagement_block3__2UO6L .ProductManagement_product__1hQMS .ProductManagement_pagination__3_Zsp .ProductManagement_arrowRight__3Giqn {
    border-radius: 50%;
    border: 2px solid;
  }

  .ProductManagement_block3__2UO6L .ProductManagement_product__1hQMS .ProductManagement_pagination__3_Zsp .ProductManagement_arrowLeft__3V8Ca > span, .ProductManagement_block3__2UO6L .ProductManagement_product__1hQMS .ProductManagement_pagination__3_Zsp .ProductManagement_arrowRight__3Giqn > span {
    border-bottom: 2px solid;
    border-right: 2px solid;
    width: 8px;
    height: 8px; 
    margin-top: -0.5px;
  }

  .ProductManagement_block3__2UO6L .ProductManagement_product__1hQMS .ProductManagement_pagination__3_Zsp .ProductManagement_arrowLeft__3V8Ca > span{ 
    -webkit-transform: rotate(127deg); 
            transform: rotate(127deg); 
    margin-right: -2.5px;
  }

  .ProductManagement_block3__2UO6L .ProductManagement_product__1hQMS .ProductManagement_pagination__3_Zsp .ProductManagement_arrowRight__3Giqn > span{ 
    -webkit-transform: rotate(315deg); 
            transform: rotate(315deg); 
    margin-left: -2.5px;
  }

  .ProductManagement_block3__2UO6L .ProductManagement_product__1hQMS .ProductManagement_pagination__3_Zsp .ProductManagement_arrowLeft__3V8Ca , .ProductManagement_block3__2UO6L .ProductManagement_product__1hQMS .ProductManagement_pagination__3_Zsp .ProductManagement_arrowRight__3Giqn, .ProductManagement_block3__2UO6L .ProductManagement_product__1hQMS .ProductManagement_pagination__3_Zsp .ProductManagement_item__3bPK1[active] {
    color: #e91e63;
    font-weight: 600;
  }

  .ProductManagement_block3__2UO6L .ProductManagement_product__1hQMS .ProductManagement_pagination__3_Zsp .ProductManagement_item__3bPK1[active] {
    cursor: default;
  }

  .ProductManagement_block3__2UO6L .ProductManagement_product__1hQMS .ProductManagement_pagination__3_Zsp .ProductManagement_item__3bPK1[disabled] {
    cursor: not-allowed;
    color: #5d5d5d;
  }
  .ProductManagement_tableWrapper__2eJXN{
    min-height: calc(100vh - 387px);
  }
/**
 * Copyright(c) 2020 Mozanta Technologies Private Ltd.
 * 
 * All rights reserved.                
 * 
 * This software is the confidential and proprietary information of Mozanta ("Confidential
 * Information"). You shall not disclose such Confidential Information and shall use it only in
 * accordance with the terms of the contract agreement you entered into with Mozanta.
 * 
 * @author Indrajith C
 */

 .ProductForm_block2__24GF7 {
    padding-top: 20px;
    padding-bottom: 20px;
  } 
  
  .ProductForm_borderRadius2__1952o {
    border-radius: 0.125rem !important;
  }
  
  .ProductForm_secondaryColor__XU5Gd {
    background-color: #757575 !important;
    border-color: #757575 !important;
  }
  
  .ProductForm_primaryColor__2WbTQ {
    background: #e91e63 !important;
    border-color: #e91e63 !important;
  }
  
  .ProductForm_btn__1eP6h {
    min-width: 7rem;
  }
  
  .ProductForm_icon__27pA3 {
    width: 1.25rem;
  }
  
  /* ========== FORM AREA ============ */
  .ProductForm_block3__2s86Q {
    padding: 1.25rem;
  }

  .ProductForm_block3__2s86Q .ProductForm_noLabelFormGroup__1fZBK {
    margin-top: 1.75rem;
    padding: 0.375rem 0.75rem;
  }

  .ProductForm_block3__2s86Q .ProductForm_variantTable__rN9Lx thead {
    background-color: #edf0f5;
  }
  
  .ProductForm_block3__2s86Q .ProductForm_variantTable__rN9Lx tbody .ProductForm_productImage__25r1c {
    max-height: 50px;
  }

  .ProductForm_block3__2s86Q .ProductForm_variantTable__rN9Lx tbody .ProductForm_mw75__3FYkB {
     max-width: 75px;
  }
 


  /* ============= PRODUCT TITLE ========= */
  .ProductForm_titleTextContainer__4nINn {
    padding: 0 2.1rem 0.125rem 2.1rem;
    margin: 0 -0.938rem;
  }
  
  .ProductForm_titleTextContainer__4nINn .ProductForm_pageTitleText__26csQ{
    text-align: left;
    color: #333333;
    padding-bottom: 17px;
  }

  .ProductForm_collapseIcon__QPPZm > img {
    width: 15px;
    height: 15px;
    -webkit-transition: 0.6s ease-out;
    transition:  0.6s ease-out;
  }

  .ProductForm_openCollapse__3jzqU {
    -webkit-transform: rotateZ(90deg);
    transform: rotateZ(90deg);
  }

  .ProductForm_closeCollapse__spw_9 { 
    -webkit-transform: rotateZ(-90deg);
    transform: rotateZ(-90deg);
  }
/**
 * Copyright(c) 2020 Mozanta Technologies Private Ltd.
 * 
 * All rights reserved.                
 * 
 * This software is the confidential and proprietary information of Mozanta ("Confidential
 * Information"). You shall not disclose such Confidential Information and shall use it only in
 * accordance with the terms of the contract agreement you entered into with Mozanta.
 * 
 * @author Indrajith C
 */

 .AddProduct_block2__2CjKz {
  padding-top: 20px;
  padding-bottom: 20px;
} 

.AddProduct_borderRadius2__1Z33e {
  border-radius: 0.125rem !important;
}

.AddProduct_secondaryColor__cHhez {
  background-color: #757575 !important;
  border-color: #757575 !important;
}

.AddProduct_primaryColor__BUVeP {
  background: #17a2b8 !important;
  border-color: #17a2b8 !important;
}

.AddProduct_btn__2uc9E {
  min-width: 7rem;
}


/* ========== FORM AREA ============ */
.AddProduct_block3__2rGGo {
  padding: 1.25rem;
}

/* ============= PRODUCT TITLE ========= */
.AddProduct_titleTextContainer__2YfJ8 {
  padding: 0 2.1rem 0.125rem 2.1rem;
  margin: 0 -0.938rem;
}

.AddProduct_titleTextContainer__2YfJ8 .AddProduct_pageTitleText__5Yqde{
  text-align: left;
  color: #333333;
  padding-bottom: 17px;
}
 


/** ========= PARENT CATEGORY  ================ **/
.AddProduct_block5__1hLzu {
padding: 1.25rem;
}
/**
 * Copyright(c) 2020 Mozanta Technologies Private Ltd.
 * 
 * All rights reserved.                
 * 
 * This software is the confidential and proprietary information of Mozanta ("Confidential
 * Information"). You shall not disclose such Confidential Information and shall use it only in
 * accordance with the terms of the contract agreement you entered into with Mozanta.
 * 
 * @author Indrajith C
 */

 .EditProduct_block2__13END {
  padding-top: 20px;
  padding-bottom: 20px;
} 

.EditProduct_borderRadius2__9SIQO {
  border-radius: 0.125rem !important;
}

.EditProduct_secondaryColor__2zv06 {
  background-color: #757575 !important;
  border-color: #757575 !important;
}

.EditProduct_primaryColor__vrvtt {
  background: #17a2b8 !important;
  border-color: #17a2b8 !important;
}

.EditProduct_btn__1P8dk {
  min-width: 7rem;
}


/* ========== FORM AREA ============ */
.EditProduct_block3__6Rpb3 {
  padding: 1.25rem;
}

/* ============= PRODUCT TITLE ========= */
.EditProduct_titleTextContainer__2W5qj {
  padding: 0 2.1rem 0.125rem 2.1rem;
  border-bottom: 1px solid #edf0f5;
  margin: 0 -0.938rem;
}

 .EditProduct_variantsText__2ieAa{
  text-align: left;
  font-weight: 600;
  font-size: 16px;
  color: #212121;
}
 


/** ========= PARENT CATEGORY  ================ **/
.EditProduct_block5__2-9ne {
  padding: 1.25rem;
}

/** ========= Table Area  ================ **/

  .EditProduct_block3__6Rpb3 .EditProduct_noLabelFormGroup__1nyN3 {
    margin-top: 1.75rem;
    padding: 0.375rem 0.75rem;
  }

  .EditProduct_block3__6Rpb3 .EditProduct_variantTable__VUXJ6 thead {
    background-color: #edf0f5;
  }
  
  .EditProduct_block3__6Rpb3 .EditProduct_variantTable__VUXJ6 tbody .EditProduct_productImage__2QCLE {
    max-height: 50px;
  }

  .EditProduct_block3__6Rpb3 .EditProduct_variantTable__VUXJ6 tbody .EditProduct_mw75__1QMwQ {
     max-width: 75px;
  }

  /** =========== details table ============== **/
  .EditProduct_tableDetails__D-2l-> tbody> tr> th {
    color: grey;
  }
  .EditProduct_tableDetails__D-2l-> tbody> tr> td {
    font-weight: 600;
  }
/**
 * Copyright(c) 2020 Mozanta Technologies Private Ltd.
 * 
 * All rights reserved.                
 * 
 * This software is the confidential and proprietary information of Mozanta ("Confidential
 * Information"). You shall not disclose such Confidential Information and shall use it only in
 * accordance with the terms of the contract agreement you entered into with Mozanta.
 * 
 * @author Indrajith C
 */

.ImageSelection_uploadImagePreview__3ig_e {
    max-width: 150px;
    margin-right: 1rem;
    position: relative;
  }
.ImageSelection_uploadImagePreviewIcon__fFoPB {
    max-width: 150px;
}
.ImageSelection_uploadImagePreviewClose__M1SCL{
  display: none;
}
.ImageSelection_uploadImagePreview__3ig_e:hover .ImageSelection_uploadImagePreviewClose__M1SCL {
  display: flex;
  border-radius: 50%;
  position: absolute;
  top: -4px;
  right: -4px;
  background: #b3b3b3;
  font-size: 11px;
  padding: 0px 5px;
  font-weight: 600;
  cursor: pointer;
}

.ImageSelection_uploadImagePreview__3ig_e:last-child {
      margin-right: 0;
  }


.ProductsUI_ContentOuter__1w33E h6 {
  font-size: 14px;
}
.ProductsUI_ProductListOuter__3WbEw {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  list-style: none;
  padding: 0;
  margin: 0;
}
.ProductsUI_ProductListOuter__3WbEw .ProductsUI_ProductItem__1UQvN {
  display: flex;
  flex-direction: column;
  margin-right: 2rem;
  margin-bottom: 2rem;
}
.ProductsUI_ProductListOuter__3WbEw .ProductsUI_ProductItem__1UQvN figure {
  width: 100px;
  height: 100px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 0.5rem;
  background-color: #f2f2f2;
  position: relative;
}
.ProductsUI_ProductListOuter__3WbEw .ProductsUI_ProductItem__1UQvN figure .ProductsUI_BtnClose__1THsx {
  position: absolute;
  right: -10px;
  top: -10px;
  z-index: 10;
}
.ProductsUI_ProductListOuter__3WbEw .ProductsUI_ProductItem__1UQvN figure>img {
  max-width: 100%;
  max-height: 100%;
}
.ProductsUI_ProductListOuter__3WbEw .ProductsUI_ProductItem__1UQvN p {
  margin: 0;
}

/**
 * Copyright(c) 2020 Mozanta Technologies Private Ltd.
 * 
 * All rights reserved.                
 * 
 * This software is the confidential and proprietary information of Mozanta ("Confidential
 * Information"). You shall not disclose such Confidential Information and shall use it only in
 * accordance with the terms of the contract agreement you entered into with Mozanta.
 * 
 * @author Aneesh K
 */

.ProductOptionManagement_block2__1umt6 {
    padding-top: 20px;
    padding-bottom: 20px;
}

.ProductOptionManagement_borderRadius2__3nAWL {
    border-radius: 0.125rem !important;
}

/** =========== ACTION AREA STYLES ============== **/
.ProductOptionManagement_block2__1umt6 .ProductOptionManagement_areaActionRight__YfBQG {
    display: flex;
    justify-content: flex-end;
}

.ProductOptionManagement_block2__1umt6 .ProductOptionManagement_areaActionRight__YfBQG>div:first-child {
    margin-right: 0.3rem;
}

.ProductOptionManagement_block2__1umt6 .ProductOptionManagement_areaActionRight__YfBQG>div:last-child {
    display: flex;
}

/** ========= SEARCH FILED ================ **/
.ProductOptionManagement_block2__1umt6 .ProductOptionManagement_areaActionRight__YfBQG .ProductOptionManagement_search__1fHI0 {
    border: 1px solid;
    border-radius: 0.125rem;
}

.ProductOptionManagement_block2__1umt6 .ProductOptionManagement_areaActionRight__YfBQG .ProductOptionManagement_search__1fHI0 input {
    border: 0;
    width: 100%;
    height: calc(1.5em + 0.75rem + 0px);
}

.ProductOptionManagement_block2__1umt6 .ProductOptionManagement_areaActionRight__YfBQG .ProductOptionManagement_search__1fHI0 div {
    border: 0;
    border-radius: 0;
    background-color: inherit;
    padding-left: 0.6rem;
    padding-right: 0.35rem;
}

/***** ========== MAIN ACTION AREA ============ **********/
.ProductOptionManagement_block3__2Efb5 {
    padding: 1.25rem 0;
}

/***** ============== MAIN ACTION COMPONENT ========= *******/
.ProductOptionManagement_block3__2Efb5 .ProductOptionManagement_product__1LVau {
    position: relative;
}

/***** ============== ACTION COMPONENT BODY ========= *******/

.ProductOptionManagement_block3__2Efb5 .ProductOptionManagement_product__1LVau .ProductOptionManagement_productBody__2ocn4 .ProductOptionManagement_productTable__17jwr thead {
    background-color: #edf0f5;
}

.ProductOptionManagement_block3__2Efb5 .ProductOptionManagement_product__1LVau .ProductOptionManagement_productBody__2ocn4 .ProductOptionManagement_productItem__IEoYo {
    display: flex;
    flex-direction: column;
}

.ProductOptionManagement_block3__2Efb5 .ProductOptionManagement_product__1LVau .ProductOptionManagement_productBody__2ocn4 .ProductOptionManagement_productTable__17jwr .ProductOptionManagement_productName__bHSsP {
    max-width: 100px;
}

.ProductOptionManagement_block3__2Efb5 .ProductOptionManagement_product__1LVau .ProductOptionManagement_productBody__2ocn4 .ProductOptionManagement_productTable__17jwr .ProductOptionManagement_productImage__1vY8X {
    max-height: 50px;
}

/***** ===================== PAGINATION ======================= ******/
.ProductOptionManagement_block3__2Efb5 .ProductOptionManagement_product__1LVau .ProductOptionManagement_pagination__1vYwc {}

.ProductOptionManagement_block3__2Efb5 .ProductOptionManagement_product__1LVau .ProductOptionManagement_pagination__1vYwc .ProductOptionManagement_item__2a_Rp {
    height: 25px;
    width: 25px;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
}

.ProductOptionManagement_block3__2Efb5 .ProductOptionManagement_product__1LVau .ProductOptionManagement_pagination__1vYwc .ProductOptionManagement_arrowLeft__1sC3B,
.ProductOptionManagement_block3__2Efb5 .ProductOptionManagement_product__1LVau .ProductOptionManagement_pagination__1vYwc .ProductOptionManagement_arrowRight__2TJrK {
    border-radius: 50%;
    border: 2px solid;
}

.ProductOptionManagement_block3__2Efb5 .ProductOptionManagement_product__1LVau .ProductOptionManagement_pagination__1vYwc .ProductOptionManagement_arrowLeft__1sC3B>span,
.ProductOptionManagement_block3__2Efb5 .ProductOptionManagement_product__1LVau .ProductOptionManagement_pagination__1vYwc .ProductOptionManagement_arrowRight__2TJrK>span {
    border-bottom: 2px solid;
    border-right: 2px solid;
    width: 8px;
    height: 8px;
    margin-top: -0.5px;
}

.ProductOptionManagement_block3__2Efb5 .ProductOptionManagement_product__1LVau .ProductOptionManagement_pagination__1vYwc .ProductOptionManagement_arrowLeft__1sC3B>span {
    -webkit-transform: rotate(127deg);
            transform: rotate(127deg);
    margin-right: -2.5px;
}

.ProductOptionManagement_block3__2Efb5 .ProductOptionManagement_product__1LVau .ProductOptionManagement_pagination__1vYwc .ProductOptionManagement_arrowRight__2TJrK>span {
    -webkit-transform: rotate(315deg);
            transform: rotate(315deg);
    margin-left: -2.5px;
}

.ProductOptionManagement_block3__2Efb5 .ProductOptionManagement_product__1LVau .ProductOptionManagement_pagination__1vYwc .ProductOptionManagement_arrowLeft__1sC3B,
.ProductOptionManagement_block3__2Efb5 .ProductOptionManagement_product__1LVau .ProductOptionManagement_pagination__1vYwc .ProductOptionManagement_arrowRight__2TJrK,
.ProductOptionManagement_block3__2Efb5 .ProductOptionManagement_product__1LVau .ProductOptionManagement_pagination__1vYwc .ProductOptionManagement_item__2a_Rp[active] {
    color: #e91e63;
    font-weight: 600;
}

.ProductOptionManagement_block3__2Efb5 .ProductOptionManagement_product__1LVau .ProductOptionManagement_pagination__1vYwc .ProductOptionManagement_item__2a_Rp[active] {
    cursor: default;
}

.ProductOptionManagement_block3__2Efb5 .ProductOptionManagement_product__1LVau .ProductOptionManagement_pagination__1vYwc .ProductOptionManagement_item__2a_Rp[disabled] {
    cursor: not-allowed;
    color: #5d5d5d;
}

.ProductOptionManagement_tableWrapper__3bqAW {
    min-height: calc(100vh - 387px);
}
/**
 * Copyright(c) 2020 Mozanta Technologies Private Ltd.
 * 
 * All rights reserved.                
 * 
 * This software is the confidential and proprietary information of Mozanta ("Confidential
 * Information"). You shall not disclose such Confidential Information and shall use it only in
 * accordance with the terms of the contract agreement you entered into with Mozanta.
 * 
 * @author Aneesh K
 */

.EditProductOption_block2__iQdQ2 {
    padding-top: 20px;
    padding-bottom: 20px;
}

.EditProductOption_borderRadius2__1G1SA {
    border-radius: 0.125rem !important;
}

.EditProductOption_secondaryColor__1s-dG {
    background-color: #757575 !important;
    border-color: #757575 !important;
}

.EditProductOption_primaryColor__2hVGX {
    background: #17a2b8 !important;
    border-color: #17a2b8 !important;
}

.EditProductOption_btn__1AA6X {
    min-width: 7rem;
}


/* ========== FORM AREA ============ */
.EditProductOption_block3__3MxFs {
    padding: 1.25rem;
}

/* ============= PRODUCT TITLE ========= */
.EditProductOption_titleTextContainer__oeorz {
    padding: 0 2.1rem 0.125rem 2.1rem;
    border-bottom: 1px solid #edf0f5;
    margin: 0 -0.938rem;
}

.EditProductOption_variantsText__8sfD0 {
    text-align: left;
    font-weight: 600;
    font-size: 16px;
    color: #212121;
}



/** ========= PARENT CATEGORY  ================ **/
.EditProductOption_block5__JZ2M3 {
    padding: 1.25rem;
}

/** ========= Table Area  ================ **/

.EditProductOption_block3__3MxFs .EditProductOption_noLabelFormGroup__2HJg9 {
    margin-top: 1.75rem;
    padding: 0.375rem 0.75rem;
}

.EditProductOption_block3__3MxFs .EditProductOption_variantTable__2bhMS thead {
    background-color: #edf0f5;
}

.EditProductOption_block3__3MxFs .EditProductOption_variantTable__2bhMS tbody .EditProductOption_productImage__1s-6X {
    max-height: 50px;
}

.EditProductOption_block3__3MxFs .EditProductOption_variantTable__2bhMS tbody .EditProductOption_mw75__K2Ftl {
    max-width: 75px;
}

/** =========== details table ============== **/
.EditProductOption_tableDetails__33rAt>tbody>tr>th {
    color: grey;
}

.EditProductOption_tableDetails__33rAt>tbody>tr>td {
    font-weight: 600;
}
/**
 * Copyright(c) 2020 Mozanta Technologies Private Ltd.
 * 
 * All rights reserved.                
 * 
 * This software is the confidential and proprietary information of Mozanta ("Confidential
 * Information"). You shall not disclose such Confidential Information and shall use it only in
 * accordance with the terms of the contract agreement you entered into with Mozanta.
 * 
 * @author Aneesh K
 */

.ProductOptionForm_block2__39Rw0 {
    padding-top: 20px;
    padding-bottom: 20px;
}

.ProductOptionForm_borderRadius2__3b1z0 {
    border-radius: 0.125rem !important;
}

.ProductOptionForm_secondaryColor__SOkeE {
    background-color: #757575 !important;
    border-color: #757575 !important;
}

.ProductOptionForm_primaryColor__21msn {
    background: #e91e63 !important;
    border-color: #e91e63 !important;
}

.ProductOptionForm_btn__wVpYb {
    min-width: 7rem;
}

.ProductOptionForm_icon__1KBGn {
    width: 1.25rem;
}

/* ========== FORM AREA ============ */
.ProductOptionForm_block3__1g5vj {
    padding: 1.25rem;
}

.ProductOptionForm_block3__1g5vj .ProductOptionForm_noLabelFormGroup__3hV6z {
    margin-top: 1.75rem;
    padding: 0.375rem 0.75rem;
}

.ProductOptionForm_block3__1g5vj .ProductOptionForm_variantTable__1ErZL thead {
    background-color: #edf0f5;
}

.ProductOptionForm_block3__1g5vj .ProductOptionForm_variantTable__1ErZL tbody .ProductOptionForm_productImage__hSB_X {
    max-height: 50px;
}

.ProductOptionForm_block3__1g5vj .ProductOptionForm_variantTable__1ErZL tbody .ProductOptionForm_mw75__-IAkc {
    max-width: 75px;
}



/* ============= PRODUCT TITLE ========= */
.ProductOptionForm_titleTextContainer__24VfG {
    padding: 0 2.1rem 0.125rem 2.1rem;
    margin: 0 -0.938rem;
}

.ProductOptionForm_titleTextContainer__24VfG .ProductOptionForm_pageTitleText__1VraU {
    text-align: left;
    color: #333333;
    padding-bottom: 17px;
}

.ProductOptionForm_collapseIcon__2fx35>img {
    width: 15px;
    height: 15px;
    -webkit-transition: 0.6s ease-out;
    transition: 0.6s ease-out;
}

.ProductOptionForm_openCollapse__1uryT {
    -webkit-transform: rotateZ(90deg);
    transform: rotateZ(90deg);
}

.ProductOptionForm_closeCollapse__Y6bO3 {
    -webkit-transform: rotateZ(-90deg);
    transform: rotateZ(-90deg);
}
/**
 * Copyright(c) 2020 Mozanta Technologies Private Ltd.
 * 
 * All rights reserved.                
 * 
 * This software is the confidential and proprietary information of Mozanta ("Confidential
 * Information"). You shall not disclose such Confidential Information and shall use it only in
 * accordance with the terms of the contract agreement you entered into with Mozanta.
 * 
 * @author Indrajith C
 */


 .PromotionListView_liveBtnWrapper__2Qn1C .PromotionListView_liveBtn__3JUeg{
  border: none;
  border-radius: 2px;
  text-transform: uppercase;
}

 .PromotionListView_block2__3Yvd5 {
  padding-top: 20px;
  padding-bottom: 20px;
}

.PromotionListView_block2__3Yvd5 {
  padding-top: 20px;
  padding-bottom: 20px;
} 

.PromotionListView_borderRadius2__2TGuV {
  border-radius: 0.125rem !important;
}

.PromotionListView_secondaryColor__109Eg {
  background-color: #757575 !important;
  border-color: #757575 !important;
}

.PromotionListView_primaryColor__1TZ-U {
  background: #e91e63 !important;
  border-color: #e91e63 !important;
}

.PromotionListView_btn__2shC3 {
  min-width: 7rem;
}

/* ============= PRODUCT TITLE ========= */
.PromotionListView_titleTextContainer__3xFCX {
  padding: 0 2.1rem 0.125rem 2.1rem;
  border-bottom: 1px solid #edf0f5;
  margin: 0 -0.938rem;
}

.PromotionListView_titleTextContainer__3xFCX .PromotionListView_pageTitleText__2ZqP5{
  text-align: left;
  color: #333333;
  padding-bottom: 17px;
}
 
 

/** =========== ACTION AREA STYLES ============== **/
.PromotionListView_block2__3Yvd5 .PromotionListView_areaActionRight__3lvqB {
  display: flex;
  justify-content: flex-end;
}

.PromotionListView_block2__3Yvd5 .PromotionListView_borderRadius2__2TGuV {
  border-radius: 0.125rem;
}

.PromotionListView_block2__3Yvd5 .PromotionListView_areaActionRight__3lvqB > div:first-child {
  margin-right: 0.3rem;
}
.PromotionListView_block2__3Yvd5 .PromotionListView_areaActionRight__3lvqB > div:last-child {
  display: flex;
}

/** ========= SEARCH FILED ================ **/
.PromotionListView_block2__3Yvd5 .PromotionListView_areaActionRight__3lvqB .PromotionListView_search__QtVHn {
  border: 1px solid;
  border-radius: 0.125rem;
}

.PromotionListView_block2__3Yvd5 .PromotionListView_areaActionRight__3lvqB .PromotionListView_search__QtVHn input {
  border: 0;
  width: 100%;
  height: calc(1.5em + 0.75rem + 0px);
}

.PromotionListView_block2__3Yvd5 .PromotionListView_areaActionRight__3lvqB .PromotionListView_search__QtVHn div {
  border: 0;
  border-radius: 0;
  background-color: inherit;
  padding-left: 0.6rem;
  padding-right: 0.35rem;
}

/***** ========== MAIN ACTION AREA ============ **********/
.PromotionListView_block3__3YhyH {
  padding: 1.25rem 0;
}

/***** ===================== PAGINATION ======================= ******/
 .PromotionListView_pagination__23Oc- { }

 .PromotionListView_pagination__23Oc- .PromotionListView_item__1vIg0 {
  height: 25px;
  width: 25px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}

 .PromotionListView_pagination__23Oc- .PromotionListView_arrowLeft__2c_nv,  .PromotionListView_pagination__23Oc- .PromotionListView_arrowRight__2ZR_b {
  border-radius: 50%;
  border: 2px solid;
}

 .PromotionListView_pagination__23Oc- .PromotionListView_arrowLeft__2c_nv > span,  .PromotionListView_pagination__23Oc- .PromotionListView_arrowRight__2ZR_b > span {
  border-bottom: 2px solid;
  border-right: 2px solid;
  width: 8px;
  height: 8px; 
  margin-top: -0.5px;
}

 .PromotionListView_pagination__23Oc- .PromotionListView_arrowLeft__2c_nv > span{ 
  -webkit-transform: rotate(127deg); 
          transform: rotate(127deg); 
  margin-right: -2.5px;
}

 .PromotionListView_pagination__23Oc- .PromotionListView_arrowRight__2ZR_b > span{ 
  -webkit-transform: rotate(315deg); 
          transform: rotate(315deg); 
  margin-left: -2.5px;
}

 .PromotionListView_pagination__23Oc- .PromotionListView_arrowLeft__2c_nv ,  .PromotionListView_pagination__23Oc- .PromotionListView_arrowRight__2ZR_b,  .PromotionListView_pagination__23Oc- .PromotionListView_item__1vIg0[active] {
  color: #e91e63;
  font-weight: 600;
}

 .PromotionListView_pagination__23Oc- .PromotionListView_item__1vIg0[active] {
  cursor: default;
}

 .PromotionListView_pagination__23Oc- .PromotionListView_item__1vIg0[disabled] {
  cursor: not-allowed;
  color: #5d5d5d;
}

.PromotionListView_tableWrapper__Bv0iT{
  min-height: calc(100vh - 391px);
}

/***** ============== MAIN ACTION COMPONENT ========= *******/
.PromotionListView_block3__3YhyH .PromotionListView_promotion__11Z40 {
  position: relative;
}

/***** ============== ACTION COMPONENT BODY ========= *******/

.PromotionListView_block3__3YhyH .PromotionListView_promotion__11Z40 .PromotionListView_promotionBody__3mOK2 .PromotionListView_promotionTable__15Lwm thead {
  background-color: #edf0f5;
}

.PromotionListView_block3__3YhyH .PromotionListView_promotion__11Z40 .PromotionListView_promotionBody__3mOK2 .PromotionListView_productItem__2rWNf {
  display: flex;
  flex-direction: column;
}

.PromotionListView_block3__3YhyH .PromotionListView_promotion__11Z40 .PromotionListView_promotionBody__3mOK2 .PromotionListView_promotionTable__15Lwm .PromotionListView_promotionName__1RXFx {
  max-width: 100px;
}

.PromotionListView_block3__3YhyH .PromotionListView_promotion__11Z40 .PromotionListView_promotionBody__3mOK2 .PromotionListView_promotionTable__15Lwm .PromotionListView_productImage__1p2K- {
  max-height: 50px;
}

/***** ===================== PAGINATION ======================= ******/
.PromotionListView_block3__3YhyH .PromotionListView_promotion__11Z40 .PromotionListView_pagination__23Oc- { }

.PromotionListView_block3__3YhyH .PromotionListView_promotion__11Z40 .PromotionListView_pagination__23Oc- .PromotionListView_item__1vIg0 {
  height: 25px;
  width: 25px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}

.PromotionListView_block3__3YhyH .PromotionListView_promotion__11Z40 .PromotionListView_pagination__23Oc- .PromotionListView_arrowLeft__2c_nv, .PromotionListView_block3__3YhyH .PromotionListView_promotion__11Z40 .PromotionListView_pagination__23Oc- .PromotionListView_arrowRight__2ZR_b {
  border-radius: 50%;
  border: 2px solid;
}

.PromotionListView_block3__3YhyH .PromotionListView_promotion__11Z40 .PromotionListView_pagination__23Oc- .PromotionListView_arrowLeft__2c_nv > span, .PromotionListView_block3__3YhyH .PromotionListView_promotion__11Z40 .PromotionListView_pagination__23Oc- .PromotionListView_arrowRight__2ZR_b > span {
  border-bottom: 2px solid;
  border-right: 2px solid;
  width: 8px;
  height: 8px; 
  margin-top: -0.5px;
}

.PromotionListView_block3__3YhyH .PromotionListView_promotion__11Z40 .PromotionListView_pagination__23Oc- .PromotionListView_arrowLeft__2c_nv > span{ 
  -webkit-transform: rotate(127deg); 
          transform: rotate(127deg); 
  margin-right: -2.5px;
}

.PromotionListView_block3__3YhyH .PromotionListView_promotion__11Z40 .PromotionListView_pagination__23Oc- .PromotionListView_arrowRight__2ZR_b > span{ 
  -webkit-transform: rotate(315deg); 
          transform: rotate(315deg); 
  margin-left: -2.5px;
}

.PromotionListView_block3__3YhyH .PromotionListView_promotion__11Z40 .PromotionListView_pagination__23Oc- .PromotionListView_arrowLeft__2c_nv , .PromotionListView_block3__3YhyH .PromotionListView_promotion__11Z40 .PromotionListView_pagination__23Oc- .PromotionListView_arrowRight__2ZR_b, .PromotionListView_block3__3YhyH .PromotionListView_promotion__11Z40 .PromotionListView_pagination__23Oc- .PromotionListView_item__1vIg0[active] {
  color: #e91e63;
  font-weight: 600;
}

.PromotionListView_block3__3YhyH .PromotionListView_promotion__11Z40 .PromotionListView_pagination__23Oc- .PromotionListView_item__1vIg0[active] {
  cursor: default;
}

.PromotionListView_block3__3YhyH .PromotionListView_promotion__11Z40 .PromotionListView_pagination__23Oc- .PromotionListView_item__1vIg0[disabled] {
  cursor: not-allowed;
  color: #5d5d5d;
}
/**
 * Copyright(c) 2020 Mozanta Technologies Private Ltd.
 * 
 * All rights reserved.                
 * 
 * This software is the confidential and proprietary information of Mozanta ("Confidential
 * Information"). You shall not disclose such Confidential Information and shall use it only in
 * accordance with the terms of the contract agreement you entered into with Mozanta.
 * 
 * @author Indrajith C
 */


 .PromotionListView_liveBtnWrapper__2HsOH .PromotionListView_liveBtn__3g8Jr{
  border: none;
  border-radius: 2px;
  text-transform: uppercase;
}

 .PromotionListView_block2__2bnCM {
  padding-top: 20px;
  padding-bottom: 20px;
}

.PromotionListView_block2__2bnCM {
  padding-top: 20px;
  padding-bottom: 20px;
} 

.PromotionListView_borderRadius2__gYmfo {
  border-radius: 0.125rem !important;
}

.PromotionListView_secondaryColor__3VbvA {
  background-color: #757575 !important;
  border-color: #757575 !important;
}

.PromotionListView_primaryColor__3rXbA {
  background: #e91e63 !important;
  border-color: #e91e63 !important;
}

.PromotionListView_btn__1TfEs {
  min-width: 7rem;
}

/* ============= PRODUCT TITLE ========= */
.PromotionListView_titleTextContainer__hz7tD {
  padding: 0 2.1rem 0.125rem 2.1rem;
  border-bottom: 1px solid #edf0f5;
  margin: 0 -0.938rem;
}

.PromotionListView_titleTextContainer__hz7tD .PromotionListView_pageTitleText__1v5XL{
  text-align: left;
  color: #333333;
  padding-bottom: 17px;
}
 
 

/** =========== ACTION AREA STYLES ============== **/
.PromotionListView_block2__2bnCM .PromotionListView_areaActionRight__2XZkG {
  display: flex;
  justify-content: flex-end;
}

.PromotionListView_block2__2bnCM .PromotionListView_borderRadius2__gYmfo {
  border-radius: 0.125rem;
}

.PromotionListView_block2__2bnCM .PromotionListView_areaActionRight__2XZkG > div:first-child {
  margin-right: 0.3rem;
}
.PromotionListView_block2__2bnCM .PromotionListView_areaActionRight__2XZkG > div:last-child {
  display: flex;
}

/** ========= SEARCH FILED ================ **/
.PromotionListView_block2__2bnCM .PromotionListView_areaActionRight__2XZkG .PromotionListView_search__2CVa2 {
  border: 1px solid;
  border-radius: 0.125rem;
}

.PromotionListView_block2__2bnCM .PromotionListView_areaActionRight__2XZkG .PromotionListView_search__2CVa2 input {
  border: 0;
  height: calc(1.5em + 0.75rem + 0px);
}

.PromotionListView_block2__2bnCM .PromotionListView_areaActionRight__2XZkG .PromotionListView_search__2CVa2 div {
  border: 0;
  border-radius: 0;
  background-color: inherit;
  padding-left: 0.6rem;
  padding-right: 0.35rem;
}

/***** ========== MAIN ACTION AREA ============ **********/
.PromotionListView_block3__3DzGr {
  padding: 1.25rem 0;
}

/***** ===================== PAGINATION ======================= ******/
 .PromotionListView_pagination__2q5c0 { }

 .PromotionListView_pagination__2q5c0 .PromotionListView_item__2JkPx {
  height: 25px;
  width: 25px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}

 .PromotionListView_pagination__2q5c0 .PromotionListView_arrowLeft__P9Y31,  .PromotionListView_pagination__2q5c0 .PromotionListView_arrowRight__2QxO3 {
  border-radius: 50%;
  border: 2px solid;
}

 .PromotionListView_pagination__2q5c0 .PromotionListView_arrowLeft__P9Y31 > span,  .PromotionListView_pagination__2q5c0 .PromotionListView_arrowRight__2QxO3 > span {
  border-bottom: 2px solid;
  border-right: 2px solid;
  width: 8px;
  height: 8px; 
  margin-top: -0.5px;
}

 .PromotionListView_pagination__2q5c0 .PromotionListView_arrowLeft__P9Y31 > span{ 
  -webkit-transform: rotate(127deg); 
          transform: rotate(127deg); 
  margin-right: -2.5px;
}

 .PromotionListView_pagination__2q5c0 .PromotionListView_arrowRight__2QxO3 > span{ 
  -webkit-transform: rotate(315deg); 
          transform: rotate(315deg); 
  margin-left: -2.5px;
}

 .PromotionListView_pagination__2q5c0 .PromotionListView_arrowLeft__P9Y31 ,  .PromotionListView_pagination__2q5c0 .PromotionListView_arrowRight__2QxO3,  .PromotionListView_pagination__2q5c0 .PromotionListView_item__2JkPx[active] {
  color: #e91e63;
  font-weight: 600;
}

 .PromotionListView_pagination__2q5c0 .PromotionListView_item__2JkPx[active] {
  cursor: default;
}

 .PromotionListView_pagination__2q5c0 .PromotionListView_item__2JkPx[disabled] {
  cursor: not-allowed;
  color: #5d5d5d;
}

.PromotionListView_tableWrapper__JGYtZ{
  min-height: calc(100vh - 391px);
}

/***** ============== MAIN ACTION COMPONENT ========= *******/
.PromotionListView_block3__3DzGr .PromotionListView_promotion__CKKyv {
  position: relative;
}

/***** ============== ACTION COMPONENT BODY ========= *******/

.PromotionListView_block3__3DzGr .PromotionListView_promotion__CKKyv .PromotionListView_promotionBody__2lzEc .PromotionListView_promotionTable__LSTH7 thead {
  background-color: #edf0f5;
}

.PromotionListView_block3__3DzGr .PromotionListView_promotion__CKKyv .PromotionListView_promotionBody__2lzEc .PromotionListView_productItem__1JNaT {
  display: flex;
  flex-direction: column;
}

.PromotionListView_block3__3DzGr .PromotionListView_promotion__CKKyv .PromotionListView_promotionBody__2lzEc .PromotionListView_promotionTable__LSTH7 .PromotionListView_promotionName__38JiA {
  max-width: 100px;
}

.PromotionListView_block3__3DzGr .PromotionListView_promotion__CKKyv .PromotionListView_promotionBody__2lzEc .PromotionListView_promotionTable__LSTH7 .PromotionListView_productImage__382dE {
  max-height: 50px;
}

/***** ===================== PAGINATION ======================= ******/
.PromotionListView_block3__3DzGr .PromotionListView_promotion__CKKyv .PromotionListView_pagination__2q5c0 { }

.PromotionListView_block3__3DzGr .PromotionListView_promotion__CKKyv .PromotionListView_pagination__2q5c0 .PromotionListView_item__2JkPx {
  height: 25px;
  width: 25px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}

.PromotionListView_block3__3DzGr .PromotionListView_promotion__CKKyv .PromotionListView_pagination__2q5c0 .PromotionListView_arrowLeft__P9Y31, .PromotionListView_block3__3DzGr .PromotionListView_promotion__CKKyv .PromotionListView_pagination__2q5c0 .PromotionListView_arrowRight__2QxO3 {
  border-radius: 50%;
  border: 2px solid;
}

.PromotionListView_block3__3DzGr .PromotionListView_promotion__CKKyv .PromotionListView_pagination__2q5c0 .PromotionListView_arrowLeft__P9Y31 > span, .PromotionListView_block3__3DzGr .PromotionListView_promotion__CKKyv .PromotionListView_pagination__2q5c0 .PromotionListView_arrowRight__2QxO3 > span {
  border-bottom: 2px solid;
  border-right: 2px solid;
  width: 8px;
  height: 8px; 
  margin-top: -0.5px;
}

.PromotionListView_block3__3DzGr .PromotionListView_promotion__CKKyv .PromotionListView_pagination__2q5c0 .PromotionListView_arrowLeft__P9Y31 > span{ 
  -webkit-transform: rotate(127deg); 
          transform: rotate(127deg); 
  margin-right: -2.5px;
}

.PromotionListView_block3__3DzGr .PromotionListView_promotion__CKKyv .PromotionListView_pagination__2q5c0 .PromotionListView_arrowRight__2QxO3 > span{ 
  -webkit-transform: rotate(315deg); 
          transform: rotate(315deg); 
  margin-left: -2.5px;
}

.PromotionListView_block3__3DzGr .PromotionListView_promotion__CKKyv .PromotionListView_pagination__2q5c0 .PromotionListView_arrowLeft__P9Y31 , .PromotionListView_block3__3DzGr .PromotionListView_promotion__CKKyv .PromotionListView_pagination__2q5c0 .PromotionListView_arrowRight__2QxO3, .PromotionListView_block3__3DzGr .PromotionListView_promotion__CKKyv .PromotionListView_pagination__2q5c0 .PromotionListView_item__2JkPx[active] {
  color: #e91e63;
  font-weight: 600;
}

.PromotionListView_block3__3DzGr .PromotionListView_promotion__CKKyv .PromotionListView_pagination__2q5c0 .PromotionListView_item__2JkPx[active] {
  cursor: default;
}

.PromotionListView_block3__3DzGr .PromotionListView_promotion__CKKyv .PromotionListView_pagination__2q5c0 .PromotionListView_item__2JkPx[disabled] {
  cursor: not-allowed;
  color: #5d5d5d;
}


/* block 2 */

.RobotsTxt_block2__2or_V{
    padding-top: 20px;
    padding-bottom: 20px;
    color: black;
}

/** =========== ACTION AREA STYLES ============== **/
.RobotsTxt_areaActionRight__ybXPU {
  display: flex;
  justify-content: flex-end;
}


.RobotsTxt_tableWrapper__h7odW  {
    min-height: calc(100vh - 420px);
   }

   
  /***** ===================== PAGINATION ======================= ******/
  .RobotsTxt_pagination__2Ch1t { }
  
  .RobotsTxt_pagination__2Ch1t .RobotsTxt_item__TvKJ- {
   height: 25px;
   width: 25px;
   display: flex;
   justify-content: center;
   align-items: center;
   cursor: pointer;
 }

  .RobotsTxt_pagination__2Ch1t .RobotsTxt_arrowLeft__O3QgD,  .RobotsTxt_pagination__2Ch1t .RobotsTxt_arrowRight__1nqyZ {
   border-radius: 50%;
   border: 2px solid;
 }

  .RobotsTxt_pagination__2Ch1t .RobotsTxt_arrowLeft__O3QgD > span,  .RobotsTxt_pagination__2Ch1t .RobotsTxt_arrowRight__1nqyZ > span {
   border-bottom: 2px solid;
   border-right: 2px solid;
   width: 8px;
   height: 8px; 
   margin-top: -0.5px;
 }

  .RobotsTxt_pagination__2Ch1t .RobotsTxt_arrowLeft__O3QgD > span{ 
   -webkit-transform: rotate(127deg); 
           transform: rotate(127deg); 
   margin-right: -2.5px;
 }

  .RobotsTxt_pagination__2Ch1t .RobotsTxt_arrowRight__1nqyZ > span{ 
   -webkit-transform: rotate(315deg); 
           transform: rotate(315deg); 
   margin-left: -2.5px;
 }

  .RobotsTxt_pagination__2Ch1t .RobotsTxt_arrowLeft__O3QgD ,  .RobotsTxt_pagination__2Ch1t .RobotsTxt_arrowRight__1nqyZ,  .RobotsTxt_pagination__2Ch1t .RobotsTxt_item__TvKJ-[active] {
   color: #e91e63;
   font-weight: 600;
 }

  .RobotsTxt_pagination__2Ch1t .RobotsTxt_item__TvKJ-[active] {
   cursor: default;
 }

  .RobotsTxt_pagination__2Ch1t .RobotsTxt_item__TvKJ-[disabled] {
   cursor: not-allowed;
   color: #5d5d5d;
 }

 .RobotsTxt_collapseIcon__1XLSJ > img {
  width: 15px;
  height: 15px;
  -webkit-transition: 0.6s ease-out;
  transition:  0.6s ease-out;
  float: right;
}

.RobotsTxt_openCollapse__2Iljw {
  -webkit-transform: rotateZ(-90deg);
  transform: rotateZ(-90deg);
}

.RobotsTxt_closeCollapse__1Alg8 { 
  -webkit-transform: rotateZ(90deg);
  transform: rotateZ(90deg);
}

.RobotsTxt_cardTitle__2WlvE{
  font-size: 16px;
}

.RobotsTxt_cardBody__1W-Ni{
  font-size: 14px;
  font-weight: 400;
}

.RobotsTxt_block3__63lCB{
  font-size:14px ;
}

.RobotsTxt_date__BdfA7{
  color: lightgray;
  font-weight: 400;
}

.RobotsTxt_secondaryColor__1bmdZ {
    background-color: #757575 !important;
    border-color: #757575 !important;
  }
  
  .RobotsTxt_primaryColor__162TH {
    background: #e91e63 !important;
    border-color: #e91e63 !important;
  }
  
  .RobotsTxt_btn1__VvFJO {
    min-width: 6rem;
  }
  
  .RobotsTxt_btn2__2pWZK {
    min-width: 9rem;
  }
  

/**
 * Copyright(c) 2020 Mozanta Technologies Private Ltd.
 *
 * All rights reserved.
 *
 * This software is the confidential and proprietary information of Mozanta
 * ("Confidential Information"). You shall not disclose such Confidential
 * Information and shall use it only in accordance with the terms of the
 * contract agreement you entered into with Mozanta.
 *
 * RoleManagement.module.css
 *
 * @author Naseef O
 *
 */

.RoleManagement_block1__ZhvQF .RoleManagement_buttonNewUser__2ykHd{
  color: #ffffff;
  width: 113px;
  border-radius: 2px;
  background-color: #e91e63;
  border: 0px;
  float: right;
}


/* block 2 */

.RoleManagement_block2__3PfVU{
    padding-top: 20px;
    padding-bottom: 20px;
}

/** =========== ACTION AREA STYLES ============== **/
.RoleManagement_block2__3PfVU .RoleManagement_areaActionRight__2ft8Z {
    display: flex;
    justify-content: flex-start;
  }
  
  .RoleManagement_block2__3PfVU .RoleManagement_borderRadius2__1e1ud {
    border-radius: 0.125rem;
  }
  
  .RoleManagement_block2__3PfVU .RoleManagement_areaActionRight__2ft8Z > div:first-child {
    margin-right: 0.3rem;
  }
  .RoleManagement_block2__3PfVU .RoleManagement_areaActionRight__2ft8Z > div:last-child {
    display: flex;
  }
  
  /** ========= SEARCH FILED ================ **/
  .RoleManagement_block2__3PfVU .RoleManagement_areaActionRight__2ft8Z .RoleManagement_search__35fF9 {
    border: 1px solid ;
    border-radius: 0.125rem;
  }
  
  .RoleManagement_block2__3PfVU .RoleManagement_areaActionRight__2ft8Z .RoleManagement_search__35fF9 input {
    border: 0;
    width: 100%;
  }
  
  .RoleManagement_block2__3PfVU .RoleManagement_areaActionRight__2ft8Z .RoleManagement_search__35fF9 div {
    border: 0;
    border-radius: 0;
    background-color: #e91e63;
    padding-left: 0.6rem;
    padding-right: 0.35rem;
  }
  
  .RoleManagement_tableWrapper__2C8eB  {
    min-height: calc(100vh - 420px);
   }

/**
 * Copyright(c) 2020 Mozanta Technologies Private Ltd.
 *
 * All rights reserved.
 *
 * This software is the confidential and proprietary information of Mozanta
 * ("Confidential Information"). You shall not disclose such Confidential
 * Information and shall use it only in accordance with the terms of the
 * contract agreement you entered into with Mozanta.
 *
 * RoleManagement.module.css
 *
 * @author Anokh J Ajai
 *
 */

  

 .SearchIndexManagement_block2__19j18 {
  padding-top: 20px;
  padding-bottom: 20px;
}

/** =========== ACTION AREA STYLES ============== **/
.SearchIndexManagement_block2__19j18 .SearchIndexManagement_areaActionRight__31NAV {
  display: flex;
  justify-content: flex-end;
}

.SearchIndexManagement_block2__19j18 .SearchIndexManagement_borderRadius2__XiQhD {
  border-radius: 0.125rem;
}

.SearchIndexManagement_block2__19j18 .SearchIndexManagement_areaActionRight__31NAV > div:first-child {
  margin-right: 0.3rem;
}
.SearchIndexManagement_block2__19j18 .SearchIndexManagement_areaActionRight__31NAV > div:last-child {
  display: flex;
}

/** ========= SEARCH FILED ================ **/
.SearchIndexManagement_block2__19j18 .SearchIndexManagement_areaActionRight__31NAV .SearchIndexManagement_search__37pYP {
  border: 1px solid;
  border-radius: 0.125rem;
}

.SearchIndexManagement_block2__19j18 .SearchIndexManagement_areaActionRight__31NAV .SearchIndexManagement_search__37pYP input {
  border: 0;
  width: 100%;
  height: calc(1.5em + 0.75rem + 0px);
}

.SearchIndexManagement_block2__19j18 .SearchIndexManagement_areaActionRight__31NAV .SearchIndexManagement_search__37pYP div {
  border: 0;
  border-radius: 0;
  background-color: inherit;
  padding-left: 0.6rem;
  padding-right: 0.35rem;
}

  .SearchIndexManagement_tableWrapper__RfVzP  {
    min-height: calc(100vh - 420px);
   }

   .SearchIndexManagement_buttonGreen__n5tp9{
     background-color: white !important;
     color:green!important;
    border-color: green !important;
    min-width: 85px !important;
   }
   .SearchIndexManagement_buttonRed__1Ddyo{
     background-color: white !important;
     color:red !important;
    border-color: red !important; 
    min-width: 85px !important;
   }

   .SearchIndexManagement_spinnerStyle__1Sigm{
     width:20px !important;
     height:20px !important;
   }

/**
 * Copyright(c) 2020 Mozanta Technologies Private Ltd.
 *
 * All rights reserved.
 *
 * This software is the confidential and proprietary information of Mozanta
 * ("Confidential Information"). You shall not disclose such Confidential
 * Information and shall use it only in accordance with the terms of the
 * contract agreement you entered into with Mozanta.
 *
 * RoleManagement.module.css
 *
 * @author Akshay Raj
 *
 */

  

 .SearchSettingsManagement_block2__337W6 {
  padding-top: 20px;
  padding-bottom: 20px;
}

/** =========== ACTION AREA STYLES ============== **/
.SearchSettingsManagement_block2__337W6 .SearchSettingsManagement_areaActionRight__37E07 {
  display: flex;
  justify-content: flex-end;
}

.SearchSettingsManagement_block2__337W6 .SearchSettingsManagement_borderRadius2__9FW0P {
  border-radius: 0.125rem;
}

.SearchSettingsManagement_block2__337W6 .SearchSettingsManagement_areaActionRight__37E07 > div:first-child {
  margin-right: 0.3rem;
}
.SearchSettingsManagement_block2__337W6 .SearchSettingsManagement_areaActionRight__37E07 > div:last-child {
  display: flex;
}

/** ========= SEARCH FILED ================ **/
.SearchSettingsManagement_block2__337W6 .SearchSettingsManagement_areaActionRight__37E07 .SearchSettingsManagement_search__O289J {
  border: 1px solid;
  border-radius: 0.125rem;
}

.SearchSettingsManagement_block2__337W6 .SearchSettingsManagement_areaActionRight__37E07 .SearchSettingsManagement_search__O289J input {
  border: 0;
  width: 100%;
  height: calc(1.5em + 0.75rem + 0px);
}

.SearchSettingsManagement_block2__337W6 .SearchSettingsManagement_areaActionRight__37E07 .SearchSettingsManagement_search__O289J div {
  border: 0;
  border-radius: 0;
  background-color: inherit;
  padding-left: 0.6rem;
  padding-right: 0.35rem;
}

  .SearchSettingsManagement_tableWrapper__1kUIa  {
    min-height: calc(100vh - 420px);
   }

   .SearchSettingsManagement_buttonGreen__3PPB-{
     background-color: white !important;
     color:green!important;
    border-color: green !important;
    min-width: 85px !important;
   }
   .SearchSettingsManagement_buttonRed__14SdZ{
     background-color: white !important;
     color:red !important;
    border-color: red !important; 
    min-width: 85px !important;
   }

   .SearchSettingsManagement_spinnerStyle__UP2Af{
     width:20px !important;
     height:20px !important;
   }


/**
 * Copyright(c) 2020 Mozanta Technologies Private Ltd.
 *
 * All rights reserved.
 *
 * This software is the confidential and proprietary information of Mozanta
 * ("Confidential Information"). You shall not disclose such Confidential
 * Information and shall use it only in accordance with the terms of the
 * contract agreement you entered into with Mozanta.
 *
 * RoleManagement.module.css
 *
 * @author Anokh J Ajai
 *
 */

  

 .SearchSynonymManagement_block2__24-AC {
  padding-top: 20px;
  padding-bottom: 20px;
}

/** =========== ACTION AREA STYLES ============== **/
.SearchSynonymManagement_block2__24-AC .SearchSynonymManagement_areaActionRight__N8B42 {
  display: flex;
  justify-content: flex-end;
}

.SearchSynonymManagement_block2__24-AC .SearchSynonymManagement_borderRadius2__VVP6A {
  border-radius: 0.125rem;
}

.SearchSynonymManagement_block2__24-AC .SearchSynonymManagement_areaActionRight__N8B42 > div:first-child {
  margin-right: 0.3rem;
}
.SearchSynonymManagement_block2__24-AC .SearchSynonymManagement_areaActionRight__N8B42 > div:last-child {
  display: flex;
}

/** ========= SEARCH FILED ================ **/
.SearchSynonymManagement_block2__24-AC .SearchSynonymManagement_areaActionRight__N8B42 .SearchSynonymManagement_search__Y9PMZ {
  border: 1px solid;
  border-radius: 0.125rem;
}

.SearchSynonymManagement_block2__24-AC .SearchSynonymManagement_areaActionRight__N8B42 .SearchSynonymManagement_search__Y9PMZ input {
  border: 0;
  width: 100%;
  height: calc(1.5em + 0.75rem + 0px);
}

.SearchSynonymManagement_block2__24-AC .SearchSynonymManagement_areaActionRight__N8B42 .SearchSynonymManagement_search__Y9PMZ div {
  border: 0;
  border-radius: 0;
  background-color: inherit;
  padding-left: 0.6rem;
  padding-right: 0.35rem;
}

  .SearchSynonymManagement_tableWrapper__cdZbB  {
    min-height: calc(100vh - 420px);
   }

   .SearchSynonymManagement_buttonGreen__2H81m{
     background-color: white !important;
     color:green!important;
    border-color: green !important;
    min-width: 85px !important;
   }
   .SearchSynonymManagement_buttonRed__2Bw7_{
     background-color: white !important;
     color:red !important;
    border-color: red !important; 
    min-width: 85px !important;
   }

   .SearchSynonymManagement_spinnerStyle__1-boM{
     width:20px !important;
     height:20px !important;
   }

.SideNavigation_sideNavigationWrapper__1Adst{
    width: 300px;
    float: left;
    position: fixed;
    top: 85px;
    background: white;
    left: 47px;
    padding: 5px 0px;
    height: 100% ;
    display: block ;
}

.SideNavigation_navClick__2-mym{
    position: absolute;
    right: -7px;
    z-index: 3;
    cursor: pointer; 
}
 .SideNavigation_navClick__2-mym:before {
    content: " ";
    background: white;
    position: absolute;
    width: 15px;
    height: 15px;
    border-radius: 50%;
    right: calc( 50% - 7px );
    top: calc( 50% - 7px );
    z-index: -1;
}

.SideNavigation_navClickClose__1wGgE{
    position: fixed;
    left: 23px;
    top: 90px;
    z-index: 3;
    cursor: pointer;
}
.SideNavigation_navClickCloseScrolled__pRj-k{
    position: fixed;
    top:70px !important;
    -webkit-transition: ease 0.5s;
    transition: ease 0.5s;
    left: 23px;
    top: 130px;
    z-index: 2;
}
.SideNavigation_sideNavScroll__FbV5q{
    top:70px !important;
    -webkit-transition: ease 0.5s;
    transition: ease 0.5s;
    width: 300px;
    float: left;
    position: fixed;
    background: white;
    left: 46px;
    padding: 5px 10px;
    height: 100% ;
    display: block ;
}

.SideNavigation_sideNavigations__3cZNn{
    padding: 0;
    margin: 0;
    display: block;
    width: 100%;
    clear: both;
    padding-top: 5px;
    overflow: hidden;
    overflow-y: auto !important;
    height: calc( 100% - 85px );
}

.SideNavigation_sideNavigations__3cZNn span{  
    font-size: 16px;
    font-weight: 700;
    padding-bottom: 10px;
    padding: 6px 15px;
}

.SideNavigation_sideNavItem__3Jt5Q{
    border: none !important; 
    float: left;
    color: grey;
    display: block;
    width: 100%;
    clear: both;
    padding-left: 25px !important;
    border-radius: 0 !important;
}
.SideNavigation_sideNavItem__3Jt5Q:hover{
    cursor: pointer;
    border-left: 4px solid #229E79 !important;
    box-shadow: 0px 0px 5px -3px #ccc;
}
.SideNavigation_sideNavItem__3Jt5Q.SideNavigation_active__2g4I0{
    color: #229E79;
    background-color: #f9f9f9 ;
    border-left: 4px solid #229E79 !important;
    font-weight: 600;
}


/* block 2 */

.SiteMap_block2__2CFRn{
    padding-top: 20px;
    padding-bottom: 20px;
    color: black;
}

/** =========== ACTION AREA STYLES ============== **/
.SiteMap_areaActionRight__3-yGi {
  display: flex;
  justify-content: flex-end;
}


.SiteMap_tableWrapper__2Msq_  {
    min-height: calc(100vh - 420px);
   }

   
  /***** ===================== PAGINATION ======================= ******/
  .SiteMap_pagination__3Q13x { }
  
  .SiteMap_pagination__3Q13x .SiteMap_item__ooDiM {
   height: 25px;
   width: 25px;
   display: flex;
   justify-content: center;
   align-items: center;
   cursor: pointer;
 }

  .SiteMap_pagination__3Q13x .SiteMap_arrowLeft__1wUe2,  .SiteMap_pagination__3Q13x .SiteMap_arrowRight__2wBz2 {
   border-radius: 50%;
   border: 2px solid;
 }

  .SiteMap_pagination__3Q13x .SiteMap_arrowLeft__1wUe2 > span,  .SiteMap_pagination__3Q13x .SiteMap_arrowRight__2wBz2 > span {
   border-bottom: 2px solid;
   border-right: 2px solid;
   width: 8px;
   height: 8px; 
   margin-top: -0.5px;
 }

  .SiteMap_pagination__3Q13x .SiteMap_arrowLeft__1wUe2 > span{ 
   -webkit-transform: rotate(127deg); 
           transform: rotate(127deg); 
   margin-right: -2.5px;
 }

  .SiteMap_pagination__3Q13x .SiteMap_arrowRight__2wBz2 > span{ 
   -webkit-transform: rotate(315deg); 
           transform: rotate(315deg); 
   margin-left: -2.5px;
 }

  .SiteMap_pagination__3Q13x .SiteMap_arrowLeft__1wUe2 ,  .SiteMap_pagination__3Q13x .SiteMap_arrowRight__2wBz2,  .SiteMap_pagination__3Q13x .SiteMap_item__ooDiM[active] {
   color: #e91e63;
   font-weight: 600;
 }

  .SiteMap_pagination__3Q13x .SiteMap_item__ooDiM[active] {
   cursor: default;
 }

  .SiteMap_pagination__3Q13x .SiteMap_item__ooDiM[disabled] {
   cursor: not-allowed;
   color: #5d5d5d;
 }

 .SiteMap_collapseIcon__3NlBJ > img {
  width: 15px;
  height: 15px;
  -webkit-transition: 0.6s ease-out;
  transition:  0.6s ease-out;
  float: right;
}

.SiteMap_openCollapse__2baZE {
  -webkit-transform: rotateZ(-90deg);
  transform: rotateZ(-90deg);
}

.SiteMap_closeCollapse__2uERY { 
  -webkit-transform: rotateZ(90deg);
  transform: rotateZ(90deg);
}

.SiteMap_cardTitle__2pT7p{
  font-size: 16px;
}

.SiteMap_cardBody__2d1BZ{
  font-size: 14px;
  font-weight: 400;
}

.SiteMap_block3__U4MLb{
  font-size:14px ;
}

.SiteMap_date__8VVa4{
  color: lightgray;
  font-weight: 400;
}

.SiteMap_secondaryColor__J-OCu {
    background-color: #757575 !important;
    border-color: #757575 !important;
  }
  
  .SiteMap_primaryColor__3nbz9 {
    background: #e91e63 !important;
    border-color: #e91e63 !important;
  }
  
  .SiteMap_btn1__1sB8B {
    min-width: 6rem;
  }
  
  .SiteMap_btn2__1QDar {
    min-width: 9rem;
  }
  
.AddUrl_block2__1FP_j{
  padding-top: 20px;
  padding-bottom: 20px;
}

.AddUrl_borderRadius2__lXrVT {
  border-radius: 0.125rem !important;
}

.PrioritySchedule_subCardBody__1QaD6{
  font-size: 14px;
  font-weight: 400;
  background-color: lightgray;
  color:black;
}
/**
 * Copyright(c) 2020 Mozanta Technologies Private Ltd.
 * 
 * All rights reserved.
 * 
 * This software is the confidential and proprietary information of Mozanta ("Confidential
 * Information"). You shall not disclose such Confidential Information and shall use it only in
 * accordance with the terms of the contract agreement you entered into with Mozanta.
 * 
 * @author Indrajith C
 * 
 */

 .MenuList_liveBtnWrapper__3Rg59 .MenuList_liveBtn__2mNYa{
  border: none;
  border-radius: 2px;
  text-transform: uppercase;
}
 .MenuList_block1__1K9kT{
  padding-top: 20px;
  padding-bottom: 20px;
}
.MenuList_block1__1K9kT .MenuList_navigationManagementnText__sMW17{
  text-align: left;
  color: #333333;
  padding-bottom: 17px;
}

.MenuList_block1__1K9kT .MenuList_buttonAddMenu__3gcXV{
  color: #ffffff;
  width: 113px;
  height: 35px;
  border-radius: 2px;
  background-color: #448aff;
  border: 0px;
  float: right;
}
.MenuList_tableWrapper__3B_El{
  min-height: calc(100vh - 385px);
}

/* ============= table actions ===================== */
.MenuList_actions__2Oh9g > span[disabled]  { 
  /* cursor: not-allowed; */
  pointer-events: none !important;
}

.MenuList_actions__2Oh9g > span[disabled] > img {
  opacity: 0.4; 
}

/**
 * Copyright(c) 2020 Mozanta Technologies Private Ltd.
 * 
 * All rights reserved.
 * 
 * This software is the confidential and proprietary information of Mozanta ("Confidential
 * Information"). You shall not disclose such Confidential Information and shall use it only in
 * accordance with the terms of the contract agreement you entered into with Mozanta.
 * 
 * @author Indrajith C
 * 
 */
 .MenuForm_noLabelFormGroup__1Mp6d {
  margin-top: 1.75rem;
  padding: 0.375rem 0.75rem;
}
/**
 * Copyright(c) 2020 Mozanta Technologies Private Ltd.
 * 
 * All rights reserved.
 * 
 * This software is the confidential and proprietary information of Mozanta ("Confidential
 * Information"). You shall not disclose such Confidential Information and shall use it only in
 * accordance with the terms of the contract agreement you entered into with Mozanta.
 * 
 * @author Indrajith C
 * 
 */
 .MenuCreator_block1__2srL4{
  padding-top: 20px;
  padding-bottom: 20px;
}
.MenuCreator_block1__2srL4 .MenuCreator_navigationManagementnText__oVPUB{
  text-align: left;
  color: #333333;
  padding-bottom: 17px;
}

.MenuCreator_block1__2srL4 .MenuCreator_buttonAddMenu__3q602{
  color: #ffffff;
  width: 113px;
  height: 35px;
  border-radius: 2px;
  background-color: #448aff;
  border: 0px;
  float: right;
}

.MenuCreator_blockWrapper__2cZtc{
  min-height: calc(100vh - 385px);
} 
 
.MenuCreator_borderRadius2__1r8zt {
  border-radius: 0.125rem !important;
}

.MenuCreator_secondaryColor__iF7DZ {
  background-color: #757575 !important;
  border-color: #757575 !important;
}

.MenuCreator_primaryColor__2VSCu {
  background: #17a2b8 !important;
  border-color: #17a2b8 !important;
}

.MenuCreator_btn__3lfy5 {
  min-width: 7rem;
}

/**
 * Copyright(c) 2020 Mozanta Technologies Private Ltd.
 * 
 * All rights reserved.
 * 
 * This software is the confidential and proprietary information of Mozanta ("Confidential
 * Information"). You shall not disclose such Confidential Information and shall use it only in
 * accordance with the terms of the contract agreement you entered into with Mozanta.
 * 
 * @author Indrajith C
 * 
 */
 .MenuEditor_block1__3OzbE{
  padding-top: 20px;
  padding-bottom: 20px;
}
.MenuEditor_block1__3OzbE .MenuEditor_navigationManagementnText__2gqb_{
  text-align: left;
  color: #333333;
  padding-bottom: 17px;
}

.MenuEditor_block1__3OzbE .MenuEditor_buttonAddMenu__2KTMH{
  color: #ffffff;
  width: 113px;
  height: 35px;
  border-radius: 2px;
  background-color: #448aff;
  border: 0px;
  float: right;
}

.MenuEditor_blockWrapper__GuNQf{
  min-height: calc(100vh - 385px);
} 
 
.MenuEditor_borderRadius2__2W0QW {
  border-radius: 0.125rem !important;
}

.MenuEditor_secondaryColor__14c67 {
  background-color: #757575 !important;
  border-color: #757575 !important;
}

.MenuEditor_primaryColor__2V_CK {
  background: #17a2b8 !important;
  border-color: #17a2b8 !important;
}

.MenuEditor_btn__2YapZ {
  min-width: 7rem;
}

/**
 * Copyright(c) 2020 Mozanta Technologies Private Ltd.
 * 
 * All rights reserved.
 * 
 * This software is the confidential and proprietary information of Mozanta ("Confidential
 * Information"). You shall not disclose such Confidential Information and shall use it only in
 * accordance with the terms of the contract agreement you entered into with Mozanta.
 * 
 * @author Indrajith C
 * 
 */
 .MenuDetails_block1__1yiFQ{
  padding-top: 20px;
  padding-bottom: 20px;
}

img.MenuDetails_icon__nkLo- {
  width: 20px;
}

.MenuDetails_cursorMove__3Bq3d { 
  cursor: move;
}

.MenuDetails_blockWrapper__3xXZn{
  min-height: calc(100vh - 385px);
}

.MenuDetails_loadingOverlay__25KaT {
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  z-index: 99;
  background: rgba(33, 37, 41, 0.01);
}

/* ============= sub menu collapse icon ===================== */
.MenuDetails_collapseIcon__1K2xg {
   min-width: 23px;
}

.MenuDetails_collapseIcon__1K2xg > img {
  width: 15px;
  height: 15px;
  -webkit-transition: 0.6s ease-out;
  transition:  0.6s ease-out;
}

.MenuDetails_openCollapse__us825 {
  -webkit-transform: rotateZ(90deg);
  transform: rotateZ(90deg);
}

.MenuDetails_closeCollapse__qM_pF { 
  -webkit-transform: rotateZ(-90deg);
  transform: rotateZ(-90deg);
}

/* ============= menu actions ===================== */
.MenuDetails_categoryTreeItem__3PqiH .MenuDetails_actions__1pTNI > span[disabled]  { 
  /* cursor: not-allowed; */
  pointer-events: none !important;
}

.MenuDetails_categoryTreeItem__3PqiH .MenuDetails_actions__1pTNI > span[disabled] > img {
  opacity: 0.4; 
}

/* ============= menu drag ===================== */
.MenuDetails_dragContainer__2SJNV *[data-rbd-placeholder-context-id] {
  margin-bottom: 0.25rem !important; /* fix for extra margin  */
}

/* ============== menu columns =================== */
.MenuDetails_menuColumnContainer__3fh1Y {
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  overflow: hidden;
}

.MenuDetails_menuColumnContainer__3fh1Y > .MenuDetails_menuColumn__1BMwy .MenuDetails_dargHand__s4E0L {
  min-width: 3px;
  height: 100%;
  display: flex;
  background: #dfdfdf;
  cursor: col-resize;
  margin: 0 1px;
  border-color: #fff;
  border-radius: 10px;
}
 
.MenuDetails_menuColumnContainer__3fh1Y > .MenuDetails_menuColumn__1BMwy:last-child > .MenuDetails_dargHand__s4E0L,
.MenuDetails_menuColumnContainer__3fh1Y > .MenuDetails_menuColumn__1BMwy.MenuDetails_onDragging__1ytmG .MenuDetails_dargHand__s4E0L {
  display: none !important;
}

.MenuDetails_menuColumnContainer__3fh1Y > .MenuDetails_dummyResize__3ttmQ {
  min-width: 0.20rem;
  display: flex; 
  cursor: col-resize; 
  border-color: #fff;
  border-radius: 20px;
  left: 0;
  position: absolute;
  top: 0;
  height: calc( 100% - 0.20rem);
  background: #2d2d2d73;
  display: none;
  z-index: 2;
}
 
.MenuDetails_menuColumnContainer__3fh1Y.MenuDetails_cursorResizeChild__OqYST, .MenuDetails_menuColumnContainer__3fh1Y.MenuDetails_cursorResizeChild__OqYST * {
cursor: col-resize;
}

.MenuDetails_menuColumnContainer__3fh1Y .MenuDetails_cardHeader__3HSot {
  display: flex;
  padding: 2px;
}

.MenuDetails_menuColumnContainer__3fh1Y .MenuDetails_cardHeader__3HSot > .MenuDetails_icon__nkLo- {
  padding: 1px 2px;
  display: flex;
  cursor: pointer;
}

.MenuDetails_menuColumnContainer__3fh1Y .MenuDetails_cardHeader__3HSot > .MenuDetails_move__1oSBo {
  cursor: -webkit-grab;
  cursor: grab;
}

.MenuDetails_menuColumnContainer__3fh1Y .MenuDetails_cardHeader__3HSot > .MenuDetails_icon__nkLo- img{
  width: 14px;
  height: 14px;
}

.MenuDetails_menuColumnContainer__3fh1Y .MenuDetails_cardHeader__3HSot > .MenuDetails_icon__nkLo-[disabled] {
  pointer-events: none;
  opacity: 0.5;
}

/* =============== sum menu item ==================== */

.MenuDetails_subMenuItem__1hXH3 {

}

.MenuDetails_fullscreen__2RoCk {
  position: fixed;
    z-index: 222;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    background: #00000077;
    padding: 2rem;
    overflow: hidden;
    overflow-y: auto;
}

.MenuDetails_fullscreen__2RoCk .MenuDetails_fullscreenBody__3qFy7 {
  background: #fff;
  border-radius: 5px;
}

.MenuDetails_minimal__1amu9 .MenuDetails_icon__nkLo- {
  width: 15px;
  display: flex;
  align-items: center;
}

.MenuDetails_subMenuItem__1hXH3{
background: #fff;
}

.MenuDetails_subMenuItem__1hXH3 .MenuDetails_smallText__22uVU {
  font-size: 12px;
}

.MenuDetails_subMenuItem__1hXH3 .MenuDetails_superSmallText__31XDM {
  font-size: 10px !important;
  margin-top: -3px;
}

.MenuDetails_subMenuItem__1hXH3 .MenuDetails_actions__1pTNI{
  position: absolute !important;
  white-space: nowrap;
  /* transform: translateX(0); */
  width: 0;
  -webkit-transition: 0.6s;
  transition: 0.6s;
  /* right: -80px; */
  right: -0.5rem;
  padding: 0 2px;
  justify-content: flex-end !important;
  overflow: hidden;
  top: 0;
  padding: 4px 2px 5px 0px;
  background: #fff;
}
.MenuDetails_subMenuItem__1hXH3  .MenuDetails_actions__1pTNI > span {
  background: #fff;
  display: flex;
}

.MenuDetails_subMenuItem__1hXH3:hover .MenuDetails_hideInHover__1md1x {
  display: none;
}

.MenuDetails_subMenuItem__1hXH3:hover .MenuDetails_actions__1pTNI {
  /* position: absolute !important; */
  /* right: 0; */
  /* padding: 3px 2px; */
  width: unset;
  padding-right: 0.5rem !important;
}

.MenuDetails_subMenuItem__1hXH3 >span {
  min-height: 1.5rem;
}

.MenuDetails_subMenuItem__1hXH3.MenuDetails_minimal__1amu9 >span {
  min-height: 1rem;
}


/**
 * Copyright(c) 2020 Mozanta Technologies Private Ltd.
 * 
 * All rights reserved.
 * 
 * This software is the confidential and proprietary information of Mozanta ("Confidential
 * Information"). You shall not disclose such Confidential Information and shall use it only in
 * accordance with the terms of the contract agreement you entered into with Mozanta.
 * 
 * @author Indrajith C
 * 
 */
 .MegaMenuItemCreator_block1__2ByyP{
  padding-top: 20px;
  padding-bottom: 20px;
}
.MegaMenuItemCreator_block1__2ByyP .MegaMenuItemCreator_navigationManagementnText__2_k8u{
  text-align: left;
  color: #333333;
  padding-bottom: 17px;
}

.MegaMenuItemCreator_block1__2ByyP .MegaMenuItemCreator_buttonAddMenu__35_nR{
  color: #ffffff;
  width: 113px;
  height: 35px;
  border-radius: 2px;
  background-color: #448aff;
  border: 0px;
  float: right;
}

.MegaMenuItemCreator_blockWrapper__2uTb1{
  min-height: calc(100vh - 385px);
} 
 
.MegaMenuItemCreator_borderRadius2__2GtL3 {
  border-radius: 0.125rem !important;
}

.MegaMenuItemCreator_secondaryColor__284pk {
  background-color: #757575 !important;
  border-color: #757575 !important;
}

.MegaMenuItemCreator_primaryColor__29u4o {
  background: #17a2b8 !important;
  border-color: #17a2b8 !important;
}

.MegaMenuItemCreator_btn__FdjMW {
  min-width: 7rem;
}

.MegaMenuItemCreator_icon__3U4tj {
  width: 1.25rem;
}

/* ======================= form ================= */
.MegaMenuItemCreator_form__2uRIs {

}

.MegaMenuItemCreator_form__2uRIs .MegaMenuItemCreator_header__gk4Wm {
  display: block;
}

.MegaMenuItemCreator_form__2uRIs .MegaMenuItemCreator_formLabel__22Ait {
  font-size: 0.875rem;
}

.MegaMenuItemCreator_form__2uRIs .MegaMenuItemCreator_header__gk4Wm .MegaMenuItemCreator_formLabel__22Ait {
  opacity: 0.4;
}

.MegaMenuItemCreator_form__2uRIs .MegaMenuItemCreator_noLabelFormGroup__26DuS {
  margin-top: 1.75rem;
  padding: 0.375rem 0.75rem;
} 

.MegaMenuItemCreator_form__2uRIs .MegaMenuItemCreator_icon__3U4tj {
  width: 1.25rem;
}
/**
 * Copyright(c) 2020 Mozanta Technologies Private Ltd.
 * 
 * All rights reserved.
 * 
 * This software is the confidential and proprietary information of Mozanta ("Confidential
 * Information"). You shall not disclose such Confidential Information and shall use it only in
 * accordance with the terms of the contract agreement you entered into with Mozanta.
 * 
 * @author Indrajith C
 * 
 */
 .MenuItemCreator_block1__1Ubf7{
  padding-top: 20px;
  padding-bottom: 20px;
}
.MenuItemCreator_block1__1Ubf7 .MenuItemCreator_navigationManagementnText__2hV2o{
  text-align: left;
  color: #333333;
  padding-bottom: 17px;
}

.MenuItemCreator_block1__1Ubf7 .MenuItemCreator_buttonAddMenu__umTLT{
  color: #ffffff;
  width: 113px;
  height: 35px;
  border-radius: 2px;
  background-color: #448aff;
  border: 0px;
  float: right;
}

.MenuItemCreator_blockWrapper__18c2J{
  min-height: calc(100vh - 385px);
} 
 
.MenuItemCreator_borderRadius2__1-oeW {
  border-radius: 0.125rem !important;
}

.MenuItemCreator_secondaryColor__32j2t {
  background-color: #757575 !important;
  border-color: #757575 !important;
}

.MenuItemCreator_primaryColor__3d2RM {
  background: #17a2b8 !important;
  border-color: #17a2b8 !important;
}

.MenuItemCreator_btn__dAuih {
  min-width: 7rem;
}

/* ======================= form ================= */
.MenuItemCreator_form__2kD84 {

}

.MenuItemCreator_form__2kD84 .MenuItemCreator_header__3rbe0 {
  display: block;
}

.MenuItemCreator_form__2kD84 .MenuItemCreator_formLabel__3t3Ir {
  font-size: 0.875rem;
}

.MenuItemCreator_form__2kD84 .MenuItemCreator_header__3rbe0 .MenuItemCreator_formLabel__3t3Ir {
  opacity: 0.4;
}

.MenuItemCreator_form__2kD84 .MenuItemCreator_noLabelFormGroup__-0Ex7 {
  margin-top: 1.75rem;
  padding: 0.375rem 0.75rem;
} 

.MenuItemCreator_form__2kD84 .MenuItemCreator_icon__27BLj {
  width: 1.25rem;
}

/* ========================  column =================== */

.MenuItemCreator_mergeStatusLabel__ur-NY {
  min-width: 15rem;
}

.MenuItemCreator_outlineNone__GnfU6 {
  outline: none !important;
}

.MenuItemCreator_form__2kD84 .MenuItemCreator_imageUpload__f3z-l {
  border-radius: 5px;
  border: 1px dashed rgba(67, 148, 209, 0.5);
  text-align: center;
}

.MenuItemCreator_form__2kD84 .MenuItemCreator_primaryTextColor__21nKS { 
  color: #4394D1;
}

.MenuItemCreator_form__2kD84 .MenuItemCreator_secondaryTextColor__3W7Xm { 
  color: #909090;
}
/**
 * Copyright(c) 2020 Mozanta Technologies Private Ltd.
 * 
 * All rights reserved.
 * 
 * This software is the confidential and proprietary information of Mozanta ("Confidential
 * Information"). You shall not disclose such Confidential Information and shall use it only in
 * accordance with the terms of the contract agreement you entered into with Mozanta.
 * 
 * @author Indrajith C
 * 
 */
 .CustomMenuItemCreator_block1__3JBvi{
  padding-top: 20px;
  padding-bottom: 20px;
}
.CustomMenuItemCreator_block1__3JBvi .CustomMenuItemCreator_navigationManagementnText__3iHi6{
  text-align: left;
  color: #333333;
  padding-bottom: 17px;
}

.CustomMenuItemCreator_block1__3JBvi .CustomMenuItemCreator_buttonAddMenu__1QB-o{
  color: #ffffff;
  width: 113px;
  height: 35px;
  border-radius: 2px;
  background-color: #448aff;
  border: 0px;
  float: right;
}
.CustomMenuItemCreator_blockWrapper__2M0G3{
  min-height: calc(100vh - 385px);
}

.CustomMenuItemCreator_borderRadius2__3AKjU {
  border-radius: 0.125rem !important;
}

.CustomMenuItemCreator_secondaryColor__2kULU {
  background-color: #757575 !important;
  border-color: #757575 !important;
}

.CustomMenuItemCreator_primaryColor__3YTqK {
  background: #e91e63 !important;
  border-color: #e91e63 !important;
}

.CustomMenuItemCreator_btn__f0bN4 {
  min-width: 7rem;
}

/* ======================= form ================= */
.CustomMenuItemCreator_form__1Fq5C {

}

.CustomMenuItemCreator_form__1Fq5C .CustomMenuItemCreator_header__14oum {
  display: block;
}

.CustomMenuItemCreator_form__1Fq5C .CustomMenuItemCreator_formLabel__1LPNh {
  font-size: 0.875rem;
}

.CustomMenuItemCreator_form__1Fq5C .CustomMenuItemCreator_header__14oum .CustomMenuItemCreator_formLabel__1LPNh {
  opacity: 0.4;
}

.CustomMenuItemCreator_form__1Fq5C .CustomMenuItemCreator_noLabelFormGroup__1m688 {
  margin-top: 1.75rem;
  padding: 0.375rem 0.75rem;
} 

.CustomMenuItemCreator_form__1Fq5C .CustomMenuItemCreator_icon__3ze55 {
  width: 1.25rem;
}

.CustomMenuItemCreator_form__1Fq5C .CustomMenuItemCreator_imageUpload__yDjnW {
  border-radius: 5px;
  border: 1px dashed rgba(67, 148, 209, 0.5);
  text-align: center;
}

.CustomMenuItemCreator_form__1Fq5C .CustomMenuItemCreator_primaryTextColor__17Mxh { 
  color: #4394D1;
}

.CustomMenuItemCreator_form__1Fq5C .CustomMenuItemCreator_secondaryTextColor__1wKgx { 
  color: #909090;
}
/**
 * Copyright(c) 2020 Mozanta Technologies Private Ltd.
 * 
 * All rights reserved.                
 * 
 * This software is the confidential and proprietary information of Mozanta ("Confidential
 * Information"). You shall not disclose such Confidential Information and shall use it only in
 * accordance with the terms of the contract agreement you entered into with Mozanta.
 * 
 * @author Indrajith C
 */

 .CategorySelector_block2__1l6xa {
  padding-top: 20px;
  padding-bottom: 20px;
} 

.CategorySelector_borderRadius2__3JS8D {
  border-radius: 0.125rem !important;
}

.CategorySelector_secondaryColor__1KlXv {
  background-color: #757575 !important;
  border-color: #757575 !important;
}

.CategorySelector_primaryColor__3QLXy {
  background: #17a2b8 !important;
  border-color: #17a2b8 !important;
}

.CategorySelector_btn__3wSSo {
  min-width: 7rem;
} 

/* ============= PRODUCT TITLE ========= */
.CategorySelector_titleTextContainer__3mz5H {
  padding: 0 2.1rem 0.125rem 2.1rem;
  border-bottom: 1px solid #edf0f5;
  margin: 0 -0.938rem;
}

/** ========= PARENT CATEGORY  ================ **/

.CategorySelector_dropdownMenu__1_u40 {
  max-height: 60vh;
  overflow-y: auto;
  width: 100%;
  background-color: #fff;
}

.CategorySelector_dropdownMenu__1_u40::-webkit-scrollbar-track {
  background: #fff !important;
}

.CategorySelector_dropdown__2Pl60 > button {
  background: transparent !important;
  text-align: left !important;
  color: black !important;
  display: flex !important;
  align-items: center !important;
}

.CategorySelector_dropdown__2Pl60 > button .CategorySelector_clear__MZJ-1 {
  margin-left: auto;
  display: flex;
  padding: 0.125rem;
  border: 1px solid;
  border-radius: 2px;
  border-color: #b7b2b2;
}

.CategorySelector_dropdown__2Pl60 > button .CategorySelector_clear__MZJ-1> img {
  width: 20px;
  height: 20px;
}
/**
 * Copyright(c) 2020 Mozanta Technologies Private Ltd.
 * 
 * All rights reserved.                
 * 
 * This software is the confidential and proprietary information of Mozanta ("Confidential
 * Information"). You shall not disclose such Confidential Information and shall use it only in
 * accordance with the terms of the contract agreement you entered into with Mozanta.
 * 
 * @author Angela
 */

.SiteSettingsManagement_collapseIcon__3r2q->img {
  width: 15px;
  height: 15px;
  -webkit-transition: 0.6s ease-out;
  transition: 0.6s ease-out;
  float: right;
}

.SiteSettingsManagement_openCollapse__1W1C9 {
  -webkit-transform: rotateZ(-90deg);
  transform: rotateZ(-90deg);
}

.SiteSettingsManagement_closeCollapse__1JMvF {
  -webkit-transform: rotateZ(90deg);
  transform: rotateZ(90deg);
}

.SiteSettingsManagement_block2__2aOW6 {
  padding-top: 20px;
  padding-bottom: 20px;
}

.SiteSettingsManagement_borderRadius2__v5ngC {
  border-radius: 0.125rem !important;
}

.SiteSettingsManagement_secondaryColor__dyK0L {
  background-color: #757575 !important;
  border-color: #757575 !important;
}

.SiteSettingsManagement_primaryColor__ulKJ3 {
  background: #17a2b8 !important;
  border-color: #17a2b8 !important;
}


.SiteSettingsManagement_btn__3BvuK {
  min-width: 7rem;
}

.SiteSettingsManagement_outOfStockSwitch__3MaKG{
    margin-left: 15px;
}

.SiteSettingsManagement_zeroPriceProductStatusSwitch__2Sstf{
    margin-left: 15px;
}

/**
 * Copyright(c) 2020 Mozanta Technologies Private Ltd.
 *
 * All rights reserved.
 *
 * This software is the confidential and proprietary information of Mozanta
 * ("Confidential Information"). You shall not disclose such Confidential
 * Information and shall use it only in accordance with the terms of the
 * contract agreement you entered into with Mozanta.
 *
 * SiteTraficReport.module.css
 *
 * @author Naseef O
 *
 */

 .SiteTraficReport_block1__f9s7V {
   background: #ffffff;
   margin: 0px !important;
   padding-top: 10px !important;
   margin-bottom: 10px !important;
}

/* block 2 */
.SiteTraficReport_block2__14GEp{
  display: flex;
  justify-content: space-between;
}
.SiteTraficReport_block2__14GEp .SiteTraficReport_reportCard__pfJ-2{
  background: white;
  margin-bottom: 10px;
  flex: 0 0 32.333333%;

}
.SiteTraficReport_block2__14GEp .SiteTraficReport_reportCard__pfJ-2 .SiteTraficReport_headding__UF7ME{
  padding: 10px 5px;
  font-size: 16px;

}
.SiteTraficReport_block2__14GEp .SiteTraficReport_reportCard__pfJ-2 .SiteTraficReport_value__3qlc9{
  padding: 10px 5px;
  font-size: 30px;
  font-weight: 300;
}
/* block 3 */

.SiteTraficReport_block3__HrCfe{
    padding-bottom: 20px;
    background: #ffffff;
    margin: 0px !important;
    min-height: calc(100vh - 512px);
    margin-bottom: 10px !important;
}

.SiteTraficReport_block3__HrCfe  .SiteTraficReport_dateFilterRow__Xg15i{
  border-radius: 4px;
  border: 1px solid #dff3f7;
  background-color: #fdfdfd;
  color: black;
  width: 126px;
  float: right;
}

.SiteTraficReport_block3__HrCfe .SiteTraficReport_verticalLine__lgm-Q {
  height: 15px;
  padding: 5px;
}
.SiteTraficReport_block3__HrCfe .SiteTraficReport_verticalLineNone__27MZR {
  padding: 5px;
  border-radius: 4px;

}
.SiteTraficReport_block3__HrCfe .SiteTraficReport_verticalLine__lgm-Q:hover {
  background-color: #17a2b8;
  border-radius: 4px;
  color: rgb(255, 255, 255);
}

.SiteTraficReport_block3__HrCfe .SiteTraficReport_verticalLineNone__27MZR:hover {
  background-color: #17a2b8;
  border-radius: 4px;
  color: rgb(255, 255, 255);
}

/**
 * Copyright(c) 2020 Mozanta Technologies Private Ltd.
 *
 * All rights reserved.
 *
 * This software is the confidential and proprietary information of Mozanta
 * ("Confidential Information"). You shall not disclose such Confidential
 * Information and shall use it only in accordance with the terms of the
 * contract agreement you entered into with Mozanta.
 *
 * StaticPages.module.css
 *
 * @author Naseef O
 *
 */


/* block 1 */
 .StaticPages_block1__3u9bI {
   background: #ffffff;
   margin: 0px !important;
   padding-top: 10px !important;
   margin-bottom: 10px !important;
}

/* block 2 */
.StaticPages_block2__2q36e{
  padding-top: 16px !important;
  background: #ffffff;
  display: flex;
  justify-content: center;
  min-height: calc(100vh - 276px);
}


/* Pagination  */
.StaticPages_pagination__15P4A .StaticPages_item__LAz4r {
  height: 25px;
  width: 25px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}

.StaticPages_pagination__15P4A .StaticPages_arrowLeft__bYC7F, .StaticPages_pagination__15P4A .StaticPages_arrowRight__bIQ_Q {
  border-radius: 50%;
  border: 2px solid;
}

.StaticPages_pagination__15P4A .StaticPages_arrowLeft__bYC7F > span, .StaticPages_pagination__15P4A .StaticPages_arrowRight__bIQ_Q > span {
  border-bottom: 2px solid;
  border-right: 2px solid;
  width: 8px;
  height: 8px; 
  margin-top: -0.5px;
}

.StaticPages_pagination__15P4A .StaticPages_arrowLeft__bYC7F > span{ 
  -webkit-transform: rotate(127deg); 
          transform: rotate(127deg); 
  margin-right: -2.5px;
}

.StaticPages_pagination__15P4A .StaticPages_arrowRight__bIQ_Q > span{ 
  -webkit-transform: rotate(315deg); 
          transform: rotate(315deg); 
  margin-left: -2.5px;
}

.StaticPages_pagination__15P4A .StaticPages_arrowLeft__bYC7F , .StaticPages_pagination__15P4A .StaticPages_arrowRight__bIQ_Q, .StaticPages_pagination__15P4A .StaticPages_item__LAz4r[active] {
  color: #e91e63;
  font-weight: 600;
}

.StaticPages_pagination__15P4A .StaticPages_item__LAz4r[active] {
  cursor: default;
}

.StaticPages_pagination__15P4A .StaticPages_item__LAz4r[disabled] {
  cursor: not-allowed;
  color: #5d5d5d;
}

.StaticPages_borderRadius2__34j1O {
 border-radius: 0.125rem !important;
}

.StaticPages_btnColor__2ZGi4{
 background: #229E79 !important;
 border-color: #229E79 !important;
}

.StaticPages_block2__2q36e{
 display: flex;
 justify-content: center;
}

/**
 * Copyright(c) 2020 Mozanta Technologies Private Ltd.
 *
 * All rights reserved.
 *
 * This software is the confidential and proprietary information of Mozanta
 * ("Confidential Information"). You shall not disclose such Confidential
 * Information and shall use it only in accordance with the terms of the
 * contract agreement you entered into with Mozanta.
 *
 * Table.module.css
 *
 * @author Naseef O
 *
 */


.Table_spinnerWrapper__fliOO{
	width: 1.5rem !important;
	height: 1.5rem !important;
	color: #17a2b8;
}

.Table_liveBtnWrapper__1edCQ .Table_liveBtn__14omj{
  border: none;
  border-radius: 2px;
  text-transform: uppercase;
}


/**
 * Copyright(c) 2020 Mozanta Technologies Private Ltd.
 *
 * All rights reserved.
 *
 * This software is the confidential and proprietary information of Mozanta
 * ("Confidential Information"). You shall not disclose such Confidential
 * Information and shall use it only in accordance with the terms of the
 * contract agreement you entered into with Mozanta.
 *
 * EditPage.module.css
 *
 * @author Naseef O
 *
 */


/* block 1 */
 .EditPage_block1__1g7H_ {
   background: #ffffff;
   margin: 0px !important;
   padding-top: 10px !important;
   margin-bottom: 10px !important;
}

/* block 2 */
.EditPage_block2__Idrws{
  padding-top: 16px !important;
  background: #ffffff;
  display: flex;
  justify-content: center;
}

/**
 * Copyright(c) 2020 Mozanta Technologies Private Ltd.
 * 
 * All rights reserved.                
 * 
 * This software is the confidential and proprietary information of Mozanta ("Confidential
 * Information"). You shall not disclose such Confidential Information and shall use it only in
 * accordance with the terms of the contract agreement you entered into with Mozanta.
 * 
 * @author Aardra S
 */

.StoreManagement_block3__3ywZc .StoreManagement_store__XUXUT .StoreManagement_storeBody__crJez .StoreManagement_storeTable__34SA2 thead {
  background-color: #edf0f5;
}

.StoreManagement_block1__2g_R4 .StoreManagement_buttonNewStore__d9I7i{
    color: #ffffff;
    width: 160px;
    border-radius: 2px;
    border: 0px;
    float: right;
  }
  
  .StoreManagement_liveBtnWrapper__YG8Qr .StoreManagement_liveBtn__37cyC{
    border: none;
    border-radius: 2px;
    text-transform: uppercase;
  }
.styles_searchBoxWrapper__18t6h {
  position: absolute;
    top: 10%;
    left: 10%;
    right: 10%;
    z-index: 3;
}

.styles_searchBoxWrapper__18t6h input {
  box-shadow:
  0 2.8px 2.2px rgba(0, 0, 0, 0.034),
  0 6.7px 5.3px rgba(0, 0, 0, 0.048),
  0 12.5px 10px rgba(0, 0, 0, 0.06),
  0 22.3px 17.9px rgba(0, 0, 0, 0.072),
  0 41.8px 33.4px rgba(0, 0, 0, 0.086),
  0 100px 80px rgba(0, 0, 0, 0.12) !important;
border: 0;
border-radius: 50px;
border: 1px solid whitesmoke;
padding-right: 2.4rem;
}

.styles_searchBoxWrapper__18t6h input:focus {
  box-shadow:
  0 2.8px 2.2px rgba(0, 0, 0, 0.034),
  0 6.7px 5.3px rgba(0, 0, 0, 0.048),
  0 12.5px 10px rgba(0, 0, 0, 0.06),
  0 22.3px 17.9px rgba(0, 0, 0, 0.072),
  0 41.8px 33.4px rgba(0, 0, 0, 0.086),
  0 100px 80px rgba(0, 0, 0, 0.12) !important;
}

.styles_searchBoxWrapper__18t6h svg {
  position: absolute;
  right: 14px;
  top: 12px;
}

.styles_marker__Zqrcr {
  position: absolute;
    top: 60%;
    left: 30%;
    width: 30px;
    height: 30px;
    -webkit-transform: translate(-50%, -50%);
            transform: translate(-50%, -50%);
    cursor: pointer;
}

.styles_marker__Zqrcr.styles_draggable__17wE5 {
  cursor: -webkit-grab;
  cursor: grab;
}
.styles_marker__Zqrcr.styles_dragging__1jpBv {
  cursor: -webkit-grabbing;
  cursor: grabbing;
}

.styles_marker__Zqrcr:hover {
  z-index: 1;
}

.styles_pin__28bJ5 {
  width: 20px;
  height: 20px;
  border-radius: 50% 50% 50% 0;
  background: #e91e63;
  position: absolute;
  -webkit-transform: rotate(-45deg);
          transform: rotate(-45deg);
  left: 50%;
  top: 50%; 
  margin: -7px 0 0 -15px;
  border: 0.05em solid #ffffff; 
}
.styles_pin__28bJ5:after {
  content: "";
  width: 14px;
  height: 14px;
  margin: 8px 0 0 8px; 
  position: absolute;
  border-radius: 50%;
}

.styles_pin__28bJ5.styles_selected__3b5eb {
background-color: #229E79;
}

.styles_bounce__NnMcF {
  -webkit-animation-name: styles_bounce__NnMcF;
          animation-name: styles_bounce__NnMcF;
  -webkit-animation-fill-mode: both;
          animation-fill-mode: both;
  -webkit-animation-duration: 1s;
          animation-duration: 1s;
}

.styles_pulse__3Oyr7 { 
  border-radius: 50%;
  height: 14px;
  width: 14px;
  position: absolute;
  left: 50%;
  top: 50%;
  margin: 11px 0px 0px -12px;
  -webkit-transform: rotateX(55deg);
          transform: rotateX(55deg);
  z-index: -2;
}

.styles_pulse__3Oyr7:after {
  content: "";
  border-radius: 50%;
  height: 40px;
  width: 40px;
  position: absolute;
  margin: -13px 0 0 -13px;
  -webkit-animation: styles_pulsate__ToAAH 1s ease-out;
          animation: styles_pulsate__ToAAH 1s ease-out;
  -webkit-animation-iteration-count: infinite;
          animation-iteration-count: infinite;
  opacity: 0;
  box-shadow: 0 0 1px 2px #e91e63;
  -webkit-animation-delay: 1.1s;
          animation-delay: 1.1s;
}

@-webkit-keyframes styles_pulsate__ToAAH {
  0% {
    -webkit-transform: scale(0.1, 0.1);
            transform: scale(0.1, 0.1);
    opacity: 0;
  }

  50% {
    opacity: 1;
  }

  100% {
    -webkit-transform: scale(1.2, 1.2);
            transform: scale(1.2, 1.2);
    opacity: 0;
  }
}

@keyframes styles_pulsate__ToAAH {
  0% {
    -webkit-transform: scale(0.1, 0.1);
            transform: scale(0.1, 0.1);
    opacity: 0;
  }

  50% {
    opacity: 1;
  }

  100% {
    -webkit-transform: scale(1.2, 1.2);
            transform: scale(1.2, 1.2);
    opacity: 0;
  }
}

@-webkit-keyframes styles_bounce__NnMcF {
  0% {
    opacity: 0;
    -webkit-transform: translateY(-2000px) rotate(-45deg);
            transform: translateY(-2000px) rotate(-45deg);
  }

  60% {
    opacity: 1;
    -webkit-transform: translateY(30px) rotate(-45deg);
            transform: translateY(30px) rotate(-45deg);
  }

  80% {
    -webkit-transform: translateY(-10px) rotate(-45deg);
            transform: translateY(-10px) rotate(-45deg);
  }

  100% {
    -webkit-transform: translateY(0) rotate(-45deg);
            transform: translateY(0) rotate(-45deg);
  }
}

@keyframes styles_bounce__NnMcF {
  0% {
    opacity: 0;
    -webkit-transform: translateY(-2000px) rotate(-45deg);
            transform: translateY(-2000px) rotate(-45deg);
  }

  60% {
    opacity: 1;
    -webkit-transform: translateY(30px) rotate(-45deg);
            transform: translateY(30px) rotate(-45deg);
  }

  80% {
    -webkit-transform: translateY(-10px) rotate(-45deg);
            transform: translateY(-10px) rotate(-45deg);
  }

  100% {
    -webkit-transform: translateY(0) rotate(-45deg);
            transform: translateY(0) rotate(-45deg);
  }
}
/**
 * Copyright(c) 2020 Mozanta Technologies Private Ltd.
 * 
 * All rights reserved.                
 * 
 * This software is the confidential and proprietary information of Mozanta ("Confidential
 * Information"). You shall not disclose such Confidential Information and shall use it only in
 * accordance with the terms of the contract agreement you entered into with Mozanta.
 * 
 * @author Aardra S
 */

.StoreForm_block3__spF9y {
    padding: 1.25rem;
  }

  .StoreForm_block3__spF9y .StoreForm_noLabelFormGroup__2-9y_ {
    margin-top: 1.75rem;
    padding: 0.375rem 0.75rem;
  }
/**
 * Copyright(c) 2020 Mozanta Technologies Private Ltd.
 * 
 * All rights reserved.                
 * 
 * This software is the confidential and proprietary information of Mozanta ("Confidential
 * Information"). You shall not disclose such Confidential Information and shall use it only in
 * accordance with the terms of the contract agreement you entered into with Mozanta.
 * 
 * @author Aardra S
 */

.AddStore_block2__2YWxo {
    padding-top: 20px;
    padding-bottom: 20px;
  } 
  
  .AddStore_borderRadius2__1_-MN {
    border-radius: 0.125rem !important;
  }
  
  .AddStore_secondaryColor__3qbfK {
    background-color: #757575 !important;
    border-color: #757575 !important;
  }
  
  .AddStore_primaryColor__10Z3- {
    background: #17a2b8 !important;
    border-color: #17a2b8 !important;
  }
  
  .AddStore_btn__1dR50 {
    min-width: 7rem;
  }
  
/**
 * Copyright(c) 2020 Mozanta Technologies Private Ltd.
 * 
 * All rights reserved.                
 * 
 * This software is the confidential and proprietary information of Mozanta ("Confidential
 * Information"). You shall not disclose such Confidential Information and shall use it only in
 * accordance with the terms of the contract agreement you entered into with Mozanta.
 * 
 * @author Aardra S
 */
.EditStore_block2__33Q4U {
    padding-top: 20px;
    padding-bottom: 20px;
  } 
  
  .EditStore_borderRadius2__28hQt {
    border-radius: 0.125rem !important;
  }
  
  .EditStore_secondaryColor__1rKGN {
    background-color: #757575 !important;
    border-color: #757575 !important;
  }
  
  .EditStore_primaryColor__3_k4C {
    background: #17a2b8 !important;
    border-color: #17a2b8 !important;
  }
  
  .EditStore_btn__2uyeD {
    min-width: 7rem;
  }
  
/**
 * Copyright(c) 2020 Mozanta Technologies Private Ltd.
 *
 * All rights reserved.
 *
 * This software is the confidential and proprietary information of Mozanta ("Confidential
 * Information"). You shall not disclose such Confidential Information and shall use it only in
 * accordance with the terms of the contract agreement you entered into with Mozanta.
 *
 * @author Amjad Rehman A
 */

.TaxClasses_borderRadius2__3DsJA {
  border-radius: 0.125rem !important;
}

/** =========== ACTION AREA STYLES ============== **/
.TaxClasses_areaActionRight__GQzA6 {
  display: flex;
  justify-content: flex-end;
}

.TaxClasses_secondaryColor__2RmO4,
.TaxClasses_secondaryColor__2RmO4:focus,
.TaxClasses_secondaryColor__2RmO4:active {
  background-color: #757575 !important;
  border-color: #757575 !important;
}

.TaxClasses_tableWrapper__1YcgY{
  min-height: calc(100vh - 365px);
}
/**
 * Copyright(c) 2020 Mozanta Technologies Private Ltd.
 *
 * All rights reserved.
 *
 * This software is the confidential and proprietary information of Mozanta ("Confidential
 * Information"). You shall not disclose such Confidential Information and shall use it only in
 * accordance with the terms of the contract agreement you entered into with Mozanta.
 *
 * @author Amjad Rehman A
 */

.TaxLocation_borderRadius2__cTvC3 {
  border-radius: 0.125rem !important;
}

/** =========== ACTION AREA STYLES ============== **/
.TaxLocation_areaActionRight__180Mz {
  display: flex;
  justify-content: flex-end;
}

.TaxLocation_secondaryColor__3SjRJ,
.TaxLocation_secondaryColor__3SjRJ:focus,
.TaxLocation_secondaryColor__3SjRJ:active {
  background-color: #757575 !important;
  border-color: #757575 !important;
}

.TaxLocation_secondaryColor__3SjRJ:hover {
  background-color: #919191 !important;
  border-color: #919191 !important;
}

.TaxLocation_border__bky5B {
  border: 1px solid #c4c4c4;
}

.TaxLocation_tableWrapper__1Faa0{
  min-height: calc(100vh - 365px);
}

.TaxLocation_pointer__Erdiz{
  cursor: pointer;
}
/**
 * Copyright(c) 2020 Mozanta Technologies Private Ltd.
 * 
 * All rights reserved.                
 * 
 * This software is the confidential and proprietary information of Mozanta ("Confidential
 * Information"). You shall not disclose such Confidential Information and shall use it only in
 * accordance with the terms of the contract agreement you entered into with Mozanta.
 * 
 * @author Aardra S
 */

 .URLRedirectsManagement_block1__35Rtd .URLRedirectsManagement_primaryColor__2KDCN {
  background: #17a2b8 !important;
  border-color: #17a2b8 !important;
}

.URLRedirectsManagement_block1__35Rtd .URLRedirectsManagement_borderRadius__2HpSr {
  border-radius: 0.125rem;
}

.URLRedirectsManagement_block1__35Rtd .URLRedirectsManagement_btn__1fkBH {
  min-width: 11rem;
}

  /***** ===================== PAGINATION ======================= ******/

  .URLRedirectsManagement_pagination__2Tl7P .URLRedirectsManagement_item__12sXv {
   height: 25px;
   width: 25px;
   display: flex;
   justify-content: center;
   align-items: center;
   cursor: pointer;
 }

  .URLRedirectsManagement_pagination__2Tl7P .URLRedirectsManagement_arrowLeft__22tWu,  .URLRedirectsManagement_pagination__2Tl7P .URLRedirectsManagement_arrowRight__1_8-v {
   border-radius: 50%;
   border: 2px solid;
 }

  .URLRedirectsManagement_pagination__2Tl7P .URLRedirectsManagement_arrowLeft__22tWu > span,  .URLRedirectsManagement_pagination__2Tl7P .URLRedirectsManagement_arrowRight__1_8-v > span {
   border-bottom: 2px solid;
   border-right: 2px solid;
   width: 8px;
   height: 8px; 
   margin-top: -0.5px;
 }

  .URLRedirectsManagement_pagination__2Tl7P .URLRedirectsManagement_arrowLeft__22tWu > span{ 
   -webkit-transform: rotate(127deg); 
           transform: rotate(127deg); 
   margin-right: -2.5px;
 }

  .URLRedirectsManagement_pagination__2Tl7P .URLRedirectsManagement_arrowRight__1_8-v > span{ 
   -webkit-transform: rotate(315deg); 
           transform: rotate(315deg); 
   margin-left: -2.5px;
 }

  .URLRedirectsManagement_pagination__2Tl7P .URLRedirectsManagement_arrowLeft__22tWu ,  .URLRedirectsManagement_pagination__2Tl7P .URLRedirectsManagement_arrowRight__1_8-v,  .URLRedirectsManagement_pagination__2Tl7P .URLRedirectsManagement_item__12sXv[active] {
   color: #e91e63;
   font-weight: 600;
 }

  .URLRedirectsManagement_pagination__2Tl7P .URLRedirectsManagement_item__12sXv[active] {
   cursor: default;
 }

  .URLRedirectsManagement_pagination__2Tl7P .URLRedirectsManagement_item__12sXv[disabled] {
   cursor: not-allowed;
   color: #5d5d5d;
 }
  
/**
 * Copyright(c) 2020 Mozanta Technologies Private Ltd.
 * 
 * All rights reserved.                
 * 
 * This software is the confidential and proprietary information of Mozanta ("Confidential
 * Information"). You shall not disclose such Confidential Information and shall use it only in
 * accordance with the terms of the contract agreement you entered into with Mozanta.
 * 
 * @author Aardra S
 */

 .AddURLRedirects_borderRadius2__ISM6A {
  border-radius: 0.125rem;
}

.AddURLRedirects_secondaryColor__1rai3 {
  background-color: #757575 !important;
  border-color: #757575 !important;
}

.AddURLRedirects_primaryColor__26ocM {
  background: #e91e63 !important;
  border-color: #e91e63 !important;
}

.AddURLRedirects_btn1__osklM {
  min-width: 6rem;
}

.AddURLRedirects_btn2__1ZU-F {
  min-width: 9rem;
}


/**
 * Copyright(c) 2020 Mozanta Technologies Private Ltd.
 *
 * All rights reserved.
 *
 * This software is the confidential and proprietary information of Mozanta
 * ("Confidential Information"). You shall not disclose such Confidential
 * Information and shall use it only in accordance with the terms of the
 * contract agreement you entered into with Mozanta.
 *
 * UserManagement.module.css
 *
 * @author Naseef O
 *
 */

 .UserManagement_block1__lk-1_ .UserManagement_buttonNewUser__38LgE{
  color: #ffffff;
  width: 113px;
  border-radius: 2px;
  background-color: #e91e63;
  border: 0px;
  float: right;
}


/* block 2 */

  /** =========== ACTION AREA STYLES ============== **/
  .UserManagement_block2__1Pob_ .UserManagement_areaActionRight__LhZ1a {
    display: flex;
    justify-content: flex-end;
  }
  
  .UserManagement_block2__1Pob_ .UserManagement_borderRadius2__2o1xS {
    border-radius: 0.125rem;
  }
  
  .UserManagement_block2__1Pob_ .UserManagement_areaActionRight__LhZ1a > div:first-child {
    margin-right: 0.3rem;
  }
  .UserManagement_block2__1Pob_ .UserManagement_areaActionRight__LhZ1a > div:last-child {
    display: flex;
  }
  
  /** ========= SEARCH FILED ================ **/
  .UserManagement_block2__1Pob_ .UserManagement_areaActionRight__LhZ1a .UserManagement_search__KvCS- {
    border: 1px solid;
    border-radius: 0.125rem;
  }
  
  .UserManagement_block2__1Pob_ .UserManagement_areaActionRight__LhZ1a .UserManagement_search__KvCS- input {
    border: 0;
    width: 100%;
    height: calc(1.5em + 0.75rem + 0px);
  }
  
  .UserManagement_block2__1Pob_ .UserManagement_areaActionRight__LhZ1a .UserManagement_search__KvCS- div {
    border: 0;
    border-radius: 0;
    background-color: inherit;
    padding-left: 0.6rem;
    padding-right: 0.35rem;
  }
  .UserManagement_pagination__2w_31 .UserManagement_item__fel_v {
    height: 25px;
    width: 25px;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
  }
  
  .UserManagement_pagination__2w_31 .UserManagement_arrowLeft__1aFsm,
  .UserManagement_pagination__2w_31 .UserManagement_arrowRight__v_xVg {
    border-radius: 50%;
    border: 2px solid;
  }
  
  .UserManagement_pagination__2w_31 .UserManagement_arrowLeft__1aFsm > span,
  .UserManagement_pagination__2w_31 .UserManagement_arrowRight__v_xVg > span {
    border-bottom: 2px solid;
    border-right: 2px solid;
    width: 8px;
    height: 8px;
    margin-top: -0.5px;
  }
  
  .UserManagement_pagination__2w_31 .UserManagement_arrowLeft__1aFsm > span {
    -webkit-transform: rotate(127deg);
            transform: rotate(127deg);
    margin-right: -2.5px;
  }
  
  .UserManagement_pagination__2w_31 .UserManagement_arrowRight__v_xVg > span {
    -webkit-transform: rotate(315deg);
            transform: rotate(315deg);
    margin-left: -2.5px;
  }
  
  .UserManagement_pagination__2w_31 .UserManagement_arrowLeft__1aFsm,
  .UserManagement_pagination__2w_31 .UserManagement_arrowRight__v_xVg,
  .UserManagement_pagination__2w_31 .UserManagement_item__fel_v[active] {
    color: #e91e63;
    font-weight: 600;
  }
  
  .UserManagement_pagination__2w_31 .UserManagement_item__fel_v[active] {
    cursor: default;
  }
  
  .UserManagement_pagination__2w_31 .UserManagement_item__fel_v[disabled] {
    cursor: not-allowed;
    color: #5d5d5d;
  }
  .UserManagement_tableWrapper__PfZ7B{
    min-height: calc(100vh - 424px);
  }
.CreateNewUser_createUserLayout__2WEp_ .CreateNewUser_BtnSecondary__3LpWq .CreateNewUser_Btn__25t4N,
.CreateNewUser_createUserLayout__2WEp_ .CreateNewUser_BtnSecondary__3LpWq .CreateNewUser_Btn__25t4N:active,
.CreateNewUser_createUserLayout__2WEp_ .CreateNewUser_BtnSecondary__3LpWq .CreateNewUser_Btn__25t4N:focus {
  background-color: #fff !important;
  color: #000 !important;
  border: 1px solid #ced4da;
  border-radius: 2px;
  box-shadow: none !important;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.CreateNewUser_createUserLayout__2WEp_ .CreateNewUser_DropdownMenu__CY34L {
  min-width: 100%;
  padding: 0;
  background: #f9f9f9;
  box-shadow: 2px 2px 2px rgba(0, 0, 0, 0.08);
}
.CreateNewUser_createUserLayout__2WEp_ .CreateNewUser_DropdownMenu__CY34L .CreateNewUser_DropdownItem__sJiPA {
  padding: 0.3rem 1rem 0.5rem;
  cursor: pointer;
}
.CreateNewUser_createUserLayout__2WEp_ .CreateNewUser_DropdownMenu__CY34L .CreateNewUser_DropdownItem__sJiPA:hover {
  background-color: #edf0f5;
}
.CreateNewUser_createUserLayout__2WEp_ .CreateNewUser_DropdownMenu__CY34L .CreateNewUser_DropdownItem__sJiPA:focus {
  outline: 0;
}
.CreateNewUser_createUserLayout__2WEp_ .CreateNewUser_DropdownMenu__CY34L .CreateNewUser_DropdownItem__sJiPA:active {
  background-color: #edf0f5;
  color: #000;
  outline: 0;
}
.CreateNewUser_createUserLayout__2WEp_ .CreateNewUser_FormCheckInput__34Mhj {
  margin-top: 0.4rem;
}
.CreateNewUser_createUserLayout__2WEp_ .CreateNewUser_btnTertiary__32OmA {
  color: #fff !important;
  background-color: #17a3b8 !important;
  border-color: #17a3b8 !important;
}
.CreateNewUser_createUserLayout__2WEp_ .CreateNewUser_BtnOk__34GP9 {
  padding: 0.2rem 0.5rem 0.4rem;
  margin: 0.25rem 0;
  border-radius: 2px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #17a3b8;
  border-color: #17a3b8;
}
.CreateNewUser_createUserLayout__2WEp_ .CreateNewUser_errorText__1LEUq {
  width: 100%;
  margin-top: 0.25rem;
  font-size: 80%;
  color: #dc3545;
}

/**
 * Copyright(c) 2021 Mozanta Technologies Private Ltd.
 *
 * All rights reserved.
 *
 * This software is the confidential and proprietary information of Mozanta ("Confidential
 * Information"). You shall not disclose such Confidential Information and shall use it only in
 * accordance with the terms of the contract agreement you entered into with Mozanta.
 *
 * @author amritha
 */

 .PreOrderManagement_borderRadius2__2VJ8U {
    border-radius: 0.125rem !important;
  }
  
  /** =========== ACTION AREA STYLES ============== **/
  .PreOrderManagement_areaActionRight__2ueiD {
    display: flex;
    justify-content: flex-end;
  }
  
  .PreOrderManagement_secondaryColor__1YSwH,
  .PreOrderManagement_secondaryColor__1YSwH:focus,
  .PreOrderManagement_secondaryColor__1YSwH:active {
    background-color: #757575 !important;
    border-color: #757575 !important;
  }
  
  .PreOrderManagement_tableWrapper__3aiNf{
    min-height: calc(10vh - 365px);
  }
  
  
  .PreOrderManagement_displayData__2YPAG {
    background: #f9f9f9;
    padding: 1rem;
  }
  
  .PreOrderManagement_dataTableList__3HMdN {
    width: 100%;
    list-style: none;
    padding: 0;
    margin: 0 auto;
    background-color: #fff;
    border-radius: .25rem;
    overflow: hidden;
  }
  
  @media (max-width: 992px) {
    .PreOrderManagement_dataTableList__3HMdN {
      max-width: 100%;
    }
  }
  .PreOrderManagement_dataTableList__3HMdN li {
    display: flex;
    flex-direction: row;
    padding: 0.75rem 1rem;
    border-bottom: 1px solid #e3e7eb;
  }
  
  .PreOrderManagement_dataTableList__3HMdN li:hover {
    background-color: #fcfcfc;
  }
  
  .PreOrderManagement_dataTableList__3HMdN li .PreOrderManagement_dataTableTitle__NJiT7 {
    width: 30%;
    flex: 0 0 30%;
    color: #8f9bb3;
    word-break: break-word;
  }
  
  .PreOrderManagement_dataTableList__3HMdN li .PreOrderManagement_dataTableText__3VsZP {
    width: calc(70% - 1rem);
    flex-grow: 0;
    flex-shrink: 0;
    flex-basis: calc(70% - 1rem);
    padding-left: 1rem;
    word-break: break-word;
  }
  .PreOrderManagement_input-done__3Uz20 .PreOrderManagement_icon__1EmW5{
    width :1em
  }

/**
 * Copyright(c) 2021 Mozanta Technologies Private Ltd.
 *
 * All rights reserved.
 *
 * This software is the confidential and proprietary information of Mozanta
 * ("Confidential Information"). You shall not disclose such Confidential
 * Information and shall use it only in accordance with the terms of the
 * contract agreement you entered into with Mozanta.
 *
 * EditPreOrder.module.css
 *
 * @author Amritha
 *
 */

  

 .EditPreOrder_block2__HLDYK {
    padding-top: 20px;
    padding-bottom: 20px;
  }
  
  /** =========== ACTION AREA STYLES ============== **/
  .EditPreOrder_block2__HLDYK .EditPreOrder_areaActionRight__I_lyS {
    display: flex;
    justify-content: flex-end;
  }
  
  .EditPreOrder_block2__HLDYK .EditPreOrder_borderRadius2__HNf9Y {
    border-radius: 0.125rem;
  }
  
  .EditPreOrder_block2__HLDYK .EditPreOrder_areaActionRight__I_lyS > div:first-child {
    margin-right: 0.3rem;
  }
  .EditPreOrder_block2__HLDYK .EditPreOrder_areaActionRight__I_lyS > div:last-child {
    display: flex;
  }
  
  /** ========= SEARCH FILED ================ **/
  .EditPreOrder_block2__HLDYK .EditPreOrder_areaActionRight__I_lyS .EditPreOrder_search__vVYhL {
    border: 1px solid;
    border-radius: 0.125rem;
  }
  
  .EditPreOrder_block2__HLDYK .EditPreOrder_areaActionRight__I_lyS .EditPreOrder_search__vVYhL input {
    border: 0;
    width: 100%;
    height: calc(1.5em + 0.75rem + 0px);
  }
  
  .EditPreOrder_block2__HLDYK .EditPreOrder_areaActionRight__I_lyS .EditPreOrder_search__vVYhL div {
    border: 0;
    border-radius: 0;
    background-color: inherit;
    padding-left: 0.6rem;
    padding-right: 0.35rem;
  }
  
    .EditPreOrder_tableWrapper__b2Rgy  {
      min-height: calc(100vh - 420px);
     }
  
     .EditPreOrder_buttonGreen__25Wyv{
       background-color: white !important;
       color:green!important;
      border-color: green !important;
      min-width: 85px !important;
     }
     .EditPreOrder_buttonRed__1QTlR{
       background-color: white !important;
       color:red !important;
      border-color: red !important; 
      min-width: 85px !important;
     }
  
     .EditPreOrder_spinnerStyle__1pQMk{
       width:20px !important;
       height:20px !important;
     }
.B2BAnalytics_nocolor__opB95 {
  background-color: transparent;
}

.B2BAnalytics_filterCard__e0TeZ {
  margin: 0px !important;
  padding-top: 20px !important;
  padding-bottom: 20px !important;
  margin-bottom: 10px !important;
  ;
}

.B2BAnalytics_filterHeader__3PsDx {
  padding-left: 30px;
  color: rgba(128, 128, 128, 1);
  ;
}

.B2BAnalytics_filterDiv__1zTTK {
  padding-top: "11%";
  padding-left: "2.5%";
  color: "#808080";
  align-items: center;
}

.B2BAnalytics_card__d2Um2 {
  z-index: 90;
  background-color: #F8F9FA;
  top: 75px;
  width: 98%;
}

.B2BAnalytics_topcard__1ULV9 {
  padding-top: 180px;
}
/**
 * Copyright(c) 2023 Mozanta Technologies Private Ltd.
 *
 * All rights reserved.
 *
 * This software is the confidential and proprietary information of Mozanta ("Confidential
 * Information"). You shall not disclose such Confidential Information and shall use it only in
 * accordance with the terms of the contract agreement you entered into with Mozanta.
 *
 * @author kishor
 *
 */

.Status_statusIcon__2PzEW {
  width: 39px;
  height: 39px;
  border-radius: 30px;
  padding-left: 12px;
  padding-top: 7.5px;
}

.Status_statusItem__3BKpu {
  background-color: #FFFFFF;
  border-radius: 10px;
  padding: 22px;
  margin: 5px;
  width: 32px;
  position: fixed;
  z-Index: "100",
}

.Status_currencyCode__2fBRb {
  font-size: 16px;
  padding-left: 1px;
  color: #212529;
}

.Status_textTitle__3vJK_ {
  font-style: normal;
  font-size: 16px;
  font-weight: 500;
  color: #212529;
  margin-top: 14px;

}

.Status_numberTitle__3zlBl {
  font-style: normal;
  font-size: 20px;
  font-weight: 700;
  color: #212529;
}

.Status_description__1JB3Y {
  color: #949292;
  font-size: 14px;
}

.Status_errorTitle__21N15 {
  font-style: normal;
  font-size: 16px;
  font-weight: 500;
  color: #F32013;
  padding-top: 14px;
}
