/**
 * Copyright(c) 2020 Mozanta Technologies Private Ltd.
 * 
 * All rights reserved.                
 * 
 * This software is the confidential and proprietary information of Mozanta ("Confidential
 * Information"). You shall not disclose such Confidential Information and shall use it only in
 * accordance with the terms of the contract agreement you entered into with Mozanta.
 * 
 * @author Indrajith C
 */

.categoryTreeItem {
}

.categoryTreeItem .toggleButton {
}

.categoryTreeItem .noDescendants {
  padding-left: 20px;
}

.categoryTreeItem .isOpen {
  color: rgba(64, 177, 196);;
}

.categoryTreeItem .isClosed {
}

.categoryTreeItem img.icon {
  width: 20px;
}

.categoryTreeItem .toggleButton > img {
  width: 20px;
}

.categoryTreeItem .categoryContent .categoryTable thead {
  background-color: #edf0f5;
}

.categoryTreeItem .loading {
  cursor: wait !important;
}

.categoryTreeItem .treeLineAnimationRootComponent > span {
  min-width: 1rem;
  position: relative;
}

/* ============== for tree line ============= */
.categoryTreeItem .treeLineAnimationRootComponent > span:last-of-type::after {
  background: rgba(64, 177, 196, 0.1);
  position: absolute;
  left: 50%;
  content: " ";
  height: 2px;
  top: 50%;
  width: 50%;
  right: 0;
}
 
/** =========== using tag name directly because of css module dynamic class name for child component */
.categoryTreeItem .treeLineAnimationRootComponent > span:before {
  background:rgba(64, 177, 196, 0.1);
  position: absolute;
  left: 50%;
  content: " ";
  height: 100%;
  width: 2px;
  bottom: 0;
}

.categoryTreeItem .treeLineAnimationRootComponent .itemTitle {
  background: #fff;
  z-index: 1;
  margin: 12px 0;
}

/** ========== fix for td height equal to tr ============= */
.categoryTreeItem .tableTd {
 height: 0px;
}

/* ============= table actions ===================== */
.categoryTreeItem .actions > span[disabled]  { 
  /* cursor: not-allowed; */
  pointer-events: none !important;
}

.categoryTreeItem .actions > span[disabled] > img {
  opacity: 0.4; 
}

/*** ============= BASIC FONT CLASSES ***/
.categoryTreeItem .fontWeightLight {
  font-weight: 300;
}

.categoryTreeItem .fontWeightRegular {
  font-weight: 400;
}

.categoryTreeItem .fontWeightMedium {
  font-weight: 500;
}

.categoryTreeItem .fontWeightBold {
  font-weight: 700;
}