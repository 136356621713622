/**
 * Copyright(c) 2020 Mozanta Technologies Private Ltd.
 * 
 * All rights reserved.                
 * 
 * This software is the confidential and proprietary information of Mozanta ("Confidential
 * Information"). You shall not disclose such Confidential Information and shall use it only in
 * accordance with the terms of the contract agreement you entered into with Mozanta.
 * 
 * @author Indrajith C
 */

 .block2 {
  padding-top: 20px;
  padding-bottom: 20px;
} 

.borderRadius2 {
  border-radius: 0.125rem !important;
}

.secondaryColor {
  background-color: #757575 !important;
  border-color: #757575 !important;
}

.primaryColor {
  background: #e91e63 !important;
  border-color: #e91e63 !important;
}

.btn {
  min-width: 7rem;
}

.icon {
  width: 1.25rem;
}

/* ============= PRODUCT TITLE ========= */
.titleTextContainer {
  padding: 0 2.1rem 0.125rem 2.1rem;
  border-bottom: 1px solid #edf0f5;
  margin: 0 -0.938rem;
}

/* =============== PRODUCT AREA =============== */
.block4 {
  padding: 1.25rem;
}

.block4 .borderBottom {
border-bottom: 1px solid ;
}


/** ========= SEARCH FILED ================ **/
.block4 .search {
border: 1px solid #e91e63;
border-radius: 0.125rem;
}

.block4 .search input {
border: 0;
width: 100%;
}

.block4 .search div {
border: 0;
border-radius: 0;
background-color: #e91e63;
padding-left: 0.6rem;
padding-right: 0.35rem;
}

.block4 img.icon {
width: 20px;
}

.block4 .rightDivider::before {
position: absolute;
background: #edf0f5;
width: 1px;
right: 0;
height: calc( 100% - 32px );
bottom: 0;
}

.block4 .searchListContainer {
height: 350px;
overflow-y: auto;
}

.block4 .selectedListContainer {
height: 390px; 
}

.block4 .selectedListContainer .maxWidthIcon {
  max-width: calc( 100% - 22px );
}

/** ========= MEDIA QUERY ============ **/
@media (min-width: 576px) {
.block4 .rightDivider::before  {
  content: " ";
}
}

.block4 .borderBottom {
border-bottom: 1px solid ;
}
