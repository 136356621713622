/*  ======== chip component =========   */
.chip {
    padding: 10px;
    border-radius: 50px;
    border: 1px solid;
    border-color: #b7b2b2;
    align-items: center;
    margin: 1px 0;
    margin-top: 10px;
}

.chip:not(:last-child) {
    margin-right: 0.125rem;
}

.span.chip > span.remove {
    border-radius: 50%;
    border: 1px solid;
    width: 20px;
    height: 20px;
    margin-left: auto;
    display: flex;
    border-color: #b7b2b2;
    cursor: pointer;
}
.span.chip > span.remove>img{
    width: 18px;
    height: 18px;
    cursor: pointer;
}