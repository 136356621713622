
/** ============= COMMON STYLE CLASSES  GLOBAL ================ **/
/* common colors */
.color-black {
  color: #000 !important;
}
.color-white {
  color: #fff !important;
}
.bg-color-black {
  background-color: #000 !important;
}
.bg-color-white {
  background-color: #fff !important;
}

/*  primary color */
.color-primary-light {
  color: #f06291 !important;
}
.bg-color-primary-light {
  color: #fff !important;
  background-color: #f06291 !important;
}
.color-primary-main {
  color: #e91e63 !important;
}
.bg-color-primary-main {
  color: #fff !important;
  background-color: #e91e63 !important;
}
.color-primary-dark {
  color: #c2185b !important;
}
.bg-color-primary-dark {
  color: #fff !important;
  background-color: #c2185b !important;
}

/*  secondary color */
.bg-color-secondary {
  color: #fff !important;
  background-color: #bdbdbd !important;
  border-color: #bdbdbd !important;
}
.color-secondary-light {
  color: #e0e0e0 !important;
}
.bg-color-secondary-light {
  color: #fff !important;
  background-color: #e0e0e0 !important;
}
.color-secondary-main {
  color: #757575 !important;
}
.bg-color-secondary-main {
  color: #fff !important;
  background-color: #757575 !important;
}
.color-secondary-dark {
  color: #616161 !important;
}
.bg-color-secondary-dark {
  color: #fff !important;
  background-color: #616161 !important;
} 

/*  tertiary color */
.color-tertiary-light {
  color: #4ec8db !important;
}
.bg-color-tertiary-light {
  color: #fff !important;
  background-color: #4ec8db !important;
}
.color-tertiary-main {
  color: #229E79 !important;
}
.bg-color-tertiary-main {
  color: #fff !important;
  background-color: #229E79 !important;
}
.color-tertiary-dark {
  color: #127a87 !important;
}
.bg-color-tertiary-dark {
  color: #fff !important;
  background-color: #127a87 !important;
} 

/* danger colors */
.color-danger {
  color: #f44336 !important;
}
.color-warning {
  color: #ff9800 !important;
}
.color-info {
  color: #2196f3 !important;
}
.color-success {
  color: #4caf50 !important;
}
.bg-color-danger {
  background-color: #f44336 !important;
}
.bg-color-warning {
  background-color: #ff9800 !important;
}
.bg-color-info {
  background-color: #2196f3 !important;
}
.bg-color-success {
  background-color: #4caf50 !important;
}

/* gary colors */
.color-gray-50 {
  color: #fafafa !important;
}
.bg-color-gray-50 {
  background-color: #fafafa !important;
}
.color-gray-100 {
  color: #f5f5f5 !important;
}
.bg-color-gray-100 {
  background-color: #f5f5f5 !important;
}
.color-gray-200 {
  color: #eeeeee !important;
}
.bg-color-gray-200 {
  background-color: #eeeeee !important;
}
.color-gray-300 {
  color: #e0e0e0 !important;
}
.bg-color-gray-300 {
  background-color: #e0e0e0 !important;
}

/*  text color */
.text-primary {
  color: rgba(0, 0, 0, 0.87) !important;
}
.text-secondary {
  color:rgba(0, 0, 0, 0.54) !important;
}
.text-disabled {
  color:rgba(0, 0, 0, 0.38) !important;
}
.text-hint {
  color: rgba(0, 0, 0, 0.38) !important;
}

/* common shadows */
.shadows-0 {
  box-shadow: none !important;
}
.shadows-50 {
  box-shadow: 0px 2px 1px -1px rgba(0,0,0,0.2),0px 1px 1px 0px rgba(0,0,0,0.14),0px 1px 3px 0px rgba(0,0,0,0.12) !important;
}
.shadows-100 {
  box-shadow: 0px 3px 1px -2px rgba(0,0,0,0.2),0px 2px 2px 0px rgba(0,0,0,0.14),0px 1px 5px 0px rgba(0,0,0,0.12) !important;
}
.shadows-200 {
  box-shadow: 0px 3px 3px -2px rgba(0,0,0,0.2),0px 3px 4px 0px rgba(0,0,0,0.14),0px 1px 8px 0px rgba(0,0,0,0.12) !important;
}
.shadows-300 {
  box-shadow: 0px 2px 4px -1px rgba(0,0,0,0.2),0px 4px 5px 0px rgba(0,0,0,0.14),0px 1px 10px 0px rgba(0,0,0,0.12) !important;
}

/* shape */
.shape-borderRadius {
  border-radius: 0.125rem !important;
}

/* typography */
.typography-htmlFontSize {
  font-size: 16px !important;
}
.typography-fontSize {
  font-size: 14px !important;
}
.typography-fontWeightThin {
  font-weight: 100 !important;
}
.typography-fontWeightLight {
  font-weight: 300 !important;
}
.typography-fontWeightRegular {
  font-weight: 500 !important;
}
.typography-fontWeightBold {
  font-weight: 700 !important;
}

/* zIndex */
.zIndex-mobileStepper {
  z-index: 1000 !important;
}
.zIndex-speedDial {
  z-index: 1050 !important;
}
.zIndex-appBar {
  z-index: 1100 !important;
}
.zIndex-modal {
  z-index: 1300 !important;
}
.zIndex-tooltip {
  z-index: 1500 !important;
}

/*  bootstrap classes */
/* basic button  */
.btn {
  border-radius: 0.125rem ;
}

/**  primary button */
.btn-primary {
  color: #fff;
  background-color: #e91e63;
  border-color: #e91e63;
}

.btn-prqqimary:hover {
  color: #fff;
  background-color: #f06291;
  border-color: #f36091;
}

.btn-primary:focus, .btn-primary.focus {
  color: #fff;
  background-color: #f06291;
  border-color: #f36091;
  box-shadow: 0 0 0 0.2rem rgba(233, 30, 99, 0.5);
}

.btn-primary.disabled, .btn-primary:disabled {
  color: #fff;
  background-color: #e91e63;
  border-color: #e91e63;
}

.btn-primary:not(:disabled):not(.disabled):active, .btn-primary:not(:disabled):not(.disabled).active,
.show > .btn-primary.dropdown-toggle {
  color: #fff;
  background-color: #e91e63;
  border-color: #c2185b;
}

.btn-primary:not(:disabled):not(.disabled):active:focus, .btn-primary:not(:disabled):not(.disabled).active:focus,
.show > .btn-primary.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(233, 30, 99, 0.5);
}

/*  secondary button */
.btn-secondary {
  color: #fff;
  background-color: #757575;
  border-color: #757575;
}

.btn-secondary:hover {
  color: #fff;
  background-color: #e0e0e0;
  border-color: #757575;
}

.btn-secondary:focus, .btn-secondary.focus {
  color: #fff;
  background-color: #5a6268;
  border-color: #545b62;
  box-shadow: 0 0 0 0.2rem rgba(117, 117, 117, 0.5);
}

.btn-secondary.disabled, .btn-secondary:disabled {
  color: #fff;
  background-color: #e0e0e0;
  border-color: #e0e0e0;
}

.btn-secondary:not(:disabled):not(.disabled):active, .btn-secondary:not(:disabled):not(.disabled).active,
.show > .btn-secondary.dropdown-toggle {
  color: #fff;
  background-color: #545b62;
  border-color: #4e555b;
}

.btn-secondary:not(:disabled):not(.disabled):active:focus, .btn-secondary:not(:disabled):not(.disabled).active:focus,
.show > .btn-secondary.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(117, 117, 117, 0.5);
}

/*  tertiary button */
.btn-tertiary {
  color: #fff !important;
  background-color: #229E79 !important;
  border-color: #229E79 !important;
}

.btn-tertiary:hover {
  color: #fff !important;
  background-color: #19b2cb !important;
  border-color: #229E79 !important;
}

.btn-tertiary:focus, .btn-tertiary.focus {
  color: #fff !important;
  background-color: #19b2cb !important;
  border-color: #148e9e !important;
  box-shadow: 0 0 0 0.2rem rgba(23, 162, 184, 0.5) !important;
}

.btn-tertiary.disabled, .btn-tertiary:disabled {
  color: #fff !important;
  background-color: #19b2cb !important;
  border-color: #19b2cb !important;
}

.btn-tertiary:not(:disabled):not(.disabled):active, .btn-tertiary:not(:disabled):not(.disabled).active,
.show > .btn-tertiary.dropdown-toggle {
  color: #fff !important;
  background-color: #229E79 !important;
  border-color: #19b2cb !important;
}

.btn-tertiary:not(:disabled):not(.disabled):active:focus, .btn-tertiary:not(:disabled):not(.disabled).active:focus,
.show > .btn-tertiary.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(23, 162, 184, 0.5) !important;
}

/*  success button */
.btn-success {
  color: #fff;
  background-color: #4caf50;
  border-color: #4caf50;
}

.btn-success:hover {
  color: #fff;
  background-color: #66bb69;
  border-color: #4caf50;
}

.btn-success:focus, .btn-success.focus {
  color: #fff;
  background-color: #66bb69;
  border-color: #4caf50;
  box-shadow: 0 0 0 0.2rem rgba(76, 175, 80, 0.5);
}

.btn-success.disabled, .btn-success:disabled {
  color: #fff;
  background-color: #4caf50;
  border-color: #4caf50;
}

.btn-success:not(:disabled):not(.disabled):active, .btn-success:not(:disabled):not(.disabled).active,
.show > .btn-success.dropdown-toggle {
  color: #fff;
  background-color: #4caf50;
  border-color: #1c7430;
}

.btn-success:not(:disabled):not(.disabled):active:focus, .btn-success:not(:disabled):not(.disabled).active:focus,
.show > .btn-success.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(76, 175, 80, 0.5);
}

/* info button */
.btn-info {
  color: #fff;
  background-color: #2194f3;
  border-color: #2194f3;
}

.btn-info:hover {
  color: #fff;
  background-color: #42a4f5;
  border-color: #1f87e5;
}

.btn-info:focus, .btn-info.focus {
  color: #fff;
  background-color: #42a4f5;
  border-color: #1f87e5;
  box-shadow: 0 0 0 0.2rem rgba(33, 148, 243, 0.5);
}

.btn-info.disabled, .btn-info:disabled {
  color: #fff;
  background-color: #2194f3;
  border-color: #2194f3;
}

.btn-info:not(:disabled):not(.disabled):active, .btn-info:not(:disabled):not(.disabled).active,
.show > .btn-info.dropdown-toggle {
  color: #fff;
  background-color: #1f87e5;
  border-color: #1a75d2;
}

.btn-info:not(:disabled):not(.disabled):active:focus, .btn-info:not(:disabled):not(.disabled).active:focus,
.show > .btn-info.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(33, 148, 243, 0.5);
}

/*  warning button */
.btn-warning {
  color: #212529;
  background-color: #ff9800;
  border-color: #ff9800;
}

.btn-warning:hover {
  color: #212529;
  background-color: #ffa826;
  border-color: #fb8d00;
}

.btn-warning:focus, .btn-warning.focus {
  color: #212529;
  background-color: #ffa826;
  border-color: #fb8d00;
  box-shadow: 0 0 0 0.2rem rgba(255, 152, 0, 0.5);
}

.btn-warning.disabled, .btn-warning:disabled {
  color: #212529;
  background-color: #ff9800;
  border-color: #ff9800;
}

.btn-warning:not(:disabled):not(.disabled):active, .btn-warning:not(:disabled):not(.disabled).active,
.show > .btn-warning.dropdown-toggle {
  color: #212529;
  background-color: #fb8d00;
  border-color: #f57d00;
}

.btn-warning:not(:disabled):not(.disabled):active:focus, .btn-warning:not(:disabled):not(.disabled).active:focus,
.show > .btn-warning.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(255, 152, 0, 0.5);
}

/*  danger button */
.btn-danger {
  color: #fff;
  background-color: #f44336;
  border-color: #f44336;
}

.btn-danger:hover {
  color: #fff;
  background-color: #ef5350;
  border-color: #e53935;
}

.btn-danger:focus, .btn-danger.focus {
  color: #fff;
  background-color: #ef5350;
  border-color: #e53935;
  box-shadow: 0 0 0 0.2rem rgba(244, 67, 54, 0.5);
}

.btn-danger.disabled, .btn-danger:disabled {
  color: #fff;
  background-color: #f44336;
  border-color: #f44336;
}

.btn-danger:not(:disabled):not(.disabled):active, .btn-danger:not(:disabled):not(.disabled).active,
.show > .btn-danger.dropdown-toggle {
  color: #fff;
  background-color: #e53935;
  border-color: #d32f2f;
}

.btn-danger:not(:disabled):not(.disabled):active:focus, .btn-danger:not(:disabled):not(.disabled).active:focus,
.show > .btn-danger.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(244, 67, 54, 0.5);
}

/* light button */
.btn-light {
  color: #212529;
  background-color: #f8f9fa;
  border-color: #f8f9fa;
}

.btn-light:hover {
  color: #212529;
  background-color: #e2e6ea;
  border-color: #dae0e5;
}

.btn-light:focus, .btn-light.focus {
  color: #212529;
  background-color: #e2e6ea;
  border-color: #dae0e5;
  box-shadow: 0 0 0 0.2rem rgba(216, 217, 219, 0.5);
}

.btn-light.disabled, .btn-light:disabled {
  color: #212529;
  background-color: #f8f9fa;
  border-color: #f8f9fa;
}

.btn-light:not(:disabled):not(.disabled):active, .btn-light:not(:disabled):not(.disabled).active,
.show > .btn-light.dropdown-toggle {
  color: #212529;
  background-color: #dae0e5;
  border-color: #d3d9df;
}

.btn-light:not(:disabled):not(.disabled):active:focus, .btn-light:not(:disabled):not(.disabled).active:focus,
.show > .btn-light.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(216, 217, 219, 0.5);
}

/* dark button */
.btn-dark {
  color: #fff;
  background-color: #343a40;
  border-color: #343a40;
}

.btn-dark:hover {
  color: #fff;
  background-color: #23272b;
  border-color: #1d2124;
}

.btn-dark:focus, .btn-dark.focus {
  color: #fff;
  background-color: #23272b;
  border-color: #1d2124;
  box-shadow: 0 0 0 0.2rem rgba(82, 88, 93, 0.5);
}

.btn-dark.disabled, .btn-dark:disabled {
  color: #fff;
  background-color: #343a40;
  border-color: #343a40;
}

.btn-dark:not(:disabled):not(.disabled):active, .btn-dark:not(:disabled):not(.disabled).active,
.show > .btn-dark.dropdown-toggle {
  color: #fff;
  background-color: #1d2124;
  border-color: #171a1d;
}

.btn-dark:not(:disabled):not(.disabled):active:focus, .btn-dark:not(:disabled):not(.disabled).active:focus,
.show > .btn-dark.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(82, 88, 93, 0.5);
}



/*** ============ CUSTOM SWITCH [B]=================== */
.custom-switch {
  min-height: 0 !important;
}

.custom-control-label {
  display: flex;
  align-items: center;
}

.custom-switch .custom-control-label::before {
  border: 0;
  background-color: #c4c4c4;
  height: 0.6rem;
  top: 0.4rem;
  width: 1.65rem !important;
  cursor: pointer;
}

.custom-switch .custom-control-label::after {
  top: calc(0.4rem - 2.5px) !important;
  left: calc(-2.5rem + 3.5px) !important;
  width: calc(0.6rem + 5px) !important;
  height: calc(0.6rem + 5px) !important;
  background-color: #a2a2a2 !important;
  cursor: pointer;
}

.custom-switch.loading .custom-control-label::before,
.custom-switch.loading .custom-control-label::after {
  cursor: wait;
}

.custom-switch .custom-control-input:checked ~ .custom-control-label::before {
  background-color: #229e79 !important;
}
.custom-switch .custom-control-input:checked ~ .custom-control-label::after {
  background-color: #0B7455 !important;
}
.custom-switch .custom-control-input:focus ~ .custom-control-label::before {
  border: 0 !important;
  box-shadow: 0px 3px 3px -2px rgba(0, 0, 0, 0.2),
    0px 3px 4px 0px rgba(0, 0, 0, 0.14), 0px 1px 8px 0px rgba(0, 0, 0, 0.12);
}

.custom-switch .custom-control-input:disabled ~ .custom-control-label::before ,
.custom-switch .custom-control-input:disabled ~ .custom-control-label::after {
  opacity: 0.3 !important;
  /* cursor: not-allowed; */
  pointer-events: none !important;
}
/*** ============ CUSTOM SWITCH [E]=================== */

/*** ============ CUSTOM RADIO [B]=================== */
.custom-radio .custom-control-label::before {
  border: #f85a94 solid 2px !important;
}

.custom-radio .custom-control-input:checked ~ .custom-control-label::before {
background: none !important;
}

.custom-radio .custom-control-input:checked ~ .custom-control-label::after {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='16' height='16' viewBox='-5 -5 10 10'%3e%3ccircle r='5' fill='%23f85a94'/%3e%3c/svg%3e") !important; 
}

.custom-radio .custom-control-input:focus ~ .custom-control-label::before {
  border: #f85a94 solid 2px !important;
  box-shadow: 0px 3px 3px -2px rgba(0, 0, 0, 0.2),
    0px 3px 4px 0px rgba(0, 0, 0, 0.14), 0px 1px 8px 0px rgba(0, 0, 0, 0.12);
} 

.custom-radio .custom-control-input:not(:disabled):active ~ .custom-control-label::before {
  background-color: #f85a945d !important;
}
/*** ============ CUSTOM RADIO [E]=================== */

/*** ============ CUSTOM SCROLL BAR [S]============== */
.custom-scrollbar {
  scrollbar-color: #00000033 transparent;
  scrollbar-width: thin;
}

.custom-scrollbar::-webkit-scrollbar-track {
  border-radius: 6px;
  background: transparent;
}

.custom-scrollbar::-webkit-scrollbar {
  width: 12px;
}

.custom-scrollbar::-webkit-scrollbar-thumb {
  background-color: #00000033;
  border-radius: 10px;
  border: 2px solid #fff;
}
/*** ============ CUSTOM SCROLL BAR [E]============== */

/*** ============ LOGIN [S]============== */
.loginWrapper{
  display: block;
  margin: 80px auto;
  background-color: white;
  -webkit-box-shadow: 0px 4px 5px 0px rgba(0, 0, 0, 0.18);
  -moz-box-shadow: 0px 4px 5px 0px rgba(0, 0, 0, 0.18);
  box-shadow: 0px 4px 5px 0px rgba(0, 0, 0, 0.18);
  border-radius: 0;
  min-width: 390px !important;
  max-width: 390px;
  min-height: 400px;
  max-height: 400px;
  padding: 30px;
}
.loginWrapper h3{
  margin: 0px ;
  font-size: 1.50rem;
  font-weight: 600;
}
.loginWrapper .loginContent{
  margin-top: 30px ;
  margin-bottom: 20px ;
}
.fixed-form-height{
  min-height: 81px;
  margin-bottom: 1px !important;
}
.fixed-form-height-pass{
  min-height: 86px;
  margin-bottom: 1px !important;
}
.pass-show-div{
  position: absolute;
  /* top: 202px; */
}
.btn-show{
  padding: 0px;
  height: 18px;
  width: 37px;
  font-size: 11px;
  font-weight: 200;
}
.loginWrapper .loginContent input{
  border-radius: 3px;
  border: solid 1px lightgrey;
  font-size: 12px;
}
.loginWrapper .forgotpass{
  float: right;
  font-size: 13px;
  padding: 2px 0px;
  margin-top: -2px;
  color: #229E79 !important;
}
.loginWrapper .checkmark{
  padding-left: 7px;
}
.loginWrapper .bluecolor{
  background-color: #229E79;
}
.loginWrapper .signup{
  padding-left: 4px;
}
.loginWrapper .loginContent .loginWrap{
  font-size: 12px;
  margin-top: 20px;
}
.loginWrapper .loginContent .loginWrap span{
  color: #229E79;
}
.checkbox{
  border-radius: 1px;
}
/*** ============ LOGIN [E]============== */
.pointer{
  cursor: pointer;
}
/*** ============ CUSTOM CHECKBOX [B]=================== */
.custom-checkbox .custom-control-label::before {
  display: inline-block; 
  background: white;
  position: absolute;
  border: 1px solid #b9b9b9 !important;
  border-radius: 0 !important;
}

.custom-checkbox  input {
  display: none !important;
}

.custom-checkbox .custom-control-input:checked ~ .custom-control-label::before {
  background-color: #707070;
  border: 1px solid #707070;
}

.custom-checkbox .custom-control-input:checked ~ .custom-control-label::after {
  content: "";
  position: absolute;
  height: 6px;
  width: 10px;
  border-left: 2px solid white;
  border-bottom: 2px solid white;
  top: 10px;
  left: -1rem;
  transform: translate(-50%, -50%) rotate(-45deg);
}

.custom-checkbox .custom-control-input:focus ~ .custom-control-label::before {
  border: #f85a94 solid 2px !important;
  box-shadow: 0px 3px 3px -2px rgba(0, 0, 0, 0.2),
    0px 3px 4px 0px rgba(0, 0, 0, 0.14), 0px 1px 8px 0px rgba(0, 0, 0, 0.12);
} 

.custom-checkbox .custom-control-input:not(:disabled):active ~ .custom-control-label::before {
  background-color: #f85a945d !important;
}
.custom-checkbox .custom-control-input:disabled:checked ~ .custom-control-label::before {
  background-color: #70707077 !important;
}
/** pending disable css */
/*** ============ CUSTOM CHECKBOX [E]=================== */

.focusedRow:hover {
  background-color: #edf0f5;
  cursor: pointer;
}
